import { variables } from 'theme/variables';
import anime from 'animejs';

const {
  animation_easing
} = variables;

export const animations = {
  paragraph: function (target, o) {
    return {
      targets: target,
      opacity: [0, (o === true ? 0.7 : 1)],
      easing: 'linear',
      duration: 500
    }
  },
  button: function (target) {
    return {
      targets: target,
      opacity: {
        value: 1,
        duration: 200,
        easing: 'linear'
      },
      duration: 300,
      translateY: {
        value: ['40px', '0px'],
        duration: 600,
        easing: animation_easing
      }
    }
  },
  dashedLine: function (target) {
    return {
      targets: target,
      clipPath: ['inset(-2px 106px -2px 106px)', 'inset(-2px 0px -2px 0px)'],
      duration: 600,
      easing: animation_easing
    }
  },
  pipeBackground: function (target) {
    return {
      targets: target,
      opacity: 1,
      easing: 'linear',
      duration: 200,
      delay: anime.stagger(25)
    }
  },
  fadeIn: function(target) {
    return {
      targets: target,
      opacity: [0, 1],
      easing: 'linear',
      duration: 200
    }
  },
  fadeInUp: function(target) {
    return {
      targets: target,
      opacity: {
        value: [0, 1],
        easing: 'linear',
        duration: 100
      },
      translateY: {
        value: [50, 0],
        easing: animation_easing,
        duration: 300
      }
    }
  },
  fadeInLeft: function(target) {
    return {
      targets: target,
      opacity: {
        value: [0, 1],
        easing: 'linear',
        duration: 100
      },
      translateX: {
        value: [-50, 0],
        easing: animation_easing,
        duration: 300
      }
    }
  },
  fadeInRight: function(target) {
    return {
      targets: target,
      opacity: {
        value: [0, 1],
        easing: 'linear',
        duration: 100
      },
      translateX: {
        value: [50, 0],
        easing: animation_easing,
        duration: 300
      }
    }
  }
}
