import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Button } from '@zendeskgarden/react-buttons';
import PublicPage from 'components/PublicPages/PublicPage'

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';


const {
  color_white,
  media_md,
  media_lg,
  custom_button_blue,
  custom_pale_blue,
  public_font_size,
  public_font_size_sm,
  public_font_size_md,
  public_font_size_lg,
  public_font_size_xxl
} = variables;


export const BlogPostTemplatePage = styled(PublicPage)`

`
export const TopSection = styled(Section)`
  
  overflow: visible;
  padding-top: 0;
  

  h5 {
    color: ${color_white};
    margin-left: 16px;
    margin-bottom: 24px;
  }

  .blog-post-text {
      position: relative;
      top: 80px;
    }

  @media ${media_md} {
    padding-top: 0;

    h1 {
      padding: 0 48px;
      margin-bottom: 24px;
    }

    h5 {
      color: ${color_white};
      margin-left: 48px;
    }

    .blog-post-text {
      position: relative;
      top: 160px;
    }
  }

  @media ${media_lg} {
    padding-top: 0;
    padding-bottom: 376px;

     h1 {
      font-size: 58px;
      line-height: 66px;
      padding: 0 0;
      font-weight: bold;
      letter-spacing: 1.32px;
      width: 842px;
      margin-bottom: 24px;
    }

    h5 {
      font-size: 18px;
      line-height: 18px;
      font-weight: bold;
      color: ${color_white};
      margin-left: 0;
    }

    .blog-post-text {
      position: relative;
      top: 216px;
      left: 120px;
    }
  }
`

export const BlogPostSection = styled(Section)`
  padding-bottom: 80px;
  .view-more {
      position: relative;
      top: 208px;
    }
  @media ${media_md} {
    padding-bottom: 200px;
    .view-more {
      position: relative;
      top: 324px;
    }
  }

  @media ${media_lg} {
    padding-bottom: 200px;
    .view-more {
      position: relative;
      top: 324px;
    }
  }
`

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
`

export const HeroImg = styled.div`
  background-image: url("/images/blog-hero-image.jpg");
  background-size: cover;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
`

export const HeroOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 27, 64, 0.59);
`

export const BackgroundCircle = styled.div`
  overflow: hidden;
  position: absolute;
  height: 1160px;	
  width: 1160px;	
  border: 200px solid rgba(1, 90, 208, 0.05);
  border-radius: 50%;
`

export const BgCircleWrapper = styled.div`

  .left-circle {
    right: 77vw;
    bottom: 0;
    top: 728px;
    margin: 0 auto;
  }

  .right-circle {
    left: 79vw;
    bottom: 0;
    top: 20px;
    margin: 0 auto;
  }
`

export const ShareWrapper = styled.div`
  position: absolute;
  top: -48px;
  right: 0;
	height: 238px;
	width: 91px;
	border: 1px solid #EDEDED;
	border-radius: 10px;
	background-color: ${color_white};
  box-shadow: 0 2px 15px 4px rgba(4,5,8,0.01);

  .top-share {
    background-color: ${custom_pale_blue};
    color: ${color_white};
    position: relative;
    right: 1px;
    top: -1px;
    border-top-left-radius: 8px 8px;
    border-top-right-radius: 8px 8px;
    padding-top: 16px;
  }

  .facebook-share {
    margin-top: 24px;
    margin-bottom: -8px;
  }

  .twitter-share {
    margin-bottom: -8px;
  }

  .email-share {
    margin-bottom: -8px;
  }


  a {
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  @media ${media_md} {
    top: -48px;
    right: 24px;
  }

  @media ${media_lg} {
    margin-left: 120px;
    top: 316px;
    left: 0;
  }
`

export const ShareButton = styled.div`
  height: 48px;
  width: 91px;
  background-color: transparent;
  border: none;
  text-align: center;

  
`

export const DividingLine = styled.div`
  box-sizing: border-box;	
  height: 1px;	
  width: 210px;	
  border-bottom: 2px dotted rgba(65, 65, 65, 0.27);
  margin-bottom: 64px;
`

export const PostWrapper = styled.div`
  max-width: 800px;
  padding: 0 24px;

  p {
    font-size: ${public_font_size_md};
    line-height: 32px;
    text-align: center;
  }

  h2 {
    text-align: center;
  }

  h3 {
    margin-bottom: 16px;
    text-align: center;
  }

  .paragraph-spacing-small {
    margin-bottom: 32px;
  }

  @media ${media_md} {
    max-width: 800px;
    padding: 0 48px;

    p {
      text-align: left;
    }

    h2 {
      text-align: left;
    }

    h3 {
      text-align: left;
      font-size: ${public_font_size_lg};
      line-height: 56px;
      font-weight: bold;
      margin-bottom: 16px;
    }
  }

  @media ${media_lg} {
    p {
    font-size: ${public_font_size_md};
    line-height: 32px;
  }

  h3 {
    font-size: ${public_font_size_lg};
    line-height: 56px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .paragraph-spacing-small {
    margin-bottom: 32px;
  }
  }
`

export const Oval = styled.div`
  display: inline-block;

  margin: 0 10px 0 0;
	height: 14px;
  width: 14px;
  border-radius: 50%;
	border: 2px solid #B0BED1;
`

export const List = styled.ul`
  margin-left: 40px;
  list-style-type: none;

  li {
    margin-bottom: 16px;
  }

  p {
    font-size: ${public_font_size_md};
    line-height: 32px;
    display: inline;
    padding: 8px 0;
  }
`

export const LongDividingLine = styled.div`
	box-sizing: border-box;
	height: 1px;
	width: 100%;
  border: 1px solid #F2F2F2;
  margin: 80px 0;
  
  @media ${media_md} {
    margin: 160px 0;
  }

  @media ${media_lg} {
    margin: 160px 0;
    width: 100%;
  }
`

export const RecentPostsWrapper = styled.div`
  max-width: 900px;
`

export const CardWrapper = styled.div`
 display: block;
  position: relative;

  @media ${media_md} {
    display: block;
    position: relative;
  }

  @media ${media_lg} {
    display: inline-block;
    position: relative;
  }
`

export const PostCard = styled.div`
    position: absolute;
    z-index: 10;
    top: 192px;
    left: 50%;
    transform: translatex(-50%);
    height: 161px;
    width: 264px;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background-color: ${color_white};

  @media ${media_md} {
    position: absolute;
    z-index: 10;
    top: 192px;
    left: 50%;
    transform: translatex(-50%);
    height: 161px;
    width: 276px;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background-color: ${color_white};
  }

  @media ${media_lg} {
    position: absolute;
    z-index: 10;
    top: 192px;
    left: 0;
    transform: translatex(0);
    margin: 24px;
    height: 161px;
    width: 296px;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background-color: ${color_white};
  }
`

export const PostContentWrapper = styled.div`
  padding: 16px 20px;

  h6 {
    font-size: ${public_font_size_md};
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 0;
  }

  .date {
    font-size: ${public_font_size_sm};
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  a {
    position: relative;
    left: 0;
    transition: left ease 0.35s;
    color: ${custom_button_blue}

    &:hover {
      text-decoration: none;
      left: 6px;
    }
  }

  @media ${media_md} {
    h6 {
    font-size: ${public_font_size};
    line-height: 32px;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 0;
  }

  .date {
    font-size: ${public_font_size_sm};
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  a {
    position: relative;
    left: 0;
    transition: left ease 0.35s;
    color: ${custom_button_blue}

    &:hover {
      text-decoration: none;
      left: 6px;
    }
  }
  }
`

export const PostImg = styled.img`
  position: relative;
  max-width: 300px;
  height: 300px;
  width: 300px;
  margin-bottom: 96px;
  border-radius: 8px;	
  box-shadow: 0 35px 30px -12px rgba(48,65,89,0.25);

  @media ${media_md} {
    margin-bottom: 96px;
    height: 300px;
    width: 300px;
  }

  @media ${media_lg} {
    max-width: 344px;
    margin-bottom: 96px;
    height: 344px;
    width: 344px;
  }
`

export const PostsWrapper = styled.div`
    max-width: 100%;
    width: 100vw;
    margin: 0 0 0 0;
    position: relative;
    top: 0px;

    h2 {
      margin-bottom: 64px;
      text-align: center;
    }

    .left-image {
      left: 50%;
      transform: translateX(-50%);
    }

    .middle-image {
      left: 50%;
      transform: translateX(-50%);
    }

    .right-image {
      left: 50%;
      transform: translateX(-50%);
    }
  
  @media ${media_md} {
    max-width: 1216px;
    width: 100vw;
    margin: 0 0 0 0;
    position: relative;
    top: 0;

    h2 {
      font-size: ${public_font_size_xxl};
      line-height: 48px;
      margin-bottom: 64px;
      text-align: center;
    }

    .left-image {
      left: 50%;
      transform: translateX(-50%);
    }

    .middle-image {
      left: 50%;
      transform: translateX(-50%);
    }

    .right-image {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media ${media_lg} {
    max-width: 1216px;
    margin: 0 0 0 0;
    position: relative;
    top: 0;

    h2 {
      font-size: ${public_font_size_xxl};
      line-height: 48px;
      margin-bottom: 80px;
      text-align: left;
    }

   
  }
`

export const ServiceBlock = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: 48px;

  @media ${media_md} {
    position: absolute;
    right: 64px;
    top: 20px;
  }

  @media ${media_lg} {
    position: relative;
    float: right;
    top: 20px;
    right: 16px;
  }
`

export const HeaderWrapper = styled.div`
  position: relative:
  top: 216px;
`
