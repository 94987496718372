// OVERIDE CSS
import {variables} from 'theme/variables';
const {
  custom_blue,
  font_weight_regular,
  font_size_sm,
  custom_grey
} = variables;

const MenuTheme = {
  'checkboxes.label': props => {
    return `
      &:before {
        outline: none;
        box-shadow: none;
      }
      ${props.inverted ? `
        padding-right: 24px;
        padding-left: 0;
        &:before {
          left: auto !important;
          right: 0 !important;
        }
      ` : ''}
      ${props.outline ? `
        &:before {
          top: 11px;
          background-color: transparent;
          border: 1px solid ${props.disabled ? custom_grey : custom_blue} !important;
        }
      ` : ''}

      ${props.blue ? `
        color: ${custom_blue} !important;
      ` : ''}

      ${props.light ? `
        font-weight: ${font_weight_regular} !important;
      ` : ''}

      ${props.small ? `
        font-size: ${font_size_sm} !important;
        &:before {
          top: 9px;
        }
      ` : ''}

      ${props.disabled ? `
        color: ${custom_grey} !important;
      ` : ''}
    `;
  },
  'checkboxes.checkbox_view': props => {
    return `
      .checkbox__c-chk__input___triCm:checked:enabled~.checkbox__c-chk__label___R6mUZ:not(.checkbox__is-disabled___10BbS):before, .checkbox__c-chk__input___triCm:enabled~.checkbox__c-chk__label___R6mUZ.checkbox__is-checked___2xZoq:not(.checkbox__is-disabled___10BbS):before, .checkbox__c-chk__input___triCm:enabled~.checkbox__c-chk__label___R6mUZ:not(.checkbox__c-chk__label--radio___1W4Mr):not(.checkbox__c-chk__label--toggle___1oiDF).checkbox__is-indeterminate___2uS-h:not(.checkbox__is-disabled___10BbS):before, .checkbox__c-chk__input___triCm:indeterminate:enabled~.checkbox__c-chk__label___R6mUZ:not(.checkbox__c-chk__label--radio___1W4Mr):not(.checkbox__c-chk__label--toggle___1oiDF):not(.checkbox__is-disabled___10BbS):before {
        border-color: ${custom_blue};
        background-color: ${custom_blue};
      }
    `;
  }
};

export default MenuTheme;
