import React from 'react';

import Icon from 'components/Icon/Icon';

import {
  ResultsWrapper,
  Section,
  DividingLine,
  TopWrapper,
  DocumentTable,
  TableWrapper,
  TableScrollWrapper
} from '../../ViewItemDetails.styles.js';

import {
  Table,
  Caption,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@zendeskgarden/react-tables';

import { variables } from 'theme/variables';
const {
  custom_button_blue
} = variables;

const DocumentationTable = (props) => {
  return (
    <DocumentTable>
      <TableWrapper>
        <TableScrollWrapper>
          <Table style={{minWidth: `1024px`}}>
            <Head>
              <HeaderRow className="table-header-row">
                <HeaderCell width="20%">Type</HeaderCell>
                <HeaderCell width="20%">Reference #</HeaderCell>
                <HeaderCell width="20%">Date</HeaderCell>
                <HeaderCell width="20%">Submitted By</HeaderCell>
                <HeaderCell width="10%">View</HeaderCell>
                <HeaderCell width="10%">Download</HeaderCell>
              </HeaderRow>
            </Head>
            <Body>
              <Row className="table-row">
                <Cell className="cell-font" width="20%">Order Release Document Package</Cell>
                <Cell className="cell-font" width="20%">RFQ548768</Cell>
                <Cell className="cell-font" width="20%">April 15, 2019</Cell>
                <Cell className="cell-font" width="20%">William Thomas</Cell>
                <Cell className="cell-font" width="10%"><Icon icon="icon-eye" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
                <Cell className="cell-font" width="10%"><Icon icon="icon-download" color={custom_button_blue} style={{position: `relative`, left: `23px`}}/></Cell>
              </Row>
              <Row className="table-row">
                <Cell className="cell-font" width="20%">Order Release Document Package</Cell>
                <Cell className="cell-font" width="20%">RFQ548768</Cell>
                <Cell className="cell-font" width="20%">April 15, 2019</Cell>
                <Cell className="cell-font" width="20%">William Thomas</Cell>
                <Cell className="cell-font" width="10%"><Icon icon="icon-eye" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
                <Cell className="cell-font" width="10%"><Icon icon="icon-download" color={custom_button_blue} style={{position: `relative`, left: `23px`}}/></Cell>
              </Row>
              <Row className="table-row">
                <Cell className="cell-font" width="20%">Order Release Document Package</Cell>
                <Cell className="cell-font" width="20%">RFQ548768</Cell>
                <Cell className="cell-font" width="20%">April 15, 2019</Cell>
                <Cell className="cell-font" width="20%">William Thomas</Cell>
                <Cell className="cell-font" width="10%"><Icon icon="icon-eye" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
                <Cell className="cell-font" width="10%"><Icon icon="icon-download" color={custom_button_blue} style={{position: `relative`, left: `23px`}}/></Cell>
              </Row>
            </Body>
          </Table>
        </TableScrollWrapper>
      </TableWrapper>
    </DocumentTable>
  )
}

export default DocumentationTable;
