import React from 'react';
import styled from 'styled-components';
import {variables} from 'theme/variables';
const {
  media_sm,
  media_md,
  media_lg,
} = variables;


function generateStyle(Type = 'div') {
  return styled(Type)`
    display: ${props => props.mobile ? 'inherit' : 'none'};
    @media ${media_sm} {
      ${props => props.tablet ? 'display: block' : 'display: none'};
    }
    @media ${media_md} {
      ${props => props.desktop ? 'display: inherit' : props.tablet ? 'display: none' : ''};
    }
    @media ${media_lg} {
      ${props => props.desktop ? 'display: inherit' : props.tablet ? 'display: none' : props.mobile ? 'display: none' : ''};       
    }
  `
}

 
const Platform = (props) => {  
  const Type = generateStyle(props.component)// === 'Col' ? PlatformCol : PlatformComponent;
  return (
    <Type {...props}>
      {props.children}
    </Type>
  )
}


export default Platform
