import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import BlogHeader from './components/TopSection/TopSection.js'
import BlogPost from './components/BlogPost/BlogPost.js'
import GetStarted from 'components/PublicPages/GetStarted';


import {
  BlogPostTemplatePage
} from './BlogPostTemplate.styles';


const BlogPostTemplate = () => {

  return (
    <BlogPostTemplatePage style={{ padding: `0`, overflow: `hidden` }}>
      <BlogHeader />
      <BlogPost />
      <GetStarted />
    </BlogPostTemplatePage>
  )
}


export default PageViewWrapper(BlogPostTemplate, { pageName: 'BlogPostTemplate' })