import {variables} from 'theme/variables';
const {
  line_height_md,
  spacing_sm,
  spacing_md,
  spacing_lg,
  spacing_xl,
  spacing_xxl,
  font_weight_regular,
  font_weight_semibold,
  font_weight_medium,
  spacing_xs,
  spacing_xxs,
  font_weight_bold,
  custom_grey,
  custom_black,
  custom_blue,
  color_white,
  custom_orange,
  color_red_400,
  custom_light_grey,
  font_size
} = variables;

const TypographyTheme = {
  'typography.xxxl': props => {
    let styles = typographyBaseStyles(props)
     return `
      ${styles}
    `;
  },
  'typography.xxl': props => {
    let styles = typographyBaseStyles(props)
     return `
      ${styles}
    `;
  },
  'typography.xl': props => {
    let styles = typographyBaseStyles(props)
     return `
      ${styles}
    `;
  },
  'typography.lg': props => {
    let styles = typographyBaseStyles(props, 'LG')
     return `
     
      ${styles}
    `;
  },
  'typography.md': props => {
    let styles = typographyBaseStyles(props, 'MD')
     return `
      ${styles}
    `;
  },
  'typography.sm': props => {
    let styles = typographyBaseStyles(props, 'SM')
    return `
      ${styles}
    `;
  }
};

function typographyBaseStyles(props, size) {
  let tt = "";
  if (props.overflowTip && props.ellipsis && (typeof props.children  === 'string' || typeof props.children  === 'number')) {
    tt = props.children || ""
  } 

  return `
    color: ${custom_black};
    ${props.center ? `
      text-align: center;
    ` : ''}

    ${props.right ? `
      text-align: right;
    ` : ''}

    ${props.borderBottom ? `
      border-bottom: 1px solid ${custom_light_grey};
    ` : ''}

    ${props.paddingHorizontalSm ? `
      padding-left: ${spacing_sm};
      padding-right: ${spacing_sm};
    ` : ''}

    ${props.paddingHorizontal ? `
      padding-left: ${spacing_md};
      padding-right: ${spacing_md};
    ` : ''}

    ${props.paddingVertical ? `
      padding-top: ${spacing_md};
      padding-bottom: ${spacing_md};
      ${props.borderBottom ? `margin-bottom: ${spacing_md};` : ''}
    ` : ''}

    ${props.paddingTopXxs ? `
      padding-top: ${spacing_xxs};
    ` : ''}

    ${props.paddingTopXs ? `
      padding-top: ${spacing_xs};
    ` : ''}

    ${props.paddingTopSm ? `
      padding-top: ${spacing_sm};
    ` : ''}

     ${props.paddingTopMd ? `
      padding-top: ${spacing_md};
    ` : ''}

    ${props.paddingTopLg ? `
      padding-top: ${spacing_lg};
    ` : ''}

    ${props.paddingTopXl ? `
      padding-top: ${spacing_xl};
    ` : ''}

    ${props.paddingTopXXl ? `
      padding-top: ${spacing_xxl};
    ` : ''}

    ${props.paddingBottomXxs ? `
      padding-bottom: ${spacing_xxs};
      ${props.borderBottom ? `margin-bottom: ${spacing_xs};` : ''}
    ` : ''}

    ${props.paddingBottomXs ? `
      padding-bottom: ${spacing_xs};
      ${props.borderBottom ? `margin-bottom: ${spacing_sm};` : ''}
    ` : ''}

    ${props.paddingBottomSm ? `
      padding-bottom: ${spacing_sm};
      ${props.borderBottom ? `margin-bottom: ${spacing_md};` : ''}
    ` : ''}

     ${props.paddingBottomMd ? `
      padding-bottom: ${spacing_md};
      ${props.borderBottom ? `margin-bottom: ${spacing_lg};` : ''}
    ` : ''}

    ${props.paddingBottomLg ? `
      padding-bottom: ${spacing_lg};
      ${props.borderBottom ? `margin-bottom: ${spacing_xl};` : ''}
    ` : ''}

    ${props.paddingBottomXl ? `
      padding-bottom: ${spacing_xl};
      ${props.borderBottom ? `margin-bottom: ${spacing_xxl};` : ''}
    ` : ''}

    ${props.paddingBottomXXl ? `
      padding-bottom: ${spacing_xxl};
      ${props.borderBottomm ? `marging-bottom: ${spacing_xxl};` : ''}
    ` : ''}

    ${props.color ? `
      color: ${props.color};
    ` : ''}

    ${props.grey ? `
      color: ${custom_grey};
    ` : ''}

    ${props.white ? `
      color: ${color_white};
    ` : ''}

    ${props.blue ? `
      color: ${custom_blue};
    ` : ''}

    ${props.bold ? `
      font-weight: ${font_weight_semibold};
    ` : ''}

    ${props.semibold ? `
      font-weight: ${font_weight_medium};
    ` : ''}

    ${props.black ? `
      font-weight: ${font_weight_bold};
    ` : ''}

     ${props.light ? `
      font-weight: ${font_weight_regular};
    ` : ''}

    ${props.uppercase ? `
      text-transform: uppercase;
    ` : ''}

    ${props.alignCenter ? `
      display: flex !important;
      align-items: center !important;
    ` : ''}

    ${props.justifyEnd ? `
      display: flex !important;
      justify-content: flex-end !important;
    ` : ''}

    ${props.justifyStart ? `
      display: flex !important;
      justify-content: flex-start !important;
    ` : ''}

    ${props.xs ? `
      font-size: 10px !important;
    ` : ''}

    ${size === 'SM' && props.paragraph ? `
      line-height: ${line_height_md} !important;
    ` : ''}

    ${size === 'MD' && props.scaled ? `
      font-size: ${font_size} !important;
    ` : ''}

    ${props.lhInherit  ? `
      line-height: inherit !important;
    ` : ''}

    ${props.ellipsis ? `
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      ${props.overflowTip ? `
        &:after {
          content:'';
          opacity: 0;
          transition: all 0.1s 0s ease;
        }
        &:hover:after {
          transition: all 0.1s 800ms ease;
          content:'${tt}';
          opacity: 1;
          overflow: visible;
          text-overflow: inherit;
          background: #fff;
          position: absolute;
          left:auto;
          top:auto;
          width: auto;
          max-width: 20rem;
          border: 1px solid #eaebec;
          border-radius: 2px;
          padding: 15px;
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
          white-space: normal;
          word-wrap: break-word;
          display:block;
          color:black;
          margin-top:-1.25rem;
          z-index: 22;
        }
      ` : ''}
    ` : ''}

    ${props.headline ? `
      line-height: 1.5em !important;
    ` : ''}

    ${props.subheadline ? `
      line-height: 2em !important;
    ` : ''}

    ${props.pointer ? `
      cursor: pointer !important;
    ` : ''}

    ${props.tab ? `
      letter-spacing: 0.3px;
      &:hover {
        color: ${custom_blue};
      }
      ${props.active ? `
        color: ${custom_blue};
        font-weight: ${font_weight_semibold};
        letter-spacing: 0;
      ` : ''}

    ` : ''}

     ${props.link ? `
      color: ${props.link === 'warning' ? custom_orange : props.link === 'error' ? color_red_400 : custom_blue};      
      cursor: pointer;
      transition: opacity 0.2s ease;
      opacity: 1;
      &:hover {
        opacity: 0.7;
      }
    ` : ''}

    ${props.error ? `
      color: ${color_red_400} !important;
    ` : ''}

    ${props.fieldLabel ? `
      position: absolute;
      right: 0px;
      display: inline;
      top: 50%;
      color: #ccc;
      margin-top: -8px;
    ` : ''}

    span.light {
      color: ${custom_grey};
      font-weight: ${font_weight_regular}
    }
  `;
}

export default TypographyTheme;