import React, { useState, useEffect, useRef } from 'react';
import {
  MobileMenuComponent,
  MobileMenuButton,
  Menu,
  MenuItem,
  Dropdown,
} from './MobileMenu.styles';
import Icon from 'components/Icon/Icon';
import { Link } from 'react-router-dom';
import anime from 'animejs';

const MobileMenu = (props) => {
  const animation1 = useRef();
  const animation2 = useRef();
  const [animationReversed, setReverse] = useState(false);
  const [submenuActive, setSubmenuActive] = useState(false);

  function toggleSubmenu() {
    setSubmenuActive(!submenuActive);
  }

  useEffect(() => {
    animation1.current = anime
      .timeline({
        autoplay: false,
      })
      .add(
        {
          targets: '.primary-mobile-menu li',
          opacity: {
            value: [0, 1],
            duration: 200,
            easing: 'linear',
          },
          delay: anime.stagger(50),
        },
        200
      )
      .add(
        {
          targets: '.buttons button',
          opacity: {
            value: [0, 1],
            duration: 200,
            easing: 'linear',
          },
          translateY: {
            value: ['40px', '0px'],
            duration: 400,
            easing: 'cubicBezier(0.220, 0.265, 0.085, 0.980)',
          },
          delay: anime.stagger(150),
        },
        200
      );

    animation2.current = anime
      .timeline({
        autoplay: false,
      })
      .add({
        targets: '.primary-mobile-menu li',
        opacity: {
          value: [1, 0],
          duration: 125,
          easing: 'linear',
        },
        direction: 'reverse',
        delay: anime.stagger(50),
      })
      .add({
        targets: '.buttons button',
        opacity: {
          value: [1, 0],
          duration: 100,
          easing: 'linear',
        },
        translateY: {
          value: ['0px', '40px'],
          duration: 400,
          easing: 'cubicBezier(0.220, 0.265, 0.085, 0.980)',
        },
        delay: anime.stagger(50),
      });

    return () => {
      animation1.current.pause();
      animation1.current = null;
      animation2.current.pause();
      animation2.current = null;
    };
  }, []);

  function activeAnimation() {
    animation1.current.play();
  }

  function inactiveAnimation() {
    animation2.current.play();
  }

  useEffect(() => {
    if (props.active) {
      activeAnimation();
    } else if (!props.active) {
      inactiveAnimation();
    }
  }, [props.active]);

  return (
    <MobileMenuComponent active={props.active}>
      <Menu active={props.active} className='primary-mobile-menu'>
        <ul>
          <MenuItem>
            <Link to='/'>Home</Link>
          </MenuItem>
          <MenuItem>
            <Link to='/find-inventory'>Find Inventory</Link>
          </MenuItem>
          <MenuItem>
            <Link to='/why-us'>Why Us</Link>
          </MenuItem>
          <MenuItem>
            <Link to='/services'>Services</Link>
          </MenuItem>
          <MenuItem hasDropdown>
            <span onClick={toggleSubmenu}>Technical Resources</span>
            <Dropdown active={submenuActive}>
              <ul>
                <MenuItem>
                  <Link to='/blog'>Blog</Link>
                </MenuItem>
                <MenuItem>
                  <Link to='/faq'>FAQ</Link>
                </MenuItem>
                <MenuItem>
                  <Link to='/help-center'>Help Center</Link>
                </MenuItem>
              </ul>
            </Dropdown>
          </MenuItem>
          <MenuItem>
            <Link to='/contact'>Contact</Link>
          </MenuItem>
          <MenuItem className='search'>
            <Link to='/'>
              Search Our Site <Icon icon='icon-search-left' />
            </Link>
          </MenuItem>
        </ul>
      </Menu>

      <div className='buttons'>
        <MobileMenuButton publicButton>Get Started</MobileMenuButton>
        <MobileMenuButton white publicButton>
          Sign In
        </MobileMenuButton>
      </div>

      <svg className='menu-background'>
        <circle
          className='circle-1'
          r='288'
          fill='none'
          strokeWidth='100px'
          stroke='rgba(255, 255, 255, 0.02)'
          cx='-110'
          cy='270'
        ></circle>
        <circle
          className='circle-2'
          r='288'
          fill='none'
          strokeWidth='100px'
          stroke='rgba(255, 255, 255, 0.02)'
          cx='500'
          cy='690'
        ></circle>
      </svg>
    </MobileMenuComponent>
  );
};

export default MobileMenu;
