import React, { useImperativeHandle, forwardRef, useRef } from 'react'
import styled from 'styled-components';
import anime from 'animejs';
const Wrapper = styled.div`
  .word {
    overflow: hidden;
    display: inline-block;
    
    .inner {
      transform: translateY(75px);
      opacity: 0;
      display: inline-block;
      will-change: transform, opacity;

      &:after {
        content: ' ';
        display: inline-block;
      }
      &.show {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
`;

const AnimatedTitle = forwardRef((props, ref) => { 
  const textRef = useRef();
  let lineIndex = 1;
  function animate() {
    const words = textRef.current.querySelectorAll('.word');
    let currentOffset = null;

    words.forEach((word, index) => {
      const offset = word.getBoundingClientRect().top;
      if (offset > currentOffset) {
        if (index !== 0) {
          lineIndex = lineIndex + 1;
        }
      }
      word.classList.add('line-' + lineIndex);
      currentOffset = offset;
    });
      doAnimation();
  };

  function doAnimation() {
    const animation = anime.timeline({
 
    })

    for (let i = 0; i < lineIndex; i++) {
      animation.add({
        targets: `.${props.className} .word.line-${i+1} .inner`,
          translateY: {
            value: ['75px', '0px'],
            easing: 'cubicBezier(0.220, 0.265, 0.085, 0.980)',
            duration: 800,
          },
          opacity: {
            value: [0, 1],
            duration: 10,
            easing: 'linear'
          }
      }, (200*(i+1)))
    }
  }

  useImperativeHandle(ref, () => ({
    animate: () => {
      animate();
    }
  }));

  function getSpanElement(elem, className, index, spacer) {
    const key = `${className}-${index}`
    return <span key={key} className={`${className} ${key}`}><span className={`inner ${props.animated ? `show` : ''}`}>{elem}{spacer}</span></span>
  }
  function getWrappedChars() {
    let wordIndex = 0
    const { children } = props;
    const wordsArray = children.split(' ')
    const words = wordsArray.map(word => {
      wordIndex += 1
      return getSpanElement(word, 'word', wordIndex, ' ')
    })
    return words
  }
  
  const wrappedChars = getWrappedChars()
  return (
    <Wrapper>
      <div ref={textRef} className={props.className}>
        {wrappedChars}
      </div>
    </Wrapper>
  )
  
})

export default React.memo(AnimatedTitle);
