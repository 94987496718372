import Baobab from 'baobab';
import _ from 'lodash'

export function state(initialState) {
  return {
    rfq: {
      activeId: null,
      summary: {},
      map: {},
      pagination: {},
      activeMapitem: null,

      list: interestList('rfq'),
      activeInterest: activeInterest('rfq'),
      activeInterestItems: activeInterestItems('rfq'),
    },

    // dictionary: {},
    // interestList: interestList(),
    // activeInterest: activeInterest(),
    // activeInterestItems: activeInterestItems(),
    // activeMapitem: null
  };
}

function interestList(type) {
  return Baobab.monkey({
    cursors: {
      data: ['interests', type, 'map'],
    },
    get({data}) {
      const list = Object.keys(data).map(id => data[id]);
      return list;
    }
  });
}

function activeInterestItems(type) {
  return Baobab.monkey({
    cursors: {
      activeId: ['interests', type, 'activeId'],
      items: ['interests', type, 'items'],
    },
    get({items = {}, activeId}) {
      const data = _.get(items, `[${activeId}].docs`);
      const list = data ? Object.keys(data).map(id => data[id]) : [];
      return list;
    }
  });
}

function activeInterest(type) {
  return Baobab.monkey({
    cursors: {
      activeId: ['interests', type, 'activeId'],
      data: ['interests', type, 'map'],
    },
    get({data = {}, activeId}) {
      return data[activeId];
    }
  });
}
