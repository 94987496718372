import '@zendeskgarden/react-textfields/dist/styles.css';
import '@zendeskgarden/react-checkboxes/dist/styles.css';
import '@zendeskgarden/react-select/dist/styles.css';
import '@zendeskgarden/react-buttons/dist/styles.css';
import '@zendeskgarden/react-tables/dist/styles.css';
import '@zendeskgarden/react-grid/dist/styles.css';
import '@zendeskgarden/react-menus/dist/styles.css';
import '@zendeskgarden/react-notifications/dist/styles.css';
import '@zendeskgarden/react-tags/dist/styles.css';
import '@zendeskgarden/react-ranges/dist/styles.css';
import '@zendeskgarden/react-tooltips/dist/styles.css';
import '@zendeskgarden/react-tabs/dist/styles.css';
import '@zendeskgarden/react-dropdowns/dist/styles.css';

export const formVariables = {
  inputTopMargin: '10px',
  footerTopMargin: '20px'
}

// https://garden.zendesk.com/css-components/utilities/color/
export const variables = {
  color_black: 'rgb(0, 0, 0)',
  color_green_100: 'rgb(237, 248, 244)',
  color_green_200: 'rgb(209, 232, 223)',
  color_green_300: 'rgb(174, 207, 194)',
  color_green_400: 'rgb(94, 174, 145)',
  color_green_500: 'rgb(34, 143, 103)',
  color_green_600: 'rgb(3, 129, 83)',
  color_green_700: 'rgb(24, 97, 70)',
  color_green_800: 'rgb(11, 59, 41)',
  color_grey_100: 'rgb(248, 249, 249)',
  color_grey_200: 'rgb(233, 235, 237)',
  color_grey_300: 'rgb(216, 220, 222)',
  color_grey_400: 'rgb(194, 200, 204)', // DISABLED_COLOR
  color_grey_500: 'rgb(135, 146, 157)',
  color_grey_600: 'rgb(104, 115, 125)',
  color_grey_700: 'rgb(73, 84, 92)',
  color_grey_800: 'rgb(47, 57, 65)',
  color_blue_100: 'rgb(237, 247, 255)',
  color_blue_200: 'rgb(206, 226, 242)',
  color_blue_300: 'rgb(173, 204, 228)',
  color_blue_400: 'rgb(82, 147, 199)',
  color_blue_500: 'rgb(51, 127, 189)',
  color_blue_600: 'rgb(31, 115, 183)',
  color_blue_700: 'rgb(20, 74, 117)',
  color_blue_800: 'rgb(15, 53, 84)',
  color_red_100: 'rgb(255, 240, 241)',
  color_red_200: 'rgb(245, 213, 216)', // used
  color_red_300: 'rgb(245, 181, 186)',
  color_red_400: 'rgb(227, 91, 102)', // ERROR_COLOR
  color_red_500: 'rgb(217, 63, 76)',
  color_red_600: 'rgb(204, 51, 64)',
  color_red_700: 'rgb(140, 35, 44)',
  color_red_800: 'rgb(104, 18, 25)',
  color_yellow_100: 'rgb(255, 248, 237)',
  color_yellow_200: 'rgb(255, 240, 219)',
  color_yellow_300: 'rgb(252, 219, 169)',
  color_yellow_400: 'rgb(255, 182, 72)',
  color_yellow_500: 'rgb(245, 161, 51)',
  color_yellow_600: 'rgb(237, 150, 28)',
  color_yellow_700: 'rgb(173, 94, 24)',
  color_yellow_800: 'rgb(112, 59, 21)',
  color_white: 'rgb(255, 255, 255)',
  // color_kale_100: 'rgb(245, 251, 252)',
  // color_kale_200: 'rgb(193, 214, 217)',
  // color_kale_300: 'rgb(129, 154, 158)',
  // color_kale_400: 'rgb(86, 119, 122)',
  // color_kale_500: 'rgb(51, 93, 99)',
  // color_kale_600: 'rgb(4, 68, 77)',
  // color_kale_700: 'rgb(3, 54, 61)',
  // color_kale_800: 'rgb(1, 43, 48)',
  // color_secondary_azure_400: 'rgb(48, 145, 236)',
  // color_secondary_azure_600: 'rgb(19, 113, 214)',
  // color_secondary_crimson_400: 'rgb(227, 79, 50)',
  // color_secondary_crimson_600: 'rgb(199, 42, 28)',
  // color_secondary_fuschia_400: 'rgb(214, 83, 194)',
  // color_secondary_fuschia_600: 'rgb(168, 24, 151)',
  // color_secondary_lemon_400: 'rgb(255, 212, 36)',
  // color_secondary_lemon_600: 'rgb(255, 187, 16)',
  // color_secondary_lime_400: 'rgb(67, 179, 36)',
  // color_secondary_lime_600: 'rgb(46, 130, 0)',
  // color_secondary_mint_400: 'rgb(0, 166, 86)',
  // color_secondary_mint_600: 'rgb(5, 133, 65)',
  // color_secondary_orange_400: 'rgb(222, 112, 29)',
  // color_secondary_orange_600: 'rgb(191, 80, 0)',
  // color_secondary_pink_400: 'rgb(236, 77, 99)',
  // color_secondary_pink_600: 'rgb(212, 32, 84)',
  // color_secondary_purple_400: 'rgb(181, 82, 226)',
  // color_secondary_purple_600: 'rgb(106, 39, 184)',
  // color_secondary_royal_400: 'rgb(93, 125, 245)',
  // color_secondary_royal_600: 'rgb(51, 83, 226)',
  // color_secondary_teal_400: 'rgb(2, 161, 145)',
  // color_secondary_teal_600: 'rgb(2, 128, 121)',
  // color_secondary_azure_M400: 'rgb(95, 141, 207)',
  // color_secondary_azure_M600: 'rgb(58, 112, 178)',
  // color_secondary_crimson_M400: 'rgb(204, 108, 91)',
  // color_secondary_crimson_M600: 'rgb(178, 74, 60)',
  // color_secondary_fuschia_M400: 'rgb(207, 98, 168)',
  // color_secondary_fuschia_M600: 'rgb(168, 69, 140)',
  // color_secondary_lemon_M400: 'rgb(231, 165, 0)',
  // color_secondary_lemon_M600: 'rgb(195, 143, 0)',
  // color_secondary_lime_M400: 'rgb(81, 158, 45)',
  // color_secondary_lime_M600: 'rgb(71, 120, 44)',
  // color_secondary_mint_M400: 'rgb(41, 156, 102)',
  // color_secondary_mint_M600: 'rgb(46, 128, 87)',
  // color_secondary_orange_M400: 'rgb(212, 119, 44)',
  // color_secondary_orange_M600: 'rgb(179, 88, 39)',
  // color_secondary_pink_M400: 'rgb(213, 114, 135)',
  // color_secondary_pink_M600: 'rgb(178, 58, 93)',
  // color_secondary_purple_M400: 'rgb(176, 114, 204)',
  // color_secondary_purple_M600: 'rgb(147, 88, 176)',
  // color_secondary_royal_M400: 'rgb(121, 134, 216)',
  // color_secondary_royal_M600: 'rgb(75, 97, 195)',
  // color_secondary_teal_M400: 'rgb(45, 158, 143)',
  // color_secondary_teal_M600: 'rgb(60, 120, 115)',
  // color_chat_orange: 'rgb(247, 154, 62)',
  // color_connect_red: 'rgb(235, 102, 81)',
  // color_explore_blue: 'rgb(48, 170, 188)',
  // color_guide_pink: 'rgb(235, 73, 98)',
  // color_message_green: 'rgb(55, 184, 175)',
  // color_sell_gold: 'rgb(212, 174, 94)',
  // color_support_green: 'rgb(120, 163, 0)',
  // color_talk_yellow: 'rgb(239, 201, 61)',
  font_size_xs: '10px',
  font_size_sm: '12px',
  font_size_md: '14px',
  font_size: '16px',
  font_size_lg: '18px',
  font_size_xl: '22px',
  font_size_xxl: '26px',
  font_size_xxxl: '36px',
  font_weight_thin: '100',
  font_weight_extralight: '200',
  font_weight_light: '300',
  font_weight_regular: '400',
  font_weight_medium: '500',
  font_weight_semibold: '600',
  font_weight_bold: '700',
  font_weight_extrabold: '800',
  font_weight_black: '900',
  font_weight_ultralight: '200',
  font_weight_ultrabold: '800',
  font_weight_heavy: '900',
  line_height_sm: '16px',
  line_height_md: '20px',
  line_height_lg: '24px',
  line_height_xl: '28px',
  line_height_xxl: '38px',
  line_height_xxxl: '48px',
  spacing_xxs: '4px',
  spacing_xs: '8px',
  spacing_sm: '10px',
  spacing_md: '15px',
  spacing: '20px',
  spacing_lg: '30px',
  spacing_xl: '40px',
  spacing_xxl: '48px',
  spacing_xxxl: '64px',

  // custom
  logo_url: "/images/logo_dark_cropped.png",
  logo_url_white: "/images/logo_white.svg",
  custom_lime: '#48b175',
  custom_teal: '#89bfdf',
  custom_blue: '#0168ef',
  custom_dark_blue: '#001D43',
  custom_navy: '#001d44',
  custom_sky_blue: '#e7f1ff',
  custom_sky_blue_30: 'rgba(231, 241, 255, 0.30)',
  custom_blue_hover: '#2c7de6',
  custom_grey: '#a2a2a2',
  custom_green: '#39d79f',
  custom_orange: '#f17452',
  custom_black: '#3e3e3e',
  custom_dark_grey: 'rgba(65,65,65,1)',
  custom_silver: '#cfcfcf',
  custom_light_grey: '#f0f3f9',
  custom_lighter_grey: '#f7f8fa',
  custom_background_color: '#f5f7fa',
  spinner_duration: '700ms',
  app_header_height: '75px',
  app_footer_height: '200px',
  app_mobile_footer_height: '500px',
  app_mobile_header_height: '110px',
  app_page_padding: '40px',
  app_page_padding_medium: '25px',
  app_page_padding_small: '15px',
  sidebar_menu_width: '240px',
  routed_modal_header_height: '50px',
  sidebar_menu_minimized: '83px',
  sidebar_padding: '20px',
  raisedValue: '0px 4px 5px rgba(66,100,251,0.031)',
  raised_card_value: '0px 0px 8px rgba(0,0,0,0.06)',
  media_xs: '(min-width: 200px)',   //. <576px Extra small
  media_sm: '(min-width: 576px)',    //. ≥576px Small  
  media_md: '(min-width: 768px)',   //. ≥768px Medium
  media_lg: '(min-width: 992px)',   //. ≥992px Large  
  media_xl: '(min-width: 1200px)',   //. ≥1200px Extra large
  media_xxl: '(min-width: 1400px)',   //. ≥1400px Extra large
  height_xxl: '62px',
  border_radius: '4px',
  font_family: "system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Arial, sans-serif",

  public_wrapper_width: '1124px',
  custom_button_blue: '#0368EF',
  custom_button_hover: '#0450B7',
  custom_pale_blue: '#1D457A',

  public_font_family: "'Roboto', -apple-system, Segoe UI, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Arial, sans-serif",
  public_font_size_xs: '12px',
  public_font_size_sm: '14px',
  public_font_size_md: '16px',
  public_font_size: '24px',
  public_font_size_lg: '32px',
  public_font_size_xl: '40px',
  public_font_size_xxl: '48px',
  public_font_size_xxxl: '56px',
  public_mobile_font_size_xs: '10px',
  public_mobile_font_size_sm: '12px',
  public_mobile_font_size_md: '16px',
  public_mobile_font_size: '18px',
  public_mobile_font_size_lg: '20px',
  public_mobile_font_size_xl: '24px',
  public_mobile_font_size_xxl: '24px',
  public_mobile_font_size_xxxl: '28px',
  public_line_height_sm: '16px',
  public_line_height_md: '20px',
  public_line_height_lg: '24px',
  public_line_height_xl: '28px',
  public_line_height_xxl: '38px',
  public_line_height_xxxl: '48px',
  public_font_weight_bold: '700',
  public_font_weight_black: '900',
  section_padding: '240px',
  section_padding_sm: '120px',
  featured_listing_bg: '#F1F7FE',
  footer_width: '770px',
  copyright_color: 'rgba(255, 255, 255, 0.3)',

  animation_easing: 'cubicBezier(0.22, 0.265, 0.085, 0.98)'

}
