import React from 'react';
import { XXL, XL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from 'components/Icon/Icon';
import { Button } from 'theme/Button';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {
  QuestionSection,
  BackgroundCircle,
  SearchFieldWrapper,
  HelpCenterButton,
  Wrapper,
  Line,
  NavSideBar,
  Oval,
  BgCircleWrapper,
} from '../../FrequentlyAskedQuestions.styles';

import { SectionArrow } from 'components/PublicPages/GlobalPublicStyles';

import DividingLine from 'components/PublicPages/DividingLine';

const { spacing } = variables;

const InfoSection = (props) => {
  return (
    <QuestionSection>
      <SectionArrow />
      <Grid>
        <Row>
          <Col md={12}>
            <Flex justifyCenter>
              <Wrapper>
                <DividingLine style={{ margin: `50px 0 80px 0` }} />
                <XXL center tag='h2'>
                  Getting To Know Global Pipe Co.
                </XXL>
                <Accordion allowZeroExpanded className='top-accordion'>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <XL left tag='h3'>
                          What is Global Pipe Co.?
                        </XL>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <MD left tag='p'>
                        We strive to give our customers the best customer
                        service to ensure your experience using Global Pipe Co.
                        is pleasent. If you need help, you can{' '}
                        <a href='#'>Contact Us</a> at any time and one of our
                        staff members will gladly assist you.
                      </MD>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <Line />
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <XL left tag='h3'>
                          How Does It Work?
                        </XL>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <MD left tag='p'>
                        We strive to give our customers the best customer
                        service to ensure your experience using Global Pipe Co.
                        is pleasent. If you need help, you can{' '}
                        <a href='#'>Contact Us</a> at any time and one of our
                        staff members will gladly assist you.
                      </MD>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <Line />
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <XL left tag='h3'>
                          Is It Really This Easy?
                        </XL>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <MD left tag='p'>
                        We strive to give our customers the best customer
                        service to ensure your experience using Global Pipe Co.
                        is pleasent. If you need help, you can{' '}
                        <a href='#'>Contact Us</a> at any time and one of our
                        staff members will gladly assist you.
                      </MD>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <Line />
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <XL left tag='h3'>
                          How Does It Compare To Other Services?
                        </XL>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <MD left tag='p'>
                        We strive to give our customers the best customer
                        service to ensure your experience using Global Pipe Co.
                        is pleasent. If you need help, you can{' '}
                        <a href='#'>Contact Us</a> at any time and one of our
                        staff members will gladly assist you.
                      </MD>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <Line />
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <XL left tag='h3'>
                          Will All of My Assets Be Safe?
                        </XL>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <MD left tag='p'>
                        We strive to give our customers the best customer
                        service to ensure your experience using Global Pipe Co.
                        is pleasent. If you need help, you can{' '}
                        <a href='#'>Contact Us</a> at any time and one of our
                        staff members will gladly assist you.
                      </MD>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
                <DividingLine className='middle-divide' />
                <XXL center tag='h2'>
                  How To Get Started
                </XXL>
                <Accordion allowZeroExpanded className='bottom-accordion'>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <XL left tag='h3'>
                          How Do I Get Started?
                        </XL>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <MD left tag='p'>
                        We strive to give our customers the best customer
                        service to ensure your experience using Global Pipe Co.
                        is pleasent. If you need help, you can{' '}
                        <a href='#'>Contact Us</a> at any time and one of our
                        staff members will gladly assist you.
                      </MD>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <Line />
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <XL left tag='h3'>
                          What Do I Need to Register?
                        </XL>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <MD left tag='p'>
                        We strive to give our customers the best customer
                        service to ensure your experience using Global Pipe Co.
                        is pleasent. If you need help, you can{' '}
                        <a href='#'>Contact Us</a> at any time and one of our
                        staff members will gladly assist you.
                      </MD>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <Line />
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <XL left tag='h3'>
                          How Do I Submit My Assets?
                        </XL>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <MD left tag='p'>
                        We strive to give our customers the best customer
                        service to ensure your experience using Global Pipe Co.
                        is pleasent. If you need help, you can{' '}
                        <a href='#'>Contact Us</a> at any time and one of our
                        staff members will gladly assist you.
                      </MD>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <Line />
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <XL left tag='h3'>
                          When Will My Assets Be Sold?
                        </XL>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <MD left tag='p'>
                        We strive to give our customers the best customer
                        service to ensure your experience using Global Pipe Co.
                        is pleasent. If you need help, you can{' '}
                        <a href='#'>Contact Us</a> at any time and one of our
                        staff members will gladly assist you.
                      </MD>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <Line />
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <XL left tag='h3'>
                          Where Can I Go If I Need Help?
                        </XL>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <MD left tag='p'>
                        We strive to give our customers the best customer
                        service to ensure your experience using Global Pipe Co.
                        is pleasent. If you need help, you can{' '}
                        <a href='#'>Contact Us</a> at any time and one of our
                        staff members will gladly assist you.
                      </MD>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </Wrapper>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Flex justifyCenter>
              <FlexItem>
                <DividingLine style={{ margin: `200px 0 100px 0` }} />
              </FlexItem>
            </Flex>
            <XXL center tag='h2' className='more-help'>
              Looking For More Help?
            </XXL>
            <Flex justifyCenter>
              <FlexItem>
                <Button publicButton>Contact Us</Button>
              </FlexItem>
            </Flex>
          </Col>
        </Row>
      </Grid>
      <BgCircleWrapper>
        <BackgroundCircle className='right-circle' />
        <BackgroundCircle className='left-circle' />
      </BgCircleWrapper>
    </QuestionSection>
  );
};

export default InfoSection;
