import fetchJson from 'utility/fetchJson';
import {BASE} from '../../constants';
import tree from 'state';

export async function getOnboardingMeta() {
  const response = await fetchJson(`${BASE}/api/on-board/meta/`, {
    method: 'GET'
  })
  return response
}

export async function getAreasOfInterest() {
  // const response = await fetchJson(`${BASE}/api/rfq/group/${id}/`, {
  //   method: 'GET'
  // })
  // return response
}

export async function deleteAreaOfInterest(id) {
  // const response = await fetchJson(`${BASE}/api/rfq/well/${id}/`, {
  //   method: 'DELETE',
  // })
  // return response
}



export async function getBusinessInfoDetails() {
  const userId = tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(`${BASE}/api/on-board/business/${userId}/`, {
    method: 'GET'
  })
  return response
}

export async function saveBusinessInfoDetails(data) {
  const userId = tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(`${BASE}/api/on-board/business/${userId}/`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return response
}

export async function updateBusinessInfoDetails(id, data) {
  const userId = tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(`${BASE}/api/on-board/business/${userId}/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  })
  return response
}


export async function getAccountType() {
  // const response = await fetchJson(`${BASE}/api/rfq/group/${id}/`, {
  //   method: 'GET'
  // })
  // return response
}

export async function saveAccountType(data) {
  const response = await fetchJson(`${BASE}/api/`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return response
}

export async function updateAccountType(id, data) {
  const response = await fetchJson(`${BASE}/api/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  })
  return response
}