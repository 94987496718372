import styled from 'styled-components';
import {variables} from 'theme/variables';
const {
  custom_blue,
  spacing
} = variables;

export const SidebarFilterHeader = styled.div`
  padding: ${props => props.padding || spacing}
  background-color: ${custom_blue};
  height: 55px;
  display: flex;
  align-items: center;
`

export const SidebarFilterBody = styled.div`
  padding: ${props => props.padding || spacing}
  flex: 1;
  position: relative;
`

export const SidebarContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;
`