import React, { useState, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import TopicHeader from './components/TopicHeader/TopicHeader';
import { FullModalWrapper, ModalScrollContainer } from 'App.styles.js';
import {get, map} from 'lodash';
import history from 'historyContainer';
import { matchRoutes } from 'react-router-config';

const RoutedModal = (props) => {
  const [isRouteRendered, setRendered] = useState(false);
  const [isDataFetched, setDataFetched] = useState(false);
  const path = props.location.pathname;
  const modalString = get(path.match(/(\/m\/\w.*)|(\/m\/)/), '[0]');
  
  function closeModal() {
    if (typeof props.route.unmountFunc === 'function') {
      props.route.unmountFunc();
    }
    history.push(path.replace(modalString, ''));
  }

  const activeRouteData = get(matchRoutes(props.route.routes, props.location.pathname), '[0].route.data');
  const routes = map(props.route.routes, (route) => {
    return {
      ...route,
      basePath: path.replace(modalString, ''),
      rfqId: props.match.params.rfqId,
      businessId: props.match.params.businessId,
      parentParams: props.match.params,
      parentMatch: props.match
    }
  });
  // PARAMS FOR REQUESTS
    const rfqId = get(props.match, 'params.rfqId');
    const businessId = get(props.match, 'params.businessId');
  //


  useEffect(() => {
    const hasFetch  = typeof props.route.fetch === 'function';
    if (isRouteRendered && hasFetch) {
      async function getData() {
        await props.route.fetch({rfqId}, props.match);
        setDataFetched(true)
      }
      getData();
    } else if(!hasFetch) {
      setDataFetched(true)
    }
  }, [isRouteRendered]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setRendered(Math.random())
  }, []);

  return (
    <FullModalWrapper className="full-modal-wrapper" {...props}>
      <ModalScrollContainer>
        <TopicHeader logo={get(activeRouteData, 'logo')} icon={get(activeRouteData, 'icon')} title={get(activeRouteData, 'title')} closeModal={closeModal}/>
        {
          isRouteRendered && isDataFetched ?
          <Switch>
            {renderRoutes(routes)}
          </Switch> : null
        }
      </ModalScrollContainer>
    </FullModalWrapper>
  )
}


export default PageViewWrapper(RoutedModal, {pageName: 'RoutedModal'})