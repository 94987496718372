import React from 'react';
import { XXXL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import Icon from 'components/Icon/Icon';
import {
  TopSection,
  BackgroundImg,
  SearchFieldWrapper,
  PipesWrapper,
  HeaderWrapper,
} from '../../FrequentlyAskedQuestions.styles';

const { spacing } = variables;

const FrequentlyAskedQuestionsSection = (props) => {
  return (
    <TopSection dark>
      <PipesWrapper>
        <BackgroundImg className='left-pipes' src='/images/hero-pipes.png' />
        <BackgroundImg className='right-pipes' src='/images/hero-pipes.png' />
        <BackgroundImg className='left-pipe' src='/images/Pipes.svg' />
        <BackgroundImg className='right-pipe' src='/images/Pipes.svg' />
      </PipesWrapper>
      <Flex justifyCenter>
        <FlexItem>
          <HeaderWrapper>
            <XXXL center tag='h1'>
              Frequently Asked Questions
            </XXXL>
            <MD center tag='p'>
              There is a lot of success that goes into Global Pipe Co.. Find the
              answers to the top questions asked by customers.
            </MD>
          </HeaderWrapper>
        </FlexItem>
      </Flex>
    </TopSection>
  );
};

export default FrequentlyAskedQuestionsSection;
