import React from 'react';
import { XXXL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';
import {
  Section,
  Logo,
  LoginForm,
  Form,
  FormFieldWrapper,
  LoginButton,
  BackgroundImg,
  FormTextBottom,
  Line,
} from '../../LoginTemplate.styles';

const { spacing } = variables;

const Login = (props) => {
  return (
    <Section dark>
      <Flex justifyCenter>
        <FlexItem>
          <Logo src='/images/logo_white.svg' />
        </FlexItem>
      </Flex>
      <XXXL center tag='h1'>
        Login to Global Pipe Co.!
      </XXXL>
      <MD center tag='p'>
        Please complete the information below.
      </MD>
      <Flex justifyCenter>
        <FlexItem>
          <LoginForm>
            <Row>
              <Col md={12}>
                <FormFieldWrapper className='email'>
                  <MD left tag='h5'>
                    Email
                  </MD>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    required
                    placeholder='Enter Email'
                  ></input>
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormFieldWrapper className='password'>
                  <MD left tag='h5'>
                    Password
                  </MD>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    required
                    placeholder='Enter Password'
                  ></input>
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Flex justifyCenter>
                  <FlexItem>
                    <Button publicButton style={{ marginBottom: `30px` }}>
                      Login
                    </Button>
                  </FlexItem>
                </Flex>
              </Col>
            </Row>
            <FormTextBottom>
              <Row>
                <Col md={12}>
                  <MD center tag='p'>
                    <a href='#'>Can't Log In?</a>
                  </MD>
                </Col>
              </Row>
              <Flex justifyCenter>
                <FlexItem>
                  <Line />
                </FlexItem>
              </Flex>
              <Row>
                <Col md={12}>
                  <MD center tag='p'>
                    Don't have a Global Pipe Co. account?{' '}
                    <a href='#'>Register</a>{' '}
                  </MD>
                </Col>
              </Row>
            </FormTextBottom>
          </LoginForm>
        </FlexItem>
      </Flex>
      <BackgroundImg src='/images/hero-pipes.png' />
    </Section>
  );
};

export default Login;
