import React from 'react';
import { XXL, XL,  MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from 'components/Icon/Icon'
import { Button } from 'theme/Button';
import { Tabs, TabPanel } from '@zendeskgarden/react-tabs';

import {
  ArticleSection,
  BackgroundCircle,
  DividingLine,
  SearchFieldWrapper,
  HelpCenterButton,
  ArticleWrapper,
  Line,
  NavSideBar,
  Oval,
  Steps,
  BgCircleWrapper,
  RelatedArticles,
  Wrapper
} from '../../HelpCenterArticle.styles';

import {
  Section,
  SectionArrow,
} from 'components/PublicPages/GlobalPublicStyles';

const {
  custom_button_blue
} = variables;

const InfoSection = (props) => {
  return (
    <ArticleSection>
      <SectionArrow />
       <BgCircleWrapper>
        <BackgroundCircle className="circle-right"/>
        <BackgroundCircle className="circle-left"/>
      </BgCircleWrapper>
      <HelpCenterButton className="back-button"><Icon icon="icon-arrow-left-long" color={custom_button_blue} style={{position: `absolute`, left: `48px`, top: `13px`}}/>Back</HelpCenterButton>
      <Wrapper style={{maxWidth: `100%`}}>
      <Grid>
          <Tabs className="vertical-tabs" vertical>
            <TabPanel label="Overview" key="tab-1">
              <Flex className="tab-content" justifyCenter full>
                <FlexItem>
                <Col md={12}>
                <ArticleWrapper>
                <XXL left tag="h2">Information Needed To Register</XXL>
                <MD left tag="p">Sed ut perspiciatis unde omnis iste natus error sit <a href="#">voluptatem</a> accusantium doloremque laudantium, totam rem aperiam, <a href="#">eaque</a> ipsa quae ab illo inventore veritatis et quasi architecto bae vitae sunt explicabo.</MD>
                <MD left tag="p">When you are ready to register, you will need the following:</MD>
                <Steps>
                  <li><span>Click the <strong>Register</strong> Button</span></li>
                  <li><span>Input the following information:</span></li>
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>First Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Last Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your <strong>Work Email</strong> address</MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Work Phone Number</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input a strong <strong>Password</strong></MD><br />
                  <li><span>Click the <strong>Create Account</strong> Button</span></li>
                  <li><span><strong>Confirm your Account</strong> by clicking on the link in your email</span></li>
                </Steps>
                <XL left tag="h3">Additional Information</XL>
                <MD left tag="p" className="information">We reserve the right to change these Terms and Conditions by posting a revised agreement. If you don't agree with these changes, you must stop using the Platform. The information on the Platform is subject to change without notice.</MD>
                <Line />
                <Flex justifyCenter>
                  <FlexItem>
                    <MD center tag="p">Was this article helpful? <a href="#">Yes</a> or <a href="#">No</a></MD>                
                  </FlexItem>
                </Flex>
                <Line />               
              </ArticleWrapper>
                </Col>
              </FlexItem>
              </Flex>
            </TabPanel>
            <TabPanel label="Buyers" key="tab-2">
              <Flex className="tab-content" justifyCenter>
                <FlexItem>
              <Col md={12}>
                <ArticleWrapper>
                <XXL left tag="h2">Information Needed To Register</XXL>
                <MD left tag="p">Sed ut perspiciatis unde omnis iste natus error sit <a href="#">voluptatem</a> accusantium doloremque laudantium, totam rem aperiam, <a href="#">eaque</a> ipsa quae ab illo inventore veritatis et quasi architecto bae vitae sunt explicabo.</MD>
                <MD left tag="p">When you are ready to register, you will need the following:</MD>
                <Steps>
                  <li><span>Click the <strong>Register</strong> Button</span></li>
                  <li><span>Input the following information:</span></li>
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>First Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Last Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your <strong>Work Email</strong> address</MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Work Phone Number</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input a strong <strong>Password</strong></MD><br />
                  <li><span>Click the <strong>Create Account</strong> Button</span></li>
                  <li><span><strong>Confirm your Account</strong> by clicking on the link in your email</span></li>
                </Steps>
                <XL left tag="h3">Additional Information</XL>
                <MD left tag="p" className="information">We reserve the right to change these Terms and Conditions by posting a revised agreement. If you don't agree with these changes, you must stop using the Platform. The information on the Platform is subject to change without notice.</MD>
                <Line />
                <Flex justifyCenter>
                  <FlexItem>
                    <MD center tag="p">Was this article helpful? <a href="#">Yes</a> or <a href="#">No</a></MD>                
                  </FlexItem>
                </Flex>
                <Line />                
              </ArticleWrapper>
              </Col>
              </FlexItem>
              </Flex>
            </TabPanel>
            <TabPanel label="Sellers" key="tab-3">
              <Flex className="tab-content" justifyCenter>
                <FlexItem>
                <Col md={12}>
                <ArticleWrapper>
                <XXL left tag="h2">Information Needed To Register</XXL>
                <MD left tag="p">Sed ut perspiciatis unde omnis iste natus error sit <a href="#">voluptatem</a> accusantium doloremque laudantium, totam rem aperiam, <a href="#">eaque</a> ipsa quae ab illo inventore veritatis et quasi architecto bae vitae sunt explicabo.</MD>
                <MD left tag="p">When you are ready to register, you will need the following:</MD>
                <Steps>
                  <li><span>Click the <strong>Register</strong> Button</span></li>
                  <li><span>Input the following information:</span></li>
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>First Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Last Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your <strong>Work Email</strong> address</MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Work Phone Number</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input a strong <strong>Password</strong></MD><br />
                  <li><span>Click the <strong>Create Account</strong> Button</span></li>
                  <li><span><strong>Confirm your Account</strong> by clicking on the link in your email</span></li>
                </Steps>
                <XL left tag="h3">Additional Information</XL>
                <MD left tag="p" className="information">We reserve the right to change these Terms and Conditions by posting a revised agreement. If you don't agree with these changes, you must stop using the Platform. The information on the Platform is subject to change without notice.</MD>
                <Line />
                <Flex justifyCenter>
                  <FlexItem>
                    <MD center tag="p">Was this article helpful? <a href="#">Yes</a> or <a href="#">No</a></MD>                
                  </FlexItem>
                </Flex>
                <Line />             
              </ArticleWrapper>
              </Col>
              </FlexItem>
            </Flex>
            </TabPanel>
            <TabPanel label="Facilitators" key="tab-4">
              <Flex className="tab-content" justifyCenter>
                <FlexItem>
                <Col md={12}>
                <ArticleWrapper>
                <XXL left tag="h2">Information Needed To Register</XXL>
                <MD left tag="p">Sed ut perspiciatis unde omnis iste natus error sit <a href="#">voluptatem</a> accusantium doloremque laudantium, totam rem aperiam, <a href="#">eaque</a> ipsa quae ab illo inventore veritatis et quasi architecto bae vitae sunt explicabo.</MD>
                <MD left tag="p">When you are ready to register, you will need the following:</MD>
                <Steps>
                  <li><span>Click the <strong>Register</strong> Button</span></li>
                  <li><span>Input the following information:</span></li>
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>First Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Last Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your <strong>Work Email</strong> address</MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Work Phone Number</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input a strong <strong>Password</strong></MD><br />
                  <li><span>Click the <strong>Create Account</strong> Button</span></li>
                  <li><span><strong>Confirm your Account</strong> by clicking on the link in your email</span></li>
                </Steps>
                <XL left tag="h3">Additional Information</XL>
                <MD left tag="p" className="information">We reserve the right to change these Terms and Conditions by posting a revised agreement. If you don't agree with these changes, you must stop using the Platform. The information on the Platform is subject to change without notice.</MD>
                <Line />
                <Flex justifyCenter>
                  <FlexItem>
                    <MD center tag="p">Was this article helpful? <a href="#">Yes</a> or <a href="#">No</a></MD>                
                  </FlexItem>
                </Flex>
                <Line />              
              </ArticleWrapper>
              </Col>
              </FlexItem>
            </Flex>
            </TabPanel>
            <TabPanel label="Administrators" key="tab-5">
              <Flex className="tab-content" justifyCenter>
                <FlexItem>
                <Col md={12}>
                <ArticleWrapper>
                <XXL left tag="h2">Information Needed To Register</XXL>
                <MD left tag="p">Sed ut perspiciatis unde omnis iste natus error sit <a href="#">voluptatem</a> accusantium doloremque laudantium, totam rem aperiam, <a href="#">eaque</a> ipsa quae ab illo inventore veritatis et quasi architecto bae vitae sunt explicabo.</MD>
                <MD left tag="p">When you are ready to register, you will need the following:</MD>
                <Steps>
                  <li><span>Click the <strong>Register</strong> Button</span></li>
                  <li><span>Input the following information:</span></li>
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>First Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Last Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your <strong>Work Email</strong> address</MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Work Phone Number</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input a strong <strong>Password</strong></MD><br />
                  <li><span>Click the <strong>Create Account</strong> Button</span></li>
                  <li><span><strong>Confirm your Account</strong> by clicking on the link in your email</span></li>
                </Steps>
                <XL left tag="h3">Additional Information</XL>
                <MD left tag="p" className="information">We reserve the right to change these Terms and Conditions by posting a revised agreement. If you don't agree with these changes, you must stop using the Platform. The information on the Platform is subject to change without notice.</MD>
                <Line />
                <Flex justifyCenter>
                  <FlexItem>
                    <MD center tag="p">Was this article helpful? <a href="#">Yes</a> or <a href="#">No</a></MD>                
                  </FlexItem>
                </Flex>
                <Line />         
              </ArticleWrapper>
              </Col>
              </FlexItem>
            </Flex>
            </TabPanel>
            <TabPanel label="Account Setup" key="tab-6">
              <Flex className="tab-content" justifyCenter>
                <FlexItem>
                <Col md={12}>
                <ArticleWrapper>
                <XXL left tag="h2">Information Needed To Register</XXL>
                <MD left tag="p">Sed ut perspiciatis unde omnis iste natus error sit <a href="#">voluptatem</a> accusantium doloremque laudantium, totam rem aperiam, <a href="#">eaque</a> ipsa quae ab illo inventore veritatis et quasi architecto bae vitae sunt explicabo.</MD>
                <MD left tag="p">When you are ready to register, you will need the following:</MD>
                <Steps>
                  <li><span>Click the <strong>Register</strong> Button</span></li>
                  <li><span>Input the following information:</span></li>
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>First Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Last Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your <strong>Work Email</strong> address</MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Work Phone Number</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input a strong <strong>Password</strong></MD><br />
                  <li><span>Click the <strong>Create Account</strong> Button</span></li>
                  <li><span><strong>Confirm your Account</strong> by clicking on the link in your email</span></li>
                </Steps>
                <XL left tag="h3">Additional Information</XL>
                <MD left tag="p" className="information">We reserve the right to change these Terms and Conditions by posting a revised agreement. If you don't agree with these changes, you must stop using the Platform. The information on the Platform is subject to change without notice.</MD>
                <Line />
                <Flex justifyCenter>
                  <FlexItem>
                    <MD center tag="p">Was this article helpful? <a href="#">Yes</a> or <a href="#">No</a></MD>                
                  </FlexItem>
                </Flex>
                <Line />
              </ArticleWrapper>
              </Col>
              </FlexItem>
              </Flex>
            </TabPanel>
          </Tabs>
            {/* <Col md={8}>
              <ArticleWrapper>
                <XXL left tag="h2">Information Needed To Register</XXL>
                <MD left tag="p">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto bae vitae sunt explicabo.</MD>
                <MD left tag="p">When you are ready to register, you will need the following:</MD>
                <Steps>
                  <li><span>Click the <strong>Register</strong> Button</span></li>
                  <li><span>Input the following information:</span></li>
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>First Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Last Name</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your <strong>Work Email</strong> address</MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input your legal <strong>Work Phone Number</strong></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p">Input a strong <strong>Password</strong></MD><br />
                  <li><span>Click the <strong>Create Account</strong> Button</span></li>
                  <li><span><strong>Confirm your Account</strong> by clicking on the link in your email</span></li>
                </Steps>
                <XL left tag="h2">Additional Information</XL>
                <MD left tag="p" className="information">We reserve the right to change these Terms and Conditions by posting a revised agreement. If you don't agree with these changes, you must stop using the Platform. The information on the Platform is subject to change without notice.</MD>
                <Line />
                <Flex justifyCenter>
                  <FlexItem>
                    <MD center tag="p">Was this article helpful? Yes or No</MD>                
                  </FlexItem>
                </Flex>
                <Line />
                <RelatedArticles>
                  <XL left tag="h5">Related Articles</XL>
                  <Oval className="grey-oval"/> <MD left tag="p"><a href="#" className="links">Yet Another Buyer Article Three</a></MD><br />
                  <Oval className="grey-oval"/> <MD left tag="p"><a href="#" className="links">Here is Another Buyer Article Four</a></MD><br />
                </RelatedArticles>              
              </ArticleWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Flex justifyCenter>
                <FlexItem>
                  <DividingLine className="bottom-divide" />
                </FlexItem>
              </Flex>
              <XXL center tag="h2">Looking For More Help?</XXL>
              <Flex justifyCenter>
                <FlexItem>
                  <Button publicButton style={{width: `200px`, margin: `30px 0 30px 0`}}>Contact Us</Button>
                </FlexItem>
              </Flex>
            </Col> */}
        
          <Flex justifyCenter>
            <FlexItem>
              <RelatedArticles>
                <XL left tag="h5">Related Articles</XL>
                <Oval className="grey-oval"/> <MD left tag="p"><a href="#" className="links">Yet Another Buyer Article Three</a></MD><br />
                <Oval className="grey-oval"/> <MD left tag="p"><a href="#" className="links">Here is Another Buyer Article Four</a></MD><br />
              </RelatedArticles>
            </FlexItem>
          </Flex>             
        </Grid>
      </Wrapper>
    </ArticleSection>

  )
}

export default InfoSection;
