import React, { useState, useRef, useEffect } from 'react';
import { variables } from 'theme/variables';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Link } from 'react-router-dom';
import {
  PublicFooterComponent,
  Logo,
  FooterMenu,
  Copyright,
  FooterGrid,
} from './PublicFooter.styles.js';
import { Waypoint } from 'react-waypoint';
import anime from 'animejs';

const { logo_url_white } = variables;

const PublicFooter = () => {
  const animation = useRef();
  const [animated, setAnimated] = useState(false);
  useEffect(() => {
    animation.current = anime
      .timeline({
        autoplay: false,
        complete: function () {
          setAnimated(true);
        },
      })
      .add({
        targets: '.footer-logo',
        opacity: {
          value: [0, 1],
          duration: 500,
          easing: 'linear',
        },
      })
      .add({
        targets: '.footer-menu',
        opacity: {
          value: [0, 1],
          duration: 100,
          easing: 'linear',
        },
        translateY: {
          value: [40, 0],
          duration: 400,
          easing: 'cubicBezier(0.220, 0.265, 0.085, 0.980)',
        },
        delay: anime.stagger(100),
      });
    return () => {
      animation.current.pause();
      animation.current = null;
    };
  }, []);

  function animate() {
    if (!animated) {
      animation.current.play();
    }
  }
  return (
    <PublicFooterComponent>
      <Waypoint onEnter={animate} />
      <FooterGrid>
        <Row>
          <Col md={12}>
            <Logo className='footer-logo'>
              <Link to='/'>
                <img src={logo_url_white} alt='' />
              </Link>
            </Logo>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FooterMenu className='footer-menu' animated={animated}>
              <div className='title'>Product</div>
              <ul>
                <li>
                  <Link to='/find-inventory'>Find Inventory</Link>
                </li>
                <li>
                  <Link to='/why-us'>Why Us</Link>
                </li>
              </ul>
            </FooterMenu>
          </Col>
          <Col md={3}>
            <FooterMenu className='footer-menu' animated={animated}>
              <div className='title'>About</div>
              <ul>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/company'>Our Company</Link>
                </li>
                <li>
                  <Link to='/blog'>Blog</Link>
                </li>
              </ul>
            </FooterMenu>
          </Col>
          <Col md={3}>
            <FooterMenu className='footer-menu' animated={animated}>
              <div className='title'>Legal</div>
              <ul>
                <li>
                  <Link to='/terms'>Terms</Link>
                </li>
                <li>
                  <Link to='/privacy-polciy'>Privacy Policy</Link>
                </li>
              </ul>
            </FooterMenu>
          </Col>
          <Col md={3}>
            <FooterMenu className='footer-menu' animated={animated}>
              <div className='title'>Support</div>
              <ul>
                <li>
                  <Link to='/contact'>Contact Us</Link>
                </li>
                <li>
                  <Link to='/faq'>FAQ</Link>
                </li>
                <li>
                  <Link to='/help-center'>Help Center</Link>
                </li>
              </ul>
            </FooterMenu>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Copyright>
              © 2022 Global Pipe Co. Trade Platform.{' '}
              <br className='mobile-br' /> All Rights Reserved.{' '}
              <span className='crafted'>
                <a
                  href='https://blackflagcreative.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Crafted by Black Flag Creative
                </a>
              </span>
            </Copyright>
          </Col>
        </Row>
      </FooterGrid>
    </PublicFooterComponent>
  );
};

export default PublicFooter;
