import {variables} from 'theme/variables';
const {
  custom_grey,
  font_weight_semibold
} = variables;

const SelectTheme = {
  'select.select_view': props => {
    return `
      ${props.pagination ? `
        padding-left: 15px !important;
        font-size: 11px !important;
        line-height: 22px !important;
        color: ${custom_grey} !important;
        font-weight: ${font_weight_semibold} !important;
        padding-right: 2.58571em !important;
        box-shadow: none !important;
        &:before {
          font-size: 14px !important;
        }
      ` : ''}      
    `;

  },
};

export default SelectTheme;
