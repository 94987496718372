import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import ResetForm from './components/ResetForm/ResetForm.js'



import {
  ResetPasswordPage
} from './ResetPassword.styles';


const ResetPassword = () => {
  
  return (
    <ResetPasswordPage style={{padding: `0`, overflow: `hidden`}}>
      <ResetForm />
    </ResetPasswordPage>
  )
}


export default PageViewWrapper(ResetPassword, {pageName: 'ResetPassword'})