import React from 'react';
import { Dot } from 'theme/Global.styles.js';
import Flex from 'styled-flex-component';
import { SM } from '@zendeskgarden/react-typography';
import { Divider } from 'theme/Global.styles.js';
import {
  StyledNotificationItem,
  StyledNotificationText,
  StyledNotificationSender,
  StyledNotificationSubject,

} from './NotificationItem.styles.js';



const NotificationItem = ({notification, ...rest}) => {
  const {subject="CRA"} = notification;
  const isNew = true;
  return (
      <StyledNotificationItem new={isNew} padding={rest.itemPadding}>
        <Flex full>
          {
            isNew ?
            <Dot style={{height: '24px'}} /> : <Dot transparent />
          }
          <div style={{ minWidth: 0}}>
            <Flex style={{height: 'auto'}} full>
              <Flex full alignCenter>
                <StyledNotificationSubject bold>{subject}</StyledNotificationSubject>
                <Divider/>
                <SM>RFQ342432</SM>
              </Flex>
              <SM alignCenter bold={isNew} blue={isNew}>{'10:02am '}</SM>
            </Flex>
            <StyledNotificationSender bold>Vince Profeta</StyledNotificationSender>
            <StyledNotificationText ellipsis grey>Hello John Smith we wanted to, this is some sample messaging text for the app to perform basic needs to display notifications and news</StyledNotificationText>
          </div>
        </Flex>
      </StyledNotificationItem> 
  )
}

export default NotificationItem