import React from 'react';
import ProjectActionItem from 'components/ProjectActionItem/ProjectActionItem';
import OrderStatusBar from 'components/OrderStatusBar/OrderStatusBar';
import {StyledInterestHeader} from './InterestHeader.styles.js';
import {variables} from 'theme/variables';
const {
  spacing_sm,
} = variables;

const statusBarStyle = {marginTop: spacing_sm, paddingBottom: 0};

const statusMap = {
  new: 'submission',
  assesment: "assesment",
  exchange: "exchange",
  'order-initiation': "order-initiation",
  'order-mgmt':  "order-mgmt",
}
const statuses = ['submission', 'assesment', 'exchange', 'order-initiation', 'order-mgmt'];

const InterestHeader = ({activeInterest}) => {
  return (
    <StyledInterestHeader>
      <ProjectActionItem simple={true} {...activeInterest} />
      <OrderStatusBar statuses={statuses} status={statusMap[activeInterest.status] || 'submission'} style={statusBarStyle} />
    </StyledInterestHeader>
  )
}

export default InterestHeader
