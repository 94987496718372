import React from 'react';
import { SM, MD } from '@zendeskgarden/react-typography';
import { Row, Col } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';
import AcrobatDownload from 'svg/acrobatDownload';
import Icon from 'components/Icon/Icon';
import {
  DocumentWrapper,
} from './DocumentDownload.styles.js';

const {
  spacing_xxs,
  custom_grey
} = variables;

 
const DocumentDownload = ({documentItem = {}}) => { 
  const {title="CRA Quote - Version 1", size="11MB", type ="PDF"} = documentItem;

  return (
    <DocumentWrapper>
      <Row>
        <Col size={'auto'}>
          <AcrobatDownload style={{height: '40px'}}/>
        </Col>

        <Col>
          <MD bold>
            {title}
          </MD>
          <SM style={{paddingTop: spacing_xxs}}>
            <span>{size} | {type}</span>
          </SM>
        </Col>

        <Col size={'auto'} alignCenter>
          <Icon icon="icon-download" color={custom_grey} fontSize="18px" />
        </Col>
      </Row>
    </DocumentWrapper>
  )
}


export default DocumentDownload
