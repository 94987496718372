import { get, keyBy, map } from 'lodash';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import tree from 'state';
import * as effects from './effects';

const interestCursor = tree.select(['interests']);

export async function getMyInterests({pagination} = {}) {
  const type = 'rfq';
  const currentMap = interestCursor.get([type, 'map']);

  interestCursor.set([type, 'loading'], true);
  try {
    const data = await effects.getRfqGroups({pagination});
    const items = get(data, 'docs');
    interestCursor.set([type, 'map'], keyBy(map(items,(itm) => createRfqMapItem(itm, get(currentMap, `${itm.id}`))), 'id'));
    interestCursor.set([type,'pagination'], data);
    interestCursor.set([type, 'loading'], false);
    interestCursor.set([type, 'error'], false);
    return data
  } catch(err) {
    interestCursor.set([type, 'loading'], false);
    interestCursor.set([type, 'error'], err.message);
    return {
      error: err.message
    }
  }
}

function createRfqMapItem(item, current = {}) {
  return {
    ...current,
    id: item.id,
    type: item.document_type,
    projectName: item.project_name,
    status: item.status_display,
    notifications: [],
    address: item.need_location,
    city: item.need_location,
    coordinates: {
      lat: item.latitude,
      lng: item.longitude
    },
    quoteDeadline: item.need_date,
    submitDate: item.create_timestamp, 
    referenceNumber: `${item.document_type}-${item.id}`, // DONT HAVE
    
    items: 'View',
    confidenceLevel: 'High',
    wellName: 'TODO Well Name',
    facilitator: 'facilitator', 
    referenceNum: 'referenceNumber',
  }
}

export async function getRfqGroupItems(interestId) {
  try {
    const data = await effects.getRfqGroupItems(interestId);
    interestCursor.set(['rfq', 'items', interestId], {docs: keyBy(data.docs, 'id'), ...data});
    return data
  } catch(err) {
    return {
      error: err.message
    }
  }
}

export async function setActiveInterest(id) {
  interestCursor.set(['rfq','activeId'], id);
}

export async function getRfqDetails(rfqId) {
  asyncTreeRequester({
    func: effects.getRfqDetails.bind(this, {rfqId}),
    cursor: interestCursor,
    path: ['rfq','summary', rfqId],
  });
}

export function resetRfqDetails() {
  interestCursor.set(['rfq','activeId'], null);
}
