import styled from 'styled-components';
import {variables} from './variables';
const {
  app_page_padding,
  media_sm,
  media_md,
  app_page_padding_medium,
  app_page_padding_small,
  sidebar_menu_width,
  sidebar_menu_minimized,
  app_header_height,
  app_mobile_header_height,
  sidebar_padding,
  app_footer_height,
  spacing,
} = variables

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.background || undefined}; 
  padding: ${props => props.padding || props.mobilePadding || app_page_padding_small};
  @media ${media_sm} {
   padding: ${props => props.padding || props.tabletPadding || app_page_padding_medium};
  }
  @media ${media_md} {
    padding: ${props => props.padding || app_page_padding};
  }
`;


export const SidebarPage = styled.div`
  padding: ${app_page_padding_small};
  @media ${media_md} {
    padding-left: ${sidebar_menu_width};
    padding-bottom: ${app_page_padding};
    padding-right: ${app_page_padding};
    transition: all 0.2s ease;
    ${props => props.sidebarMinimized ? `
      padding-left: ${sidebar_menu_minimized};
    ` : ''}
  }
`;

export const SidebarPageBody = styled.div`
  padding: ${app_page_padding_small};
  position: relative;
  background-color: #fff;
  @media ${media_md} {
    padding: ${app_page_padding_small};
  }
`;


export const SidebarPageMenu = styled.section`
  padding-top: 0;
  top: ${app_mobile_header_height};
  white-space: nowrap;
  overflow: scroll;
  height: 50px;
  padding: 0;
  width: 100%;
  margin-bottom: ${spacing};
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  & {
    ::-webkit-scrollbar {display: none }
  }
  @media ${media_md} {
    display: flex;
    white-space: unset;
    padding: ${sidebar_padding};
    display: block;
    height: auto;
    position: fixed;
    left: 0;
    margin-bottom: 0;
    width: ${sidebar_menu_width};
    overflow: scroll;
    min-height: calc(100% - ${app_header_height});
    top: ${app_header_height};
    padding-bottom: ${app_footer_height};
    transition: all 0.2s ease;
    button {
      min-width: 100% !important;
    }
    ${props => props.sidebarMinimized ? `
      width: ${sidebar_menu_minimized};
    ` : ''}
  }
`

// li {
//     width: auto !important;
//     white-space: nowrap;
//   }