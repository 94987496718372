import React from 'react';
import Icon from 'components/Icon/Icon';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField, Label, Input, Message } from '@zendeskgarden/react-textfields';
import {variables} from './variables';
const {spacing, spacing_sm, color_red_400, spacing_lg} = variables

const FormInputWrapper = styled.div`
  margin-top: ${spacing_sm};
`;

export const InputMediaIconWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  ${props => props.right ? `
    right: 3px;
    background: #fff;
    top: 4px;
    bottom: 4px
  ` : ''}
`;

export const GroupedItemSelectionInputItem = styled.div`
  margin-top: ${spacing_lg};
  ${props => props.noSpacing ? `
    margin-top: 0;
  ` : ''}
`;

export const Form = styled.form`
  footer {
    margin-top: ${spacing};
  }
`;

export const FormFooter = styled.div`
  margin-top: ${spacing};
  ${props => props.centeredButton ? `
    display: flex;
    justify-content: center;
  ` : ''}
`;

export const InputMediaWrapper = styled.div`
  position: relative;
`;


export const FormInput = ({icon, error, showMessage=false, scaled, label, ...rest}) => (
  <FormInputWrapper>
    <TextField>
      {
        label ?
        <Label style={error ? {color: color_red_400} : {}}>{label}</Label> : null
      }
      <InputMediaWrapper>
        <Input
          {...rest}
          icon={icon ? 'icon' : ''}
          scaled={scaled}
          validation={error ? 'error' : undefined}
        />
        {
          icon ?
          <InputMediaIconWrapper style={{width: '40px'}}>
            <Icon alignCenter justifyCenter icon={icon} fontSize="15px"/>
          </InputMediaIconWrapper> : null
        }
      </InputMediaWrapper>
      {
        error && showMessage ?
        <Message validation={"error"}>{error}</Message> : null
      }
    </TextField>
  </FormInputWrapper>
);

FormInput.propTypes = {
  values: PropTypes.object,
  // error: PropTypes.oneOf(['String', 'boolean']),
  showMessage: PropTypes.bool
};
