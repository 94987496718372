import React from 'react';

import Icon from 'components/Icon/Icon';
import {
  TableWrapper,
  ElementalTable
} from '../../ViewItemDetails.styles.js';

import {
  Table,
  Caption,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@zendeskgarden/react-tables';

import { variables } from 'theme/variables';
const {
  custom_button_blue
} = variables;


const ElementalCompositionTable = (props) => {
  return (
    <TableWrapper>
      <ElementalTable>
      <Table style={{maxWidth: `478px`}}>
        <Head>
          <HeaderRow className="table-header-elemental">
            <HeaderCell className="cell-font" width="9%">C</HeaderCell>
            <HeaderCell className="cell-font" width="9%">CR</HeaderCell>
            <HeaderCell className="cell-font" width="9%">Ni</HeaderCell>
            <HeaderCell className="cell-font" width="9%">Mo</HeaderCell>
            <HeaderCell className="cell-font" width="9%">Ti</HeaderCell>
            <HeaderCell className="cell-font" width="9%">V</HeaderCell>
            <HeaderCell className="cell-font" width="9%">Si</HeaderCell>
            <HeaderCell className="cell-font" width="9%">Fe</HeaderCell>
            <HeaderCell className="cell-font" width="9%">Mn</HeaderCell>
            <HeaderCell className="cell-font" width="9%">P</HeaderCell>
            <HeaderCell className="cell-font" width="10%">S</HeaderCell>
          </HeaderRow>
        </Head>
        <Body>
          <Row className="table-row">
            <Cell className="cell-font" width="9%"><Icon icon="icon-close" color={custom_button_blue} style={{ color: `#3E3E3E`, fontSize: `11px`}} /></Cell>
            <Cell className="cell-font" width="9%">11.5%</Cell>
            <Cell className="cell-font" width="9%">4.5%</Cell>
            <Cell className="cell-font" width="9%">1.5%</Cell>
            <Cell className="cell-font" width="9%">0.01%</Cell>
            <Cell className="cell-font" width="9%"><Icon icon="icon-close" color={custom_button_blue} style={{ color: `#3E3E3E`, fontSize: `11px`}} /></Cell>
            <Cell className="cell-font" width="9%"><Icon icon="icon-close" color={custom_button_blue} style={{ color: `#3E3E3E`, fontSize: `11px`}} /></Cell>
            <Cell className="cell-font" width="9%"><Icon icon="icon-close" color={custom_button_blue} style={{ color: `#3E3E3E`, fontSize: `11px`}} /></Cell>
            <Cell className="cell-font" width="9%"><Icon icon="icon-close" color={custom_button_blue} style={{ color: `#3E3E3E`, fontSize: `11px`}} /></Cell>
            <Cell className="cell-font" width="9%"><Icon icon="icon-close" color={custom_button_blue} style={{ color: `#3E3E3E`, fontSize: `11px`}} /></Cell>
            <Cell className="cell-font" width="10%"><Icon icon="icon-close" color={custom_button_blue} style={{ color: `#3E3E3E`, fontSize: `11px`}} /></Cell>
          </Row>
          <Row className="table-row">
            <Cell className="cell-font" width="9%">0.03%</Cell>
            <Cell className="cell-font" width="9%">13.5%</Cell>
            <Cell className="cell-font" width="9%">6.5%</Cell>
            <Cell className="cell-font" width="9%">3.0%</Cell>
            <Cell className="cell-font" width="9%">0.5%</Cell>
            <Cell className="cell-font" width="9%">0.5%</Cell>
            <Cell className="cell-font" width="9%">0.5%</Cell>
            <Cell className="cell-font" width="9%">0.5%</Cell>
            <Cell className="cell-font" width="9%">0.5%</Cell>
            <Cell className="cell-font" width="9%">0.02%</Cell>
            <Cell className="cell-font" width="10%">0.005%</Cell>
          </Row>
        </Body>
      </Table>
      </ElementalTable>
    </TableWrapper>
  )
}

export default ElementalCompositionTable;
