import tree from 'state';
import * as effects from './effects';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import {state} from './state';

const requestForQuoteCursor = tree.select(['requestForQuote']);

export async function getRfqMeta() {
  await asyncTreeRequester({
    func: effects.getRfqMeta,
    cursor: requestForQuoteCursor,
    path: ['meta']
  });  
}

export async function resetActiveRfq() {
  const initialState = state();
  const meta = requestForQuoteCursor.get('meta');
  requestForQuoteCursor.set({...initialState, meta});
}


export async function getActiveRfq(id) {
  const rfq = await asyncTreeRequester({
    func: effects.getRfqGroup.bind(this, id),
    cursor: requestForQuoteCursor,
    path: ['activeRfq', 'group'],
  });
  
  if (rfq && !rfq.error) {
    requestForQuoteCursor.set('id', id);
    tree.commit();
  } else if(rfq && rfq.error) {
    // TODO set error
  }
  return rfq;
}


export async function createRfq(data) {
  const rfq = await asyncTreeRequester({
    func: effects.createRfqGroup.bind(this, data),
    cursor: requestForQuoteCursor,
    path: ['activeRfq', 'group'] 
  });

  if (rfq && rfq.id) {
    requestForQuoteCursor.set('id', rfq.id);
    var oldUrl = window.location.href;
    var newUrl = oldUrl.replace('/m/rfq/', `/m/rfq/${rfq.id}/`)
    window.history.replaceState(null, null, newUrl)
  }
}

export async function updateRfq(rfqId, data) {
  await asyncTreeRequester({
    func: effects.updateRfqGroup.bind(this, rfqId, data),
    cursor: requestForQuoteCursor,
    path: ['activeRfq', 'group'],
  });
}

export async function clearError(rfqId, data) {
  requestForQuoteCursor.set(['activeRfq', 'group', 'error'], false);
}


export async function submitRfq(rfqId, data) {
  return await asyncTreeRequester({
    func: effects.submitRfq.bind(this, rfqId, data),
    handleResult: true
  });
}











export async function getActiveRfqForOverview(rfqId) {
  // asyncTreeRequester({
  //   func: effects.getRfqGroup.bind(this, rfqId),
  //   cursor: requestForQuoteCursor,
  //   path: ['activeRfq', 'group'],
  // });

  // asyncTreeRequester({
  //   func: effects.getRfqGroupItems.bind(this, rfqId),
  //   cursor: requestForQuoteCursor,
  //   path: ['activeRfq', 'items']
  // });

  // asyncTreeRequester({
  //   func: effects.getRfqWellDetails.bind(this, rfqId),
  //   cursor: requestForQuoteCursor,
  //   path: ['activeRfq', 'well']
  // });

  // asyncTreeRequester({
  //   func: effects.getRfqGroupItemOverrides.bind(this, rfqId),
  //   cursor: requestForQuoteCursor,
  //   path: ['activeRfq', 'itemOverrides']
  // });

  const a = await asyncTreeRequester({
    func: effects.getRfqGroupSummary.bind(this, rfqId),
    cursor: requestForQuoteCursor,
    path: ['activeRfq', 'summary']
  });
  // console.log(a)
}










