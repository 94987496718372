import React from 'react';
import { XXL, XL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from 'components/Icon/Icon';
import { Button } from 'theme/Button';

import {
  BlogPostsSection,
  BackgroundImg,
  BlogImg,
  BackgroundCircle,
  BlogCard,
  PostCard,
  PostImg,
  BlogWrapper,
  BlogPostWrapper,
  PostsWrapper,
  PostContentWrapper,
  BgCircleWrapper,
  ServiceBlock,
  CardWrapper,
} from '../../Blog.styles';

import { Wrapper } from 'components/PublicPages/GlobalPublicStyles';

const { custom_button_blue } = variables;

const BlogPosts = (props) => {
  return (
    <BlogPostsSection>
      <BgCircleWrapper>
        <BackgroundCircle className='right-circle' />
        <BackgroundCircle className='left-circle' />
      </BgCircleWrapper>
      <Wrapper style={{ width: `100%`, maxWidth: `1216px` }}>
        <BlogWrapper>
          <BlogImg src='/images/blog-hero-image.jpg' />
          <BlogCard>
            <BlogPostWrapper>
              <XL left tag='h3'>
                Example Of A Wonderful Blog Post Title
              </XL>
              <MD left tag='h5'>
                July 12, 2019 &nbsp; | &nbsp; Support
              </MD>
              <MD left className='display-none-mobile' tag='p'>
                All Day Supply and Demand. Technology. Experience.
                Infrastructure. Only Global Pipe Co. brings state of the art
                technology, decades of industry experience, and
                boots-on-the-ground resources to deliver a more modern
                experience and superior product.
              </MD>
              <Button publicButton>Read More</Button>
            </BlogPostWrapper>
          </BlogCard>
        </BlogWrapper>
        <Flex justifyCenter full>
          <FlexItem>
            <PostsWrapper>
              <XXL left tag='h2'>
                Recent Blog Posts
              </XXL>
              <Grid>
                <Row>
                  <Col md={12}>
                    <ServiceBlock>
                      <CardWrapper>
                        <PostImg
                          className='left-image'
                          src='/images/pipe-product.jpg'
                        />
                        <PostCard>
                          <PostContentWrapper>
                            <MD left tag='h6'>
                              Example Of A Blog Post Title Here
                            </MD>
                            <MD left tag='p' className='date'>
                              July 12, 2019 &nbsp; | &nbsp; Support
                            </MD>
                            <MD left tag='p' className='read-more'>
                              <a className='links' href='#'>
                                Read More{' '}
                                <Icon
                                  icon='icon-arrow-right-long'
                                  color={custom_button_blue}
                                  style={{
                                    display: `inline`,
                                    position: `relative`,
                                    top: `3px`,
                                  }}
                                />
                              </a>
                            </MD>
                          </PostContentWrapper>
                        </PostCard>
                      </CardWrapper>
                      <CardWrapper>
                        <PostImg
                          className='middle-image'
                          src='/images/pipe-product.jpg'
                        />
                        <PostCard>
                          <PostContentWrapper>
                            <MD left tag='h6'>
                              Example Of A Blog Post Title Here
                            </MD>
                            <MD left tag='p' className='date'>
                              July 12, 2019 &nbsp; | &nbsp; Support
                            </MD>
                            <MD left tag='p' className='read-more'>
                              <a className='links' href='#'>
                                Read More{' '}
                                <Icon
                                  icon='icon-arrow-right-long'
                                  color={custom_button_blue}
                                  style={{
                                    display: `inline`,
                                    position: `relative`,
                                    top: `3px`,
                                  }}
                                />
                              </a>
                            </MD>
                          </PostContentWrapper>
                        </PostCard>
                      </CardWrapper>
                      <CardWrapper>
                        <PostImg
                          className='right-image'
                          src='/images/pipe-product.jpg'
                        />
                        <PostCard>
                          <PostContentWrapper>
                            <MD left tag='h6'>
                              Example Of A Blog Post Title Here
                            </MD>
                            <MD left tag='p' className='date'>
                              July 12, 2019 &nbsp; | &nbsp; Support
                            </MD>
                            <MD left tag='p' className='read-more'>
                              <a className='links' href='#'>
                                Read More{' '}
                                <Icon
                                  icon='icon-arrow-right-long'
                                  color={custom_button_blue}
                                  style={{
                                    display: `inline`,
                                    position: `relative`,
                                    top: `3px`,
                                  }}
                                />
                              </a>
                            </MD>
                          </PostContentWrapper>
                        </PostCard>
                      </CardWrapper>
                    </ServiceBlock>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <ServiceBlock>
                      <CardWrapper>
                        <PostImg
                          className='left-image'
                          src='/images/pipe-product.jpg'
                        />
                        <PostCard>
                          <PostContentWrapper>
                            <MD left tag='h6'>
                              Example Of A Blog Post Title Here
                            </MD>
                            <MD left tag='p' className='date'>
                              July 12, 2019 &nbsp; | &nbsp; Support
                            </MD>
                            <MD left tag='p' className='read-more'>
                              <a className='links' href='#'>
                                Read More{' '}
                                <Icon
                                  icon='icon-arrow-right-long'
                                  color={custom_button_blue}
                                  style={{
                                    display: `inline`,
                                    position: `relative`,
                                    top: `3px`,
                                  }}
                                />
                              </a>
                            </MD>
                          </PostContentWrapper>
                        </PostCard>
                      </CardWrapper>
                      <CardWrapper>
                        <PostImg
                          className='middle-image'
                          src='/images/pipe-product.jpg'
                        />
                        <PostCard>
                          <PostContentWrapper>
                            <MD left tag='h6'>
                              Example Of A Blog Post Title Here
                            </MD>
                            <MD left tag='p' className='date'>
                              July 12, 2019 &nbsp; | &nbsp; Support
                            </MD>
                            <MD left tag='p' className='read-more'>
                              <a className='links' href='#'>
                                Read More{' '}
                                <Icon
                                  icon='icon-arrow-right-long'
                                  color={custom_button_blue}
                                  style={{
                                    display: `inline`,
                                    position: `relative`,
                                    top: `3px`,
                                  }}
                                />
                              </a>
                            </MD>
                          </PostContentWrapper>
                        </PostCard>
                      </CardWrapper>
                      <CardWrapper>
                        <PostImg
                          className='right-image'
                          src='/images/pipe-product.jpg'
                        />
                        <PostCard>
                          <PostContentWrapper>
                            <MD left tag='h6'>
                              Example Of A Blog Post Title Here
                            </MD>
                            <MD left tag='p' className='date'>
                              July 12, 2019 &nbsp; | &nbsp; Support
                            </MD>
                            <MD left tag='p' className='read-more'>
                              <a className='links' href='#'>
                                Read More{' '}
                                <Icon
                                  icon='icon-arrow-right-long'
                                  color={custom_button_blue}
                                  style={{
                                    display: `inline`,
                                    position: `relative`,
                                    top: `3px`,
                                  }}
                                />
                              </a>
                            </MD>
                          </PostContentWrapper>
                        </PostCard>
                      </CardWrapper>
                    </ServiceBlock>
                  </Col>
                </Row>
              </Grid>
            </PostsWrapper>
          </FlexItem>
        </Flex>
        <Flex justifyCenter>
          <FlexItem>
            <Button className='view-more' publicButton>
              View More Posts
            </Button>
          </FlexItem>
        </Flex>
      </Wrapper>
    </BlogPostsSection>
  );
};

export default BlogPosts;
