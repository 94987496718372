import UAParser from 'ua-parser-js';
import {canUseDOM} from 'exenv';

import {state as authentication} from './authentication/state';
import {state as dashboard} from './dashboard/state';
import {state as modals} from './modals/state';
import {state as sidebars} from './sidebars/state';
import {state as sidebarPage} from './sidebarPage/state';
import {state as interests} from './interests/state';
import {state as requestForQuote} from './requestForQuote/state';
import {state as onboarding} from './onboarding/state';


export default function getInitialState(initialState = {}) {
  const uaParser = new UAParser();
  if (initialState.userAgent) {
    uaParser.setUA(initialState.userAgent);
  }

  if (canUseDOM) {
    console.log('__INITIAL_STATE__', window.__INITIAL_STATE__)
  }

  return {
    cursors : {
      // app based state
      browser: uaParser.getResult(),
      // component based state:
      authentication: authentication(initialState),
      dashboard: dashboard(initialState),
      modals: modals(initialState),
      sidebars: sidebars(initialState),
      sidebarPage: sidebarPage(initialState),
      interests: interests(initialState),
      requestForQuote: requestForQuote(initialState),
      onboarding: onboarding(initialState)
    },
  };
}