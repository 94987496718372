import styled from 'styled-components';
import { SM, LG } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
const {
  spacing,
  spacing_xxs,
  custom_light_grey
} = variables;

export const StyledMessageItem = styled('div')`
  padding: ${props => props.padding || `${spacing} 0`};
  border-bottom: 1px solid ${custom_light_grey}
  &:first-child {
    padding-top: 0;
  }
`
export const StyledMessageText = styled(LG)`
  padding-top: ${spacing_xxs};
`

export const StyledMessageSubject = styled(LG)`
 
`

export const StyledMessageSender = styled(SM)`
  padding-top: ${spacing_xxs};  
`