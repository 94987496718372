import React, { useEffect, useRef } from 'react';
import {useBranch} from 'baobab-react/hooks';
import history from 'historyContainer';
import LoginForm from 'components/BasicForms/LoginForm';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import {
  LoginPage,
  LoginWrapper,
  PageWrapper,
  LeftWrapper
} from './login.styles';


const Login = () => {
  const mountedRef = useRef();

  const {authentication} = useBranch({
    authentication: ['authentication']
  });
  
  useEffect(() => {
    if (authentication.session && mountedRef) {
      history.push('/dashboard')
    }
    mountedRef.current = true;
  }, [authentication.session]);
  
  return (
    <LoginPage>
      <PageWrapper>
        <Grid fluid>
          <Row style={{height: '100%'}}>
            <LeftWrapper size={12} sm={12} md={6}>
              
            </LeftWrapper>
            <Col size={12} sm={12} md={6}>
              <LoginWrapper>
                <LoginForm />
              </LoginWrapper>
            </Col>
          </Row>
        </Grid>
      </PageWrapper>
    </LoginPage>
  )
}


export default PageViewWrapper(Login, {pageName: 'Login'})