import React from 'react';
import { XXXL, XXL, XL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from 'components/Icon/Icon';
import { Button } from 'theme/Button';
import { Tabs, TabPanel } from '@zendeskgarden/react-tabs';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {
  HelpSection,
  BackgroundCircle,
  DividingLineNav,
  SearchFieldWrapper,
  HelpCenterButton,
  Wrapper,
  ContentWrapper,
  Line,
  NavSideBar,
  Oval,
  BgCircleWrapper,
  DivideMobile,
} from '../../HelpCenter.styles';

import { SectionArrow } from 'components/PublicPages/GlobalPublicStyles';

import DividingLine from 'components/PublicPages/DividingLine';

const { spacing } = variables;

const InfoSection = (props) => {
  return (
    <HelpSection>
      <SectionArrow />
      <BgCircleWrapper>
        <BackgroundCircle className='circle-right' />
        <BackgroundCircle className='circle-left' />
      </BgCircleWrapper>
      <Wrapper style={{ maxWidth: `100%` }}>
        <Grid>
          <Tabs className='vertical-tabs' vertical>
            <TabPanel label='Overview' key='tab-1'>
              <Flex className='tab-content' justifyCenter full>
                <FlexItem>
                  <Col md={12}>
                    <ContentWrapper>
                      <XXL left tag='h2' className='title'>
                        Overview
                      </XXL>
                      <MD left tag='p' className='text'>
                        Using Global Pipe Co. technology, we are able to
                        immediately assess and calculate the available options
                        and the likelihood of executing in a given time frame.
                      </MD>
                      <Accordion allowZeroExpanded className='top-accordion'>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I get Started?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                What do I need to Register?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Search For Assets?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                When Will My Assets Be Delivered?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Submit an RFQ?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Can I Batch-Upload All of My Data?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                Can I Buy & Sell Inventory?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </ContentWrapper>
                  </Col>
                </FlexItem>
              </Flex>
            </TabPanel>
            <TabPanel label='Buyers' key='tab-2'>
              <Flex className='tab-content' justifyCenter>
                <FlexItem>
                  <Col md={12}>
                    <ContentWrapper>
                      <XXL left tag='h2' className='title'>
                        Buyers
                      </XXL>
                      <MD left tag='p' className='text'>
                        Using Global Pipe Co. technology, we are able to
                        immediately assess and calculate the available options
                        and the likelihood of executing in a given time frame.
                      </MD>
                      <Accordion allowZeroExpanded className='top-accordion'>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I get Started?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                What do I need to Register?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Search For Assets?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                When Will My Assets Be Delivered?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Submit an RFQ?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Can I Batch-Upload All of My Data?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                Can I Buy & Sell Inventory?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </ContentWrapper>
                  </Col>
                </FlexItem>
              </Flex>
            </TabPanel>
            <TabPanel label='Sellers' key='tab-3'>
              <Flex className='tab-content' justifyCenter>
                <FlexItem>
                  <Col md={12}>
                    <ContentWrapper>
                      <XXL left tag='h2' className='title'>
                        Sellers
                      </XXL>
                      <MD left tag='p' className='text'>
                        Using Global Pipe Co. technology, we are able to
                        immediately assess and calculate the available options
                        and the likelihood of executing in a given time frame.
                      </MD>
                      <Accordion allowZeroExpanded className='top-accordion'>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I get Started?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Seller Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Seller Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Seller Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Seller Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                What do I need to Register?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Seller Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Seller Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Seller Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Seller Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Search For Assets?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Seller Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Seller Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Seller Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Seller Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                When Will My Assets Be Delivered?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Seller Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Seller Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Seller Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Seller Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Submit an RFQ?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Seller Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Seller Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Seller Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Seller Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Can I Batch-Upload All of My Data?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Seller Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Seller Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Seller Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Seller Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                Can I Buy & Sell Inventory?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Seller Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Seller Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Seller Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Seller Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </ContentWrapper>
                  </Col>
                </FlexItem>
              </Flex>
            </TabPanel>
            <TabPanel label='Facilitators' key='tab-4'>
              <Flex className='tab-content' justifyCenter>
                <FlexItem>
                  <Col md={12}>
                    <ContentWrapper>
                      <XXL left tag='h2' className='title'>
                        Facilitators
                      </XXL>
                      <MD left tag='p' className='text'>
                        Using Global Pipe Co. technology, we are able to
                        immediately assess and calculate the available options
                        and the likelihood of executing in a given time frame.
                      </MD>
                      <Accordion allowZeroExpanded className='top-accordion'>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I get Started?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Facilitator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Facilitator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Facilitator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Facilitator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                What do I need to Register?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Facilitator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Facilitator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Facilitator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Facilitator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Search For Assets?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Facilitator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Facilitator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Facilitator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Facilitator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                When Will My Assets Be Delivered?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Facilitator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Facilitator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Facilitator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Facilitator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Submit an RFQ?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Facilitator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Facilitator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Facilitator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Facilitator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Can I Batch-Upload All of My Data?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Facilitator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Facilitator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Facilitator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Facilitator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                Can I Buy & Sell Inventory?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Facilitator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Facilitator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Facilitator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Facilitator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </ContentWrapper>
                  </Col>
                </FlexItem>
              </Flex>
            </TabPanel>
            <TabPanel label='Administrators' key='tab-5'>
              <Flex className='tab-content' justifyCenter>
                <FlexItem>
                  <Col md={12}>
                    <ContentWrapper>
                      <XXL left tag='h2' className='title'>
                        Administrators
                      </XXL>
                      <MD left tag='p' className='text'>
                        Using Global Pipe Co. technology, we are able to
                        immediately assess and calculate the available options
                        and the likelihood of executing in a given time frame.
                      </MD>
                      <Accordion allowZeroExpanded className='top-accordion'>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I get Started?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Administrator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Administrator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Administrator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Administrator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                What do I need to Register?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Administrator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Administrator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Administrator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Administrator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Search For Assets?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Administrator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Administrator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Administrator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Administrator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                When Will My Assets Be Delivered?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Administrator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Administrator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Administrator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Administrator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Submit an RFQ?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Administrator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Administrator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Administrator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Administrator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Can I Batch-Upload All of My Data?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Administrator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Administrator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Administrator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Administrator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                Can I Buy & Sell Inventory?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Administrator Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Administrator Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Administrator Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Administrator Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </ContentWrapper>
                  </Col>
                </FlexItem>
              </Flex>
            </TabPanel>
            <TabPanel label='Account Setup' key='tab-6'>
              <Flex className='tab-content' justifyCenter>
                <FlexItem>
                  <Col md={12}>
                    <ContentWrapper>
                      <XXL left tag='h2' className='title'>
                        Account Setup
                      </XXL>
                      <MD left tag='p' className='text'>
                        Using Global Pipe Co. technology, we are able to
                        immediately assess and calculate the available options
                        and the likelihood of executing in a given time frame.
                      </MD>
                      <Accordion allowZeroExpanded className='top-accordion'>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I get Started?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                What do I need to Register?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Search For Assets?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                When Will My Assets Be Delivered?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Do I Submit an RFQ?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                How Can I Batch-Upload All of My Data?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                        <Line />
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <XL left tag='h3'>
                                Can I Buy & Sell Inventory?
                              </XL>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Information Needed to Register
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Another Buyer Article Two
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Yet Another Buyer Article Three
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                Here is Another Buyer Article Four
                              </a>
                            </MD>
                            <br />
                            <Oval className='grey-oval' />{' '}
                            <MD left tag='p'>
                              <a href='#' className='accordion-links'>
                                This is Buyer Article Five
                              </a>
                            </MD>
                            <br />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </ContentWrapper>
                  </Col>
                </FlexItem>
              </Flex>
            </TabPanel>
          </Tabs>
        </Grid>
      </Wrapper>
      <Grid>
        <Row>
          <Col md={12}>
            <DividingLine className='bottom-divide' />
            <XXL center tag='h2' className='more-help'>
              Looking For More Help?
            </XXL>
            <Flex justifyCenter>
              <FlexItem>
                <Button publicButton>Contact Us</Button>
              </FlexItem>
            </Flex>
          </Col>
        </Row>
      </Grid>
    </HelpSection>
  );
};

export default InfoSection;
