import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import CompareHeader from './components/CompareHeader/CompareHeader'
import CompareTable from './components/CompareTable/CompareTable'

import { CompareItemsPage } from './CompareItems.styles'

const CompareItems = (props) => {

  return (
    <CompareItemsPage style={{ padding: `0`, overflow: `hidden` }}>
      <CompareHeader />
      <CompareTable />
    </CompareItemsPage>
  )
}

export default PageViewWrapper(CompareItems, { pageName: 'CompareItems' });