import {canUseDOM} from 'exenv';
let url;
let environment;

if (canUseDOM && process.env.REACT_APP_LOCAL_BASE) {
  url = process.env.REACT_APP_LOCAL_BASE;
} else if(canUseDOM) {
  url = window.__BASE_API__;
}

if (canUseDOM && window.__ENVIRONMENT__) {
  environment = window.__ENVIRONMENT__;
} else if(canUseDOM) {
  environment = 'dev'
}


export const BASE = url;
export const ENVIRONMENT = environment;