import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
import { Button } from '@zendeskgarden/react-buttons';

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';

const {
  custom_dark_blue,
  color_white,
  custom_dark_grey,
  spacing_sm,
  media_sm,
  media_md,
  media_lg,
  public_font_size_md,
  public_font_size_xxl,
  public_font_size,
  custom_button_blue
} = variables;




export const SearchTemplatePage = styled(Page)`

`;

export const SearchSection = styled(Section)`
  background: linear-gradient(135deg, #001E47 0%, #001A3D 100%);
  position: relative;
  padding: 120px 0 100px 0;
  width: 100%;
  height: 1000px;

  .left-circle {
    left: -30vw; 
    bottom: 0; 
    top: -130px; 
    margin: 0 auto;
  }

  .right-circle {
    left: 79vw; 
    bottom: 0 
    top: -24px; 
    margin: 0 auto; 
  }

  #name {
    padding-bottom: 20px;
  }

  @media ${media_md} {
    height: 1200px;
    padding: 200px 0 200px 0;

    h1 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
    }

    .left-circle {
      left: -30vw; 
      bottom: 0; 
      top: -312px; 
      margin: 0 auto;
    }

    .right-circle {
      left: 79vw; 
      bottom: 0 
      top: -160px; 
      margin: 0 auto; 
    }
  }

  @media ${media_lg} {
    .left-circle {
      left: -440px; 
      bottom: 0; 
      top: -830px; 
      margin: 0 auto;
    }

    .right-circle {
      left: 79vw; 
      bottom: 0 
      top: -120px; 
      margin: 0 auto; 
    }
  }
`;

export const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
`

export const BackgroundCircle = styled.div`
  overflow: hidden;
  position: absolute;
  height: 290px;	
  width: 290px;	
  border: 50px solid rgba(255, 255, 255, 0.02);
  border-radius: 50%;  

  @media ${media_md} {
    height: 580px;	
    width: 580px;	
    border: 100px solid rgba(255, 255, 255, 0.02);
    border-radius: 50%;  
  }

  @media ${media_lg} {
    height: 1160px;	
    width: 1160px;	
    border: 200px solid rgba(255, 255, 255, 0.02);
    border-radius: 50%;  
  }
`
export const Logo = styled.img`
  position: absolute;
  height: 31px;
  width: 162px;
  top: 41px;
  left: 25px;

  @media ${media_md} {
    height: 44px;
    width: 230px;
    top: 48px;
    left: 80px;
  }
`

export const DividingLine = styled.div`
  width: 210px;
  height: 2px;
  border-bottom: 2px dotted rgba(255,255,255,0.27);
  margin: 60px 0 0 0;

  @media ${media_md} {
    margin: 200px 0 0 0;
  }

  @media ${media_lg} {
    margin: 60px 0 0 0;
  }
`;

export const SearchFieldWrapper = styled.div`
  height: auto;
  width: 75vw;
  input {
    position: relative;
    z-index: 10;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    background: transparent;
    font-size: ${public_font_size};
    padding: 0 0 16px 32px;
    color: ${color_white};
    margin: 80px 0 0 0;
    font-color: rbga(255,255,255,0.4);
    ::placeholder {
      color: #67778d;
      padding-left: 30px;
    }

    :-ms-input-placeholder {
      color: #67778d;
      padding-left: 30px;
    }

    ::-ms-input-placeholder {
      color: #67778d;
      padding-left: 30px;
    }
  }

  h1 {
      margin: 0 0 0 0;
      letter-spacing: 2px;
    }

  h5 {
    padding-bottom: 20px;
  }

  .search-button {
    margin: 64px 0 0 0;
  }
  
  @media ${media_lg} {
    width: 800px;
  }
`

export const SearchButton = styled(Button)`
  padding: 20px 20px;
  border-radius: 8px;	
  margin: 90px 0 30px 0;
  width: 217px;
  background-color: ${custom_button_blue};	
  box-shadow: 0 15px 30px -12px rgba(0,0,0,0.5);
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 21px;

  
`;

export const Close = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  height: 24px;
  width: 24px;
  background-color: ${color_white};
  border-radius: 50%;

  @media ${media_md} {
    right: 64px;
    top: 64px;
    height: 48px;
    width: 48px;
  }
`
