import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Button } from 'theme/Button';
const {
  custom_dark_blue,
  color_white,
  public_font_size_md,
  font_weight_regular,
  line_height_lg,
  custom_blue,
  media_lg
} = variables;

export const MobileMenuComponent = styled.div`
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${custom_dark_blue};
  padding: 124px 24px 0px;
  pointer-events: none;
  transform: translateX(100%);
  z-index: 2;
  // opacity: 0;
  // clip-path: inset(0px 0px 0px 50%);
  transition: transform 0.6s cubic-bezier(0.220, 0.265, 0.085, 0.980) 0.3s, clip-path 0.4s cubic-bezier(0.220, 0.265, 0.085, 0.980) 0.2s;
  will-change: transform, clip-path, opacity;
  @media ${media_lg} {
    display: none;
  }

  


  .menu-background {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: -1;
    background: rgb(0, 29, 67);
    pointer-events: none;

    .circle-1 {
      transform: translateX(-300px);
      transition: transform 0.5s cubic-bezier(0.220, 0.265, 0.085, 0.980) 0.5s;
      will-change: transform;
    }
    .circle-2 {
      transform: translateX(300px);
      transition: transform 0.5s cubic-bezier(0.220, 0.265, 0.085, 0.980) 0.7s;
      will-change: transform;
    }
  }

  ${props => props.active ? `
    // opacity: 1;
    transform: translateX(0%);
    pointer-events: all;
    // clip-path: inset(0px);
    transition-delay: 0.0s;

    .menu-background .circle-1, .menu-background .circle-2 {
      transform: translateX(0px);
    }
  `: ''}

`;




export const MobileMenuButton = styled(Button)`
  width: 100%;
  margin-bottom: ${line_height_lg};
  border-radius: 4px;
  opacity: 0;
  transform: translateY(40px);
  will-change: transform, opacity;

  ${props => props.white ? `
  color: ${custom_blue} !important;
  ` : ''}
`;

export const Menu = styled.div`
  margin-bottom: 46px;

  ul {
    list-style-type: none;
    li {
      opacity: 0;
      will-change: opacity;


      a, span {
        color: ${color_white} !important;
        font-size: ${public_font_size_md};
        font-weight: ${font_weight_regular};
        line-height: 18px;
        padding: 18px 0px;
        display: block;
      }


      &.search {
        a {
          position: relative;
          .icon-search-left {
            color: ${color_white};
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            position: absolute;
            top: 0;
            line-height: 20px;
            height: 20px;
          }
          
        }
      }
    }
  }

  ${props => props.active ? `
    ul li {

    }
  ` : ''}

`;


export const MenuItem = styled.li`
    

  ${props => props.hasDropdown ? `


  ` : ''}
`;

export const Dropdown = styled.div`
    margin-left: 24px;
    height: 0;
    transition: height 0.4s ease-out;
    overflow: hidden;

    ${props => props.active ? `
      height: auto;
    `: ''}
`;
