import React from 'react'
import Spinner from 'components/Spinner/Spinner';
import Loadable from 'react-loadable';
import RoutedModal from './routes/RoutedModal/RoutedModal';
import { getRfqMeta, getActiveRfq, resetActiveRfq } from 'state/requestForQuote/actions';
import { getOnboardingMeta } from 'state/onboarding/actions';
import {requireAuth} from './routes';

const PageLoader = (props) => {
  return (
    <div style={{}}>
      <Spinner />
    </div>
  );
};
 
const AsyncRFQ_HowItWorks = Loadable({
    loader: () => import(/* webpackChunkName: "rfqRoutes" */ './routes/SubmitRequestForQuote/routes/RFQ_HowItWorks/RFQ_HowItWorks'),
    loading: () => <PageLoader />,
    modules: ['AsyncRFQ_HowItWorks'],
});

const AsyncRFQ_HowToProceed = Loadable({
    loader: () => import(/* webpackChunkName: "rfqRoutes" */ './routes/SubmitRequestForQuote/routes/RFQ_HowToProceed/RFQ_HowToProceed'),
    loading: () => <PageLoader />,
    modules: ['AsyncRFQ_HowToProceed'],
});

const AsyncRFQ_ItemSelection = Loadable({
    loader: () => import(/* webpackChunkName: "rfqRoutes" */ './routes/SubmitRequestForQuote/routes/RFQ_ItemSelection/RFQ_ItemSelection'),
    loading: () => <PageLoader />,
    modules: ['AsyncRFQ_ItemSelection'],
});

const AsyncRFQ_Information = Loadable({
    loader: () => import(/* webpackChunkName: "rfqRoutes" */ './routes/SubmitRequestForQuote/routes/RFQ_Information/RFQ_Information'),
    loading: () => <PageLoader />,
    modules: ['AsyncRFQ_Information'],
});

const AsyncRFQ_Details = Loadable({
    loader: () => import(/* webpackChunkName: "rfqRoutes" */ './routes/SubmitRequestForQuote/routes/RFQ_Details/RFQ_Details'),
    loading: () => <PageLoader />,
    modules: ['AsyncRFQ_Details'],
});

const AsyncRFQ_ReviewAndSubmit = Loadable({
    loader: () => import(/* webpackChunkName: "rfqRoutes" */ './routes/SubmitRequestForQuote/routes/RFQ_ReviewAndSubmit/RFQ_ReviewAndSubmit'),
    loading: () => <PageLoader />,
    modules: ['AsyncRFQ_ReviewAndSubmit'],
});

const AsyncRFQ_UploadMaterials = Loadable({
    loader: () => import(/* webpackChunkName: "rfqRoutes" */ './routes/SubmitRequestForQuote/routes/RFQ_UploadMaterials/RFQ_UploadMaterials'),
    loading: () => <PageLoader />,
    modules: ['AsyncRFQ_UploadMaterials'],
});

const AsyncRFQ_TechnicalAssistance = Loadable({
    loader: () => import(/* webpackChunkName: "rfqRoutes" */ './routes/SubmitRequestForQuote/routes/RFQ_TechnicalAssistance/RFQ_TechnicalAssistance'),
    loading: () => <PageLoader />,
    modules: ['AsyncRFQ_TechnicalAssistance'],
});


// CONSIGNMENT
const AsyncRFQ_SubmitConsignment = Loadable({
    loader: () => import(/* webpackChunkName: "onboardingRoutes" */ './routes/SubmitConsignment/routes/Consignment/Consignment'),
    loading: () => <PageLoader />,
    modules: ['AsyncRFQ_SubmitConsignment'],
});


// ONBOARDING
const AsyncOnboardingAccountType = Loadable({
    loader: () => import(/* webpackChunkName: "onboardingRoutes" */ './routes/NewUserOnboarding/routes/OnboardingAccountType/OnboardingAccountType'),
    loading: () => <PageLoader />,
    modules: ['AsyncOnboardingAccountType'],
});

const AsyncOnboardingAlerts = Loadable({
    loader: () => import(/* webpackChunkName: "onboardingRoutes" */ './routes/NewUserOnboarding/routes/OnboardingAlerts/OnboardingAlerts'),
    loading: () => <PageLoader />,
    modules: ['AsyncOnboardingAlerts'],
});

const AsyncOnboardingBusinessInfo = Loadable({
    loader: () => import(/* webpackChunkName: "onboardingRoutes" */ './routes/NewUserOnboarding/routes/OnboardingBusinessInfo/OnboardingBusinessInfo'),
    loading: () => <PageLoader />,
    modules: ['AsyncOnboardingBusinessInfo'],
});

const AsyncOnboardingCategoryInterest = Loadable({
    loader: () => import(/* webpackChunkName: "onboardingRoutes" */ './routes/NewUserOnboarding/routes/OnboardingCategoryInterest/OnboardingCategoryInterest'),
    loading: () => <PageLoader />,
    modules: ['AsyncOnboardingCategoryInterest'],
});

const AsyncOnboardingItemInterests = Loadable({
    loader: () => import(/* webpackChunkName: "onboardingRoutes" */ './routes/NewUserOnboarding/routes/OnboardingItemInterests/OnboardingItemInterests'),
    loading: () => <PageLoader />,
    modules: ['AsyncOnboardingItemInterests'],
});

const AsyncOnboardingAreasOfInterest = Loadable({
    loader: () => import(/* webpackChunkName: "onboardingRoutes" */ './routes/NewUserOnboarding/routes/OnboardingAreasOfInterest/OnboardingAreasOfInterest'),
    loading: () => <PageLoader />,
    modules: ['AsyncOnboardingAreasOfInterest'],
});


const Routes = [
  {
    path: [
      '/*/m/onboarding/business-info',
      '/*/m/onboarding',
      '/*/m/onboarding/*',
      '/m/onboarding',
      '/m/onboarding/*',


      '/dashboard/m/rfq/:rfqId/*',
      '/dashboard/*/m/rfq/:rfqId/*',
      '/dashboard/*/m',
      '/dashboard/*/m/*',
      '/dashboard/m/*'
    ],
    component: requireAuth(RoutedModal),
    exact: true,
    fetch: async (data = {}, route = {}) => {
      getRfqMeta();
      if (data.rfqId) {
        await getActiveRfq(data.rfqId);
      }
      if (route.path && route.path.indexOf('onboarding') > -1) {
        await getOnboardingMeta();
      }
    },
    unmountFunc:() => {
      resetActiveRfq()
    },
    routes: [
      // onboarding
      {
        path: ['/m/onboarding/business-info', '/*/m/onboarding/business-info'],
        component: AsyncOnboardingBusinessInfo,
        exact: true,
        data: {
          logo: true
        },
      },
      {
        path: ['/m/onboarding/category-interest', '/*/m/onboarding/category-interest'],
        component: AsyncOnboardingCategoryInterest,
        exact: true,
        data: {
          logo: true
        },
      },
      {
        path: ['/m/onboarding/interests', '/*/m/onboarding/interests'],
        component: AsyncOnboardingItemInterests,
        exact: true,
        data: {
          logo: true
        },
      },
      {
        path: ['/m/onboarding/account-type', '/*/m/onboarding/account-type'],
        component: AsyncOnboardingAccountType,
        exact: true,
        data: {
          logo: true
        },
      },
      {
        path: ['/m/onboarding/areas-of-interests', '/*/m/onboarding/areas-of-interests'],
        component: AsyncOnboardingAreasOfInterest,
        exact: true,
        data: {
          logo: true
        },
      },
      {
        path: ['/m/onboarding/areas-of-interests', '/*/m/onboarding/areas-of-interests'],
        component: AsyncOnboardingAreasOfInterest,
        exact: true,
        data: {
          logo: true
        },
      },
      {
        path: ['/m/onboarding/alerts', '/*/m/onboarding/alerts'],
        component: AsyncOnboardingAlerts,
        exact: true,
        data: {
          logo: true
        },
      },

      // consignment
      {
        path: ['/dashboard/*/m/consignment', '/dashboard/m/consignment'],
        component: AsyncRFQ_SubmitConsignment,
        exact: true,
        data: {
          title: 'Submit Consignment',
          icon: 'icon-edit'
        },
      },

      // RFQ
      {
        path: ['/dashboard/*/m/how-to-proceed', '/dashboard/*/m/rfq'],
        component: AsyncRFQ_HowToProceed,
        exact: true,
        data: {
          title: 'Submit Request For Quote',
          icon: 'icon-edit'
        },
      },
      {
        path: ['/dashboard/m/rfq/:id/item-selection', '/dashboard/*/m/rfq/:id/item-selection', '/dashboard/*/m/rfq/item-selection', '/dashboard/*/m/item-selection'],
        component: AsyncRFQ_ItemSelection,
        data: {
          title: 'Submit Request For Quote',
          icon: 'icon-edit'
        },
      },
      {
        path: ['/dashboard/*/m/rfq-information', '/dashboard/*/m/rfq/information', '/dashboard/*/m/rfq/:id/information', '/dashboard/m/rfq/:id/information'],
        component: AsyncRFQ_Information,
        data: {
          title: 'Submit Request For Quote',
          icon: 'icon-edit'
        },
      },
      {
        path: ['/dashboard/*/m/rfq-uplod-materials', '/dashboard/*/m/rfq/:id/uplod-materials', '/dashboard/m/rfq/:id/uplod-materials'],
        component: AsyncRFQ_UploadMaterials,
        data: {
          title: 'Submit Request For Quote',
          icon: 'icon-edit'
        },
      },
      {
        path: ['/dashboard/*/m/rfq-technical-assistance', '/dashboard/*/m/rfq/technical-assistance', '/dashboard/m/rfq/technical-assistance'],
        component: AsyncRFQ_TechnicalAssistance,
        data: {
          title: 'Submit Request For Quote',
          icon: 'icon-edit'
        },
      },
      {
        path: ['/dashboard/*/m/rfq/:id/details', '/dashboard/m/rfq/:id/details'],
        component: AsyncRFQ_Details,
        data: {
          title: 'Submit Request For Quote',
          icon: 'icon-edit'
        },
      },
      {
        path: ['/dashboard/*/m/rfq-review', '/dashboard/*/m/rfq/:id/review', '/dashboard/m/rfq/:id/review'],
        component: AsyncRFQ_ReviewAndSubmit,
        data: {
          title: 'Submit Request For Quote',
          icon: 'icon-edit'
        },
      },
      {
        component: AsyncRFQ_HowItWorks,
        data: {
          title: 'Submit Request For Quote',
          icon: 'icon-edit'
        },
      },
    ]
  },
];


export default Routes;