import { get } from 'lodash'
import Promise from 'bluebird';
import tree from 'state';

const inFlight = {}

export default async function asyncTreeRequester({metadata, delayedLoading, cursor, path, errorPath, func, transformer, onSuccess, onError, handleResult}) {
  let data;
  const c = cursor; //? cursor : tree.select(path);
  const cursorHash = `${c ? c.hash : 'd'}-${path.join('-')}`;
  if ((!c || !path) && !handleResult) {
    throw new Error('Path and Cursor Must Be Present')
  }
  const cursorData = c ? c.get(path) : null;
  let initialSet = 'merge'
  if (!cursorData) {
    initialSet = 'set'
  }

  if (get(cursorData, 'loading')) {
    console.log('request while loading')
    return inFlight[cursorHash]
  }

  if (c) {
    c[initialSet](path, {loading: true}); // if the path does not exist we cannot merge
  }

  try {
    const reqFunc = func()
    inFlight[cursorHash] = reqFunc;
    data = await reqFunc;
    inFlight[cursorHash] = null;


    if (handleResult) {
      // DO NOTHING - LET FUNCTION HANDLE RESULT
    } else if (onSuccess) {
      onSuccess(data, {cursor, path, errorPath})
    } else {
      c.merge(path, {result: transformer ? transformer({result: data, cursor: c.get(path), metadata}) : data, error: false})
      if (errorPath) {
        c.set(errorPath, null);
      }
    }
  } catch(err) {
    if (handleResult) {
      data = {error: err.message};
    } else if (onError) {
      onError(err, {cursor, path, errorPath});
    } else {
      if (errorPath) {
        c.set(errorPath, {error: err.message});
      } else {
        c.merge(path, {error: err.message})
      }
      data = {error: err.message};
    }
  }

  await Promise.delay(delayedLoading || 0);
  if (c) {
    c.merge(path, {loading: false});
    tree.commit();
  }
  return data;
}

