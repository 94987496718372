import React from 'react';
import { XXL, XL, MD } from '@zendeskgarden/react-typography';

import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from 'components/Icon/Icon';

import Slider from 'react-slick';
import { RecentPosts } from './BlogPost.styles';

import {
  BlogPostSection,
  BackgroundCircle,
  BgCircleWrapper,
  ShareWrapper,
  PostWrapper,
  DividingLine,
  Oval,
  List,
  LongDividingLine,
  PostsWrapper,
  ServiceBlock,
  CardWrapper,
  PostImg,
  PostCard,
  PostContentWrapper,
  IconWrapper,
  ShareButton,
} from '../../BlogPostTemplate.styles';

import { Wrapper } from 'components/PublicPages/GlobalPublicStyles';

import { variables } from 'theme/variables';
const { custom_button_blue } = variables;

const BlogPost = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <BlogPostSection>
      <BgCircleWrapper>
        <BackgroundCircle className='right-circle' />
        <BackgroundCircle className='left-circle' />
      </BgCircleWrapper>
      <ShareWrapper>
        <ShareButton className='top-share'>Share</ShareButton>
        <a href='#'>
          <ShareButton className='facebook-share'>
            <Icon
              icon='icon-facebook'
              color='#414141'
              style={{ cursor: `pointer` }}
            />
          </ShareButton>
        </a>
        <a href='#'>
          <ShareButton className='twitter-share'>
            <Icon
              icon='icon-twitter'
              color='#414141'
              style={{ cursor: `pointer` }}
            />
          </ShareButton>
        </a>
        <a href='#'>
          <ShareButton className='email-share'>
            <Icon
              icon='icon-email-fill'
              color='#414141'
              style={{ cursor: `pointer` }}
            />
          </ShareButton>
        </a>
        <a href='#'>
          <ShareButton className='link-share'>
            <Icon
              icon='icon-hyperlink'
              color='#414141'
              style={{ cursor: `pointer` }}
            />
          </ShareButton>
        </a>
      </ShareWrapper>
      <Wrapper style={{ width: `100%`, maxWidth: `1216px` }}>
        <Flex justifyCenter>
          <FlexItem>
            <PostWrapper>
              <Flex justifyLeft>
                <FlexItem>
                  <DividingLine />
                </FlexItem>
              </Flex>
              <XXL left tag='h2'>
                Example Of An Amazing Blog Post!
              </XXL>
              <MD left tag='p'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua
                Contact Us.{' '}
              </MD>
              <XL left tag='h3'>
                General Information
              </XL>
              <MD left tag='p' className='paragraph-spacing-small'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Lorem ipsum dolor non proident, id est laborum. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua Contact Us.{' '}
              </MD>
              <MD left tag='p' className='paragraph-spacing-small'>
                You agree to not engage in any of the following:
              </MD>
              <List>
                <li>
                  <Oval className='grey-oval' />{' '}
                  <MD left tag='p'>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure dolor in reprehenderit quis nostrud exercitation.
                  </MD>
                  <br />
                </li>
                <li>
                  <Oval className='grey-oval' />{' '}
                  <MD left tag='p'>
                    Enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure dolor in reprehenderit quis nostrud exercitation.
                    Nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Duis aute irure dolor in reprehenderit
                    quis nostrud exercitation.
                  </MD>
                  <br />
                </li>
                <li>
                  <Oval className='grey-oval' />{' '}
                  <MD left tag='p'>
                    Global Pipe Co. minim veniam, quis nostrud exercitation
                    ullamco laboris.
                  </MD>
                  <br />
                </li>
                <li>
                  <Oval className='grey-oval' />{' '}
                  <MD left tag='p'>
                    But enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure dolor in reprehenderit quis nostrud exercitation.
                  </MD>
                  <br />
                </li>
                <li>
                  <Oval className='grey-oval' />{' '}
                  <MD left tag='p'>
                    Tubular enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit quis nostrud
                    exercitation. Nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit quis nostrud exercitation.
                  </MD>
                  <br />
                </li>
                <li>
                  <Oval className='grey-oval' />{' '}
                  <MD left tag='p'>
                    Platform enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris.
                  </MD>
                  <br />
                </li>
              </List>
              <LongDividingLine />
            </PostWrapper>
          </FlexItem>
        </Flex>
        <Flex justify full>
          <FlexItem>
            <PostsWrapper>
              {/* <IconWrapper>
                <Icon icon="icon-arrow-left" color="#414141" style={{display: `inline`, position: `relative`, top: `3px`, paddingRight: `10px`}}/>
                <Icon icon="icon-arrow-right" color="#414141" style={{display: `inline`, position: `relative`, top: `3px`}}/>
              </IconWrapper> */}
              <XXL left tag='h2'>
                Recent Blog Posts
              </XXL>
              <Grid>
                <Row>
                  <Col md={12}>
                    <RecentPosts>
                      <Slider {...settings} className='slider'>
                        <CardWrapper>
                          <PostImg
                            className='left-image'
                            src='/images/pipe-product.jpg'
                          />
                          <PostCard>
                            <PostContentWrapper>
                              <MD left tag='h6'>
                                Example Of A Blog Post Title Here
                              </MD>
                              <MD left tag='p' className='date'>
                                July 12, 2019 &nbsp; | &nbsp; Support
                              </MD>
                              <MD left tag='p' className='read-more'>
                                <a className='links' href='#'>
                                  Read More{' '}
                                  <Icon
                                    icon='icon-arrow-right-short'
                                    color={custom_button_blue}
                                    style={{
                                      display: `inline`,
                                      position: `relative`,
                                      top: `3px`,
                                    }}
                                  />
                                </a>
                              </MD>
                            </PostContentWrapper>
                          </PostCard>
                        </CardWrapper>
                        <CardWrapper>
                          <PostImg
                            className='middle-image'
                            src='/images/pipe-product.jpg'
                          />
                          <PostCard>
                            <PostContentWrapper>
                              <MD left tag='h6'>
                                Example Of A Blog Post Title Here
                              </MD>
                              <MD left tag='p' className='date'>
                                July 12, 2019 &nbsp; | &nbsp; Support
                              </MD>
                              <MD left tag='p' className='read-more'>
                                <a className='links' href='#'>
                                  Read More{' '}
                                  <Icon
                                    icon='icon-arrow-right-short'
                                    color={custom_button_blue}
                                    style={{
                                      display: `inline`,
                                      position: `relative`,
                                      top: `3px`,
                                    }}
                                  />
                                </a>
                              </MD>
                            </PostContentWrapper>
                          </PostCard>
                        </CardWrapper>
                        <CardWrapper>
                          <PostImg
                            className='right-image'
                            src='/images/pipe-product.jpg'
                          />
                          <PostCard>
                            <PostContentWrapper>
                              <MD left tag='h6'>
                                Example Of A Blog Post Title Here
                              </MD>
                              <MD left tag='p' className='date'>
                                July 12, 2019 &nbsp; | &nbsp; Support
                              </MD>
                              <MD left tag='p' className='read-more'>
                                <a className='links' href='#'>
                                  Read More{' '}
                                  <Icon
                                    icon='icon-arrow-right-short'
                                    color={custom_button_blue}
                                    style={{
                                      display: `inline`,
                                      position: `relative`,
                                      top: `3px`,
                                    }}
                                  />
                                </a>
                              </MD>
                            </PostContentWrapper>
                          </PostCard>
                        </CardWrapper>
                        <CardWrapper>
                          <PostImg
                            className='right-image'
                            src='/images/pipe-product.jpg'
                          />
                          <PostCard>
                            <PostContentWrapper>
                              <MD left tag='h6'>
                                Example Of A Blog Post Title Here
                              </MD>
                              <MD left tag='p' className='date'>
                                July 12, 2019 &nbsp; | &nbsp; Support
                              </MD>
                              <MD left tag='p' className='read-more'>
                                <a className='links' href='#'>
                                  Read More{' '}
                                  <Icon
                                    icon='icon-arrow-right-short'
                                    color={custom_button_blue}
                                    style={{
                                      display: `inline`,
                                      position: `relative`,
                                      top: `3px`,
                                    }}
                                  />
                                </a>
                              </MD>
                            </PostContentWrapper>
                          </PostCard>
                        </CardWrapper>
                      </Slider>
                    </RecentPosts>
                  </Col>
                </Row>
              </Grid>
            </PostsWrapper>
          </FlexItem>
        </Flex>
      </Wrapper>
    </BlogPostSection>
  );
};

export default BlogPost;
