import styled from 'styled-components';
import { variables } from 'theme/variables';
import PublicPage from 'components/PublicPages/PublicPage'

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';


const {
  custom_dark_blue,
  color_white,
  custom_dark_grey,
  spacing_sm,
  public_font_size,
  public_font_weight_bold,
  spacing_xs,
  public_font_size_sm,
  public_line_height_md,
  media_sm,
  media_md,
  media_lg,
  custom_button_blue,
  public_font_size_md,
  public_font_size_lg,
  public_font_size_xxl
} = variables;


export const BlogTemplatePage = styled(PublicPage)`

`
export const TopSection = styled(Section)`
  
  overflow: visible;
  padding-top: 60px;
  

  @media ${media_lg} {
    padding-top: 80px;
    padding-bottom: 376px;
  }
`

export const BlogPostsSection = styled(Section)`
  
  padding-bottom: 280px;
  .view-more {
      position: relative;
      top: 208px;
    }
  @media ${media_md} {
    padding-bottom: 440px;
    .view-more {
      position: relative;
      top: 324px;
    }
  }

  @media ${media_lg} {
    padding-bottom: 600px;
    .view-more {
      position: relative;
      top: 324px;
    }
  }
`

export const BlogCard = styled.div`
  position: absolute;
  top: -108px;
  left: 50%;
  transform: translateX(-50%);
  height: 212px;
  max-width: 264px;
  width: 80%;
  border: 1px solid #EDEDED;
  border-radius: 8px;
  background-color: ${color_white};
  box-shadow: 0 0 25px 25px rgba(0,0,0,0.03);

  @media ${media_md} {
    top: -212px;
    left: 50%;
    transform: translateX(-50%);
    height: 434px;
    max-width: 1056px;
    width: 80%;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background-color: ${color_white};
    box-shadow: 0 0 25px 25px rgba(0,0,0,0.03);
    
  } 

  @media ${media_lg} {
    top: -212px;
    left: 50%;
    transform: translateX(-50%);
    height: 360px;
    max-width: 1056px;
    width: 88%;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background-color: ${color_white};
    box-shadow: 0 0 25px 25px rgba(0,0,0,0.03);
  }
`

export const PostCard = styled.div`
    position: absolute;
    z-index: 10;
    top: 192px;
    left: 50%;
    transform: translatex(-50%);
    height: 161px;
    width: 264px;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background-color: ${color_white};

  @media ${media_md} {
    position: absolute;
    z-index: 10;
    top: 192px;
    left: 50%;
    transform: translatex(-50%);
    height: 161px;
    width: 276px;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background-color: ${color_white};
  }

  @media ${media_lg} {
    position: absolute;
    z-index: 10;
    top: 192px;
    left: 0;
    transform: translatex(0);
    margin: 24px;
    height: 161px;
    width: 296px;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background-color: ${color_white};
  }
`

export const PostContentWrapper = styled.div`
  padding: 16px 20px;

  h6 {
    font-size: ${public_font_size_md};
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 0;
  }

  .date {
    font-size: ${public_font_size_sm};
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  a {
    position: relative;
    left: 0;
    transition: left ease 0.35s;
    color: ${custom_button_blue}

    &:hover {
      text-decoration: none;
      left: 6px;
    }
  }

  @media ${media_md} {
    h6 {
    font-size: ${public_font_size};
    line-height: 32px;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 0;
  }

  .date {
    font-size: ${public_font_size_sm};
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  a {
    position: relative;
    left: 0;
    transition: left ease 0.35s;
    color: ${custom_button_blue}

    &:hover {
      text-decoration: none;
      left: 6px;
    }
  }
  }
`

export const CardWrapper = styled.div`
 display: block;
  position: relative;

  @media ${media_md} {
    display: block;
    position: relative;
  }

  @media ${media_lg} {
    display: inline-block;
    position: relative;
  }
`

export const BlogImg = styled.img`
  overflow: visible;
  position: absolute;
  top: -228px;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 300px;
  border-radius: 8px;

  @media ${media_md} {
    top: -436px;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    width: 686px;
    border-radius: 8px;
  }

  @media ${media_lg} {
    top: -550px;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    width: 1216px;
    border-radius: 8px;
  }
`

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
`

export const PostImg = styled.img`
  position: relative;
  max-width: 300px;
  height: 300px;
  width: 300px;
  margin-bottom: 96px;
  border-radius: 8px;	
  box-shadow: 0 15px 50px 25px rgba(14,18,29,0.1);

  @media ${media_md} {
    margin-bottom: 96px;
    height: 300px;
    width: 300px;
  }

  @media ${media_lg} {
    max-width: 344px;
    margin-bottom: 96px;
    height: 344px;
    width: 344px;
  }
`

export const BackgroundCircle = styled.div`
  overflow: hidden;
  position: absolute;
  height: 1160px;	
  width: 1160px;	
  border: 200px solid rgba(1, 90, 208, 0.05);
  border-radius: 50%;
`

export const PipesWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0; 
  left: 0;
  position: absolute; 
  overflow: hidden; 

  .right-pipe {
    width: 165px;
    left: 0;
    right: -225px;
    bottom: 0;
    top: 19px;
    margin: 0 auto;
  }

  .left-pipe {
    width: 165px;
    left: -207px;
    right: 0%;
    bottom: 0;
    top: 77px;
    margin: 0 auto;
  }

    .left-pipes {
      display: none;
    }

    .right-pipes {
      display: none;
  }

  @media ${media_sm} {
    .left-pipes {
      display: block;
      width: 500px;
      left: -90%;
      right: 0;
      bottom: 0;
      top: 152px;
      margin: 0 auto;
    }

    .right-pipes {
      display: block;
      width: 500px;
      left: 0;
      right: -80%;
      bottom: 0;
      top: 112px;
      margin: 0 auto;
    }

    .right-pipe {
      display: none;
  }

    .left-pipe {
      display: none;
  }
  }

  @media ${media_md} {
    .left-pipes {
      display: block;
      width: 500px;
      left: -90%;
      right: 0;
      bottom: 0;
      top: 136px;
      margin: 0 auto;
      height: auto;
    }

    .right-pipes {
      display: block;
      width: 500px;
      left: 0;
      right: -80%;
      bottom: 0;
      top: 92px;
      margin: 0 auto;
      height: auto;
    }

    .right-pipe {
      display: none;
  }

    .left-pipe {
      display: none;
  }
}
  
`

export const BlogWrapper = styled.div`
  position: relative;
`

export const BlogPostWrapper = styled.div`
  width: 100%;
  padding: 24px;
  h3 {
      font-size: ${public_font_size_md};
      margin-bottom: 8px;
    }

    h5 {
      font-size: ${public_font_size_sm};
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 0px;
    }

    .display-none-mobile {
      display: none;
    }

  @media ${media_md} {
    padding: 48px;

     h3 {
      font-size: ${public_font_size_lg};
      line-height: 32px;
      font-weight: 900;
      margin-bottom: 24px;
    }

    h5 {
      font-size: ${public_font_size_md};
      line-height: 16px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    p {
      font-size: ${public_font_size_md};
      line-height: 32px;
      margin-bottom: 28px;
    }

    .display-none-mobile {
      display: block;
    }
  }

  @media ${media_lg} {
    padding: 48px;

    h3 {
      font-size: ${public_font_size_lg};
      line-height: 32px;
      font-weight: 900;
      margin-bottom: 24px;
    }

    h5 {
      font-size: ${public_font_size_md};
      line-height: 16px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    p {
      font-size: ${public_font_size_md};
      line-height: 32px;
      margin-bottom: 28px;
    }
  } 
`

export const PostsWrapper = styled.div`
    max-width: 300px;
    margin: 0 0 0 0;
    position: relative;
    top: 176px;

    h2 {
      margin-bottom: 64px;
      text-align: center;
    }

    .left-image {
      left: 50%;
      transform: translateX(-50%);
    }

    .middle-image {
      left: 50%;
      transform: translateX(-50%);
    }

    .right-image {
      left: 50%;
      transform: translateX(-50%);
    }
  
  @media ${media_md} {
    max-width: 1216px;
    margin: 0 0 0 0;
    position: relative;
    top: 286px;

    h2 {
      font-size: ${public_font_size_xxl};
      line-height: 48px;
      margin-bottom: 64px;
      text-align: left;
    }

    .left-image {
      left: 50%;
      transform: translateX(-50%);
    }

    .middle-image {
      left: 50%;
      transform: translateX(-50%);
    }

    .right-image {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media ${media_lg} {
    max-width: 1216px;
    margin: 0 0 0 0;
    position: relative;
    top: 286px;

    h2 {
      font-size: ${public_font_size_xxl};
      line-height: 48px;
      margin-bottom: 80px;
    }

    .left-image {
      margin-right: 74px;
      left: 0;
      transform: translateX(0);
    }

    .middle-image {
      margin-right: 74px;
      left: 0;
      transform: translateX(0);
    }

    .right-image {
      left: 0;
      transform: translateX(0);
    }
  }
`

export const BgCircleWrapper = styled.div`

  .left-circle {
    right: 77vw;
    bottom: 0;
    top: 728px;
    margin: 0 auto;
  }

  .right-circle {
    left: 79vw;
    bottom: 0;
    top: 20px;
    margin: 0 auto;
  }
`

export const ServiceBlock = styled.div`
  position: relative;
`;
