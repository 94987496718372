import React, { useState, useEffect } from 'react';
import {addClass, removeClass} from 'utility/domUtils';
import {useBranch} from 'baobab-react/hooks';
import Modal from "forks/customModal";
import {get} from 'lodash';
import {closeSidebar} from 'state/sidebars/actions';
import GlobalInterestsFilter from './sidebars/GlobalInterestsFilter/GlobalInterestsFilter';
import InterestSummarySidebar from './sidebars/InterestSummarySidebar/InterestSummarySidebar';
import InterestItemsSidebar from './sidebars/InterestItemsSidebar/InterestItemsSidebar';

import {GLOBAL_INTERESTS_FILTERS, INTEREST_SUMMARY, INTEREST_ITEMS} from './GlobalSideBarTypes';

const StyledSidebar = Modal.styled`
  width: ${props => props.width || '100%'};
  height: 100vh;
  position: fixed;  
  top: 0px;
  bottom: 0;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  opacity: 1;
  max-width: ${props => props.maxWidth || '550px'};
  transform: ${props => props.opacity ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0px, 0)'};
  transition: transform cubic-bezier(0.2, 0, 0, 1) 240ms 10ms;
  overflow: scroll;
  box-shadow: ${props => props.boxShadow ? props.boxShadow : '0,0,0,0'};
`
 
const GlobalSideBar = (props) => {
  const [opacity, setOpacity] = useState(0);
  const {sidebars} = useBranch({
    sidebars: ['sidebars']
  });

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1)
    }, 100);
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0)
      setTimeout(resolve, 300);
    });
  }

  const sidebarOpen = sidebars.sidebarOpen;
  const sidebarType = sidebars.sidebarType;
  const sidebarFilterStyle = get(sidebars, 'options.style') === 'filter';
  const sidebarWidth = get(sidebars, 'options.width', null);
  const sidebarMaxWidth = get(sidebars, 'options.maxWidth', null);
  const sidebarBackgroundStyles = sidebarFilterStyle ? {
    background: 'background-color: rgba(9, 30, 66, 0);',
  } : {} 

  useEffect(() => {
    if (sidebarType) {
      console.log('sdjhsdfkjh')
      addClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    } else {
      removeClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    }
    return () => {
      removeClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    }
  }, [sidebarType])


  return (
    <StyledSidebar
      boxShadow={sidebarFilterStyle ? '-4px 0px 50px rgba(0, 0, 0, 0.25)' : null}
      width={sidebarWidth}
      maxWidth={sidebarMaxWidth}
      isOpen={sidebarOpen}
      onBackgroundClick={closeSidebar}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onEscapeKeydown={closeSidebar}
      opacity={opacity}
      allowScroll={true}
      backgroundProps={{ opacity: opacity, ...sidebarBackgroundStyles }}>
        {
          sidebarType === GLOBAL_INTERESTS_FILTERS ? 
          <GlobalInterestsFilter /> :
          sidebarType === INTEREST_SUMMARY ? 
          <InterestSummarySidebar /> :
          sidebarType === INTEREST_ITEMS ? 
          <InterestItemsSidebar /> : null
        }
    </StyledSidebar>
  )
}


export default GlobalSideBar;