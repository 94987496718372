import React from 'react';
import Flex from 'styled-flex-component';

const NoDataPlaceholder = (props) => {
  return (
    <Flex full alignCenter justifyCenter>
      {props.children}
    </Flex>
  )
}

export default NoDataPlaceholder