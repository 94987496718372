import styled from 'styled-components';
import {variables} from 'theme/variables';
const {
  custom_lighter_grey,
  spacing
} = variables;

export const StyledInterestHeader = styled.div`
  padding: ${spacing}
  background-color: ${custom_lighter_grey};
`
