import React, { useState } from 'react';
import { LG, MD } from '@zendeskgarden/react-typography';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from "components/Icon/Icon";

import { 
  Dropdown, 
  Menu, 
  Item, 
  Trigger,
  Field,
  Select
} from '@zendeskgarden/react-dropdowns';



import {
  FormFieldWrapper,
  FiltersWrapper,
  MobileWrapper
} from '../../ItemSubmission.styles';


const TechnicalRequirements = (props) => {

  const category = [
    { label: 'Choose Category', value: 'Choose Category' },
    { label: 'Category 1', value: 'Category 1' },
    { label: 'Category 2', value: 'Category 2' },
    { label: 'Category 3', value: 'Category 3' },
    { label: 'Category 4', value: 'Category 4' }
  ];

  const [selectedItem, setSelectedItem] = useState(category[0]);

    return (
      <Grid>
        <MobileWrapper>
          <FiltersWrapper>
            <Row>
              <Col md={6}>
                <FormFieldWrapper className="category">
                  <LG left tag="h5">Category</LG>
                 <Dropdown className="dropdown"
                  selectedItem={selectedItem}
                  onSelect={item => setSelectedItem(item)}
                  downshiftProps={{ itemToString: item => item && item.label }}
                >
                <Field>
                  <Select>{selectedItem.value}</Select>
                </Field>
                <Menu>
                  {category.map(category => (
                    <Item key={category.value} value={category}>
                      {category.label}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
                </FormFieldWrapper>
              </Col>
              <Col md={6}>
                <FormFieldWrapper className="od">
                    <LG left tag="h5" style={{display: `inline-block`}}>Min Burst</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Min Burst"></input>
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormFieldWrapper className="category">
                  <LG left tag="h5" style={{display: `inline-block`}}>Min Collapse</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Min Collapse"></input>
                </FormFieldWrapper>
              </Col>
              <Col md={6}>
                <FormFieldWrapper className="od">
                  <LG left tag="h5">Min Tensile Strength</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Min Tensile Strength"></input>
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormFieldWrapper className="category">
                  <LG left tag="h5" style={{display: `inline-block`}}>Max OD</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Max OD"></input>
                </FormFieldWrapper>
              </Col>
              <Col md={6}>
                <FormFieldWrapper className="od">
                  <LG left tag="h5">Min ID</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Min ID"></input>
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormFieldWrapper className="category min-pren">
                  <LG left tag="h5">Min PREN</LG>
                  <input type="text" id="min-pren" name="name" required placeholder="Enter Min PREN"></input>
                </FormFieldWrapper>
              </Col>
            </Row> 
          </FiltersWrapper>
        </MobileWrapper>
      </Grid>
    )
}

export default TechnicalRequirements;