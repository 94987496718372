import React from 'react';
import NotificationItem from 'components/NotificationItem/NotificationItem';
import {map} from 'lodash'
import { StyledNotificationDropdownList } from './NotificationDropdownList.styles.js';

const NotificationDropdownList = ({notifications, ...rest}) => {
  return (
    <StyledNotificationDropdownList {...rest}>
      {
        map(notifications, (notification, i) => {
          return <NotificationItem key={i} notification={notification} {...rest} />
        })
      }
    </StyledNotificationDropdownList>
  )
}

export default NotificationDropdownList;