import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
import { Button } from '@zendeskgarden/react-buttons';
import PublicPage from 'components/PublicPages/PublicPage'

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';


const {
  custom_dark_blue,
  color_white,
  custom_dark_grey,
  spacing_sm,
  media_md,
  media_lg,
  spacing,
  custom_button_blue,
  public_mobile_font_size_xs,
  public_font_size,
  public_font_size_lg,
  public_font_size_xl,
  public_font_size_md,
  public_font_size_sm,
  public_font_size_xs
} = variables;



export const ViewItemDetailsPage = styled(PublicPage)`
  padding: 0;
`;

export const TopSection = styled(Section)`
  padding: 80px 0px 60px;

  

  h6 {
     margin: 0 0 20px 0;
  }

  @media ${media_md} {
   
  }

  @media ${media_lg} {
     padding: 80px 0 0 0;
   
  }
`;

export const InfoSection = styled(Section)`
  padding: 0px 0px 446px;

  

  @media ${media_md} {
    padding: 0 0 80px 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
`

export const HeaderWrapper = styled.div`
  maxWidth: 1000px;
  margin: 0 auto; 
`


export const ItemInformationWrapper = styled.div`
  margin: 0 0 0 280px;
  padding: 0px 40px;
  width: 100%;

  .information {
    margin: 8px 0 0 0;
    padding: 32px 0;
  }

  .technical-information {
    padding: 60px 0 32px 0;
  }

  .documentation {
    padding: 60px 0 32px 0;
  }

  .similar {
    padding: 60px 0 32px 0;
  }

  .size-information {
    margin-top: 40px;
  }

  .no-display-mobile {
    display: none;
  }

  .size {
    margin-bottom: -200px;
  }

  .approved {
    position: relative;
    left: 64px;
    top: -4px;
  }

  .display-alloy-mobile {
    display: block;
    margin-top: -40px;
  }

  .display-tech-mobile {
    display: block;
  }

  .display-finish-mobile {
    display: block;
  }

  .display-mobile {
    display: block;
    position: relative;
    top: -37px;
    

    h6 {
      padding: 0;
      display: block;
      text-align: center;
      margin: 0 20px 20px 0;
    }

    p {
      display: block;
      text-align: center;
    }

    .od {
      position: relative;
      left: 10px;
    }
    .id {
      position: relative;
      left: 100px;
      top: -52px;
    }
    .weight {
      position: relative;
      top: -105px;
      left: 176px;
    }
    .wall {
      position: relative;
      top: -66px;
      left: 10px;
    }

    .drift {
      position: relative;
      top: -119px;
      left: 100px;
    }

    .yield {
      position: relative;
      top: -171px;
      left: 176px;
    }
  }

  .size-line {
    margin-top: -50px;
  }


  .alloy-line {
    margin-top: -278px;
  }

  .finish-line {
    margin-top: -278px;
  }

  .tech-line {
    margin-top: -214px;
  }

  @media ${media_md} {
    margin: 0 0 0 280px;
    padding: 0px 40px;
    width: 100%;

    .information {
      margin: 8px 0 0 0;
      padding: 32px 0;
    }

    .technical-information {
      padding: 60px 0 32px 0;
    }

    .documentation {
      padding: 60px 0 32px 0;
    }

    .similar {
      padding: 60px 0 32px 0;
    }

    .size-information {
      margin-top: 0;
    }

    .no-display-mobile {
      display: block;
    }

    .display-mobile {
      display: none;
    }

    .display-alloy-mobile {
      display: none;
    }

     .size-line {
        margin-top: 0;
      }


    .alloy-line {
      margin-top: 0;
    }

    .finish-line {
      margin-top: 0;
    }

    .tech-line {
      margin-top: 0;
    }

    .display-tech-mobile {
      display: none;
    }

    .display-finish-mobile {
      display: none;
    }

  }
`

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
`

export const WorldMap = styled.img`
  height: 452px;
  width: 682px;
  overflow: hidden;
  position: relative;
  top: -80px;
  margin-bottom: -80px;
`
export const WorldMapBg = styled.div`
  height: 388px;
  width: 682px;
  position: relative;
  top: -440px;
  margin-bottom: -410px;
  background-color: rgba(255, 255, 255, 0.89);
`;

export const WorldMapBgLoggedIn = styled.div`
  height: 352px;
  width: 682px;
  position: relative;
  top: -460px;
  margin-bottom: -410px;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.6),rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9));
`

export const DocumentationBg = styled.div`
  height: 275px;	
  width: 1200px;
  position: relative;
  top: -264px;
  margin-bottom: -280px;
  background-color: rgba(255, 255, 255, 0.94)
`

export const PipesImg = styled.img`
  height: 72px;
  width: 114px;
  overflow: hidden;
  position: relative;
  height: auto;
  padding: 0 0 0 0;
  top: -351px;

  @media ${media_md} {
    top: -105px;
  }

  @media ${media_lg} {
    height: 144px;
    width: 228px;
    
  }
`

export const ViewItemDetailsButton = styled(Button)`
  height: 46px;	
  width: 167px;	
  border-radius: 4px;	
  background-color: ${custom_button_blue}
  color: ${color_white};
  border: none;

  
`;

export const SignUpButton = styled(Button)`
  position: relative;
  left: 70px;
  margin: 20px 0 0 0;
  height: 46px;	
  width: 167px;	
  border-radius: 4px;	
  background-color: ${custom_button_blue}
  color: ${color_white};
  border: none;

`;

export const ViewGalleryButton = styled(Button)`
  position: relative;
  padding: 0;
  top: -380px;
  left: 32px;
  height: 40px;	
  width: 65px;	
  border-radius: 4px;	
  background-color: ${custom_button_blue}
  color: ${color_white};
  border: none;

  &:hover {
    background-color: ${custom_button_blue} !important;
  }

  @media ${media_md} {
    position: relative;
    padding: 0;
    top: -100px;
    left: -100px;
    height: 40px;	
    width: 65px;	
    border-radius: 4px;	
    background-color: ${custom_button_blue}
    color: ${color_white};
    border: none;
  }

  @media ${media_lg} {
    top: -100px;
    left: -150px;
    height: 40px;	
    width: 171px;	
  }
`;

export const TransparentButton = styled(Button)`
  height: 46px;
  width: 167px;
  border-radius: 4px;	
  padding: 0 0 0 16px;
  background-color: transparent;
  color: ${color_white};
  border: 1px solid ${color_white};
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 21px;

  &:hover {
    background-color: ${custom_button_blue} !important;
  }
`;

export const LongLine = styled.div`
	box-sizing: border-box;
	height: 1px;
	width: 300px;
  border: 1px solid #ECECEC;

  @media ${media_md} {
    width: 1073px;
  }
`

export const SideBarFloat = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  margin: 0;
  height: 0;
  width: 27vw;

  .links {
    display: block;
    position: relative;
    left: 0;
    color: #414141 !important;
    transition: left ease 0.35s;
    
    &:hover {
      text-decoration: none;
      left: 6px;
    }

    &:hover p {
      color: blue !important;
    }
  }

  @media ${media_lg} {
    width: 280px;
  }
`

export const SideContainer = styled.div`
    position: relative;
    left: -10px;
    height: 100px;
    border: 1px solid #F2F5F9;
    
    p {
      margin-left: 30px;
      font-size: 18px;
      line-height: 21px;
    }
`

export const SizeWrapper = styled.div`
    margin-bottom: -100px;
    h6 {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      color: #414141;
      letter-spacing: 0.75px;
      margin: 0;
    }

    p {
      font-size: ${public_font_size_sm};
      line-height: 14px;
      color: #3E3E3E;
      letter-spacing: 0.2px;
      margin: 0;
    }

    @media ${media_md} {
      h6 {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      color: #414141;
      letter-spacing: 0.75px;
      margin: 0 20px 20px 0;
    }

    p {
      font-size: ${public_font_size_sm};
      line-height: 14px;
      color: #3E3E3E;
      letter-spacing: 0.2px;
      margin: 0 0 50px 0;
    }
    }
`

export const BrandWrapper = styled.div`
    margin: 0 0 40px 0;

    h6 {
      font-weight: 500;
      color: #414141;
      letter-spacing: 0.75px;
      margin: 0 0 20px 0;
    }
    p {
      font-size: ${public_font_size_sm};
      line-height: 20px;
      letter-spacing: 0.58px;
      color: #414141;
    }

    li {
      padding: 0 0 10px 0;
    }

    .move-mobile-up {
      position: relative;
      top: -40px;

      li {
        width: 302px;
      }
    }

    @media ${media_md} {
      .move-mobile-up {
        position: static;

        li {
          width: 400px;
        }
      }
    }

    @media ${media_lg} {
      .move-mobile-up {
        position: static;

        li {
          width: 600px;
        }
      }
    }
`

export const AlloyWrapper = styled.div`

  h6 {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      color: #414141;
      letter-spacing: 0.75px;
      margin: 0 20px 20px 0;
    }

    p {
        font-size: ${public_font_size_sm};
        line-height: 14px;
        color: #3E3E3E;
        letter-spacing: 0.2px;
        margin: 0 0 50px 0;
    }

    .group {
      position: relative;
    }

    .alloy-name {
      position: relative;
      top: -102px;
      left: 104px;
    }

    .alloy-pren {
      position: relative;
      top: -116px;
    }

    .uns {
      position: relative;
      top: -218px;
      left: 105px;
    }

    .alt-names {
      position: relative;
      top: -407px;
      left: 181px;
    }

    .mobile-move-wrapper {
      position: relative;
      top: -350px;
    }

    .description {
      position: relative;
      left: -10px;
    }

    @media ${media_md} {

    }

    @media ${media_lg} {
      .left {
        margin-left: -60px;
      }

      .move-left {
        margin-left: -100px;
      }
    }
`
export const FinishWrapper = styled.div`
    h6 {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      color: #414141;
      letter-spacing: 0.75px;
      margin: 0 20px 20px 0;
    }

    p {
        font-size: ${public_font_size_sm};
        line-height: 14px;
        color: #3E3E3E;
        letter-spacing: 0.2px;
        margin: 0 0 50px 0;
    }

    .left {
      margin-left: 0px;
    }

    .move-left {
      margin-left: 8px;
    }

    .finish-name {
      position: relative;
      top: -50px;
    }

    .finish-category {
      position: relative;
      top: -152px;
      left: 92px;
    }

    .license {
      position: relative;
      top: -254px;
      left: 204px;
    }

    .thread-type {
      position: relative;
      top: -266px;
    }

    .alt-threads {
      position: relative;
      top: -284px;
    }

    .move-mobile-finish {
      position: relative;
      top: -300px;
      left: -10px;
    }


    @media ${media_lg} {
      .left {
        margin-left: -60px;
      }

      .move-left {
        margin-left: -100px;
      }
    }
`

export const TechnicalWrapper = styled.div`
    h6 {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      color: #414141;
      letter-spacing: 0.75px;
      margin: 0 20px 20px 0;
    }

    p {
        font-size: ${public_font_size_sm};
        line-height: 14px;
        color: #3E3E3E;
        letter-spacing: 0.2px;
        margin: 0 0 50px 0;
    }

    .right {
      margin-right: -20px;
    }

    .burst {
      position: relative;
      top: -16px;
    }

    .tech-collapse {
      position: relative;
      top: -118px;
      left: 106px;
    }

    .tensile-strength {
      position: relative;
      top: -132px;
    }

    .tech-drift {
      position: relative;
      top: -322px;
      left: 226px;
    }

    @media ${media_lg} {
      .right {
        margin-right: 0;
      }
    }
`



export const AvailabilityWrapper = styled.div`
    position: relative;
    top: -420px;

    margin-bottom: -20px;
    h5 {
      font-size: 18px;
      line-height: 18px;
      color: #414141;
      padding: 0 0 20px 0;
      margin-bottom: 0;
    }

    p {
      font-size: ${public_font_size_sm};
      line-height: 14px;
    }

    .no-display-mobile-map {
        display: none;
      }

    @media ${media_md} {
      .no-display-mobile-map {
        display: block;
      }
    }
`

export const MarginWrapper = styled.div`
    margin: 0 0 0 60px;

    .view-availability {
        margin: 0 0 18px -60px;
      }

      .view-availability-button {
        left: -40px;
      }

      .view-documentation {
        margin-left: -72px;
      }

      .view-documentation-button {
        position: relative;
        left: -42px;
      }

    

    @media ${media_md} {
      .view-availability {
        margin: 0 0 18px -18px;
      }

      .view-availability-button {
        left: 0;
      }

      .view-documentation {
        margin-left: -12px;
      }

      .view-documentation-button {
        left: 0;
      }
    }
`
export const ConfidenceSmall = styled.div`
    font-size: ${public_mobile_font_size_xs};
    line-height: 10px;
    height: 21px;	
    width: 52px;	
    border-radius: 4px;	
    background-color: #38D79F;
    color: ${color_white};
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ToggleButton = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px
  height: 14px;
  display: inline-block;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: 1px solid #B0BED1;
  cursor: pointer;
  background-color: ${color_white};
  transition: background-color ease 0.3s;

 &:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  width: 6px;
  height: 6px;
  background: ${custom_button_blue}
  left: 2px;
  top: 3px;
  border-radius: 50%;
  font: 10px/28px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -22px;
  word-spacing: 37px;
  color: ${color_white};
  text-shadow: -1px -1px rgba(0,0,0,0.15);
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

&:checked {
  background-color: ${color_white};
  border: 1px solid ${custom_button_blue}
  color: ${custom_button_blue}
  
  &:before {
    left: 10px;
   }
}
`

export const ConfidenceLarge = styled.div`
  height: 21px;
  width: 52px;
  border-radius: 4px;
  color: ${color_white};
  font-size: ${public_mobile_font_size_xs};
  line-height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ResultsWrapper = styled.div`
  position: relative;
  height: 393px;
  width: 75vw;
  background-color: ${color_white};
  border-radius: 4px;

  p {
    font-size: ${public_font_size_sm};
  }
`

export const TopWrapper = styled.div`
  height: 46px;
  width: 100%;
  background-color: #E7EAEE;

  p {
    font-size: ${public_font_size_sm};
    position: relative;
    bottom: 15px;
  }
`

export const DividingLine = styled.div`
  position: relative;
  left: 1%;
  width: 98%;
  height: 1px;
  border: 1px solid #EFEFEF;
`

export const PipesWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden; 

  .leftPipes {
    width: 500px;
    left: -90%;
    right: 0;
    bottom: 0;
    top: 12vh;
    margin: 0 auto; 
  }

  .rightPipes {
    width: 500px;
    left: 0;
    right: -65%;
    bottom: 0;
    top: 9vh;
    margin: 0 auto; 
  }
`

export const TopButtonsWrapper = styled.div`
  position: relative;

  .pricing {
    position: absolute; 
    z-index: 10;
    left: 8px;
    top: -8px;
  }

  .back {
    position: absolute; 
    z-index: 10;
    right: 8px;
    top: -8px;
  }

  @media ${media_md} {
    .back {
      position: absolute; 
      z-index: 10;
      right: 16px;
      top: -32px;
    }

    .pricing {
      position: absolute; 
      z-index: 10;
      left: 16px;
      top: -32px;
    }
  }

  

  @media ${media_lg} {
    .pricing {
    position: relative; 
    z-index: 10;
    left: 186px;
    top: -40px;
  }

  .back {
    position: relative; 
    z-index: 10;
    right: 186px;
    top: -40px;
  }
  }

`

export const ItemsTable = styled.table`
  color: #3E3E3E;
  border-collapse: collapse;
  border-left: 1px solid #EFEFEF;
  border-right: 1px solid #EFEFEF;

  thead {
    height: 14px;	
    color: #3E3E3E;
    font-family: Roboto;	
    font-size: ${public_font_size_sm};	
    font-weight: bold;	
    letter-spacing: 0.28px;	
    line-height: 30px;
    background-color: #E7EAEE;
  }

    th, td {
    border-bottom: 1px solid #EFEFEF;
  }


  tbody {
    line-height: 50px;
  }

  .Item {
    width: 25%;
  }

  .Brand {
    width: 10%;
    padding-left: 20px;
  }

  .Manufacturer {
    width: 10%;
  }

  .MinBurst {
    width: 10%;
  }

  .Collapse {
    width: 10%;
  } 

  .Yield {
    width: 15%;
    margin: 0 20px;
  }

  .Drift {
    width: 10%;
  }

  .PREN {
    width: 10%;
  }

  .MTR {
    width: 10%;
    padding-right: 10px;
  }
`

export const DocTable = styled.table`
  color: #3E3E3E;
  border-collapse: collapse;
  width: 72%;
  margin: 0 0 40px 0;
  border-left: 1px solid #EFEFEF;
  border-right: 1px solid #EFEFEF;

  thead {
    height: 14px;	
    color: #3E3E3E;
    font-family: Roboto;	
    font-size: ${public_font_size_sm};	
    font-weight: bold;	
    letter-spacing: 0.28px;	
    line-height: 30px;
    background-color: #E7EAEE;
    border-bottom: 1px solid black;
  }

  th, td {
    border-bottom: 1px solid #EFEFEF;
  }

  tbody {
    line-height: 50px;
  }

  .Type {
    width: 20%;
  }

  .Reference {
    width: 20%;
    padding-left: 20px;
  }

  .Date {
    width: 20%;
  }

  .Submitted {
    width: 10%;
  }

  .View {
    width: 10%;
  } 

  .Download {
    width: 10%;
    margin: 0 20px;
  }
`

export const TableWrapper = styled.div`
  max-width: 100%;
  min-width: 300px;

  .table-header-row {
    padding: 0 0 16px 0;
    background-color: #E7EAEE;

    &:hover {
      background-color: #E7EAEE !important;
    }
  }

  .table-header-elemental {
    padding: 0 0 16px 0;
    background-color: transparent;

    &:hover {
      background-color: transparent !important;
    }
  }

  .table-row {
    padding: 16px 0;

    h5 {
      font-size: 20px;
      line-height: 32px;
      color: #3E3E3E;
    }

    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      border-lef
    }
  }

  .table-row-top {
    padding: 16px 0;
    border-bottom: none;

    h5 {
      font-size: 20px;
      line-height: 32px;
      color: #3E3E3E;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .cell-font {
    font-size: ${public_font_size_xs};
  }

  @media ${media_lg} {
    max-width: 85%;
  }
`

export const TableMarginWrapper = styled.div`
  margin: 0 64px;
`

export const LineDivideVertical = styled.div`
  position: absolute;
  left: 24vw;
  height 100%;
  width: 2px;
  border-right: 1px solid #F2F5F9;

  @media ${media_lg} {
    left: 258px;
  }
`

export const TableScrollWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  clear: both;
`

export const DescriptionWrapper = styled.div`
  max-width: 482px;
  margin-bottom: 16px;

  p {
    font-size: ${public_font_size_sm};
    line-height: 24px;
    letter-spacing: 0.2px;
  }
` 

export const ElementalTable = styled.div`
  .cell-font {
    font-size: 8px;
  }

  @media ${media_md} {
    .cell-font {
      font-size: ${public_font_size_xs};
    }
  }
`

export const DocumentTable = styled.div`
  .table-header-row {
    padding: 0 0 8px 0;
    background-color: #E7EAEE;

    &:hover {
      background-color: #E7EAEE !important;
    }
  }

  .table-row {
    padding: 8px 0;

    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      border-lef
    }
  }
`

export const ContentWrapper = styled.div`
  max-width: 80vw;
  position: relative;
  left: -300px;
  top: 386px;

  @media ${media_md} {
    position: static;
    margin-left: -130px;
  }

  @media ${media_lg} {
    margin-left: 0;
  }
`

export const LogoImg = styled.img`
  max-width: 140%;
  margin: -24px 0 64px 0;

  @media ${media_md} {
    max-width: 100%;
  }
`

export const Oval = styled.div`
  display: inline-block;
  margin: 0 10px 0 10px;
	height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid ${custom_button_blue}
  
  @media ${media_md} {
    margin: 0 10px 0 0px;
  }

  @media ${media_lg} {
    margin: 0 10px 0 0;
  }
`

export const NavSideBar = styled.div`
  position: relative;
  top: 16px;
  width: 360px;
  left: 20px;

  p {
    padding: 20px 0;
    display: inline-block;
  }

  

  @media ${media_md} {
    width: 200px;
    margin 0 0 0 0;
    left: 0;
  }

  @media ${media_lg} {
    top: 24px;
    width: 100%;
    margin: 56px 0 0 32px;

    p {
      padding: 20px 0;
      display: inline-block;
    }
  }
 
`

export const DividingLineNav = styled.div`
  width: 88%;
  height: 2px;
  border-bottom: 2px dotted rgba(65, 65, 65, 0.27);

  @media ${media_md} {
    width: 160px;
  }

  @media ${media_lg} {
    width: 210px;
  }
`;

export const NameWrapper = styled.div`
  position: relative;
  top: -40px;

  @media ${media_md} {
    position: static;
    margin-right: 16px;
  }

  @media ${media_lg} {
    position: static;
    margin-right: 80px;
  }
`
