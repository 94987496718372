import React from 'react';
import { XXXL} from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import {
  Wrapper,
  TopSection,
  BackgroundImg,
  PipesWrapper
} from '../../FindInventoryResults.styles.js';

const InventoryResults = (props) => {
  return (
    <TopSection dark style={{padding: `80px 0 120px 0`}}>
      <PipesWrapper>
          <BackgroundImg className="left-pipes"  src="/images/hero-pipes.png" />
          <BackgroundImg className="right-pipes" src="/images/hero-pipes.png" />
          <BackgroundImg className="left-pipe"  src="/images/Pipes.svg" />
          <BackgroundImg className="right-pipe" src="/images/Pipes.svg" />
        </PipesWrapper>
      <Wrapper>
        <Grid>
          <Row justifyContent="center">
            <Col sm={12} md={12}>
              <Flex alignCenter justifyCenter>
                <FlexItem>
                  <XXXL center tag="h1">Inventory Results</XXXL>
                </FlexItem>
              </Flex>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
    </TopSection>

  )
}

export default InventoryResults;