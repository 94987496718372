import React, { useState, useEffect } from 'react';
import { LG, MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from "components/Icon/Icon";

import { 
  Dropdown, 
  Menu, 
  Item, 
  Trigger,
  Field,
  Select
} from '@zendeskgarden/react-dropdowns';


import {
  FormFieldWrapper,
  FiltersButton,
  FiltersWrapper
} from '../../ItemSubmission.styles';
import { variables } from 'theme/variables';
const {
  color_white
} = variables;


const MoreFilters = (props) => {

  const [count, setCount] = useState(1)

  const category = [
    { label: 'Choose Category', value: 'Choose Category' },
    { label: 'Category 1', value: 'Category 1' },
    { label: 'Category 2', value: 'Category 2' },
    { label: 'Category 3', value: 'Category 3' },
    { label: 'Category 4', value: 'Category 4' }
  ];


  const alloy = [
    { label: 'Choose Alloy', value: 'Choose Alloy' },
    { label: 'Alloy 1', value: 'Alloy 1' },
    { label: 'Alloy 2', value: 'Alloy 2' },
    { label: 'Alloy 3', value: 'Alloy 3' },
    { label: 'Alloy 4', value: 'Alloy 4' }
  ];

  const min = [
    { label: 'Choose Min Yield', value: 'Choose Min Yield' },
    { label: 'Min Yield 1', value: 'Min Yield 1' },
    { label: 'Min Yield 2', value: 'Min Yield 2' },
    { label: 'Min Yield 3', value: 'Min Yield 3' },
    { label: 'Min Yield 4', value: 'Min Yield 4' }
  ];

  const manufacturer = [
    { label: 'Choose Manufacturer', value: 'Choose Manufacturer' },
    { label: 'Manufacturer 1', value: 'Manufacturer 1' },
    { label: 'Manufacturer 2', value: 'Manufacturer 2' },
    { label: 'Manufacturer 3', value: 'Manufacturer 3' },
    { label: 'Manufacturer 4', value: 'Manufacturer 4' }
  ];

  const mill = [
    { label: 'Choose Mill Year', value: 'Choose Mill Year' },
    { label: '2008', value: '2008' },
    { label: '2009', value: '2009' },
    { label: '2010', value: '2010' },
    { label: '2011', value: '2011' }
  ];


  const [selectedItem, setSelectedItem] = useState(category[0]);

  const [selectedAlloy, setSelectedAlloy] = useState(alloy[0]);

  const [selectedMin, setSelectedMin] = useState(min[0]);

  const [selectedMan, setSelectedMan] = useState(manufacturer[0]);

  const [selectedMill, setSelectedMill] = useState(mill[0]);

  if (count % 2 == 0) {
    return (
      <Grid>
        <FiltersWrapper>
          <Row>
          <Col md={6}>
            <FormFieldWrapper className="category">
              <LG left tag="h5">Category</LG>
                <Dropdown className="dropdown"
                  selectedItem={selectedItem}
                  onSelect={item => setSelectedItem(item)}
                  downshiftProps={{ itemToString: item => item && item.label }}
                >
                <Field>
                  <Select>{selectedItem.value}</Select>
                </Field>
                <Menu>
                  {category.map(category => (
                    <Item key={category.value} value={category}>
                      {category.label}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="od">
              <LG left tag="h5" style={{display: `inline-block`}}>OD</LG>
              <select className="dropdown-small">
                <option value="EQ">Equals</option>
                <option value="RNG">Range</option>
              </select> 
              <Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `-10px`, left: `4px`, fontSize: `10px`}} />
              <input type="text" id="name" name="name" required placeholder="Enter OD"></input>
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormFieldWrapper className="ft">
              <LG left tag="h5" style={{display: `inline-block`}}>#/FT</LG>
              <select className="dropdown-small">
                <option value="RNG">Range</option>
                <option value="DEC">Decimal</option>
                <option value="EN">Exact Number</option>
              </select><Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `-10px`, left: `4px`, fontSize: `10px`}} />
              <Row>
                <Col md={6}>
                  <input className="min" type="text" id="min" name="feet" required placeholder="Min"></input>
                </Col>
                <Col md={6}>
                  <input className="max" type="text" id="max" name="feet" required placeholder="Max"></input>
                </Col>
              </Row>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="alloy">
              <LG left tag="h5">Alloy</LG>
              <Dropdown className="dropdown"
                selectedAlloy={selectedAlloy}
                onSelect={item => setSelectedAlloy(item)}
                downshiftProps={{ itemToString: item => item && item.label }}
              >
                <Field>
                  <Select>{selectedAlloy.value}</Select>
                </Field>
                <Menu>
                  {alloy.map(alloy => (
                    <Item key={alloy.value} value={alloy}>
                      {alloy.label}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormFieldWrapper className="min-yield">
              <LG left tag="h5" style={{display: `inline`}}>Min Yield</LG>
              <select className="dropdown-small">
                <option value="">Equals</option>
                <option value="AL">Range</option>
              </select><Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 0 0 0`, position: `relative`, top: `-10px`, left: `-4px`, fontSize: `10px`}} />
              <Dropdown className="dropdown min" 
                selectedMin={selectedMin}
                onSelect={item => setSelectedMin(item)}
                downshiftProps={{ itemToString: item => item && item.label }}
              >
                <Field style={{marginTop: `20px`}}>
                  <Select>{selectedMin.value}</Select>
                </Field>
                <Menu>
                  {min.map(min => (
                    <Item key={min.value} value={min}>
                      {min.label}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="end-finish">
              <LG left tag="h5">End Finish</LG>
              <input type="text" id="name" name="name" required placeholder="End Finish"></input>
            </FormFieldWrapper>
          </Col>
        </Row>
          <Row>
            <Col sm={12} md={6}>
              <FormFieldWrapper className="accepted">
                <LG left tag="h5">Accepted Manufacturers</LG>
              <Dropdown className="dropdown"
                selectedMan={selectedMan}
                onSelect={item => setSelectedMan(item)}
                downshiftProps={{ itemToString: item => item && item.label }}
              >
                <Field>
                  <Select>{selectedMan.value}</Select>
                </Field>
                <Menu>
                  {manufacturer.map(manufacturer => (
                    <Item key={manufacturer.value} value={manufacturer}>
                      {manufacturer.label}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>

              </FormFieldWrapper>
            </Col>
            <Col sm={12} md={6}>
              <FormFieldWrapper className="min-mill">
                <LG left tag="h5">Min Mill Year</LG>
              <Dropdown className="dropdown"
                selectedMill={selectedMill}
                onSelect={item => setSelectedMill(item)}
                downshiftProps={{ itemToString: item => item && item.label }}
              >
                <Field>
                  <Select>{selectedMill.value}</Select>
                </Field>
                <Menu>
                  {mill.map(mill => (
                    <Item key={mill.value} value={mill}>
                      {mill.label}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
              </FormFieldWrapper>
            </Col>
          </Row> 
          <Row>
            <Col md={6}>
              <FormFieldWrapper className="range">
                <LG left tag="h5">Range</LG>
                <Row>
                <Col md={6}>
                  <input className="min" type="text" id="min" name="name" required placeholder="Min"></input>
                </Col>
                <Col md={6}>
                  <input className="max" type="text" id="max" name="name" required placeholder="Max"></input>
                </Col>
              </Row>
              </FormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Flex justifyCenter>
                <FlexItem>
                  <FiltersButton className="filters-button" onClick={() => setCount(count + 1)}>Less Filters</FiltersButton>
                </FlexItem>
              </Flex>
            </Col>
          </Row>
        </FiltersWrapper>
      </Grid>
    )
  }
  return (
    <Grid>
      <FiltersWrapper>
        <Row>
          <Col md={6}>
            <FormFieldWrapper className="category">
              <LG left tag="h5">Category</LG>
              <Dropdown className="dropdown"
                selectedItem={selectedItem}
                onSelect={item => setSelectedItem(item)}
                downshiftProps={{ itemToString: item => item && item.label }}
              >
                <Field>
                  <Select>{selectedItem.value}</Select>
                </Field>
                <Menu>
                  {category.map(category => (
                    <Item key={category.value} value={category}>
                      {category.label}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="od">
              <LG left tag="h5" style={{display: `inline-block`}}>OD</LG>
              <select className="dropdown-small">
                <option value="EQ">Equals</option>
                <option value="RNG">Range</option>
              </select><Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `-10px`, left: `4px`, fontSize: `10px`}} />
              <input type="text" id="name" name="name" required placeholder="Enter OD"></input>
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormFieldWrapper className="ft">
              <LG left tag="h5" style={{display: `inline-block`}}>#/FT</LG>
              <select className="dropdown-small">
                <option value="RNG">Range</option>
                <option value="DEC">Decimal</option>
                <option value="EN">Exact Number</option>
              </select><Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `-10px`, left: `4px`, fontSize: `10px`}} />
              <Row>
                <Col md={6}>
                  <input className="min" type="text" id="min" name="name" required placeholder="Min"></input>
                </Col>
                <Col md={6}>
                  <input className="max" type="text" id="max" name="name" required placeholder="Max"></input>
                </Col>
              </Row>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="alloy">
              <LG left tag="h5">Alloy</LG>
              <Dropdown className="dropdown"
                selectedAlloy={selectedAlloy}
                onSelect={item => setSelectedAlloy(item)}
                downshiftProps={{ itemToString: item => item && item.label }}
              >
                <Field>
                  <Select>{selectedAlloy.value}</Select>
                </Field>
                <Menu>
                  {alloy.map(alloy => (
                    <Item key={alloy.value} value={alloy}>
                      {alloy.label}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormFieldWrapper className="min-yield">
              <LG left tag="h5" style={{display: `inline`}}>Min Yield</LG>
              <select className="dropdown-small">
                <option value="">Equals</option>
                <option value="AL">Range</option>
              </select><Icon icon="icon-chevron-down" color={color_white} style={{display: `inline-block`, float: `right`, margin: `16px 0 0 0`, position: `relative`, top: `-10px`, left: `-4px`, fontSize: `10px`}} />
              <Dropdown className="dropdown min" 
                selectedMin={selectedMin}
                onSelect={item => setSelectedMin(item)}
                downshiftProps={{ itemToString: item => item && item.label }}
              >
                <Field style={{marginTop: `20px`}}>
                  <Select>{selectedMin.value}</Select>
                </Field>
                <Menu>
                  {min.map(min => (
                    <Item key={min.value} value={min}>
                      {min.label}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="end-finish">
              <LG left tag="h5">End Finish</LG>
              <input type="text" id="name" name="name" required placeholder="End Finish"></input>
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Flex justifyCenter>
              <FlexItem>
                <FiltersButton onClick={() => setCount(count + 1)}>More Filters</FiltersButton>
              </FlexItem>
            </Flex>
          </Col>
        </Row>
      </FiltersWrapper>
    </Grid>
  );
}

export default MoreFilters;
