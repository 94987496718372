import fetchJson from 'utility/fetchJson';
import {BASE} from '../../constants'


export async function getInventory({pagination={}}) {
  const response = await fetchJson(`${BASE}/api/inventory/search/`, {
    method: 'POST',
    body: JSON.stringify({
      ...pagination
    })
  })
  return response
}

export async function getRfqGroupItems(id) {
  const response = await fetchJson(`${BASE}/api/rfq/group/item/${id}/`, {
    method: 'GET'
  })
  return response
}

export async function getRfqGroups({pagination={}}) {
  const response = await fetchJson(`${BASE}/api/rfq/group/list/?limit=100&sort=id`, {
    method: 'GET',
  })
  return response
}

export async function getRfqDetails({rfqId}) {
  const response = await fetchJson(`${BASE}/api/rfq/group/summary/${rfqId}/`, {
    method: 'GET',
  })
  return response
}


// create_timestamp: "2019-07-10T00:59:07.298000Z"
// document_type: "PSRFQ"
// google_address_id: 1155
// id: 6
// is_parent: true
// latitude: 39.986156
// longitude: -74.261284
// need_date: "2019-08-19T00:00:00Z"
// need_location: "1451 NJ-37, Toms River, NJ 08755, USA"
// place_id: "ChIJRywd81x2wYkRzLWkXxB4hrE"
// project_name: "Vince needed"
// status: "un_submitted"
// status_display: "Un-submitted"
// submitter_name: "Vincent Profetta"
// submitter_user: 74
// timestamp: "2019-07-23T10:46:21.355000Z"
// update_timestamp: "2019-07-10T19:53:31.637000Z"
// user: 74
// user_name: "Vincent Profetta"

// id: 6,
// type: "Request for Quote",
// projectName: "Houston Oil & Piping",
// referenceNumber: "RFQ46278",
// wellName: "1342B",
// status: "new",
// notifications: [],
// address: "2727 West Georgia Ave Suite A",
// city: "Austin, Tx",
// coordinates: {
//   lat: 22.052235,
//   lng: -110.243683
// },
// facilitator: 'CRA', 
// referenceNum: '2324234',
// items: 'View',
// submitDate: '06/22/20', 
// quoteDeadline: '06/22/20',
// confidenceLevel: 'High'