import React from 'react';
import { XXL, LG, MD } from '@zendeskgarden/react-typography';
import { TabsContainer } from '@zendeskgarden/react-tabs';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';

import { Tabs, TabPanel } from '@zendeskgarden/react-tabs';

import {
  ResultsSection,
  SectionArrow,
  BackgroundCircle,
  SearchResultsButton,
  Result,
  ResultsFound,
  Wrapper,
  Line,
  BgCircleWrapper,
} from '../../SearchResults.styles';

import DividingLine from 'components/PublicPages/DividingLine';

const Results = (props) => {
  return (
    <ResultsSection>
      <SectionArrow />
      <Flex justifyCenter>
        <FlexItem>
          <Wrapper>
            <LG tag='h6' className='results-found'>
              <b>197</b> Results Found
            </LG>
            <ResultsFound>
              <Tabs className='results'>
                <TabPanel label='All Results' key='tab-1'>
                  <Row>
                    <Col md={12}>
                      <Result className='top-result'>
                        <LG left tag='h5' style={{ marginBottom: `20px` }}>
                          Facilitators
                        </LG>
                        <MD right tag='p' className='top-update'>
                          Last Update: Jan 3, 2019
                        </MD>
                        <MD left tag='p' style={{ marginBottom: `5px` }}>
                          <a href='#'>
                            https://globalpipeco.blackflagcreative.com/facilitators
                          </a>
                        </MD>
                        <MD left tag='p'>
                          Sed ut perspiciatis unde omnis iste natus error sit{' '}
                          <a href='#'>voluptatem</a> accusantium doloremque
                          laudantium, totam rem aperiam,{' '}
                          <a href='#'>eaque ipsa</a> quae ab illo inventore
                          veritatis et{' '}
                          <b>quasi architecto bae vitae sunt explicabo.</b>
                        </MD>
                      </Result>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Result>
                        <LG left tag='h5' style={{ marginBottom: `20px` }}>
                          Global Pipe Co. Facilitators
                        </LG>
                        <MD right tag='p' className='update'>
                          Last Update: Jan 3, 2019
                        </MD>
                        <MD left tag='p' style={{ marginBottom: `5px` }}>
                          <a href='#'>
                            https://globalpipeco.blackflagcreative.com/facilitators
                          </a>
                        </MD>
                        <MD left tag='p'>
                          Sed ut perspiciatis unde omnis iste natus error sit{' '}
                          <a href='#'>voluptatem</a> accusantium doloremque
                          laudantium, totam rem aperiam,{' '}
                          <a href='#'>eaque ipsa</a> quae ab illo inventore
                          veritatis et{' '}
                          <b>quasi architecto bae vitae sunt explicabo.</b>
                        </MD>
                      </Result>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Result>
                        <LG left tag='h5' style={{ marginBottom: `20px` }}>
                          Buyer Facilitators
                        </LG>
                        <MD right tag='p' className='update'>
                          Last Update: Jan 3, 2019
                        </MD>
                        <MD left tag='p' style={{ marginBottom: `5px` }}>
                          <a href='#'>
                            https://globalpipeco.blackflagcreative.com/facilitators
                          </a>
                        </MD>
                        <MD left tag='p'>
                          Sed ut perspiciatis unde omnis iste natus error sit{' '}
                          <a href='#'>voluptatem</a> accusantium doloremque
                          laudantium, totam rem aperiam,{' '}
                          <a href='#'>eaque ipsa</a> quae ab illo inventore
                          veritatis et{' '}
                          <b>quasi architecto bae vitae sunt explicabo.</b>
                        </MD>
                      </Result>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Result>
                        <LG left tag='h5' style={{ marginBottom: `20px` }}>
                          Seller Facilitators
                        </LG>
                        <MD right tag='p' className='update'>
                          Last Update: Jan 3, 2019
                        </MD>
                        <MD left tag='p' style={{ marginBottom: `5px` }}>
                          <a href='#'>
                            https://globalpipeco.blackflagcreative.com/facilitators
                          </a>
                        </MD>
                        <MD left tag='p'>
                          Sed ut perspiciatis unde omnis iste natus error sit{' '}
                          <a href='#'>voluptatem</a> accusantium doloremque
                          laudantium, totam rem aperiam,{' '}
                          <a href='#'>eaque ipsa</a> quae ab illo inventore
                          veritatis et{' '}
                          <b>quasi architecto bae vitae sunt explicabo.</b>
                        </MD>
                      </Result>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Result>
                        <LG left tag='h5' style={{ marginBottom: `20px` }}>
                          Buyer Facilitators
                        </LG>
                        <MD right tag='p' className='update'>
                          Last Update: Jan 3, 2019
                        </MD>
                        <MD left tag='p' style={{ marginBottom: `5px` }}>
                          <a href='#'>
                            https://globalpipeco.blackflagcreative.com/facilitators
                          </a>
                        </MD>
                        <MD left tag='p'>
                          Sed ut perspiciatis unde omnis iste natus error sit{' '}
                          <a href='#'>voluptatem</a> accusantium doloremque
                          laudantium, totam rem aperiam,{' '}
                          <a href='#'>eaque ipsa</a> quae ab illo inventore
                          veritatis et{' '}
                          <b>quasi architecto bae vitae sunt explicabo.</b>
                        </MD>
                      </Result>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Result>
                        <LG left tag='h5' style={{ marginBottom: `20px` }}>
                          Seller Facilitators
                        </LG>
                        <MD right tag='p' className='update'>
                          Last Update: Jan 3, 2019
                        </MD>
                        <MD left tag='p' style={{ marginBottom: `5px` }}>
                          <a href='#'>
                            https://globalpipeco.blackflagcreative.com/facilitators
                          </a>
                        </MD>
                        <MD left tag='p'>
                          Sed ut perspiciatis unde omnis iste natus error sit{' '}
                          <a href='#'>voluptatem</a> accusantium doloremque
                          laudantium, totam rem aperiam,{' '}
                          <a href='#'>eaque ipsa</a> quae ab illo inventore
                          veritatis et{' '}
                          <b>quasi architecto bae vitae sunt explicabo.</b>
                        </MD>
                      </Result>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel className='tabs' label='Pages' key='tab-2'>
                  <Row>
                    <Col md={12}>
                      <Result className='top-result'>
                        <LG left tag='h5' style={{ marginBottom: `20px` }}>
                          Buyer Facilitators
                        </LG>
                        <MD right tag='p' className='top-update'>
                          Last Update: Jan 3, 2019
                        </MD>
                        <MD left tag='p' style={{ marginBottom: `5px` }}>
                          <a href='#'>
                            https://globalpipeco.blackflagcreative.com/facilitators
                          </a>
                        </MD>
                        <MD left tag='p'>
                          Sed ut perspiciatis unde omnis iste natus error sit{' '}
                          <a href='#'>voluptatem</a> accusantium doloremque
                          laudantium, totam rem aperiam,{' '}
                          <a href='#'>eaque ipsa</a> quae ab illo inventore
                          veritatis et{' '}
                          <b>quasi architecto bae vitae sunt explicabo.</b>
                        </MD>
                      </Result>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Result>
                        <LG left tag='h5' style={{ marginBottom: `20px` }}>
                          Seller Facilitators
                        </LG>
                        <MD right tag='p' className='update'>
                          Last Update: Jan 3, 2019
                        </MD>
                        <MD left tag='p' style={{ marginBottom: `5px` }}>
                          <a href='#'>
                            https://globalpipeco.blackflagcreative.com/facilitators
                          </a>
                        </MD>
                        <MD left tag='p'>
                          Sed ut perspiciatis unde omnis iste natus error sit{' '}
                          <a href='#'>voluptatem</a> accusantium doloremque
                          laudantium, totam rem aperiam,{' '}
                          <a href='#'>eaque ipsa</a> quae ab illo inventore
                          veritatis et{' '}
                          <b>quasi architecto bae vitae sunt explicabo.</b>
                        </MD>
                      </Result>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel className='tabs' label='Inventory' key='tab-3'>
                  <Row>
                    <Col md={12}>
                      <Result className='top-result'>
                        <LG left tag='h5' style={{ marginBottom: `20px` }}>
                          Global Pipe Co. Facilitators
                        </LG>
                        <MD right tag='p' className='top-update'>
                          Last Update: Jan 3, 2019
                        </MD>
                        <MD left tag='p' style={{ marginBottom: `5px` }}>
                          <a href='#'>
                            https://globalpipeco.blackflagcreative.com/facilitators
                          </a>
                        </MD>
                        <MD left tag='p'>
                          Sed ut perspiciatis unde omnis iste natus error sit{' '}
                          <a href='#'>voluptatem</a> accusantium doloremque
                          laudantium, totam rem aperiam,{' '}
                          <a href='#'>eaque ipsa</a> quae ab illo inventore
                          veritatis et{' '}
                          <b>quasi architecto bae vitae sunt explicabo.</b>
                        </MD>
                      </Result>
                    </Col>
                  </Row>
                </TabPanel>
              </Tabs>
            </ResultsFound>
            <Line />
          </Wrapper>
        </FlexItem>
      </Flex>
      <Flex justifyCenter>
        <FlexItem>
          <Button
            publicButton
            style={{ width: `255px`, marginTop: `32px`, marginBottom: `200px` }}
          >
            Load More Results
          </Button>
        </FlexItem>
      </Flex>
      <Flex justifyCenter>
        <FlexItem>
          <DividingLine />
        </FlexItem>
      </Flex>
      <XXL center tag='h2'>
        Looking For More Help?
      </XXL>
      <Flex justifyCenter>
        <FlexItem>
          <Button publicButton style={{ width: `200px`, margin: `30px 0` }}>
            Contact Us
          </Button>
        </FlexItem>
      </Flex>
      <BgCircleWrapper>
        <BackgroundCircle className='right-circle' />
        <BackgroundCircle className='left-circle' />
      </BgCircleWrapper>
    </ResultsSection>
  );
};

export default Results;
