import React, { useState } from 'react';
import {trackClick} from 'utility/analytics';
import history from 'historyContainer';
import { Row } from '@zendeskgarden/react-grid';
import Flex from 'styled-flex-component';
import { SM, MD } from '@zendeskgarden/react-typography';
import { IconButton } from '@zendeskgarden/react-buttons';
import { Tag } from '@zendeskgarden/react-tags';
import { Tooltip } from '@zendeskgarden/react-tooltips';
import { variables } from 'theme/variables';
import { INTEREST_SUMMARY, INTEREST_ITEMS } from 'components/GlobalSideBar/GlobalSideBarTypes';
import ProjectActionItemExpandable from './components/ProjectActionItemExpandable/ProjectActionItemExpandable';

import { openSidebar } from 'state/sidebars/actions';
import { setActiveInterest } from 'state/interests/actions';
import { Divider } from 'theme/Global.styles.js';

import Icon from 'components/Icon/Icon';

import {
  ActionRow,
  LowerText,
  UpperText,
  TypeCol,
  ProjectNameCol,
  WellNameCol,
  LocationCol,
  NotificationCol,
  ActionCol,
  defineRowStyles
} from './ProjectActionItem.styles.js';

const {
  spacing_sm,
  spacing_xxs,
  custom_blue,
  custom_green,
  custom_orange
} = variables;

 
const ProjectActionItem = ({index, id, type, projectName, address, city, referenceNumber, coordinates, wellName, status, notifications, condensed, simple, hideActions, table, setMapCenter, ...rest}) => { 
  hideActions = simple || hideActions
  const [isExpanded, setExpanded] = useState(undefined);
  const statusColors= {
    new: custom_blue,
    New: custom_blue,
    quoted: custom_green,
    order: custom_orange
  } 

  function toggleExpanded() {
    trackClick('expandActionItem', 'actionItemRow', 'ProjectActionItem');
    setExpanded(!isExpanded);
  }

  function openSummarySidebar(e) {
    e.stopPropagation();
    setActiveInterest(id);
    openSidebar({type: INTEREST_SUMMARY, options: {style: 'filter', maxWidth: '560px'}});
  }

  function openItemsSidebar(e) {
    e.stopPropagation();
    setActiveInterest(id);
    openSidebar({type: INTEREST_ITEMS, options: {style: 'filter', maxWidth: '900px'}});
  }

  function routeToDetails(id, e) {
    trackClick('interestItemDetails', 'icon-arrow-right', 'ProjectActionItem');
    e.stopPropagation();
    history.push(`/dashboard/my-requests/${id}`);
  }

  const {typeColStyle, projectNameColStyle, wellNameColStyle, locationColStyle} = defineRowStyles({simple, condensed, table, hideActions})

  const actionRowStyle = index === 0 ? {borderTop: 'none'} : {};

  return (
    <ActionRow style={actionRowStyle} simple={simple}>
      <Row onClick={toggleExpanded}>
        <TypeCol {...typeColStyle}>
          <Flex alignEnd style={table ? {display: 'none'} : {}}>
            <Icon icon="icon-edit" style={{lineHeight: '20px', marginBottom: '0px', marginRight: spacing_xxs}} fontSize={'15px'}/>
            <MD bold>{type}</MD>
          </Flex>
          <Flex alignCenter style={table ? {} : {paddingTop: spacing_sm}}>
            <SM style={{minWidth: '60px'}}>{referenceNumber}</SM>
            <Divider/>
            <Tag size="small" color={statusColors[status]} uppercase center style={{minWidth: 50}}>
              {status}
            </Tag>
          </Flex>
        </TypeCol>

        <ProjectNameCol {...projectNameColStyle}>
          <UpperText table={table} grey>Project Name</UpperText>
          <LowerText table={table}>{projectName}</LowerText>
        </ProjectNameCol>

        {
          !condensed ?
          <WellNameCol {...wellNameColStyle}>
            <UpperText table={table} grey>Well Name</UpperText>
            <LowerText table={table}>{wellName}</LowerText>
          </WellNameCol> : null
        }

        {
          !condensed ?
          <LocationCol {...locationColStyle}>
              <Tooltip
                zIndex={2}
                type="light"
                size="large"
                placement="start"
                offsetTop={index === 0 ? '-15px' : null}
                appendToBody={index === 0}
                trigger={
                  <div onClick={setMapCenter ? (e) => {
                    trackClick('setMapCenter', 'icon-pin-fill', 'ProjectActionItem');
                    e.stopPropagation();
                    setMapCenter(coordinates)
                  } : () => {}}>
                    <UpperText table={table} grey center>Location</UpperText>
                    <Flex style={table ? {} : {marginTop: '7px' }} justifyCenter>
                      <Icon hoverColor={custom_blue} button icon="icon-pin-fill" fontSize={table ? '20px' : '18px'}/>
                    </Flex>
                  </div>
                }>
                <MD center black>{address}</MD>
              </Tooltip>
          </LocationCol> : null
        }
        
        {
          !hideActions ?
          <NotificationCol size="auto" alignCenter>
            <IconButton onClick={openSummarySidebar} small square whiteButtonLink black bold alignCenter justifyCenter>
              {
                notifications.length ?
                <Tag xs pill absolute shadow circle blue button>{notifications.length}</Tag> : null
              }
              <Icon icon="icon-flag" fontSize={'14px'}/>
            </IconButton>
          </NotificationCol> : null
        }

        {
          !hideActions ?
          <ActionCol size="auto" alignCenter justifyEnd>
            <IconButton onClick={routeToDetails.bind(null, id)} small square whiteButtonLink black bold alignCenter justifyCenter>
              <Icon icon="icon-arrow-right" fontSize={'14px'}/>
            </IconButton>
          </ActionCol> : null
        }
        
      </Row>

      {
        isExpanded ?
        <ProjectActionItemExpandable openItemsSidebar={openItemsSidebar} {...rest} /> : null
      }
    </ActionRow>
  )
}


export default ProjectActionItem
