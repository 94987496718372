import {createBrowserHistory as createHistory} from 'history';
import {canUseDOM} from 'exenv';
import _ from 'lodash';
import queryString from 'query-string';


// Replace hash routes from old urls
if (canUseDOM && window.location.hash && window.history.replaceState) {
  const pathname = window.location.hash.split('#')[1];
  window.history.replaceState(null, null, pathname);
}

let state;
let prevState;
let history;
function addLocationQuery(history) {
  history.location = _.assign(
    history.location,
    {
      query: queryString.parse(history.location.search)
    }
  );
}
if (canUseDOM) {
  history = createHistory();
  addLocationQuery(history);
  history.listen((s) => {
    prevState = state;
    state = s;
    state.previous = prevState;
    addLocationQuery(history);
  });
}

export function getState() {
  return state || window.location;
}

export default history;