import React from 'react';
import { XXXL, XXL, XL, LG, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from 'components/Icon/Icon';

import {
  TopSection,
  PipesWrapper,
  BackgroundImg,
  Sticky,
} from '../../CompareItems.styles';

const { color_white } = variables;

const RecoverForm = (props) => {
  return (
    <TopSection style={{padding: `0`}} dark>
      <Sticky>
        <Icon icon="icon-icon-compare" color={color_white} style={{position: `relative`, top: `30px`, left: `20px`}}/>
        <Icon icon="icon-close" color={color_white} style={{position: `absolute`, top: `30px`, right: `40px`}}/>
        <XL tag="h3">Compare Items</XL>
      </Sticky>
      <XXXL center tag="h1">Compare Items</XXXL>
      <PipesWrapper>
        <BackgroundImg className="left-pipes" src="/images/hero-pipes.png" />
        <BackgroundImg className="right-pipes" src="/images/hero-pipes.png" />
      </PipesWrapper>
    </TopSection>

  )
}

export default RecoverForm;
