import {variables} from 'theme/variables';
const {
  line_height_md,
  spacing_sm,
  spacing_md,
  spacing_lg,
  spacing_xl,
  spacing_xxl,
  font_weight_regular,
  font_weight_semibold,
  font_weight_medium,
  spacing_xs,
  spacing_xxs,
  font_weight_bold,
  custom_grey,
  custom_black,
  custom_blue,
  color_white,
  custom_orange,
  color_red_400,
  font_size,
  public_font_family,
  public_font_size_xs,
  public_font_size_sm,
  public_font_size_md,
  public_font_size,
  public_font_size_lg,
  public_font_size_xl,
  public_font_size_xxl,
  public_font_size_xxxl,
  public_mobile_font_size_xs,
  public_mobile_font_size_sm,
  public_mobile_font_size_md,
  public_mobile_font_size,
  public_mobile_font_size_lg,
  public_mobile_font_size_xl,
  public_mobile_font_size_xxl,
  public_mobile_font_size_xxxl,
  public_font_weight_bold,
  media_md
} = variables;

const TypographyTheme = {
  'typography.xxxl': props => {
    let styles = typographyBaseStyles(props, 'XXXL')
     return `
      ${styles}
    `;
  },
  'typography.xxl': props => {
    let styles = typographyBaseStyles(props, 'XXL')
     return `
      ${styles}
    `;
  },
  'typography.xl': props => {
    let styles = typographyBaseStyles(props, 'XL')
     return `
      ${styles}
    `;
  },
  'typography.lg': props => {
    let styles = typographyBaseStyles(props, 'LG')
     return `
     
      ${styles}
    `;
  },
  'typography.md': props => {
    let styles = typographyBaseStyles(props, 'MD')
     return `
      ${styles}
    `;
  },
  'typography.sm': props => {
    let styles = typographyBaseStyles(props, 'SM')
    return `
      ${styles}
    `;
  }
};

function typographyBaseStyles(props, size) {
  return `
    ${size !== 'MD' && size !== 'SM' ? `
    font-weight: ${public_font_weight_bold};
    line-height: normal;
    letter-spacing: 1px;
    ` : ''}

    font-family: ${public_font_family};

    color: ${custom_black};

    ${size === 'XXXL' ? `
      font-size: ${public_mobile_font_size_xxxl};
      line-height: 38px;
      margin-bottom: 32px;
      @media ${media_md} {
        font-size: ${public_font_size_xxxl};
        line-height: 72px;
        margin-bottom: 72px;  
      }
    ` : ''}

    ${size === 'XXL' ? `
      font-size: ${public_mobile_font_size_xxl};
      line-height: 32px;
      margin-bottom: 24px;
      @media ${media_md} {
        font-size: ${public_font_size_xxl};
        line-height: 56px;
        margin-bottom: 28px;
      }
    ` : ''}

    ${size === 'XL' ? `
      font-size: ${public_mobile_font_size_xl};
      margin-bottom: 24px;
      @media ${media_md} {
        font-size: ${public_font_size_xl};
        margin-bottom: 32px;
      }
    ` : ''}

    ${size === 'LG' ? `
      font-size: ${public_mobile_font_size_lg};
      margin-bottom: 24px;
      @media ${media_md} {
        font-size: ${public_font_size_lg};
        margin-bottom: 32px;
      }
    ` : ''}

    ${size === 'LG' && props.h5 ? `
      font-size: ${public_mobile_font_size};
      margin-bottom: 8px;
      @media ${media_md} {
        font-size: ${public_font_size};
        margin-bottom: 8px;
      }
    ` : ''}

    ${size === 'MD' ? `
      font-size: ${public_mobile_font_size_md};
      line-height: 32px;
      margin-bottom: 46px;
      @media ${media_md} {
        font-size: ${public_font_size_md};
        line-height: 32px;
        margin-bottom: 46px;
      }
    ` : ''}

    ${size === 'SM' ? `
      font-size: ${public_mobile_font_size_sm};
      line-height: 24px;
      @media ${media_md} {
        font-size: ${public_font_size_sm};
      }
    ` : ''}

    ${props.lowOpacity ? `
      opacity: 0.7;
    ` : ''}

    ${props.animated === false ? `
      opacity: 0;
    ` : ''}
    ${props.animated !== 'undefined' ? `
      will-change: opacity;
    ` : ''}

    ${props.centerMaxWidth ? `
      margin-left: auto;
      margin-right: auto;
      max-width: ${props.centerMaxWidth}px;
    ` : ''}

    ${props.center ? `
      text-align: center;
    ` : ''}

    ${props.right ? `
      text-align: right;
    ` : ''}

    ${props.paddingHorizontal ? `
      padding-left: ${spacing_md};
      padding-right: ${spacing_md};
    ` : ''}

    ${props.paddingVertical ? `
      padding-top: ${spacing_md};
      padding-bottom: ${spacing_md};
    ` : ''}

    ${props.paddingTopXxs ? `
      padding-top: ${spacing_xxs};
    ` : ''}

    ${props.paddingTopXs ? `
      padding-top: ${spacing_xs};
    ` : ''}

    ${props.paddingTopSm ? `
      padding-top: ${spacing_sm};
    ` : ''}

     ${props.paddingTopMd ? `
      padding-top: ${spacing_md};
    ` : ''}

    ${props.paddingTopLg ? `
      padding-top: ${spacing_lg};
    ` : ''}

    ${props.paddingTopXl ? `
      padding-top: ${spacing_xl};
    ` : ''}

    ${props.paddingTopXXl ? `
      padding-top: ${spacing_xxl};
    ` : ''}

    ${props.paddingBottomXxs ? `
      padding-bottom: ${spacing_xxs};
    ` : ''}

    ${props.paddingBottomXs ? `
      padding-bottom: ${spacing_xs};
    ` : ''}

    ${props.paddingBottomSm ? `
      padding-bottom: ${spacing_sm};
    ` : ''}

     ${props.paddingBottomMd ? `
      padding-bottom: ${spacing_md};
    ` : ''}

    ${props.paddingBottomLg ? `
      padding-bottom: ${spacing_lg};
    ` : ''}

    ${props.paddingBottomXl ? `
      padding-bottom: ${spacing_xl};
    ` : ''}

    ${props.paddingBottomXXl ? `
      padding-bottom: ${spacing_xxl};
    ` : ''}

    ${props.color ? `
      color: ${props.color};
    ` : ''}

    ${props.grey ? `
      color: ${custom_grey};
    ` : ''}

    ${props.white ? `
      color: ${color_white};
    ` : ''}

    ${props.blue ? `
      color: ${custom_blue};
    ` : ''}

    ${props.bold ? `
      font-weight: ${font_weight_medium};
    ` : ''}

    ${props.semibold ? `
      font-weight: ${font_weight_medium};
    ` : ''}

    ${props.black ? `
      font-weight: ${font_weight_bold};
    ` : ''}

     ${props.light ? `
      font-weight: ${font_weight_regular};
    ` : ''}

    ${props.uppercase ? `
      text-transform: uppercase;
    ` : ''}

    ${props.alignCenter ? `
      display: flex !important;
      align-items: center !important;
    ` : ''}

    ${props.justifyEnd ? `
      display: flex !important;
      justify-content: flex-end !important;
    ` : ''}

    ${props.justifyStart ? `
      display: flex !important;
      justify-content: flex-start !important;
    ` : ''}

    ${props.xs ? `
      font-size: ${public_mobile_font_size_xs} !important;
    ` : ''}

    ${size === 'SM' && props.paragraph ? `
      line-height: ${line_height_md} !important;
    ` : ''}

    ${size === 'MD' && props.scaled ? `
      font-size: ${font_size} !important;
    ` : ''}

    ${props.lhInherit  ? `
      line-height: inherit !important;
    ` : ''}

    ${props.ellipsis ? `
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    ` : ''}

    ${props.headline ? `
      line-height: 1.5em !important;
    ` : ''}

    ${props.subheadline ? `
      line-height: 2em !important;
    ` : ''}

    ${props.pointer ? `
      cursor: pointer !important;
    ` : ''}

    ${props.tab ? `
      letter-spacing: 0.3px;
      &:hover {
        color: ${custom_blue};
      }
      ${props.active ? `
        color: ${custom_blue};
        font-weight: ${font_weight_semibold};
        letter-spacing: 0;
      ` : ''}

    ` : ''}

     ${props.link ? `
      color: ${props.link === 'warning' ? custom_orange : props.link === 'error' ? color_red_400 : custom_blue};      
      cursor: pointer;
      transition: opacity 0.2s ease;
      opacity: 1;
      &:hover {
        opacity: 0.7;
      }
    ` : ''}
  `;
}

export default TypographyTheme;
