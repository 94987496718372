import {canUseDOM} from 'exenv';
import { merge, isObject, isArray, reduce, isString, map } from 'lodash';

export default async function fetchJSON(url, fetchOptions = {}, {cookies, token, session, headers} = {}) {
  if (canUseDOM && window.OFFLINE_MODE) {
    return Promise.resolve({})
  }

  // if ((!token || !session) && !canUseDOM) {
  //   throw new Error('Session required');
  // }
  const requestHeaders = {
    'Content-Type': 'application/json',
  };

  const csrfToken = getCookie("csrftoken")
  if (csrfToken) {
    requestHeaders["X-CSRFToken"] = csrfToken;
  }

  // TODO - explore cookies with credentials
  const options = merge({
    headers: requestHeaders,
  }, fetchOptions);

  if (fetchOptions.credentials === false) {
    delete options.credentials;
  } else {
    options.credentials = 'include';
  }

  
  const resp = await fetch(url, options);
  if (resp.ok) {
    try {
      return await resp.json();
    } catch(err) {
      // HANDLE WHEN RESPONSE IS BLANK BUT 200
      return {success: true}
    }
  } else {
    let errorMessage;
    if (resp.status >= 500) {
      errorMessage = 'We are experiecing difficulties, please try again later.'
    } else {
      errorMessage = await resp.json();
      if (errorMessage && errorMessage.non_field_errors) {
        errorMessage = errorMessage.non_field_errors.join(', ')
      } else if (errorMessage && errorMessage.detail) {
        errorMessage = errorMessage.detail;
      } else if (isObject(errorMessage)) {
        errorMessage =  generateErrorMessgae(errorMessage) || "An Unknown Error Occured Generating Error Message";
      } else {
        errorMessage = "An Unknown Error Occured";
      }
    }
    throw new Error(errorMessage.replace('place_id', 'Location'))
  }
}

function generateErrorMessgae(errorMessage) {
  try {
    const array = map(errorMessage, (v, key) => {
      if (isObject(v) && !isArray(v)) {
        return generateErrorMessgaeFromObject(key, v)
      } else if (isArray(v)) {
        const reduceValues = reduce(v, (accum, value) => {
          if (isString(value)) {
            accum.push(value)
          }
          return accum;
        }, [])
        return [`${key}: ${reduceValues.join(', ')}`];
      } else if(isString(v)) {
        return [`${key}: ${v}`];
      } 
    }); 
    return array.join(', ');
  } catch(err) {
    return "An Unknown Error Occured Determining Message";
  }
}

// Avoiding recursion from above - dup code but with rootKey - Business Information onboarding passes back an object as error. 
function generateErrorMessgaeFromObject(rootKey, errorMessage) {
  try {
    const array = map(errorMessage, (v, key) => {
     if (isArray(v)) {
        const reduceValues = reduce(v, (accum, value) => {
          if (isString(value)) {
            accum.push(value)
          }
          return accum;
        }, [])
        return [`${rootKey}: ${reduceValues.join(', ')}`];
      } else if(isString(v)) {
        return [`${rootKey}: ${v}`];
      } 
    }); 
    return array.join(', ');
  } catch(err) {
    return "An Unknown Error Occured Determining Message";
  }
}



function getCookie(name) {
  var cookieValue = null;
  if (canUseDOM && document && document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
