import React, { useState, useEffect } from 'react';
import {trackClick} from 'utility/analytics';
import history from 'historyContainer';
import { IconButton } from '@zendeskgarden/react-buttons';
import { Tag } from '@zendeskgarden/react-tags';
import { Dropdown, Trigger, Menu, Item } from '@zendeskgarden/react-dropdowns';
import {variables} from 'theme/variables';
import Flex from 'styled-flex-component';
import { MD } from '@zendeskgarden/react-typography';
import { Button } from 'theme/Button';
import { MenuHeader, MenuBody, MenuFooter } from './NotificationDropdown.styles.js';
import NotificationDropdownList from './components/NotificationDropdownList/NotificationDropdownList';
import Icon from 'components/Icon/Icon';

const {
  spacing_xs,
  custom_light_grey,
  line_height_xxl
} = variables;

const NotificationDropdown = ({logout}) => {
  const [isOpenGlobal, setOpen] = useState(undefined);

  function toggleOpen() {
    setOpen(!isOpenGlobal)
  }

  function close() {
    if (isOpenGlobal) {
      setOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', close);
    return () => {
      window.removeEventListener('click', close);
    };
  });

  return (
    <Dropdown 
      style={{width: '200px'}}
      arrow={false}
      isOpen={isOpenGlobal}>
      <Trigger>
        <span style={{outline: 'none'}}>
          <IconButton onClick={(e) => {
            e.stopPropagation()
            toggleOpen()
          }} xl square whiteButtonLink black bold alignCenter justifyCenter>
            <Tag size="small" absolute shadow circle blue button pill>
              1
            </Tag>
            <Icon pointer icon="icon-notification" fontSize={'20px'} />
          </IconButton>
        </span>
      </Trigger>
      <Menu
        style={{padding: '0px', width: '400px'}}
        menuHeader
        arrow={false}
        placement="bottom-end">
        <span>
          <MenuHeader onClick={(e) => e.stopPropagation()}>
            <Flex style={{width: '100%'}} justifyBetween alignCenter>
              <Flex alignCenter>
                <Icon icon="icon-notification" fontSize={'16px'} color={'#fff'} />
                <MD style={{paddingLeft: spacing_xs}} color="#fff">Notifications</MD>
              </Flex>
              <IconButton onClick={(e) => {
                trackClick('closeNotificationDropdown', 'icon-close', 'NotificationDropdown');
                e.stopPropagation()
                toggleOpen()
              }} disableBackground alignCenter justifyEnd  aria-label="closeNotificationDropdown">
                <Icon button icon="icon-close" fontSize={'12px'} color={'#fff'} />
              </IconButton>
            </Flex>
          </MenuHeader>

          <MenuHeader onClick={(e) => e.stopPropagation()} style={{backgroundColor: custom_light_grey, height: line_height_xxl}}>
            <MD bold>Today</MD>
          </MenuHeader>

          <MenuBody onClick={(e) => e.stopPropagation()}>
            <NotificationDropdownList notifications={[1,2,3]}/>
          </MenuBody>

          <MenuFooter onClick={(e) => {
            e.stopPropagation()
            trackClick('viewAllNotifications', 'View All Notification', 'NotificationDropdown');
            history.push('/notifications');
          }}>
            <Button link>
              View All Notification
            </Button>
          </MenuFooter>
        </span>
        
      </Menu>
    </Dropdown>

  )
}

export default NotificationDropdown;

