// OVERIDE CSS
import {variables} from 'theme/variables';
const {
  custom_blue,
  custom_black,
  font_weight_semibold,
  custom_navy,
  color_red_400,
  custom_grey

} = variables;

const TabTheme = {
  'tabs.tablist': props => { 
    return `
      ${props.border === false ? `
        border-bottom: 0px solid !important;
      ` : ''}
    `;
  },

  'tabs.tab': props => {
    return `
      color: ${props.navy ? custom_grey : custom_black} !important;
      border-width: 2px !important;
      padding: 0 !important;
      margin: 0px 24px 0 0 !important;
      margin-bottom: ${props.noBottomMargin ? '0px' : '6px'} !important;
      line-height: 30px;
      ${
        props.bold ? 
        `font-weight: ${font_weight_semibold};` : `
          letter-spacing: 0.35px;
          * {
            letter-spacing: 0.35px;
          }
        `
      };
      * {
        line-height: 30px;
        color: ${props.navy ? custom_grey : custom_black} !important;
      }

      .hover-tab {
        opacity: 0;
        pointer-events: none;
        &:hover {
          color: ${color_red_400} !important;
        }
      }

      &:hover {
        color: ${props.navy ? custom_navy : custom_blue} !important;
        * {
          color: ${props.navy ? custom_navy : custom_blue} !important;
        }
      }


      ${props.selected === true ? `
        letter-spacing: 0px;
        color: ${props.navy ? custom_navy : custom_blue} !important;
        border-bottom: 2px solid ${props.navy ? custom_navy : custom_blue};
        font-weight: ${font_weight_semibold};
        &:before {
          display: none;
        }
        .hover-tab {
          opacity: 1;
          pointer-events: all;
        }
      ` : ''}

      &[class*="is-selected"], &:active, &:focus {
        letter-spacing: 0px;
        color: ${props.navy ? custom_navy : custom_blue} !important;
        border-bottom: 2px solid ${props.navy ? custom_navy : custom_blue};
        font-weight: ${font_weight_semibold};
        &:before {
          display: none;
        }
        * {
          letter-spacing: 0px;
          color: ${props.navy ? custom_navy : custom_blue} !important;
        }
      }
    `;
  }
};

export default TabTheme;
