import React from 'react';
import {trackClick} from 'utility/analytics';
import {useBranch} from 'baobab-react/hooks';
import {variables} from 'theme/variables';
import { SearchInput } from 'theme/SearchInput';
import Flex from 'styled-flex-component';
import { Row } from '@zendeskgarden/react-grid';
import { IconButton } from '@zendeskgarden/react-buttons';
import history from 'historyContainer';
import {logout} from 'state/authentication/actions';
import { Col } from '@zendeskgarden/react-grid';
import Platform from 'components/Platform/Platform';

import AccountMenuDropdown from './components/AccountMenuDropdown/AccountMenuDropdown';
import NotificationDropdown from './components/NotificationDropdown/NotificationDropdown';

import {toggleDrawer} from 'state/sidebars/actions';
import {toggleExpand} from 'state/sidebarPage/actions';

import Icon from 'components/Icon/Icon';

import {
  MenuColumn,
  SearchWrapperCol,
  AppHeaderComponentSidebar,
  LogoColumn,
  ActionColumn,
  HeaderGrid,
  Logo
} from './app-header.styles.js'

const {
  logo_url
} = variables;

 
const AppHeader = (props) => { 
  const {sidebarExpanded} = useBranch({
    sidebarExpanded: ['sidebarPage', 'sidebarExpanded'],
  }); 
 
  async function appLogout() {
    trackClick('logout', 'logout', 'AppHeader');
    const logoutRequest = await logout();
    if (!logoutRequest.error) {
      history.push('/login');
    } else {
      alert('There was an error signing out.')
    }
  }

  return (
    <AppHeaderComponentSidebar>
      <HeaderGrid fluid>
        <Row gutters={false} alignItems="center">
          <LogoColumn>
            <Flex alignCenter>
              <Logo onClick={() => history.push('/dashboard')} src={logo_url} />
            </Flex>
          </LogoColumn>

          <Platform component={Col} mobile alignCenter justifyEnd size={2}>
            <IconButton style={{marginBottom: 10}} onClick={toggleDrawer} disableBackground alignCenter justifyEnd size="large" aria-label="Menu">
              <Icon icon="icon-align-text" fontSize={'18px'}/>
            </IconButton>
          </Platform>

          <ActionColumn size={12} sm={false} paddingRight>
            <Row style={{minWidth: '100%'}} alignItems="center" justifyContent="between">
              
              <Col>
                <Row alignItems="center">
                  <Platform component={Col} size="auto" desktop>
                    <IconButton onClick={() => {
                      trackClick('toggleExpandSidebar', 'icon-expand', 'AppHeader', {expanded: !sidebarExpanded});
                      toggleExpand();
                    }} xl square whiteButtonLink black bold alignCenter justifyCenter>
                      {
                        sidebarExpanded ?
                        <Icon icon="icon-align-text" fontSize={'18px'}/> :
                        <Icon icon="icon-arrow-right-outside" fontSize={'18px'}/>
                      }
                    </IconButton>
                  </Platform>
                  <SearchWrapperCol>
                    <SearchInput size="xl" placeholder="Search Vendors & Inventory" />
                  </SearchWrapperCol>
                </Row>
              </Col>

              
              <MenuColumn style={{marginLeft: 20}} size={'auto'}>
                <Row alignItems="center">
                  <Col size="auto">
                    <NotificationDropdown />
                  </Col>
                  <Col size="auto">
                    <AccountMenuDropdown logout={appLogout} />
                  </Col>
                </Row>
              </MenuColumn>
            </Row>
          </ActionColumn>
        </Row>
      </HeaderGrid>
    </AppHeaderComponentSidebar>
  )
}


export default AppHeader;
