import React from 'react';
import { XXL, XL, LG, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';


import SideBar from '../SideBar/SideBar'
import WorldMapOverlay from '../WorldMapOverlay/WorldMapOverlay'
import SimilarItemsTable from '../SimilarItemsTable/SimilarItemsTable'
import DocumentationTable from '../DocumentationTable/DocumentationTable'
import ElementalCompositionTable from '../ElementalCompositionTable/ElementalCompositionTable'
import DocumentationOverlay from '../DocumentationOverlay/DocumentationOverlay'


import {
  InfoSection,
  BackgroundImg,
  ViewItemDetailsButton,
  ViewGalleryButton,
  TransparentButton,
  ItemInformationWrapper,
  LongLine,
  SideContainer,
  PipesImg,
  SizeWrapper,
  BrandWrapper,
  AlloyWrapper,
  FinishWrapper,
  TechnicalWrapper,
  WorldMap,
  ToggleButton,
  ConfidenceLarge,
  WorldMapBg,
  MarginWrapper,
  DocumentationBg,
  SignUpButton,
  DescriptionWrapper,
  ContentWrapper,
  LogoImg,
  NameWrapper
} from '../../ViewItemDetails.styles';

import {PublicButton} from 'components/PublicPages/GlobalPublicStyles';

const { spacing } = variables;

const InformationSection = (props) => {
  return (
    <InfoSection style={{ position: `relative` }}>
      <SideBar />
      <ContentWrapper className="content-wrapper">
      <Grid>
      <ItemInformationWrapper id="item-information">
        <XXL className="information" tag="h2">Item Information</XXL>
        <LongLine />
      </ItemInformationWrapper>
      <ItemInformationWrapper>
        <Row>
          <Col sm={4} md={4}>
            <XL tag="h3">Item Images</XL>
          </Col>
          <Col sm={12} md={8}>
            <XL left tag="h3" className="size-information">Size Information</XL>
            <SizeWrapper className="no-display-mobile">
              <Row>
                <Col sm={1} md={3} lg={2}>
                  <LG left tag="h6">OD</LG>
                </Col>
                <Col sm={1} md={3} lg={2}>
                  <LG left tag="h6">ID</LG>
                </Col>
                <Col sm={1} md={4} lg={4}>
                  <LG left tag="h6">Weigth Per Foot</LG>
                </Col>
                <Col sm={1} md={2} lg={2}>
                  <LG left tag="h6">Wall</LG>
                </Col>
              </Row>
              <Row>
                <Col md={3} lg={2}>
                  <MD left tag="p">10.75#</MD>
                </Col>
                <Col md={3} lg={2}>
                  <MD left tag="p">10.75#</MD>
                </Col>
                <Col md={4} lg={4}>
                  <MD left tag="p">65.7#</MD>
                </Col>
                <Col md={2} lg={2}>
                  <MD left tag="p">65.7#  </MD>
                </Col>
              </Row>
              <Row>
                <Col md={3} lg={2}>
                  <LG left tag="h6">Drift</LG>
                </Col>
                <Col md={3} lg={3}>
                  <LG left tag="h6">Min Yeild</LG>
                </Col>
              </Row>
              <Row>
                <Col md={3} lg={2}>
                  <MD left tag="p">9.404"</MD>
                </Col>
                <Col md={3} lg={3}>
                  <MD left tag="p">50</MD>
                </Col>
              </Row>
            </SizeWrapper>
            <SizeWrapper className="display-mobile size">
              <Row className="od">
                  <LG left tag="h6">OD</LG><br />
              </Row>
              <Row className="od">
                  <MD left tag="p">10.75#</MD>
              </Row>
              <Row className="id">
                  <LG left tag="h6">ID</LG>
              </Row>
              <Row className="id">
                  <MD left tag="p">10.75#</MD>
              </Row>
                <Row className="weight">
                  <LG left tag="h6">Weigth Per Foot</LG>
                </Row>
                <Row className="weight">  
                  <MD left tag="p">65.7#</MD>
                </Row>
                <Row className="wall">
                  <LG left tag="h6">Wall</LG>
                </Row>
                <Row className="wall">
                  <MD left tag="p">65.7#  </MD>
                </Row>
                <Row className="drift">
                  <LG left tag="h6">Drift</LG>
                </Row>
                <Row className="drift">
                  <MD left tag="p">9.404"</MD>
                </Row>
                <Row className="yield">
                  <LG left tag="h6">Min Yeild</LG>
                </Row>
                <Row className="yield">
                  <MD left tag="p">50</MD>
               </Row>
            </SizeWrapper>
          </Col>
        </Row>
        <PipesImg src='images/Pipes-Images.png' />
        <ViewGalleryButton>View Gallery(13)</ViewGalleryButton>
        <LongLine className="size-line" />
      </ItemInformationWrapper>
      <ItemInformationWrapper>
        <XL tag="h3">Brand Information</XL>
        <BrandWrapper>
          <Row>
            <NameWrapper className="name-wrapper">
            <Col md={2}>
              <LG tag="h6">Name</LG>
              <MD tag="p"className="display-mobile approved">CRA Approved</MD>
            </Col>
            </NameWrapper>
            <Col md={10}>
              <LG tag="h6" className="move-mobile-up" >Description</LG>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <MD tag="p" className="no-display-mobile">CRA Approved</MD>
            </Col>
            <Col md={10}>
              <ul className="move-mobile-up">
                <li>
                  • &ensp; Material purchased directly from mill or mill appointed distributor.
                </li>
                <li>
                  • &ensp; Fully complies with the requirements of API 5CRA or API 5CT revision in effect at time of manufacture
                </li>
                <li>
                  • &ensp; Full traceability to mill certifications via stencils.
                </li>
                <li>
                  • &ensp; Mill certifications provided for customer review and acceptance.
                </li>
                <li>
                  • &ensp; Customer purchase order considered acceptance of mill certifications.
                </li>
              </ul>
            </Col>
          </Row>
        </BrandWrapper>
        <LongLine />
      </ItemInformationWrapper>
      <ItemInformationWrapper>
        <LG tag="h3">Manufacturer</LG>
        <LogoImg src="images/SumitomoLogo.png" />
        <LongLine />
      </ItemInformationWrapper>
      <ItemInformationWrapper>
        <LG tag="h3">Alloy Information</LG>
        <AlloyWrapper className="alloy display-alloy-mobile">
          <Grid>
                <Row className="group">
                  <LG left tag="h6">Group</LG><br />
                </Row>
                <Row className="group">
                  <MD left tag="p">CH Nickel</MD>
                </Row>
                <Row className="alloy-name">
                  <LG left tag="h6">Name</LG>
                </Row>
                <Row className="alloy-name">
                  <MD left tag="p">G3</MD>
                </Row>
                <Row className="alloy-pren">
                  <LG left tag="h6">PREN</LG>
                </Row>
                <Row className="alloy-pren">
                  <MD left tag="p">16.45 - 32.4</MD>
                </Row>
                <Row className="uns">
                  <LG left tag="h6">UNS</LG>
                </Row>
                <Row className="uns">
                  <MD left tag="p">N08825</MD>
                </Row>
                <Row className="alt-names">
                  <LG left tag="h6">Alternative Trade Names</LG>
                </Row>
                <Row className="alt-names">
                    <ul className="move-left">
                      <li>
                        •	&ensp; Inconel 825
                  </li>
                      <li>
                        •	&ensp; Inc825
                  </li>
                      <li>
                        •	&ensp; Alloy 825
                  </li>
                    </ul>
                </Row>
            {/* <Row>
              <Col md={2}>
                <LG left tag="h6">Group</LG>
              </Col>
              <Col md={2}>
                <LG left tag="h6" className="left">Name</LG>
              </Col>
              <Col md={2}>
                <LG left tag="h6" className="move-left">PREN</LG>
              </Col>
              <Col md={2}>
                <LG left tag="h6" className="move-left">UNS</LG>
              </Col>
              <Col md={4}>
                <LG left tag="h6" className="move-left">Alternative Trade Names</LG>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <MD left tag="p">CH Nickel</MD>
              </Col>
              <Col md={2}>
                <MD left tag="p" className="left">G3</MD>
              </Col>
              <Col md={2}>
                <MD left tag="p" className="move-left">16.45 - 32.4</MD>
              </Col>
              <Col md={2}>
                <MD left tag="p" className="move-left">N08825</MD>
              </Col>
              <Col md={4}>
                <ul className="move-left">
                  <li>
                    •	&ensp; Inconel 825
                  </li>
                  <li>
                    •	&ensp; Inc825
                  </li>
                  <li>
                    •	&ensp; Alloy 825
                  </li>
                </ul>
              </Col>
            </Row>
          <Row> */}
          <div className="mobile-move-wrapper">
                <DescriptionWrapper className="description">
                  <LG left tag="h6">Description</LG>
                  <MD left tag="p" style={{width: `280px`}}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</MD>
                </DescriptionWrapper>
              <Row>
                <LG left tag="h6">Elemental Composition</LG>
              </Row>
              <Row>
                <ElementalCompositionTable />
              </Row>
            </div>
          </Grid>
        </AlloyWrapper>
            <AlloyWrapper className="alloy no-display-mobile">
              <Grid>
                <Row>
                  <Col md={2}>
                    <LG left tag="h6">Group</LG>
                  </Col>
                  <Col md={2}>
                    <LG left tag="h6" className="left">Name</LG>
                  </Col>
                  <Col md={2}>
                    <LG left tag="h6" className="move-left">PREN</LG>
                  </Col>
                  <Col md={2}>
                    <LG left tag="h6" className="move-left">UNS</LG>
                  </Col>
                  <Col md={4}>
                    <LG left tag="h6" className="move-left">Alternative Trade Names</LG>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <MD left tag="p">CH Nickel</MD>
                  </Col>
                  <Col md={2}>
                    <MD left tag="p" className="left">G3</MD>
                  </Col>
                  <Col md={2}>
                    <MD left tag="p" className="move-left">16.45 - 32.4</MD>
                  </Col>
                  <Col md={2}>
                    <MD left tag="p" className="move-left">N08825</MD>
                  </Col>
                  <Col md={4}>
                    <ul className="move-left">
                      <li>
                        •	&ensp; Inconel 825
                  </li>
                      <li>
                        •	&ensp; Inc825
                  </li>
                      <li>
                        •	&ensp; Alloy 825
                  </li>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <DescriptionWrapper>
                    <LG left tag="h6">Description</LG>
                    <MD left tag="p">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</MD>
                  </DescriptionWrapper>
                </Row>
                <Row>
                  <LG left tag="h6">Elemental Composition</LG>
                </Row>
                <Row>
                  <ElementalCompositionTable />
                </Row>
              </Grid>
            </AlloyWrapper>
        <LongLine className="alloy-line"/>
      </ItemInformationWrapper>
      <ItemInformationWrapper>
        <LG tag="h3">End Finish</LG>
        <FinishWrapper className="no-display-mobile">
        <Grid>
          <Row>
              <Col md={2}>
                <LG left tag="h6">Name</LG>
              </Col>
              <Col md={2}>
                <LG left tag="h6" className="left">Category</LG>
              </Col>
              <Col md={2}>
                <LG left tag="h6" className="move-left">License</LG>
              </Col>
              <Col md={2}>
                <LG left tag="h6" className="move-left">Thread Type</LG>
              </Col>
              <Col md={4}>
                <LG left tag="h6" className="move-left">Alternative Thread Types</LG>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <MD left tag="p">VAM TOP</MD>
              </Col>
              <Col md={2}>
                <MD left tag="p" className="left">OCTG</MD>
              </Col>
              <Col md={2}>
                <MD left tag="p" className="move-left">VAM TOP</MD>
              </Col>
              <Col md={2}>
                <MD left tag="p" className="move-left">VAM TOP</MD>
              </Col>
              <Col md={4}>
                <MD left tag="p" className="move-left">G3</MD>
              </Col>
            </Row>
          <Row>
            <DescriptionWrapper>
              <LG left tag="h6">Description</LG>
              <MD left tag="p">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</MD>
            </DescriptionWrapper>
          </Row>
          </Grid>
          </FinishWrapper>
            <FinishWrapper className="display-finish-mobile">
              <Grid>
                <Row className="finish-name">
                  <LG left tag="h6">Name</LG><br />
                </Row>
                <Row className="finish-name">
                  <MD left tag="p">VAM TOP</MD>
                </Row>
                <Row className="finish-category">
                  <LG left tag="h6">Category</LG>
                </Row>
                <Row className="finish-category">
                  <MD left tag="p">OCTG</MD>
                </Row>
                <Row className="license">
                  <LG left tag="h6">License</LG>
                </Row>
                <Row className="license">
                  <MD left tag="p">VAM TOP</MD>
                </Row>
                <Row className="thread-type">
                  <LG left tag="h6">Thread Type</LG>
                </Row>
                <Row className="thread-type">
                  <MD left tag="p">VAM TOP</MD>
                </Row>
                <Row className="alt-threads">
                  <LG left tag="h6" style={{ width: `200px` }}>Alternative Thread Type</LG>
                </Row>
                <Row className="alt-threads">
                  <MD left tag="p">G3</MD>
                </Row>
                  <DescriptionWrapper className="move-mobile-finish">
                    <LG left tag="h6">Description</LG>
                    <MD left tag="p" style={{width: `280px`}}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</MD>
                  </DescriptionWrapper>
              </Grid>
            </FinishWrapper>
        <LongLine className="finish-line" />
      </ItemInformationWrapper>
      <ItemInformationWrapper id="technical">
        <XXL tag="h2" className="technical-information">Technical Information</XXL>
        <TechnicalWrapper className="no-display-mobile">
          <Grid>
            <Row>
              <Col md={3} lg={2}>
                <LG left tag="h6">Burst</LG>
              </Col>
              <Col md={3} lg={2}>
                <LG left tag="h6" className="right">Collapse</LG>
              </Col>
              <Col md={3} lg={3}>
                <LG left tag="h6" className="right">Tensile Strength</LG>
              </Col>
              <Col md={3} lg={2}>
                <LG left tag="h6" className="right">Drift</LG>
              </Col>
            </Row>
            <Row>
            <Col md={3} lg={2}>
              <MD left tag="p">VAM TOP</MD>
            </Col>
            <Col md={3} lg={2}>
              <MD left tag="p" className="right">OCTG</MD>
            </Col>
            <Col md={3} lg={3}>
              <MD left tag="p" className="right">VAM TOP</MD>
            </Col>
            <Col md={3} lg={2}>
              <MD left tag="p" className="right">VAM TOP</MD>
            </Col>
          </Row>
          </Grid>
        </TechnicalWrapper>
          <TechnicalWrapper className="display-tech-mobile">
            <Grid>
              <Row className="burst">
                <LG left tag="h6">Burst</LG><br />
              </Row>
              <Row className="burst">
                <MD left tag="p">VAM TOP</MD>
              </Row>
              <Row className="tech-collapse">
                <LG left tag="h6">Collapse</LG>
              </Row>
              <Row className="tech-collapse">
                <MD left tag="p">OCTG</MD>
              </Row>
              <Row className="tensile-strength">
                <LG left tag="h6">Tensile Strength</LG>
              </Row>
              <Row className="tensile-strength">
                <MD left tag="p">VAM TOP</MD>
              </Row>
              <Row className="tech-drift">
                <LG left tag="h6">Drift</LG>
              </Row>
              <Row className="tech-drift">
                <MD left tag="p">VAM TOP</MD>
              </Row>
            </Grid>
          </TechnicalWrapper>
        <LongLine className="tech-line"/>
      </ItemInformationWrapper>
      <ItemInformationWrapper id="availability">
        <XXL tag="h2">Availability</XXL>
        <WorldMap src="images/world.svg" />
        <WorldMapOverlay />
        <LongLine />
      </ItemInformationWrapper>
      <ItemInformationWrapper id="documentation">
        <XXL tag="h2" className="documentation">Documentation</XXL>
        <DocumentationTable />
        <DocumentationOverlay />
        <LongLine />
      </ItemInformationWrapper>
      <ItemInformationWrapper id="similar-items">
        <XXL tag="h2" className="similar">Similar Items</XXL>
        <SimilarItemsTable />
      </ItemInformationWrapper>
      </Grid>
    </ContentWrapper>
    </InfoSection>
  )
}

export default InformationSection;