import React from 'react';
import Spinner from 'components/Spinner/Spinner';
import Loadable from 'react-loadable';
import authOnEnter from './utility/authOnEnter';
import { Route, Redirect } from 'react-router';
import LoginPage from './routes/Login/Login';

import ItemSubmissionPage from './routes/ItemSubmission/ItemSubmission';
import FindInventoryResults from './routes/FindInventoryResults/FindInventoryResults';
import ViewItemDetails from './routes/ViewItemDetails/ViewItemDetails';
import HelpCenter from './routes/HelpCenter/HelpCenter';
import HelpCenterArticle from './routes/HelpCenterArticle/HelpCenterArticle';
import FrequentlyAskedQuestions from './routes/FAQ/FrequentlyAskedQuestions';
import CantLogin from './routes/CantLogin/CantLogin';
import CantLoginRecover from './routes/CantLoginRecover/CantLoginRecover';
import CreateAccountTemplate from './routes/CreateAccountTemplate/CreateAccount';
import LoginTemplate from './routes/LoginTemplate/LoginTemplate';
import AccountCreated from './routes/AccountCreated/AccountCreated';
import ResetPassword from './routes/ResetPassword/ResetPassword';
import ResetPasswordSuccessful from './routes/ResetPasswordSuccessful/ResetPasswordSuccessful';
import SearchTemplate from './routes/SearchTemplate/SearchTemplate';
import SearchResults from './routes/SearchResults/SearchResults';
import CompareItems from './routes/CompareItems/CompareItems';
import BlogTemplate from './routes/BlogTemplate/BlogTemplate';
import BlogPostTemplate from './routes/BlogPostTemplate/BlogPostTemplate';

export const requireAuth = (Component) => {
  return (props) => {
    return authOnEnter() ? (
      <Component {...props} />
    ) : (
      <RedirectWithStatus
        status={302}
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    );
  };
};

const RedirectWithStatus = ({ from, to, status }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.status = status;
      }
      return <Redirect from={from} to={to} />;
    }}
  />
);

const error404 = (Component) => {
  return (props) => {
    return (
      <Route
        render={({ staticContext }) => {
          if (staticContext) {
            staticContext.missed = true;
            staticContext.status = 404;
          }
          return <Component {...props} />;
        }}
      />
    );
  };
};

const PageLoader = (props) => {
  return (
    <div style={{}}>
      <Spinner />
    </div>
  );
};

// const AsyncPageCreateAccount = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/CreateAccount/CreateAccount'),
//     loading: () => <PageLoader />,
//     modules: ['CreateAccount'],
// });

const AsyncPageLanding = Loadable({
  loader: () =>
    import(/* webpackChunkName: "pageDefault" */ './routes/Landing/Landing'),
  loading: () => <PageLoader />,
  modules: ['Landing'],
});

// const AsyncForgotPassword = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/ForgotPassword/ForgotPassword'),
//     loading: () => <PageLoader />,
//     modules: ['ForgotPassword'],
// });

const AsyncPageError = Loadable({
  loader: () =>
    import(/* webpackChunkName: "pageDefault" */ './routes/Error/Error'),
  loading: () => <PageLoader />,
  modules: ['Error'],
});

const AsyncPageWhyUs = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "pageDefault" */ './routes/WhyPipesearch/WhyPipesearch'
    ),
  loading: () => <PageLoader />,
  modules: ['WhyUs'],
});

const AsyncPageServices = Loadable({
  loader: () =>
    import(/* webpackChunkName: "pageDefault" */ './routes/Services/Services'),
  loading: () => <PageLoader />,
  modules: ['Services'],
});

const AsyncPageInventory = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "pageDefault" */ './routes/Inventory/Inventory'
    ),
  loading: () => <PageLoader />,
  modules: ['Inventory'],
});

// const AsyncPageNotifications = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/Notifications/Notifications'),
//     loading: () => <PageLoader />,
//     modules: ['Notifications'],
// });

const AsyncPageCompany = Loadable({
  loader: () =>
    import(/* webpackChunkName: "pageDefault" */ './routes/Company/Company'),
  loading: () => <PageLoader />,
  modules: ['Company'],
});

// const AsyncPageTerms = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/Terms/Terms'),
//     loading: () => <PageLoader />,
//     modules: ['Terms'],
// });

// const AsyncPagePrivacyPolicy = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/PrivacyPolicy/PrivacyPolicy'),
//     loading: () => <PageLoader />,
//     modules: ['PrivacyPolicy'],
// });

const AsyncPageContact = Loadable({
  loader: () =>
    import(/* webpackChunkName: "pageDefault" */ './routes/Contact/Contact'),
  loading: () => <PageLoader />,
  modules: ['Contact'],
});

// const AsyncPageSitemap = Loadable({
//   loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/Sitemap/Sitemap'),
//   loading: () => <div>loading page...</div>,
//   modules: ['Sitemap'],
// });

//ADMIN PAGES

// const AsyncAdminPanel = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/AdminPanel/AdminPanel'),
//     loading: () => <PageLoader />,
//     modules: ['AdminPanel'],
// });

// const AsyncDashboard = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/Dashboard/Dashboard'),
//     loading: () => <PageLoader />,
//     modules: ['Dashboard'],
// });

// const AsyncPageMessages = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/Messages/Messages'),
//     loading: () => <PageLoader />,
//     modules: ['Messages'],
// });

// const AsyncPageMyRequests = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/MyRequests/MyRequests'),
//     loading: () => <PageLoader />,
//     modules: ['MyRequests'],
// });

// const AsyncPageMyOrders = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/MyOrders/MyOrders'),
//     loading: () => <PageLoader />,
//     modules: ['MyOrders'],
// });

// const AsyncPageSellMyAssets = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/SellMyAssets/SellMyAssets'),
//     loading: () => <PageLoader />,
//     modules: ['SellMyAssets'],
// });

// const AsyncPageMyListings = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/MyListings/MyListings'),
//     loading: () => <PageLoader />,
//     modules: ['MyListings'],
// });

// const AsyncPageTechnicalResources = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/TechnicalResources/TechnicalResources'),
//     loading: () => <PageLoader />,
//     modules: ['TechnicalResources'],
// });

// const AsyncPageMarketData = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/MarketData/MarketData'),
//     loading: () => <PageLoader />,
//     modules: ['MarketData'],
// });

// const AsyncPageWishList = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/WishList/WishList'),
//     loading: () => <PageLoader />,
//     modules: ['WishList'],
// });

// const AsyncPageActivity = Loadable({
//     loader: () => import(/* webpackChunkName: "pageDefault" */ './routes/Activity/Activity'),
//     loading: () => <PageLoader />,
//     modules: ['Activity'],
// });

// const AsyncRequestForQuoteDetails = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/RequestForQuoteDetails/RequestForQuoteDetails'),
//     loading: () => <PageLoader />,
//     modules: ['RequestForQuoteDetails'],
// });

// const AsyncRfqDetailsInformation = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/RequestForQuoteDetails/routes/RfqDetailsInformation/RfqDetailsInformation'),
//     loading: () => <PageLoader />,
//     modules: ['RfqDetailsInformation'],
// });

// const AsyncRfqDetailsItems = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/RequestForQuoteDetails/routes/RfqDetailsItems/RfqDetailsItems'),
//     loading: () => <PageLoader />,
//     modules: ['RfqDetailsItems'],
// });

// const AsyncRfqDetailsMessagingAndActivity = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/RequestForQuoteDetails/routes/RfqDetailsMessagingAndActivity/RfqDetailsMessagingAndActivity'),
//     loading: () => <PageLoader />,
//     modules: ['RfqDetailsMessagingAndActivity'],
// });

// const AsyncRfqDetailsDocuments = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/RequestForQuoteDetails/routes/RfqDetailsDocuments/RfqDetailsDocuments'),
//     loading: () => <PageLoader />,
//     modules: ['RfqDetailsDocuments'],
// });

// const AsyncRfqDetailsOrderInitiation = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/RequestForQuoteDetails/routes/RfqDetailsOrderInitiation/RfqDetailsOrderInitiation'),
//     loading: () => <PageLoader />,
//     modules: ['RfqDetailsOrderInitiation'],
// });

// const AsyncRfqDetailsOrderManagement = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/RequestForQuoteDetails/routes/RfqDetailsOrderManagement/RfqDetailsOrderManagement'),
//     loading: () => <PageLoader />,
//     modules: ['RfqDetailsOrderManagement'],
// });

// //SETTINGS PAGES\
// const AsyncSettingsPanel = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/SettingsPanel/SettingsPanel'),
//     loading: () => <PageLoader />,
//     modules: ['SettingsPanel'],
// });

// const AsyncAccountSettings = Loadable({
//     loader: () => import(/* webpackChunkName: "pageAnother" */ './routes/AccountSettings/AccountSettings'),
//     loading: () => <PageLoader />,
//     modules: ['AccountSettings'],
// });

const Routes = [
  {
    path: ['/', '/m/*', '/m/onboarding/*'],
    exact: true,
    component: AsyncPageLanding,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/blog',
    exact: true,
    component: BlogTemplate,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/blog-post',
    exact: true,
    component: BlogPostTemplate,
    loadData: () => {
      return {};
    },
  },
  // {
  //   path: "/create-account",
  //   exact: true,
  //   component: AsyncPageCreateAccount
  // },
  // {
  //   path: "/create-account-template",
  //   exact: true,
  //   component: CreateAccountTemplate,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/account-created",
  //   exact: true,
  //   component: AccountCreated,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/login",
  //   exact: true,
  //   component: LoginPage,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/login-template",
  //   exact: true,
  //   component: LoginTemplate,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/cant-login",
  //   exact: true,
  //   component: CantLogin,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/cant-login-recover",
  //   exact: true,
  //   component: CantLoginRecover,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/reset-password",
  //   exact: true,
  //   component: ResetPassword,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/reset-password-successful",
  //   exact: true,
  //   component: ResetPasswordSuccessful,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/forgot-password",
  //   exact: true,
  //   component: AsyncForgotPassword,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  {
    path: '/why-us',
    exact: true,
    component: AsyncPageWhyUs,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/services',
    exact: true,
    component: AsyncPageServices,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/company',
    exact: true,
    component: AsyncPageCompany,
    loadData: () => {
      return {};
    },
  },
  // {
  //   path: "/search-template",
  //   exact: true,
  //   component: SearchTemplate,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  {
    path: '/search-results',
    exact: true,
    component: SearchResults,
    loadData: () => {
      return {};
    },
  },
  // {
  //   path: "/terms",
  //   exact: true,
  //   component: AsyncPageTerms,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/privacy-policy",
  //   exact: true,
  //   component: AsyncPagePrivacyPolicy,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  {
    path: '/contact',
    exact: true,
    component: AsyncPageContact,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/not-found',
    component: AsyncPageError,
    loadData: () => {
      return {};
    },
  },
  // {
  //   path: "/find",
  //   exact: true,
  //   component: requireAuth(AsyncPageInventory),
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/notifications",
  //   exact: true,
  //   component: requireAuth(AsyncPageNotifications),
  //   loadData: () => {
  //     return {};
  //   }
  // },
  // {
  //   path: "/find-inventory",
  //   exact: true,
  //   component: ItemSubmissionPage,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  {
    path: '/find-inventory-results',
    exact: true,
    component: FindInventoryResults,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/view-item-details',
    exact: true,
    component: ViewItemDetails,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/compare-items',
    exact: true,
    component: CompareItems,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/landing',
    exact: true,
    component: AsyncPageLanding,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/help-center',
    exact: true,
    component: HelpCenter,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/help-center-article',
    exact: true,
    component: HelpCenterArticle,
    loadData: () => {
      return {};
    },
  },
  {
    path: '/faq',
    exact: true,
    component: FrequentlyAskedQuestions,
    loadData: () => {
      return {};
    },
  },
  // {
  //   path: "/sitemap",
  //   exact: true,
  //   component: AsyncPageSitemap,
  //   loadData: () => {
  //     return {};
  //   }
  // },

  // {
  //   path: "/dashboard",
  //   component: requireAuth(AsyncAdminPanel),
  //   routes: [
  //     {
  //       path: "/dashboard/messages",
  //       component: requireAuth(AsyncPageMessages),
  //       exact: true
  //     },
  //     {
  //       path: "/dashboard/technical-resources",
  //       component: requireAuth(AsyncPageTechnicalResources),
  //       exact: true
  //     },
  //     {
  //       path: ["/dashboard/my-requests", "/dashboard/my-requests/m/*"],
  //       exact: true,
  //       component: requireAuth(AsyncPageMyRequests)
  //     },
  //     {
  //       path: "/dashboard/my-requests/:id",
  //       component: requireAuth(AsyncRequestForQuoteDetails),
  //       routes: [
  //         {
  //           path: ['/dashboard/my-requests/:id/information', '/dashboard/my-requests/:id', '/dashboard/my-requests/:id/information/*', '/dashboard/my-requests/:id/m/*'],
  //           exact: true,
  //           component: requireAuth(AsyncRfqDetailsInformation)
  //         },
  //         {
  //           path: ["/dashboard/my-requests/:id/items"],
  //           exact: true,
  //           component: requireAuth(AsyncRfqDetailsItems)
  //         },
  //         {
  //           path: ["/dashboard/my-requests/:id/activity"],
  //           exact: true,
  //           component: requireAuth(AsyncRfqDetailsMessagingAndActivity)
  //         },
  //         {
  //           path: ["/dashboard/my-requests/:id/documents"],
  //           exact: true,
  //           component: requireAuth(AsyncRfqDetailsDocuments)
  //         },
  //         {
  //           path: ["/dashboard/my-requests/:id/order-initiation"],
  //           exact: true,
  //           component: requireAuth(AsyncRfqDetailsOrderInitiation)
  //         },
  //         {
  //           path: ["/dashboard/my-requests/:id/order-management"],
  //           exact: true,
  //           component: requireAuth(AsyncRfqDetailsOrderManagement)
  //         }
  //       ]
  //     },
  //     {
  //       path: "/dashboard/my-orders",
  //       component: requireAuth(AsyncPageMyOrders),
  //       exact: true
  //     },
  //     {
  //       path: "/dashboard/sell-assets",
  //       component: requireAuth(AsyncPageSellMyAssets),
  //       exact: true
  //     },
  //     {
  //       path: "/dashboard/my-listings",
  //       component: requireAuth(AsyncPageMyListings),
  //       exact: true
  //     },
  //     {
  //       path: "/dashboard/market-data",
  //       component: requireAuth(AsyncPageMarketData),
  //       exact: true
  //     },
  //     {
  //       path: "/dashboard/wish-list",
  //       component: requireAuth(AsyncPageWishList),
  //       exact: true
  //     },
  //     {
  //       path: "/dashboard/activity",
  //       component: requireAuth(AsyncPageActivity),
  //       exact: true
  //     },
  //     {
  //       component: requireAuth(AsyncDashboard)
  //     }
  //   ]
  // },
  // {
  //   path: "/settings",
  //   component: requireAuth(AsyncSettingsPanel),
  //   routes: [
  //     {
  //       component: requireAuth(AsyncAccountSettings)
  //     }
  //   ]
  // },
  {
    component: AsyncPageError,
  },
];

export default Routes;
