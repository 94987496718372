import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import InventoryResults from './components/InventoryResults/InventoryResults'
import ResultsComponent from './components/ResultsComponent/ResultsComponent'

import { InventoryResultsPage } from './FindInventoryResults.styles'

const FindInventoryResults = (props) => {

  return (
    <InventoryResultsPage style={{ padding: `0`, overflow: `hidden` }}>
      <InventoryResults />
      <ResultsComponent />
    </InventoryResultsPage>
  )
}

export default PageViewWrapper(FindInventoryResults, { pageName: 'FindInventoryResults' });