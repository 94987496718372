import React from 'react';
import { XXXL, XXL, XL, LG, MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button'
import {
  Section,
  Logo,
  LoginForm,
  FormFieldWrapper,
  BackgroundImg,
  Line
} from '../../CantLogin.styles';

const RecoverForm = (props) => {
  return (
    <Section dark>
      <Flex justifyCenter>
        <FlexItem>
          <Logo src="/images/logo_white.svg" />
        </FlexItem>
      </Flex>
      <XXXL center tag="h1">Can't Login</XXXL>
      <MD center tag="p">Let's recover your password.</MD>
      <Flex justifyCenter>
        <FlexItem>
          <LoginForm>
            <Row>
              <Col md={12}>
                <FormFieldWrapper>
                  <LG left tag="h5">Email</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Email"></input>
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Flex justifyCenter>
                  <FlexItem>
                    <Button publicButton style={{marginBottom: `30px`}}>Recover</Button>
                  </FlexItem>
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <MD center className="login" tag="p"><a href="#">Return to Login</a></MD>
                <Flex justifyCenter>
                  <FlexItem>
                    <Line />
                  </FlexItem>
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <MD center className="trouble" tag="p">Still having trouble? <a href="#">Contact Support</a> </MD>
              </Col>
            </Row>
          </LoginForm>
        </FlexItem>
      </Flex>
      <BackgroundImg src="/images/hero-pipes.png" />
    </Section>

  )
}

export default RecoverForm;