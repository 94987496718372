import Baobab from 'baobab';
import { every, pick, reduce, isEmpty } from 'lodash'


export function state(initialState) {
  return {
    id: null,
    activeRfq: {
      itemOverrides: {},
      items: {},
      group: {},
      well: {},
      summary: {}
    },
    meta: {},
    itemSelectionArray: [],
    itemSelection: {},
    itemSelectionRfqUpdateError: null, // We need to update RFQ when editing and adding items from within item selection screen
    rfqDetails: {
      list: {}, // list of ids in a dictionary. issues with pushing and baobab
      map: {}
    },
    rfqItemInformations: {
      map: {},
      itemOverrideMap: {},
      name: {},
      date: {},
      location: {},
    },
    // Monkeys
    rfqItemsValid: rfqItemsValidMonkey(),
    rfqDetailsValid: rfqDetailsValidMonkey(),
  };
}

function rfqItemsValidMonkey() {
  return Baobab.monkey({
    cursors: {
      itemSelection: ['requestForQuote', 'itemSelection'],
    },
    get({itemSelection}) {
      if (isEmpty(itemSelection)) {
        return {};
      }
      return reduce(Object.values(itemSelection), (accum, item) => {
        if (accum.allValid && !item.valid) {
          accum.allValid = false
        }

        if (accum.allSaved && !item.saved) {
          accum.allSaved = false
        }
        return accum
      }, {allValid: true, allSaved: true});
    }
  });
}

function rfqDetailsValidMonkey() {
  return Baobab.monkey({
    cursors: {
      details: ['requestForQuote', 'rfqDetails', 'map'],
    },
    get({details}) {
      if (isEmpty(details)) {
        return {};
      }
      return reduce(Object.values(details), (accum, item) => {
        const groupsValid = every(pick(item, ['basicInformation', 'wellContent', 'wellInformation']), (it) => {
          return it.valid !== false
        });
        if (accum.allValid && !groupsValid) {
          accum.allValid = false
        }

        if (accum.allSaved && !item.saved) {
          accum.allSaved = false
        }
        return accum
      }, {allValid: true, allSaved: true});
    }
  });
}

