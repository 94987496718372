import React from 'react';
import { XXL, MD } from '@zendeskgarden/react-typography';

import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from "components/Icon/Icon";
import { Button } from 'theme/Button';
import {
  InventorySection,
  Logo,
  Filters,
  Line,
  Close,
  ButtonWrapper,
  BackgroundContainer
} from './InventoryOverlay.styles';

import InventoryButtons from './components/InventoryButton/InventoryButton.js'

import {
  BackgroundCircleXL,
  Wrapper
} from 'components/PublicPages/GlobalPublicStyles'

import DividingLine from 'components/PublicPages/DividingLine';

import {
  InventoryOverlayStyles
} from './InventoryOverlay.styles';

import { variables } from 'theme/variables';
const {
  custom_button_blue
} = variables;

const InventoryOverlay = (props) => {

  return (
    <InventoryOverlayStyles dark active={props.active}>
      <Wrapper className="inventory-inner">

      </Wrapper>
      <Flex justifyLeft>
        <FlexItem>
          <Logo src="/images/logo_white.svg" />
        </FlexItem>
      </Flex>
      <BackgroundContainer>
        <BackgroundCircleXL style={{top: `-900px`, right: `55%` }} />
        <BackgroundCircleXL style={{top: `-180px`, left: `74%` }} />
      </BackgroundContainer>
      <Flex justifyRight>
        <FlexItem>
          <Close onClick={props.toggleInventory}><Icon icon="icon-close" color={custom_button_blue} style={{ position: `absolute`, top: `50%`, left: `50%`, fontSize: `16px`, fontWeight: `900`, margin: `-8px 0 0 -8px` }} /></Close>
        </FlexItem>
      </Flex>
      <Grid>
        <Flex justifyCenter>
          <FlexItem>
            <DividingLine light />
            <XXL center white tag="h1">
              Find Inventory
            </XXL>
            <InventoryButtons />
            <Row>
              <Col md={12}>
                <Flex justifyCenter>
                  <FlexItem>
                    <ButtonWrapper>
                      <Button publicButton className="button-mobile" style={{ width: `217px`}}>
                        Search
                      </Button>
                    </ButtonWrapper>
                  </FlexItem>
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Flex justifyCenter>
                  <FlexItem>
                    <MD tag="p" className="reset"><a href="#">Reset</a></MD>
                  </FlexItem>
                </Flex>
              </Col>
            </Row>
          </FlexItem>
        </Flex>
      </Grid>
      
    </InventoryOverlayStyles>
  );
}

export default InventoryOverlay;
