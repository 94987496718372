import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Button } from '@zendeskgarden/react-buttons';
import PublicPage from 'components/PublicPages/PublicPage'

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';


const {
    custom_dark_blue,
    color_white,
    custom_dark_grey,
    spacing_sm,
    media_sm,
    media_md,
    media_lg,
    custom_button_blue,
    public_font_size,
  public_font_size_xxl,
  public_font_size_md
} = variables;


export const FrequentlyAskedQuestionsPage = styled(PublicPage)`
  
`;

export const TopSection = styled(Section)`
  

  @media ${media_md} {
    padding: 140px 0 200px 0;
  }

  @media ${media_lg} {
    padding: 180px 0px 160px;
  }
`;

export const QuestionSection = styled(Section)`


  

  p {
    max-width: 600px;
    margin-bottom: 0;
  }

  .accordion__panel {
    animation: fadein 0.35s ease-in;
  }

  .accordion__button { 
    transition: transform 0.35s ease-in;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
  }

  .accordion__button:focus { 
    outline: none; 
  }

  .accordion__button:before {
    position: relative;
    top: 10px;
    display: inline-block;
    content: '';
    height: 12px;
    width: 12px;
    color: ${custom_button_blue}
    margin: 30px 16px 0 0;
    float: right;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transition: 0.2s ease-in;
    transform: rotate(45deg);
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
      transform: rotate(225deg);
  }

  @keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
  }

  .more-help {
    margin-bottom: 48px;
  }

  .top-accordion {
      margin: 0 0 64px 0;
    }

    .bottom-accordion {
      margin: 0 0 64px 0;
    }
  
  @media ${media_md} {
    padding: 240px 0 216px 0;

    h1 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
      letter-spacing: 2px;
    }

    h2 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
    }

    .top-accordion {
      margin: 0 0 136px 0;
    }

    .bottom-accordion {
      margin: 0 0 136px 0;
    }
  }

  
`;

export const SearchFieldWrapper = styled.div`
  height: 51px;
  width: 800px;
  margin-top: 56px;
  input {
    position: relative:
    z-index: 2;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    background: transparent;
    font-size: ${public_font_size};
    padding: 0 0 20px 30px;
    color: ${color_white};
    font-color: rgba(255,255,255,0.4);
    ::placeholder {
      color: rgba(255,255,255,0.4);
      padding-left: 30px;
    }

    :-ms-input-placeholder {
      color: rgba(255,255,255,0.4);
      padding-left: 30px;
    }

    ::-ms-input-placeholder {
      color: rgba(255,255,255,0.4);
      padding-left: 30px;
    }
  }

  

  h5 {
    padding-bottom: 20px;
  }
`;

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
`

export const SectionArrow = styled.div`
  position: absolute;
  width: 100%;
  top: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;

  &:after {
    content: '';
    background-color: ${custom_dark_blue};
    width: 160px;
    height: 160px;
    border-bottom-right-radius: 20px;
    transform: rotate(45deg);
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

export const BackgroundCircle = styled.div`
  overflow: hidden;
  position: absolute;
  height: 1160px;	
  width: 1160px;	
  border: 200px solid rgba(1, 90, 208, 0.05);
  border-radius: 50%;
`

export const DividingLine = styled.div`
  width: 210px;
  height: 2px;
  border-bottom: 2px dotted rgba(65, 65, 65, 0.27);
`;

export const HelpCenterButton = styled(Button)`
  padding: 20px 20px;
  border-radius: 8px;	
  margin: 30px 0 30px 0;
  width: 200px;
  background-color: ${custom_button_blue};	
  box-shadow: 0 15px 30px -12px rgba(0,0,0,0.5);
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 21px;
`;

export const Line = styled.div`
  padding: 6px 0;
  box-sizing: border-box;	
  height: 1px;	
  width: 345px;
  border-bottom: 1px solid #E9EDF2;

  @media ${media_md} {
    width: 650px;	
  }
`

export const Wrapper = styled.div`
  max-width: 350px;

  h1 {
    margin: 0 0 10px 0;
  }

  h3 {
    margin-bottom: 0;
    padding: 19px 0 3px 0;
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
  }

  p {
    display: inline-block;
    padding: 10px 0;
  }

  .greyOval {
    border: 2px solid #B0BED1;
    margin: 0 10px 0 30px;
  }

  @media ${media_md} {
    max-width: 660px;

    h3 {
      line-height: 56px;
    }
  }
`

export const NavSideBar = styled.div`
  p {
    padding: 20px 0;
    display: inline-block;
  }

 
`

export const Oval = styled.div`
  display: inline-block;
  margin: 0 10px 0 0;
	height: 12px;
  width: 12px;
  border-radius: 50%;
	border: 2px solid ${custom_button_blue}
`

export const PipesWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0; 
  left: 0;
  position: absolute; 
  overflow: hidden; 

  .right-pipe {
    width: 165px;
    left: 0;
    right: -225px;
    bottom: 0;
    top: 19px;
    margin: 0 auto;
  }

  .left-pipe {
    width: 165px;
    left: -207px;
    right: 0%;
    bottom: 0;
    top: 77px;
    margin: 0 auto;
  }

    .left-pipes {
      display: none;
    }

    .right-pipes {
      display: none;
  }

  @media ${media_sm} {
    .left-pipes {
      display: block;
      width: 500px;
      left: -90%;
      right: 0;
      bottom: 0;
      top: 152px;
      margin: 0 auto;
    }

    .right-pipes {
      display: block;
      width: 500px;
      left: 0;
      right: -80%;
      bottom: 0;
      top: 112px;
      margin: 0 auto;
    }

    .right-pipe {
      display: none;
  }

    .left-pipe {
      display: none;
  }
  }

  @media ${media_md} {
    .left-pipes {
      display: block;
      width: 500px;
      left: -90%;
      right: 0;
      bottom: 0;
      top: 188px;
      margin: 0 auto;
    }

    .right-pipes {
      display: block;
      width: 500px;
      left: 0;
      right: -80%;
      bottom: 0;
      top: 132px;
      margin: 0 auto;
    }

    .right-pipe {
      display: none;
  }

    .left-pipe {
      display: none;
  }
}
  
`

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 10;
   h1 {
    margin: 0 0 32px 0;
  }

  p {
    max-width: 621px;
    color: rgba(255, 255, 255, 0.7);
  }

  @media ${media_md} {
    p {
      margin-left: 40px;
    }
  }
`

export const BgCircleWrapper = styled.div`

  .right-circle {
    right: 77vw;
    bottom: 0;
    top: 1100px;
    margin: 0 auto;
  }

  .left-circle {
    left: 79vw;
    bottom: 0;
    top: 40px;
    margin: 0 auto;
  }
`
