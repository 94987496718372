import fetchJson from 'utility/fetchJson';
import {BASE} from '../../constants';


export async function fetchAddressSuggestion(location) {
  const response = await fetchJson(`${BASE}/api/autocomplete/location/?location=${location}`, {
    method: 'GET',
  })
  return response
}

export async function fetchSuggestion(type, value) {
  const response = await fetchJson(`${BASE}/api/autocomplete/${type}/?${type}=${value}`, {
    method: 'GET',
  })
  return response
}

export async function fetchFileProxy(endpoint) {
  const response = await fetchJson(`${BASE}${endpoint}`, {
    method: 'GET',
  })
  return response
}
