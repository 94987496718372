import fetchJson from 'utility/fetchJson';
import {BASE} from '../../constants'

export async function getRfqMeta() {
  const response = await fetchJson(`${BASE}/api/meta/item/`, {
    method: 'GET'
  })
  return response
}

export async function getRfqGroup(id) {
  const response = await fetchJson(`${BASE}/api/rfq/group/${id}/`, {
    method: 'GET'
  })
  return response
}

export async function getRfqOrphans() {
  const response = await fetchJson(`${BASE}/api/rfq/item/orphans/?limit=100`, {
    method: 'GET'
  })
  return response
}



export async function getUserInterestItems() {
  const response = await fetchJson(`${BASE}/api/on-board/product/`, {
    method: 'GET'
  })
  return response
}

export async function saveUserInterestItems(data) {
  const response = await fetchJson(`${BASE}/api/on-board/product/`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return response
}

export async function updateUserInterestItems(id, data) {
  const response = await fetchJson(`${BASE}/api/on-board/product/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  })
  return response
}

export async function deleteUserInterestItems(id) {
  const response = await fetchJson(`${BASE}/api/on-board/product/${id}/`, {
    method: 'DELETE'
  })
  return response
}




export async function getRfqGroupItems(id) {
  const response = await fetchJson(`${BASE}/api/rfq/group/item/${id}/`, {
    method: 'GET'
  })
  return response
}

export async function getRfqItemDetails(id) {
  const response = await fetchJson(`${BASE}/api/rfq/item/${id}/`, {
    method: 'GET'
  })
  return response
}

export async function createRfqGroup(data) {
  const response = await fetchJson(`${BASE}/api/rfq/group/`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return response
}

export async function updateRfqGroup(id, data) {
  const response = await fetchJson(`${BASE}/api/rfq/group/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  })
  return response
}

export async function getRfqGroupItemOverrides(rfqId) {
  const response = await fetchJson(`${BASE}/api/rfq/group/item/attr/summary/${rfqId}/`, {
    method: 'GET',
  })
  return response
}

export async function getRfqGroupItemOverride(id) {
  const response = await fetchJson(`${BASE}/api/rfq/group/item/attr/${id}/`, {
    method: 'GET',
  })
  return response
}

export async function createRfqGroupItemOverride(data) {
  const response = await fetchJson(`${BASE}/api/rfq/group/item/attr/`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return response
}

export async function updateRfqGroupItemOverride(id, data) {
  const response = await fetchJson(`${BASE}/api/rfq/group/item/attr/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  })
  return response
}

export async function deleteRfqGroupItemOverride(id) {
  const response = await fetchJson(`${BASE}/api/rfq/group/item/attr/${id}/`, {
    method: 'DELETE',
  })
  return response
}

export async function getRfqTechnicalData(data) {
  const response = await fetchJson(`${BASE}/api/rfq/item/technical/`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return response
}

export async function saveItemData(data) {
  const response = await fetchJson(`${BASE}/api/rfq/item/`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return response
}

export async function updateItemData(id, data) {
  const response = await fetchJson(`${BASE}/api/rfq/item/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  })
  return response
}

export async function deleteItemData(id) {
  const response = await fetchJson(`${BASE}/api/rfq/item/${id}/`, {
    method: 'DELETE'
  })
  return response
}


export async function getRfqWellDetails(rfqId) {
  const response = await fetchJson(`${BASE}/api/rfq/group/well/${rfqId}/`, {
    method: 'GET',
  })
  return response
}


export async function saveRfqWellDetails(data) {
  const response = await fetchJson(`${BASE}/api/rfq/well/`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return response
}

export async function updateRfqWellDetails(id, data) {
  const response = await fetchJson(`${BASE}/api/rfq/well/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  })
  return response
}

export async function deleteRfqWellDetails(id) {
  const response = await fetchJson(`${BASE}/api/rfq/well/${id}/`, {
    method: 'DELETE',
  })
  return response
}

export async function getRfqGroupSummary(rfqId) {
  const response = await fetchJson(`${BASE}/api/rfq/group/summary/${rfqId}/`, {
    method: 'GET',
  })
  return response
}

export async function submitRfq(rfqId, data) {
  const response = await fetchJson(`${BASE}/api/rfq/group/${rfqId}/submit/`, {
    method: 'POST',
    body: JSON.stringify({})
  })
  return response
}