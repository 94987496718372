import tree from 'state';
import * as effects from './effects';

export async function signIn({email, password}) {
  try {
    const session = await effects.signIn({email, password});
    tree.set(['authentication', 'session'], session);
    return session;
  } catch(err) {
    return {
      error: err.message
    }
  }
}

export async function forgotPassword({email}) {
  try {
    await effects.forgotPassword({email});
    return {email}
  } catch(err) {
    return {
      error: err.message
    }
  }
}

export async function logout() {
  try {
    await effects.logout();
    tree.set(['authentication', 'session'], null);
    return {logout: true}
  } catch(err) {
    return {
      error: err.message
    }
  }
}

export async function getToken({state, effects}) {
  try {
    const {csrftoken} = await effects.authentication.getToken();    
    tree.set(['authentication', 'csrftoken'], csrftoken);
  } catch(err) {
    console.log(err, 'ERROR GETTING TOKEN')
    return {
      error: err.message
    }
  }
}

