import {variables} from 'theme/variables';
const {
  spacing_sm,
  line_height_xxxl,
  height_xxl,
  raisedValue,
  custom_blue,
  custom_blue_hover,
  font_weight_semibold,
  spacing,
  color_white,
  custom_black,
  font_size_md,
  custom_navy,
  custom_grey,
  custom_light_grey,
  font_size_lg,
  custom_lighter_grey,
  raised_card_value,
  font_size,
  custom_button_blue,
  public_font_size_md,
  public_font_weight_bold,
  border_radius,
  custom_button_hover
} = variables;



const ButtonTheme = {
  'buttons.icon_button': props => {
    return `
      ${buttonBaseStyles(props, 'icon')}
    `
  },
  'buttons.button': props => {
    return `
      ${buttonBaseStyles(props)}
    `
  }
};


function buttonBaseStyles(props, type) {
  return `
    *:not(*[class*="icon-"]) {
      ${!props.mini ? `
        line-height: ${props.ignoreLineHeight ? 'inherit' : (props.lineHeight || '38px')} !important;
      `: ''}
      ${props.size === "large" ? `
        line-height: ${props.lineHeight || '46px'} !important;
      ` : ''}
    }
    ${props.spacingLeft ? `
      margin-left: ${spacing}
    ` : ''}

    ${props.spacingRight ? `
      margin-right: ${spacing}
    ` : ''}

    ${props.spacingBottom ? `
      margin-bottom: ${spacing}
    ` : ''}

    ${props.spacingLeftSm ? `
      margin-left: ${spacing_sm}
    ` : ''}

    ${props.spacingRightSm ? `
      margin-right: ${spacing_sm}
    ` : ''}

    ${props.spacingBottomSm ? `
      margin-bottom: ${spacing_sm}
    ` : ''}


    ${props.alignCenter ? `
      display: flex !important;
      alignItems: center !important;
    ` : ''}

    ${props.justifyCenter ? `
      display: flex !important;
      justify-content: center !important;
    ` : ''}

    ${props.justifyEnd ? `
      display: flex !important;
      justify-content: flex-end !important;
    ` : ''}

    ${props.justifyStart ? `
      display: flex !important;
      justify-content: flex-start !important;
    ` : ''}

    ${props.black ? `
      color: ${custom_black} !important;
    ` : ''}

    ${props.color ? `
      color: ${props.color} !important;
    ` : ''}

    ${props.bold ? `
      font-weight: 500 !important;
    ` : ''}

    ${props.semiBold ? `
      font-weight: ${font_weight_semibold} !important;
    ` : ''}

    ${props.disableBackground ? `
      background-color: transparent !important;
      border-color: transparent !important;
    ` : ''}

    ${props.uppercase ? `
      text-transform: uppercase !important;
    ` : ''}

    ${props.square ? `
      border-radius: 3px !important;
      min-width: 40px;
      ${props.small ? `
        min-width: 32px;
        min-height: 32px;
        max-width: 32px;
        max-height: 32px;
      ` : ''}
    ` : ''}

    ${props.underline ? `
      border-bottom: 2px solid transparent !important;
      text-decoration: none !important;
      padding-bottom: 3px !important;
      ${props.active ? `border-bottom: 2px solid ${custom_blue} !important;` : ''}
    ` : ''}

    ${props.blue && props.link ? `
      color: ${custom_blue} !important;
      * {
        color: ${custom_blue} !important;
      }
      ${props.semibold ? `
        font-weight: ${font_weight_semibold} !important;
      ` : ''}
    ` : ''}

    ${props.whiteButtonLink ? `
      position: relative;
      background-color: ${!props.inactive ? `${color_white} !important;` : 'transparent !important;'} 
      border: ${!props.inactive ? '1px solid #eeeeee !important;' : '1px solid transparent !important;'} 
      overflow: visible !important;
      outline: none;
      box-shadow: none !important;
      &:hover {
        background-color: ${props.active ? `${color_white} !important;` : '#fff !important;'} 
      }
    ` : ''}

    ${props.whiteOutline ? `
      position: relative;
      background-color: transparent !important; 
      border: 1px solid ${props.borderColor || color_white} !important;
      color: ${props.color || color_white} !important;
      outline: none;
      box-shadow: none !important;
      [class*="icon-"] {
        color: ${props.color || color_white} !important;
      }
      ${props.disabled ? `
        cursor: default;
        opacity: 0.6 !important;
      ` : ''}
      ${!props.disabled ? `
        &:hover {
          background-color: ${props.hoverColor || custom_blue} !important;
          border: 1px solid ${props.hoverColor || custom_blue} !important;
          color: ${color_white} !important;
        }
      ` : ''}
    ` : ''}

    ${props.menu ? `
      padding: 0 ${spacing_sm} !important;
      min-width: 0 !important;
    ` : ''}

    ${props.xl ? `
      height: ${line_height_xxxl} !important;
      ${props.square ? `
        min-width: ${line_height_xxxl} !important;
      ` : ''}
    ` : ''}

    ${props.xxl ? `
      height: ${height_xxl} !important;
      min-width: 200px !important;
    ` : ''}

    ${props.mini ? `
      min-width: 28px;
      max-height: 28px;
      background: rgb(255,255,255) !important;
      color: ${custom_blue};
      line-height: 25px;
      padding: 0 1em;
      font-size: 13px;
      * {
        line-height: 25px !important;
      }
    ` : ''}

    ${props.raised ? `
      box-shadow: ${typeof props.raised === 'string' ? props.raised : raisedValue} !important;
    ` : ''}

    ${props.primary ? `
      background: ${custom_blue} !important;
      [class*="icon-"] {
        color: ${color_white} !important;
      }
      &:hover {
        background: ${custom_blue_hover} !important
      }
      &:disabled {
        background: ${custom_light_grey} !important
        color: ${custom_grey};
        border-color: ${custom_light_grey}     
      }
    ` : ''}

    ${props.secondary ? `
      background: #d4dded !important;
      border: 1px solid #d4dded;
      color: ${custom_navy} !important;
      [class*="icon-"] {
        color: ${custom_navy} !important;
      }
      &:hover {
        background: ${custom_blue_hover} !important;
        color: ${color_white} !important;
        [class*="icon-"] {
          color: ${color_white} !important;
        }
      }
    ` : ''}

    ${props.white ? `
      background: ${color_white} !important;
      color: ${custom_blue}
      border: 1px solid ${custom_light_grey} !important;
      &:hover {
        border: 1px solid ${custom_light_grey} !important;
        color: ${custom_blue} !important;
        background: ${custom_light_grey} !important
      }
      &:disabled {
        border: 1px solid ${custom_light_grey} !important;
        color: ${custom_grey} !important;
        background: ${custom_light_grey} !important
      }
    ` : ''}

    ${props.navy ? `
      background-color: #0052c1 !important;
      color: ${color_white} !important;
      border: 1px solid  ${color_white} !important;
      &:hover {
        color: ${custom_blue} !important;
        background:  #0052c1 !important
      }
      &:disabled {
        border: 1px solid #0052c1 !important;
        color: ${color_white} !important;
        background: #0052c1 !important
        opacity: 0.3;
      }
    ` : ''}


    ${props.marginTop ? `
      margin-top: ${props.marginTop} !important;
    ` : ''}

    ${props.perplex ? `
      position: relative;
      background-color: ${color_white} !important;
      outline: none;
      box-shadow: ${props.shadow || 'none'} !important;
      line-height: 110px !important;
      height: 110px;
      max-width: 350px !important;
      width: 100%;
      font-size: ${font_size_lg} !important;
      line-height: 28px;
      padding: 0 20px !important;
      border-color: transparent !important;
      white-space: normal !important;
      font-weight: ${font_weight_semibold} !important;
      color: ${custom_black} !important;
      ${props.gutter ? `margin: 0 40px !important;` : ''}
      [class*="icon-"] {
        position: absolute;
        right: ${props.iconRightPosition || '-40px'} !important;;
        color: rgba(0, 0, 0, 0.06);
        bottom: -10px;
        transition: all 0.3s ease;
      }
      ${props.active ? `
        background-color: ${custom_blue} !important;
        color: ${color_white} !important;
        color: rgba(0, 0, 0, 0.05);
        [class*="icon-"] {
          color: rgba(255, 255, 255, 0.08);
        }
      ` : ''}
      &:hover {
        background-color: ${custom_blue} !important;
        color: ${color_white} !important;
        color: rgba(0, 0, 0, 0.05);
        [class*="icon-"] {
          color: rgba(255, 255, 255, 0.08);
        }
      }

    ` : ''}

    ${props.rowButton ? `
      padding: 20px;
      position: relative;
      background-color: transparent !important; 
      border: 1px solid ${custom_lighter_grey} !important;
      height: 80px;
      font-size: ${font_size} !important;
      outline: none;
      box-shadow: none !important;
      min-width: 100%;
      box-shadow: ${raised_card_value} !important;
      color: ${custom_blue} !important;
      [class*="icon-"] {
        color: ${custom_blue} !important;
        font-size: 17px !important;
      }
      &:hover {
        background-color: ${custom_blue} !important;
        border: 1px solid ${custom_blue} !important;
        color: ${color_white} !important;
        [class*="icon-"] {
          color: ${color_white} !important;
        }
      }
    ` : ''}


     ${props.publicButton ? `
      padding: 20px 60px;
      border-radius: 8px;
      background-color: ${custom_button_blue};
      box-shadow: 0 15px 30px -12px rgba(0,0,0,0.5);
      color: ${color_white};
      border: 0;
      outline: none;
      font-size: ${public_font_size_md};
      font-weight: ${public_font_weight_bold};
      position: relative;
      &&& div {
        line-height: 20px !important;
      }
      div[class^="flex-"] {
        transition: transform 0.3s ease-out;
      }
      .icon-arrow-right-short  {
        position: absolute;
        transform: translateX(-50px);
        opacity: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        display: block;
        height: 20px;
        font-size: ${public_font_size_md};
        transition: transform 0.3s ease-out, opacity 0.1s ease-out;
      }

      &&&&:hover {
        background-color: ${custom_button_hover};

        div[class^="flex-"] {
          transform: translateX(20px);
        }

        .icon-arrow-right-short {
          transform: translateX(-20px);
          opacity: 1;
        }
      }
    ` : ''}

    ${(props.animated === false) ? `
      opacity: 0;
      transform: translateY(40px);
    ` : ''}

    ${props.headerButton ? `
      padding: 20px 24px;
      border-radius: ${border_radius};	
      background-color: ${color_white};	
      box-shadow: 0 20px 20px -12px rgba(13,43,83,0.5);
      color: ${custom_button_blue};
      border: 0;
      outline: none;
      font-size: ${public_font_size_md};
      font-weight: ${public_font_weight_bold};
      transition: all 0.3s linear;
      &&& div {
        line-height: 18px !important;
      }
      &&&:hover {
        background-color: ${custom_button_hover};
        color: ${color_white} !important;
      }
      
      margin-left: 16px;
    ` : ''}
   
  `;
}

export default ButtonTheme;

