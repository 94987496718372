import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
import { Button } from '@zendeskgarden/react-buttons';

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';

const {
  custom_dark_blue,
  color_white,
  custom_dark_grey,
  spacing_sm,
  media_sm,
  media_md,
  media_lg,
  public_font_size_sm,
  public_font_size_xxl,
  public_font_size,
  custom_button_blue
} = variables;



export const ItemSubmissionPage = styled(Page)`

`;


export const InventorySection = styled(Section)`
  background: linear-gradient(135deg, #001E47 0%, #001A3D 100%);
  padding: 64px 0 280px 0;
  position: relative;
  width: 100vw;
  height: auto;


  h1 {
    font-size: 28px;
    line-height: 32px;
  }

  .reset {
    margin-top: 30px;

    a {
      color: ${color_white} !important;
    }
  }

  .button-mobile {
      margin-top: 48px;
    }

  @media ${media_md} {
    padding: 80px 0 280px 0;
    h1 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
    }

    .button-mobile {
      margin-top: 48px;
    }
  }

  @media ${media_lg} {
    padding: 80px 0 280px 0;
    h1 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
    }

    .button-mobile {
      margin-top: 80px;
    }
  }
`;

export const Logo = styled.img`
  height: 44px;
  width: 230px;
  margin: 0 0 0 60px;
`

export const Filters = styled.form`
  position: relative;
  zIndex: 2;
  height: auto;
  width: 790px;	
  margin-top: 40px;
`

export const FormFieldWrapper = styled.div`
  position: relative;
  height: 117px;
  width: 100%;
  
  input {
    font-size: 18px;
    height: 30px;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    background-color: transparent;
    color: ${color_white};
    padding: 19px 0 23px 0;
    ::placeholder {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .option {
    font-size: 18px;
    position: relative;
    z-index: 10;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    width: 100%;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.22);
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    padding-bottom: 8px;
  }

  #min-yield {
    position: relative;
    top: 6px;
  }

  .dropdown-small {
    position: absolute;
    left: 262px;
     z-index: 10;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    background-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    border: none;
    width: 72px;

    option {
      background-color: ${color_white};
      color: #414141;
    }
  }

  h5 {
    padding-bottom: 20px;
    color: ${color_white};
  }

  p {
    color: rgba(255, 255, 255, 0.4)
  }

  #min {
    margin-bottom: 16px;
  }

  .min-pren {
    margin-bottom: 24px;
  }

  .m-yield {
      margin-top: 22px;
    }

  div[class^="StyledSelect-"], div[class*="StyledSelect-"] {
    font-size: 18px;
    line-height: 24px;
    padding: 0;
    padding-bottom: 10px;
    border: none;
    color: rgba(255, 255, 255, 0.22);
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
  }

  div[class*="StyledMenu__"] {

    li {
      color: #414141;
    }
  }

  @media ${media_md} {
    height: 117px;
    width: 240px;

    .option {
      width: 240px;
      font-size: 18px;
      line-height: 32px;
    }

    .dropdown-small {
      left: 170px;
    }

    #min {
      margin-bottom: 0;
    }

    .min-pren {
      margin-bottom: 0;
    }

    .m-yield {
      margin-top: 22px;
    }

    #min-pren {
      margin-bottom: 48px;
    }
  }

  @media ${media_lg} {
    height: 117px;
    width: 380px;

    .option {
      width: 380px;
      font-size: ${public_font_size};
      line-height: 32px;
    }

    .dropdown-small {
      left: 310px;
    }

    .m-yield {
      margin-top: 22px;
    }

    

  input {
      font-size: ${public_font_size};
      height: 30px;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.17);
      width: 100%;
      background-color: transparent;
      color: ${color_white};
      padding: 19px 0 23px 0;
      ::placeholder {
        font-size: ${public_font_size};
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .dropdown {
      font-size: ${public_font_size};
      height: 30px;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.17);
      width: 100%;
      background-color: transparent;
      color: ${color_white};
      padding: 19px 0 23px 0;
      ::placeholder {
        font-size: ${public_font_size};
        color: rgba(255, 255, 255, 0.4);
      }

      li {
        color: purple;
      }
    }

    div[class^="StyledSelect-"], div[class*="StyledSelect-"] {
    font-size: ${public_font_size};
    line-height: 32px;
    padding: 0;
    padding-bottom: 10px;
    border: none;
    color: rgba(255, 255, 255, 0.22);
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
  }

  #min-pren {
      margin-bottom: 0;
  }
`

export const FiltersButton = styled(Button)`
  height: 56px;
  width: 217px;
  padding: 0;
  border-radius: 8px;	
  margin: 10px 0 32px 0;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.4);
  outline: none;
  font-size: ${public_font_size_sm};
  font-weight: normal
  line-height: 19px;

  &:hover {
    background-color: ${custom_button_blue} !important;
  }

  @media ${media_lg} {
    margin: 10px 0 0 0;
  }
`;

export const InventoryButtonItem = styled.div`
  font-size: 20px;
  line-height: 32px;
  display: inline-block;
  padding: 0;
  margin: 60px 0 0 30px;
  background: none;
  color: rgb(255, 255, 255);
  outline: none;
  font-size: 20px;
  line-height: 32px;
  &:hover {
    cursor: pointer;
  }

  @media ${media_md} {
    margin: 60px 30px 0 0;
  }

`;


export const InventoryButtonTechnical = styled.div`
  font-size: 20px;
  line-height: 32px;
  display: inline-block;
  padding: 0;
  margin: 60px 0 0 30px;
  background: none;
  color: rgb(255, 255, 255);
  outline: none;
  font-size: 20px;
  line-height: 32px;
  &:hover {
    cursor: pointer;
  }
`;


export const Line = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
	box-sizing: border-box;
	height: 2px;
	width: 210px;
  border: 1px dashed rgba(255, 255, 255, 0.27);
  margin: 60px 0 80px 0;
`

export const InventoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%

  .inventory-mobile-right {
    font-size: ${public_font_size_sm};
    line-height: 24px
    width: 50%;
    padding: 0;
    margin: 48px 10px 20px;
  }

  .inventory-mobile-left {
    font-size: ${public_font_size_sm};
    line-height: 24px
    width: 50%;
    padding: 0 0 16px 0;
    margin: 48px 10px 20px;
  }
  
  @media ${media_md} {
    .inventory-mobile-right {
      font-size: 20px;
      line-height: 32px;
      width: auto;
      margin: 60px 0 0 30px;
      padding: 0;
    }

    .inventory-mobile-left {
      font-size: 20px;
      line-height: 32px;
      width: auto;
      margin: 60px 0 0 30px;
      padding: 0;
    }
  }
`

export const Close = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  height: 24px;
  width: 24px;
  background-color: ${color_white};
  border-radius: 50%;

  @media ${media_md} {
    right: 64px;
    top: 64px;
    height: 48px;
    width: 48px;
  }
`

export const FiltersWrapper = styled.div`
  margin: 32px 0 0 0;

  .ft {
    margin-bottom: 56px;
    input {
      display: inline;
    }

  }

  .range {
    margin-bottom: 32px;
    input {
      display: inline;
    }
  }

  .category {
      margin: 0 0 0 0;
  }

  .filters-button {
    margin-top: 32px;
  }

  .min-pren {
    margin-bottom: 16px;
  }

  @media ${media_md} {
    margin: 0;

    .category {
      margin: 48px 0 0 0;
    }

    .od {
      margin: 48px 0 0 24px;
    }

    .ft {
      margin: 48px 0 0 0;
    }

    .alloy {
      margin: 48px 0 0 24px;
    }

    .min-yield {
      margin: 48px 0 0 0;
    }

    .end-finish {
      margin: 48px 0 0 24px;
    }

    .accepted {
      margin: 48px 0 0 0;
    }

    .min-mill {
      margin: 48px 0 0 24px;
    }

    .range {
      margin: 48px 0 0 0;
    }

    .filters-button {
      margin-top: 16px;
    }

    .min-pren {
      margin-bottom: -32px;
    }
  }
  
  #media ${media_lg} {
    margin: 0;

    .category {
      margin: 64px 0 0 0;
    }

    .od {
      margin: 48px 0 0 24px;
    }

    .ft {
      margin: 48px 0 0 0;
    }

    .alloy {
      margin: 48px 0 0 24px;
    }

    .min-yield {
      margin: 48px 0 0 0;
    }

    .end-finish {
      margin: 48px 0 0 24px;
    }

    .accepted {
      margin: 48px 0 0 0;
    }

    .min-mill {
      margin: 48px 0 0 24px;
    }

    .range {
      margin: 48px 0 0 0;
    }
  }
`

export const MobileWrapper = styled.div`
  position: relative;
`

export const ButtonWrapper = styled.div`
`

export const BackgroundContainer = styled.div`
  position: relative;
`
