import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Row } from '@zendeskgarden/react-grid';
import PublicPage from 'components/PublicPages/PublicPage'
import { Button } from '@zendeskgarden/react-buttons';

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';

const {
    custom_dark_blue,
    color_white,
    custom_dark_grey,
    spacing_sm,
    media_sm,
    media_md,
    media_lg,
  custom_button_blue,
  public_font_size_xxl,
  public_font_size_md,
  public_font_size,
} = variables;


export const HelpCenterArticlePage = styled(PublicPage)`
  
`;

export const ArticleSection = styled(Section)`
  
  
  padding: 80px 0 80px 0;

  p {
    margin-bottom: 0;
  }

  a {
    color: ${custom_button_blue} !important;
  }

  li span {
    font-size: ${public_font_size_md};
    line-height: 32px;
    color: #414141;
    top: 0;
  }

  .top-divide {
    margin: 80px 0 80px 0;
    width: 260px;
  }

  .bottom-divide {
    margin: 80px 0 80px 0;
  }

  .links {
    position: relative;
    left: 0;
    color: #414141 !important;
    transition: left ease 0.35s;
    
    &:hover {
      color: blue!important;
      text-decoration: none;
      left: 6px;
    }
  }

  .back-button {
    position: absolute;
    z-index: 20;
    top: 80px;
    left: -15px;
    padding: 0 0 0 16px;
    margin: 0 0 32px 20px;
    height: 46px;
    width: 167px;
    background-color: ${color_white};
    color: ${custom_button_blue}
    border: 1px solid ${custom_button_blue}
    box-shadow: none;
    border-radius: 4px;
  }

  @media ${media_md} {
    padding: 160px 0 160px 0;

    h2 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
    }

    .top-divide {
      margin: 50px 0 80px 0;
    }

    .bottom-divide {
      margin: 200px 0 100px 0;
    }

    .back-button {
      position: absolute;
      z-index: 20;
      top: 213px;
      left: 2vw;
    }
  }

  @media ${media_lg} {
    padding: 200px 0 200px 0;

    .top-divide {
      margin: 50px 0 80px 0;
    }

    .bottom-divide {
      margin: 200px 0 100px 0;
    }

    .back-button {
      position: absolute;
      z-index: 20;
      top: 256px;
      left: 11vw;
    }
  }

`;

export const TopSection = styled.section`
  position: relative;
  padding: 0px 0 200px 0;

  

  h1 {
    font-size: 28px;
    line-heigth: 32px;
    position: relative;
    z-index: 10;
  }

  @media ${media_md} {
    h1 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
      letter-spacing: 2px;
    }

    padding: 80px 0 280px 0;
  }

  @media ${media_lg} {
    padding: 134px 0 280px 0;
  }
`;

export const SearchFieldWrapper = styled.div`
  height: 51px;
  width: 50vw;
  margin-top: 56px;
  input {
    position: relative;
    z-index: 10;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    background: transparent;
    font-size: ${public_font_size_md};
    padding: 0 0 20px 30px;
    color: ${color_white};
    font-color: rgba(255,255,255,0.4);
    ::placeholder {
      color: rgba(255,255,255,0.4);
      padding-left: 30px;
    }

    :-ms-input-placeholder {
      color: rgba(255,255,255,0.4);
      padding-left: 30px;
    }

    ::-ms-input-placeholder {
      color: rgba(255,255,255,0.4);
      padding-left: 30px;
    }
  }
    h1 {
      margin: 0 0 50px 0;
    }

    h5 {
    padding-bottom: 20px;
  }

  @media ${media_md} {
    input {
      font-size: ${public_font_size};
    }
  }
`;

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
`

export const SectionArrow = styled.div`
  position: absolute;
  width: 100%;
  top: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;

  &:after {
    content: '';
    background-color: ${custom_dark_blue};
    width: 160px;
    height: 160px;
    border-bottom-right-radius: 20px;
    transform: rotate(45deg);
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

export const BackgroundCircle = styled.div`
  overflow: hidden;
  position: absolute;
  height: 1160px;	
  width: 1160px;	
  border: 200px solid rgba(1, 90, 208, 0.05);
  border-radius: 50%;
`

export const DividingLine = styled.div`
  width: 335px;
  height: 2px;
  border-bottom: 2px dotted rgba(65, 65, 65, 0.27);

  @media ${media_md} {
    width: 200px;
  }

  @media ${media_lg} {
    width: 210px;
  }
`;

export const HelpCenterButton = styled(Button)`
  padding: 20px 20px;
  border-radius: 8px;	
  margin: 30px 0 30px 0;
  width: 176px;
  background-color: ${custom_button_blue};	
  box-shadow: 0 15px 30px -12px rgba(0,0,0,0.5);
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 21px;

   &:hover {
    background-color: ${custom_button_blue} !important;
  }
`;

export const Line = styled.div`
  margin: 16px 0;
  box-sizing: border-box;	
  height: 1px;	
  max-width: 800px;	
  border-bottom: 1px solid #E9EDF2;
`

export const ArticleWrapper = styled.div`
  max-width: 360px;
  

  h1 {
    margin: 0 0 10px 0;
  }

  h2 {
    margin-bottom: 0;
  }

  h3 {
    padding: 16px 0 0 0;
  }



  p {
    display: inline-block;
    padding: 10px 0;
  }

  .grey-oval {
    border: 2px solid #B0BED1;
    margin: 0 10px 0 30px;
  }

  information {
    margin-bottom: 60px;
  }

  @media ${media_md} {
    max-width: 500px;
    margin-left: -40px;
  }

  @media ${media_lg} {
    max-width: 800px;
    margin-left: 0px;
  }
`

export const Oval = styled.div`
  display: inline-block;
  margin: 0 10px 0 0;
	height: 12px;
  width: 12px;
  border-radius: 50%;
	border: 2px solid ${custom_button_blue}
`

export const Steps = styled.ol`
  list-style: none;
  counter-reset: my-step-counter;
  margin: 0 0 80px 24px;
  
  li {
    counter-increment: my-step-counter;
    margin: 20px 0 20px 0;
    font-size: ${public_font_size_md};
  }
  
  li::before {
  content: counter(my-step-counter) ".";
	height: 32px;
	width: 21px;
	color: #B0BED1;
	font-family: Roboto;
	font-size: ${public_font_size};
	font-weight: bold;
  line-height: 32px;
  margin: 0 20px 0 0;
}


`

export const PipesWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0; 
  left: 0;
  position: absolute; 
  overflow: hidden; 

  .right-pipe {
    width: 165px;
    left: 0;
    right: -225px;
    bottom: 0;
    top: 19px;
    margin: 0 auto;
  }

  .left-pipe {
    width: 165px;
    left: -207px;
    right: 0%;
    bottom: 0;
    top: 77px;
    margin: 0 auto;
  }

    .left-pipes {
      display: none;
    }

    .right-pipes {
      display: none;
  }

  @media ${media_sm} {
    .left-pipes {
      display: block;
      width: 500px;
      left: -90%;
      right: 0;
      bottom: 0;
      top: 152px;
      margin: 0 auto;
    }

    .right-pipes {
      display: block;
      width: 500px;
      left: 0;
      right: -80%;
      bottom: 0;
      top: 112px;
      margin: 0 auto;
    }

    .right-pipe {
      display: none;
  }

    .left-pipe {
      display: none;
  }
  }

  @media ${media_md} {
    .left-pipes {
      display: block;
      width: 500px;
      left: -90%;
      right: 0;
      bottom: 0;
      top: 152px;
      margin: 0 auto;
    }

    .right-pipes {
      display: block;
      width: 500px;
      left: 0;
      right: -80%;
      bottom: 0;
      top: 112px;
      margin: 0 auto;
    }

    .right-pipe {
      display: none;
  }

    .left-pipe {
      display: none;
  }
}
`

export const BgCircleWrapper = styled.div`

  .circle-left {
    right: 77vw; 
    bottom: 0; 
    top: 990px;
    margin: 0 auto;
  }

  .circle-right {
    left: 79vw;
    bottom: 0;
    top: 40px;
    margin: 0 auto;
  }
`

export const RelatedArticles = styled.div`
  margin: 64px 0 0 0;

  h5 {
    margin: 0 0 32px 0;
  }

  p {
    display: inline-block;
    padding: 10px 0;
  }

  .grey-oval {
    border: 2px solid #B0BED1;
    margin: 0 10px 0 30px;
  }

  .links {
    color: ${custom_button_blue} !important;
  }
`

export const Wrapper = styled.div`
  max-width: 360px;
  

  h1 {
    margin: 0 0 10px 0;
  }

  p {
    font-size: ${public_font_size_md};
    line-height: 32px;
  }

  .grey-oval {
    border: 2px solid #B0BED1;
    margin: 0 10px 0 30px;
  }

  .title {
    margin-bottom: 8px;
  }

  .text {
    margin-bottom: 16px;
  }

  #garden-tabs-container-2--container {
    font-size: ${public_font_size_md};
    line-height: 21px;
    position: relative;
    z-index: 20;
    left: 24px;
    top: 84px;
  }

  @media ${media_md} {
    max-width: 500px;
    #garden-tabs-container-2--container {
      font-size: ${public_font_size_md};
      line-height: 21px;
      position: relative;
      z-index: 20;
      left: 24px;
      top: 166px;
    }

    .tab-content {
      position: relative;
      left: 50%;
      width: 100vw;
      transform: translateX(-50%);
      margin-left: -25px;
    }
  }

  @media ${media_lg} {
    max-width: 100%;

    .tab-content {
      position: relative;
      left: 50%;
      width: 100vw;
      transform: translateX(-50%);
      margin-left: -25px;
    }

    #garden-tabs-container-2--container {
      position: relative;
      z-index: 20;
      left: 12vw;
      top: 144px;
      
      
    }
    #garden-tabs-container-2--item-tab-1 {
      transition: left ease 0.35s;
      left: 0;
      border: none;
      border-bottom: 1px dotted #414141;
      margin-bottom: 0;
      padding: 16px 0;
      width: 200px;

      &:hover {
        color: ${custom_button_blue}
        left: 6px;
      }
    }
    #garden-tabs-container-2--item-tab-2 {
      transition: left ease 0.35s;
      left: 0;
      border: none;
      border-bottom: 1px dotted #414141;
      margin-bottom: 0;
      padding: 16px 0;
      width: 200px;
  
      &:hover {
        color: ${custom_button_blue}
        left: 6px;
      }
    }
    #garden-tabs-container-2--item-tab-3 {
      transition: left ease 0.35s;
      left: 0;
      border: none;
      border-bottom: 1px dotted #414141;
      margin-bottom: 0;
      padding: 16px 0;
      width: 200px;
   
      &:hover {
        color: ${custom_button_blue}
        left: 6px;
      }
    }
    #garden-tabs-container-2--item-tab-4 {
      transition: left ease 0.35s;
      left: 0;
      border: none;
      border-bottom: 1px dotted #414141;
      margin-bottom: 0;
      padding: 16px 0;
      width: 200px;
    
      &:hover {
        color: ${custom_button_blue}
        left: 6px;
      }
    }
    #garden-tabs-container-2--item-tab-5 {
      transition: left ease 0.35s;
      left: 0;
      border: none;
      border-bottom: 1px dotted #414141;
      margin-bottom: 0;
      padding: 16px 0;
      width: 200px;

      &:hover {
        color: ${custom_button_blue}
        left: 6px;
      }
    }

    #garden-tabs-container-2--item-tab-6 {
      transition: left ease 0.35s;
      left: 0;
      border: none;
      border-bottom: 1px dotted #414141;
      margin-bottom: 0;
      padding: 16px 0;
      width: 200px;

      &:hover {
        color: ${custom_button_blue}
        left: 6px;
      }
    }
  }
`
