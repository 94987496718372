import React, { useState, useEffect } from 'react';
import { LG, MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from "components/Icon/Icon";

import { 
  Dropdown, 
  Menu, 
  Item, 
  Trigger,
  Field,
  Select
} from '@zendeskgarden/react-dropdowns';


import {
  FormFieldWrapper,
  FiltersButton,
  FiltersWrapper
} from '../../InventoryOverlay.styles';
import { variables } from 'theme/variables';
const {
  color_white
} = variables;

const MoreFilters = (props) => {

  const [count, setCount] = useState(1)

  const category = [
    { label: 'Choose Category', value: 'Choose Category' },
    { label: 'Category 1', value: 'Category 1' },
    { label: 'Category 2', value: 'Category 2' },
    { label: 'Category 3', value: 'Category 3' },
    { label: 'Category 4', value: 'Category 4' }
  ];

  const [selectedItem, setSelectedItem] = useState(category[0]);

  if (count % 2 == 0) {
    return (
      <Grid>
        <FiltersWrapper>
          <Row>
          <Col md={6}>
            <FormFieldWrapper className="category">
              <LG left tag="h5">Category<Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `32px`, left: `8px`}} /></LG>
              {/* <select className="dropdown">
                <option value="">Choose Category</option>
                <option value="C1">Category 1</option>
                <option value="C2">Category 2</option>
                <option value="C3">Category 3</option>
                <option value="C4">Category 4</option>
                <option value="C5">Category 5</option>
                <option value="C6">Category 6</option>
                <option value="C7">Category 7</option>
                <option value="C8">Category 8</option>
              </select> */}
                <Dropdown className="dropdown">
                  <Trigger>
                    <div className="option">Category</div>
                  </Trigger>
                  <Menu>
                    <Item value="option-1">Option 1</Item>
                    <Item value="option-2">Option 2</Item>
                    <Item value="option-3">Option 3</Item>
                  </Menu>
                </Dropdown>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="od">
              <LG left tag="h5" style={{display: `inline-block`}}>OD</LG>
              <select className="dropdown-small">
                <option value="EQ">Equals</option>
                <option value="RNG">Range</option>
              </select> 
              <Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `-10px`, left: `4px`, fontSize: `10px`}} />
              <input type="text" id="name" name="name" required placeholder="Enter OD"></input>
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormFieldWrapper className="ft">
              <LG left tag="h5" style={{display: `inline-block`}}>#/FT</LG>
              <select className="dropdown-small">
                <option value="RNG">Range</option>
                <option value="DEC">Decimal</option>
                <option value="EN">Exact Number</option>
              </select><Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `-10px`, left: `4px`, fontSize: `10px`}} />
              <Row>
                <Col md={6}>
                  <input className="min" type="text" id="min" name="feet" required placeholder="Min"></input>
                </Col>
                <Col md={6}>
                  <input className="max" type="text" id="max" name="feet" required placeholder="Max"></input>
                </Col>
              </Row>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="alloy">
              <LG left tag="h5">Alloy<Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `32px`, left: `8px`}} /></LG>
              {/* <select className="dropdown">
                <option value="">Choose Alloy</option>
                <option value="A1">Alloy 1</option>
                <option value="A2">Alloy 2</option>
                <option value="A3">Alloy 3</option>
                <option value="A4">Alloy 4</option>
                <option value="A5">Alloy 5</option>
                <option value="A6">Alloy 6</option>
                <option value="A7">Alloy 7</option>
                <option value="A8">Alloy 8</option>
              </select> */}
              <Dropdown className="dropdown">
                <Trigger>
                  <div className="option">Choose Alloy</div>
                </Trigger>
                <Menu>
                  <Item value="alloy-1">Alloy 1</Item>
                  <Item value="alloy-2">Alloy 2</Item>
                  <Item value="alloy-3">Alloy 3</Item>
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormFieldWrapper className="min-yield">
              <LG left tag="h5" style={{display: `inline`}}>Min Yield<Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `32px`, left: `8px`}} /></LG>
              <select className="dropdown-small">
                <option value="">Equals</option>
                <option value="AL">Range</option>
              </select><Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 0 0 0`, position: `relative`, top: `-10px`, left: `24px`, fontSize: `10px`}} />
              {/* <select className="dropdown" style={{top: `10px`}}>
                <option value="">Choose Min Yield</option>
                <option value="Y1">Yield 1</option>
                <option value="Y2">Yield 2</option>
                <option value="Y3">Yield 3</option>
                <option value="Y4">Yield 4</option>
                <option value="Y5">Yield 5</option>
                <option value="Y6">Yield 6</option>
                <option value="Y7">Yield 7</option>
                <option value="Y8">Yield 8</option>
              </select> */}
               <Dropdown className="dropdown">
                <Trigger>
                  <div className="option m-yield">Choose Min Yield</div>
                </Trigger>
                <Menu>
                  <Item value="min-yield-1">Min Yield 1</Item>
                  <Item value="min-yield-2">Min Yield 2</Item>
                  <Item value="min-yield-3">Min Yield 3</Item>
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="end-finish">
              <LG left tag="h5">End Finish</LG>
              <input type="text" id="name" name="name" required placeholder="End Finish"></input>
            </FormFieldWrapper>
          </Col>
        </Row>
          <Row>
            <Col sm={12} md={6}>
              <FormFieldWrapper className="accepted">
                <LG left tag="h5">Accepted Manufacturers<Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `32px`, left: `8px`}} /></LG>
                {/* <select className="dropdown">
                  <option value="">Choose Manufacturer</option>
                  <option value="M1">Manufacturer 1</option>
                  <option value="M2">Manufacturer 2</option>
                  <option value="M3">Manufacturer 3</option>
                  <option value="M4">Manufacturer 4</option>
                  <option value="M5">Manufacturer 5</option>
                  <option value="M6">Manufacturer 6</option>
                  <option value="M7">Manufacturer 7</option>
                  <option value="M8">Manufacturer 8</option>
                </select> */}
                <Dropdown className="dropdown">
                <Trigger>
                  <div className="option">Choose Manufacturer</div>
                </Trigger>
                <Menu>
                  <Item value="manufacturer-1">Manufacturer 1</Item>
                  <Item value="manufacturer-2">Manufacturer 2</Item>
                  <Item value="manufacturer-3">Manufacturer 3</Item>
                </Menu>
              </Dropdown>
              </FormFieldWrapper>
            </Col>
            <Col sm={12} md={6}>
              <FormFieldWrapper className="min-mill">
                <LG left tag="h5">Min Mill Year<Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `32px`, left: `8px`}} /></LG>
                {/* <select className="dropdown">
                  <option value="">Choose Mill Year</option>
                  <option value="2006">2006</option>
                  <option value="2007">2007</option>
                  <option value="2008">2008</option>
                  <option value="2009">2009</option>
                  <option value="2010">2010</option>
                  <option value="2011">2011</option>
                  <option value="2012">2012</option>
                  <option value="2013">2013</option>
                </select> */}
                <Dropdown className="dropdown">
                <Trigger>
                  <div className="option">Choose Mill Year</div>
                </Trigger>
                <Menu>
                  <Item value="mill-year-2010">2010</Item>
                  <Item value="mill-year-2011">2011</Item>
                  <Item value="mill-year-2012">2012</Item>
                </Menu>
              </Dropdown>
              </FormFieldWrapper>
            </Col>
          </Row> 
          <Row>
            <Col md={6}>
              <FormFieldWrapper className="range">
                <LG left tag="h5">Range</LG>
                <Row>
                <Col md={6}>
                  <input className="min" type="text" id="min" name="name" required placeholder="Min"></input>
                </Col>
                <Col md={6}>
                  <input className="max" type="text" id="max" name="name" required placeholder="Max"></input>
                </Col>
              </Row>
              </FormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Flex justifyCenter>
                <FlexItem>
                  <FiltersButton className="filters-button" onClick={() => setCount(count + 1)}>Less Filters</FiltersButton>
                </FlexItem>
              </Flex>
            </Col>
          </Row>
        </FiltersWrapper>
      </Grid>
    )
  }
  return (
    <Grid>
      <FiltersWrapper>
        <Row>
          <Col md={6}>
            <FormFieldWrapper className="category">
              <LG left tag="h5">Category</LG>
              {/* <select className="dropdown">
                <option value="">Choose Category</option>
                <option value="C1">Category 1</option>
                <option value="C2">Category 2</option>
                <option value="C3">Category 3</option>
                <option value="C4">Category 4</option>
                <option value="C5">Category 5</option>
                <option value="C6">Category 6</option>
                <option value="C7">Category 7</option>
                <option value="C8">Category 8</option>
              </select> */}
              {/* <Dropdown className="dropdown" onSelect={value => value}>
                <Trigger>
                  <div className="option">Category</div>
                </Trigger>
                <Menu>
                  <Item value="category-1">Category 1</Item>
                  <Item value="category-2">Category 2</Item>
                  <Item value="category-3">Category 3</Item>
                </Menu>
              </Dropdown> */}
              <Dropdown className="dropdown"
                selectedItem={selectedItem}
                onSelect={item => setSelectedItem(item)}
                downshiftProps={{ itemToString: item => item && item.label }}
              >
                <Field>
                  <Select className="dropdown-select">{selectedItem.value}</Select>
                </Field>
                <Menu className="dropdown-menu">
                  {category.map(category => (
                    <Item key={category.value} value={category}>
                      {category.label}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="od">
              <LG left tag="h5" style={{display: `inline-block`}}>OD</LG>
              <select className="dropdown-small">
                <option value="EQ">Equals</option>
                <option value="RNG">Range</option>
              </select><Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `-10px`, left: `4px`, fontSize: `10px`}} />
              <input type="text" id="name" name="name" required placeholder="Enter OD"></input>
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormFieldWrapper className="ft">
              <LG left tag="h5" style={{display: `inline-block`}}>#/FT</LG>
              <select className="dropdown-small">
                <option value="RNG">Range</option>
                <option value="DEC">Decimal</option>
                <option value="EN">Exact Number</option>
              </select><Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `-10px`, left: `4px`, fontSize: `10px`}} />
              <Row>
                <Col md={6}>
                  <input className="min" type="text" id="min" name="name" required placeholder="Min"></input>
                </Col>
                <Col md={6}>
                  <input className="max" type="text" id="max" name="name" required placeholder="Max"></input>
                </Col>
              </Row>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="alloy">
              <LG left tag="h5">Alloy<Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `32px`, left: `8px`}} /></LG>
              {/* <select className="dropdown">
                <option value="">Choose Alloy</option>
                <option value="A1">Alloy 1</option>
                <option value="A2">Alloy 2</option>
                <option value="A3">Alloy 3</option>
                <option value="A4">Alloy 4</option>
                <option value="A5">Alloy 5</option>
                <option value="A6">Alloy 6</option>
                <option value="A7">Alloy 7</option>
                <option value="A8">Alloy 8</option>
              </select> */}
              <Dropdown className="dropdown">
                <Trigger>
                  <div className="option">Choose Alloy</div>
                </Trigger>
                <Menu>
                  <Item value="alloy-1">Alloy 1</Item>
                  <Item value="alloy-2">Alloy 2</Item>
                  <Item value="alloy-3">Alloy 3</Item>
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormFieldWrapper className="min-yield">
              <LG left tag="h5" style={{display: `inline`}}>Min Yield<Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `32px`, left: `8px`}} /></LG>
              <select className="dropdown-small">
                <option value="">Equals</option>
                <option value="AL">Range</option>
              </select><Icon icon="icon-chevron-down" color={color_white} style={{display: `inline-block`, float: `right`, margin: `16px 0 0 0`, position: `relative`, top: `-10px`, left: `24px`, fontSize: `10px`}} />
              {/* <select className="dropdown" style={{top: `10px`}}>
                <option value="">Choose Min Yield</option>
                <option value="Y1">Yield 1</option>
                <option value="Y2">Yield 2</option>
                <option value="Y3">Yield 3</option>
                <option value="Y4">Yield 4</option>
                <option value="Y5">Yield 5</option>
                <option value="Y6">Yield 6</option>
                <option value="Y7">Yield 7</option>
                <option value="Y8">Yield 8</option>
              </select> */}
              <Dropdown className="dropdown">
                <Trigger>
                  <div className="option m-yield">Choose Min Yield</div>
                </Trigger>
                <Menu>
                  <Item value="min-yield-1">Min Yield 1</Item>
                  <Item value="min-yield-2">Min Yield 2</Item>
                  <Item value="min-yield-3">Min Yield 3</Item>
                </Menu>
              </Dropdown>
            </FormFieldWrapper>
          </Col>
          <Col md={6}>
            <FormFieldWrapper className="end-finish">
              <LG left tag="h5">End Finish</LG>
              <input type="text" id="name" name="name" required placeholder="End Finish"></input>
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Flex justifyCenter>
              <FlexItem>
                <FiltersButton onClick={() => setCount(count + 1)}>More Filters</FiltersButton>
              </FlexItem>
            </Flex>
          </Col>
        </Row>
      </FiltersWrapper>
    </Grid>
  );
}

export default MoreFilters;
