import styled from 'styled-components';
import {variables} from 'theme/variables';
import { SM } from '@zendeskgarden/react-typography';
import { Col, Row } from '@zendeskgarden/react-grid';
const {
  spacing_sm,
  spacing_lg,
  custom_grey
} = variables;

export const ExpandedRow = styled(Row)`
  padding: 0 0 0 ${spacing_lg};
  position: relative
`

export const ExpandedIcon = styled('div')`
  width: 15px;
  height: 15px;
  border-left: 1px solid ${custom_grey};
  border-bottom: 1px solid ${custom_grey};
  position: absolute;
  top: 3px;
  left: ${spacing_sm};
`

export const LowerText = styled(SM)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: ${spacing_sm}
  padding-bottom: ${spacing_sm}
`
export const UpperText = styled(SM)`
  line-height: 24px; 
  padding-top: ${spacing_sm};
`

export const ItemCol = styled(Col)`
  min-width: 90px; 
`