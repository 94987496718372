import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import CreateAccountForm from './components/CreateAccountForm/CreateAccountForm.js'



import {
  CreateAccountTemplatePage
} from './CreateAccount.styles';


const CreateAccountTemplate = () => {
  
  return (
    <CreateAccountTemplatePage style={{padding: `0`, overflow: `hidden`}}>
      <CreateAccountForm />
    </CreateAccountTemplatePage>
  )
}


export default PageViewWrapper(CreateAccountTemplate, {pageName: 'CreateAccountTemplate'})