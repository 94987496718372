import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
import { Button } from '@zendeskgarden/react-buttons';

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';

const {
    custom_dark_blue,
    color_white,
    media_sm,
    media_md,
    media_lg,
  public_font_size_xxl,
  public_font_size_md,
  public_font_size_xl,
  public_font_size,
  custom_button_blue,
  public_font_size_sm
} = variables;



export const SearchResultsPage = styled(Page)`
  padding: 0;
  overflow: hidden;
`;

export const SearchSection = styled(Section)`
  
  position: relative;
  padding: 0px 0 180px 0;

  h1 {
    margin-bottom: 32px;
  }

  h1, h2, h3, h4, p {
    color: ${props => props.dark ? color_white : custom_dark_blue}
  }

  @media ${media_md} {
    padding: 80px 0 160px 0;
  }

  @media ${media_lg} {
    padding: 136px 0 280px 0;
    h1 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
    }
  }
`;

export const ResultsSection = styled(Section)`
  
  position: relative;
  padding: 150px 0 160px 0;

  h1, h2, h3, h4, p {
    color: ${props => props.dark ? color_white : custom_dark_blue}
  }

  p {
    font-size: ${public_font_size_md};
    line-height: 32px;
  }



  @media ${media_md} {
    padding: 200px 0 180px 0;

    .results-found {
      float: right;
      position: relative;
      top: 30px;
    }

    h2 {
      font-size: ${public_font_size_xl};
      line-height: 52px;
    }

    h5 {
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
    }
  }
`;

export const Wrapper = styled.div`
  max-width: 360px;
  margin: 0 auto;
  width: 100%;

  @media ${media_md} {
    max-width: 400px;
    width: 400px;
  }

  @media ${media_lg} {
    max-width: 800px;
    width: 800px;
  }
`

export const BackgroundCircle = styled.div`
  overflow: hidden;
  position: absolute;
  height: 1160px;	
  width: 1160px;	
  border: 200px solid rgba(1, 90, 208, 0.05);
  border-radius: 50%;
`
export const Logo = styled.img`
  position: absolute;
  top: 40px;
  left: 80px;
  height: 44px;
  width: 230px;
`
export const DividingLine = styled.div`
  width: 210px;
  height: 2px;
  border-bottom: 2px dotted #414141;
  margin: 200px 0 100px 0;
`;

export const SearchFieldWrapper = styled.div`
  height: 51px;
  width: 50vw;
  margin-top: 56px;
  input {
    position: relative;
    z-index: 2;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    background: transparent;
    font-size: ${public_font_size};
    padding: 0 0 20px 30px;
    color: ${color_white};
    font-color: rbga(255,255,255,0.4);
    ::placeholder {
      color: #67778d;
      padding-left: 30px;
    }

    :-ms-input-placeholder {
      color: #67778d;
      padding-left: 30px;
    }

    ::-ms-input-placeholder {
      color: #67778d;
      padding-left: 30px;
    }
  }



  h5 {
    padding-bottom: 20px;
  }
`

export const SearchResultsButton = styled(Button)`
  padding: 20px 20px;
  border-radius: 8px;	
  margin: 30px 0 30px 0;
  width: 176px;
  background-color: ${custom_button_blue};	
  box-shadow: 0 15px 30px -12px rgba(0,0,0,0.5);
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 21px;

  
`;

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
`

export const SectionArrow = styled.div`
  position: absolute;
  width: 100%;
  top: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;

  &:after {
    content: '';
    background-color: ${custom_dark_blue};
    width: 160px;
    height: 160px;
    border-bottom-right-radius: 20px;
    transform: rotate(45deg);
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

export const Result = styled.div`
  max-width: 200px;
  margin: 0 0 40px 0;

  h5 {
    margin: 0 0 20px 0;
  }
  .update {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: ${public_font_size_sm};
    color: #B0BED1;
  }

  .top-update {
    position: absolute;
    right: 10px;
    top: 40px;
    font-size: ${public_font_size_sm};
    color: #B0BED1;
  }

  @media ${media_md} {
    max-width: 800px;
  }
`

export const ResultsFound = styled.div`
  max-width: 360px;

  h6 {
    display: inline-block;
    margin: 0;
  }

  .tabs {
    font-size: ${public_font_size_md};
  }

  .results {
    font-size: ${public_font_size_md};
    width: 360px;
  }

  .top-result {
    margin-top: 40px;
  }

  @media ${media_md} {
    max-width: 600px;

    .results {
      font-size: ${public_font_size_md};
      width: 600px;
    }
  }

  @media ${media_lg} {
    max-width: 800px;
    width: 800px;

    .results {
    font-size: ${public_font_size_md};
    width: 800px;
  }
  }
`

export const Line = styled.div`
  margin: 0 0 80px 0;
  box-sizing: border-box;	
  height: 1px;	
  width: 800px;	
  border: 1px solid rgba(65, 65, 65, 0.17);
`

export const PipesWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0; 
  left: 0;
  position: absolute; 
  overflow: hidden; 

  .right-pipe {
    width: 165px;
    left: 0;
    right: -225px;
    bottom: 0;
    top: 19px;
    margin: 0 auto;
  }

  .left-pipe {
    width: 165px;
    left: -207px;
    right: 0%;
    bottom: 0;
    top: 77px;
    margin: 0 auto;
  }

    .left-pipes {
      display: none;
    }

    .right-pipes {
      display: none;
  }

  @media ${media_sm} {
    .left-pipes {
      display: block;
      width: 500px;
      left: -90%;
      right: 0;
      bottom: 0;
      top: 152px;
      margin: 0 auto;
    }

    .right-pipes {
      display: block;
      width: 500px;
      left: 0;
      right: -80%;
      bottom: 0;
      top: 112px;
      margin: 0 auto;
    }

    .right-pipe {
      display: none;
  }

    .left-pipe {
      display: none;
  }
  }

  @media ${media_md} {
    .left-pipes {
      display: block;
      width: 500px;
      left: -90%;
      right: 0;
      bottom: 0;
      top: 192px;
      margin: 0 auto;
    }

    .right-pipes {
      display: block;
      width: 500px;
      left: 0;
      right: -80%;
      bottom: 0;
      top: 142px;
      margin: 0 auto;
    }

    .right-pipe {
      display: none;
  }

    .left-pipe {
      display: none;
  }
}
`

export const BgCircleWrapper = styled.div`

  .right-circle {
    right: 80vw;
    bottom: 0; 
    top: 830px; 
    margin: 0 auto; 
  }

  .left-circle {
    left: 79vw;
    bottom: 0; 
    top: 40px; 
    margin: 0 auto; 
  }
`
