import React from 'react';
import { Divider } from 'theme/Global.styles.js';
import Flex from 'styled-flex-component';
import { SM } from '@zendeskgarden/react-typography';
import {variables} from 'theme/variables';
import DocumentDownload from 'components/DocumentDownload/DocumentDownload';

import {
  StyledDocumentTitle,
  StyledDocumentItem
} from './DocumentItem.styles.js';

const {
  spacing,
} = variables;

const DocumentItem = ({document, ...rest}) => {
  const {title="CRA", documentItem} = document;
  return (
    <StyledDocumentItem padding={rest.itemPadding}>
      <Flex alignCenter>
        <StyledDocumentTitle bold>{title}</StyledDocumentTitle>
        <Divider/>
        <SM>{'10:02am '}</SM>
      </Flex>
      <div style={{padding: `${spacing} 0`}}>
        <DocumentDownload document={documentItem} />
      </div>
    </StyledDocumentItem>
  )
}

export default DocumentItem