import React from 'react';
import {useRoot} from 'baobab-react/hooks';
import tree from './state';

const Root = (props) => {  
  const Root = useRoot(tree);
  return (
    <Root>
      {props.children}
    </Root>
  )
}

export default Root

