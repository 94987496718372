
import * as effects from './effects';
import { map } from 'lodash';
import tree from 'state';
// import * as mock from './mock';
// const applicationCursor = tree.select(['application']);

export async function setRoutedModalIsAnimating(state) {
  tree.set(['application', 'routedModalIsAnimating'], state);
}

export async function getSuggestion(type, value, {getter} = {}) {
  try {
    const {results} = await effects.fetchSuggestion(type, value);
    return map(results, (r) => {
      r.value = r.id;
      r.label = r[getter||type];
      return r;
    });
  } catch(err) {
    return {
      error: err.message
    }
  }
}


export async function addressSuggestion(address) {
  try {
    const {results} = await effects.fetchAddressSuggestion(address);
    return map(results, (r) => {
      r.value = r.description;
      r.label = r.description;
      return r;
    });
  } catch(err) {
    return {
      error: err.message
    }
  }
}


export async function fileProxy(endpoint) {
  try {
    const file = await effects.fetchFileProxy(endpoint);
    return file;
  } catch(err) {
    return {
      error: err.message
    }
  }
}