import React from 'react';
import { XXXL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button'
import Icon from 'components/Icon/Icon';
import {
  Wrapper,
  TopSection,
  BackgroundImg,
  TransparentButton,
  PipesWrapper,
  HeaderWrapper,
  TopButtonsWrapper
} from '../../ViewItemDetails.styles';

const { color_white } = variables;

const GetPricingSection = (props) => {
  return (
    <TopSection dark>
      <PipesWrapper>
        <BackgroundImg className="leftPipes" src="/images/hero-pipes.png" />
        <BackgroundImg className="rightPipes" src="/images/hero-pipes.png" />
      </PipesWrapper>
      <Wrapper>
        <Grid>
          <Row justifyContent="center">
            <Col sm={12} md={12}>
              <HeaderWrapper>
                <Flex alignCenter justifyCenter>
                  <FlexItem>
                    <XXXL center tag="h1">OCTG 2.875" 7.8# 825 110 Plain End R3.</XXXL>
                  </FlexItem>
                </Flex>
              </HeaderWrapper>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
      <Flex justifyCenter>
        <FlexItem>
          <TopButtonsWrapper>
            <Button publicButton className="pricing" style={{padding: `13px 40px`, borderRadius: `4px`}}>Get Pricing</Button>
            <TransparentButton className="back"><Icon icon="icon-arrow-left-long" color={color_white} style={{position: `absolute`, left: `48px`, top: `12px`}}/>Back</TransparentButton>
          </TopButtonsWrapper>
        </FlexItem>
      </Flex>
    </TopSection>
  )
}

export default GetPricingSection;
