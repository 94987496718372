import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
import { Button } from '@zendeskgarden/react-buttons';

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';

const {
  custom_dark_blue,
  color_white,
  custom_dark_grey,
  spacing_sm,
  media_sm,
  media_md,
  media_lg,
  spacing,
  custom_button_blue,
  public_font_size_xxl,
  public_font_size_md
} = variables;


export const ResetPasswordPage = styled(Page)`
  
`;

export const PasswordSection = styled(Section)`
  
  padding: 64px 0 280px;
  position: relative;
  width: 100vw;
  height: auto;

  h1, h2, h3, h4, p {
    color: ${props => props.dark ? color_white : custom_dark_blue}
  }

  h1 {
    font-size: 28px;
    line-height: 32px;
    padding: ${spacing} 0 ${spacing} 0;
    letterSpacing: 2px;
  }
  
  p {
    padding: ${spacing} 0 ${spacing} 0;
    color: rgba(255, 255, 255, 0.7);
  }

  @media ${media_md} {
    padding: 80px 0px 440px;

    h1 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
    }
  }

  @media ${media_lg} {
    padding: 110px 0px 400px;
  }

`;

export const Logo = styled.img`
  height: 44px;
  width: 230px;
  margin-bottom: 30px;
`

export const ResetPasswordForm = styled.form`
  position: relative; 
  z-index: 2;
  height: 507px;	
  width: 90vw;	
  margin-top: 64px;
  border-radius: 4px;	
  background-color: ${color_white};	
  box-shadow: 0 30px 50px 0 rgba(68,89,109,0.1);

  p {
    color: ${custom_button_blue}
    fontSize: 14px;
    margin-bottom: 25px;
  }

  @media ${media_sm} {
    height: 507px;	
    width: 480px;	
  }
`

export const FormFieldWrapper = styled.div`
  height: 117px;
  width: 75vw;
  padding: 0;
  margin: 64px 0 0 0;
  input {
    border: none;
    border-bottom: 1px solid #D0D0D0;
    width: 100%;
    padding-bottom: 10px;
  }

  h5 {
    padding-bottom: 20px;
  }

  .password {
    margin: -24px 0 0 0;
  }

  @media ${media_sm} {
    width: 360px;
    input {
      width: 360px;
    }
  }

  @media ${media_md} {
    width: 352px;
    input {
      width: 352px;
    }
  }  
`

export const ResetPasswordButton = styled(Button)`
  width: 181px;
  padding: 20px 20px;
  border-radius: 8px;	
  margin-bottom: 16px;
  background-color: ${custom_button_blue};
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 21px;

  
`;

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
  width: 500px; 
  left: 0; 
  right: -74%;
  bottom: 0; 
  top: 400px; 
  margin: 0 auto; 
`

export const Line = styled.div`
	box-sizing: border-box;
	height: 2px;
	width: 34px;
  border: 1px solid #EAEAEA;
  margin-bottom: 40px;
`
