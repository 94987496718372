import React, { useEffect } from 'react';
import {useBranch} from 'baobab-react/hooks';
import Icon from 'components/Icon/Icon';
import {SidebarFilterHeader, SidebarFilterBody} from '../../GlobalSideBar.styles.js';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { getRfqGroupItems } from 'state/interests/actions';
import { LG } from '@zendeskgarden/react-typography';
import {closeSidebar} from 'state/sidebars/actions';
import {variables} from 'theme/variables';
import InterestHeader from '../components/InterestHeader/InterestHeader';
import SidebarItemsTable from '../components/SidebarItemsTable/SidebarItemsTable';
const {
  spacing
} = variables;

const InterestItemsSidebar = (props) => {
  const {interestListItems, interestLoading, activeId, activeInterest, activeInterestItems} = useBranch({
    activeInterest: ['interests', 'rfq', 'activeInterest'],
    activeId: ['interests', 'rfq', 'activeId'],
    activeInterestItems: ['interests', 'rfq', 'activeInterestItems'],

    interestListItems: ['interests', 'rfq', 'items'],
    interestLoading: ['interests', 'rfq', 'loading']
  });
  
  useEffect(() => {
    getRfqGroupItems(activeId);
  }, [activeId]);


  console.log(activeInterestItems, 'activeInterestItems')

  
  return (
    <div style={props.styles}>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <Icon icon="icon-grid-menu" fontSize="20px" color={'#fff'} />
            <LG paddingHorizontal bold color="#fff">View Items</LG>
          </Flex>
          <IconButton onClick={closeSidebar} disableBackground alignCenter justifyEnd size="large" aria-label="closeSidebar">
            <Icon button icon="icon-close" fontSize="13px" color={'#fff'} />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>

      <InterestHeader activeInterest={activeInterest}/>

      <SidebarFilterBody padding={`0 ${spacing} ${spacing} ${spacing}`}>
        <SidebarItemsTable data={activeInterestItems} />
      </SidebarFilterBody>
    </div>
  )
}

export default InterestItemsSidebar
