import React from 'react';
import { XXXL, XL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { TopSection, BackgroundImg, PipesWrapper } from '../../Blog.styles';

const { spacing } = variables;

const BlogHeader = (props) => {
  return (
    <TopSection dark>
      <PipesWrapper>
        <BackgroundImg className='left-pipes' src='/images/hero-pipes.png' />
        <BackgroundImg className='right-pipes' src='/images/hero-pipes.png' />
        <BackgroundImg className='left-pipe' src='/images/Pipes.svg' />
        <BackgroundImg className='right-pipe' src='/images/Pipes.svg' />
      </PipesWrapper>
      <Flex justifyCenter>
        <FlexItem>
          <XXXL center tag='h1'>
            Welcome to the Global Pipe Co. Blog!
          </XXXL>
        </FlexItem>
      </Flex>
    </TopSection>
  );
};

export default BlogHeader;
