import React from 'react';
import Accordion from 'theme/Accordion';
import Icon from 'components/Icon/Icon';
import {SidebarFilterHeader, SidebarFilterBody} from '../../GlobalSideBar.styles.js';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { LG } from '@zendeskgarden/react-typography';
import {closeSidebar} from 'state/sidebars/actions';
import {variables} from 'theme/variables';
const {
  spacing
} = variables;

const GlobalInterestsFilter = (props) => {
  return (
    <div style={props.styles}>
      <SidebarFilterHeader padding={`${spacing} ${spacing} ${spacing} ${spacing}`}>
        <Flex style={{width: '100%'}} justifyBetween alignCenter>
          <Flex alignCenter>
            <Icon icon="icon-settings" fontSize="20px" color={'#fff'} />
            <LG paddingHorizontal bold color="#fff">Filters</LG>
          </Flex>
          <IconButton onClick={closeSidebar} disableBackground alignCenter justifyEnd size="large" aria-label="closeSidebar">
            <Icon button icon="icon-close" fontSize="13px" color={'#fff'} />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>

      <SidebarFilterBody>
        <Accordion style={{paddingTop: 0}} title={"Saved Filters"}>
          TODO
        </Accordion>

        <Accordion title={"Status"}>
          TODO
        </Accordion>

        <Accordion title={"Owned By"}>
          TODO
        </Accordion>
      </SidebarFilterBody>
    </div>
  )
}

export default GlobalInterestsFilter
