import React, { useState } from 'react';
import {trackClick} from 'utility/analytics';
import moment from 'moment'
import { MD } from '@zendeskgarden/react-typography';
import {
  Row,
  Cell
} from '@zendeskgarden/react-tables';
import ItemsItemizedTable from '../ItemsItemizedTable/ItemsItemizedTable';

 
const SidebarItemsTableRow = ({data, sortedData}) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <div onClick={() => {
      trackClick('expandNestedTableRow', 'table-row', 'SidebarItemsTableRow', {expanded: !isExpanded});
      setExpanded(!isExpanded)
    }}>
      <Row wrapRow>
        <Cell width="40%">
          <MD overflowTip ellipsis>{data.name}</MD>
        </Cell>
        <Cell width="19%">
          <MD ellipsis>{data.quantity}</MD>
        </Cell>
        <Cell width="18%">
          <MD ellipsis>{data.availability}</MD>
        </Cell>
        <Cell width="15%">
          <MD ellipsis>{moment(data.need_date).format('YYYY-MM-DD')}</MD>
        </Cell>
        <Cell width="8%">...</Cell>

        {
          isExpanded ?
          <Cell expandedCell width="100%">
            <ItemsItemizedTable data={sortedData} />
          </Cell> : null
        }
      </Row>
    </div>
  )
}

export default SidebarItemsTableRow

