import React from 'react';
import Flex from 'styled-flex-component';
import { SM } from '@zendeskgarden/react-typography';
import {variables} from 'theme/variables';
import DocumentDownload from 'components/DocumentDownload/DocumentDownload';
import { Divider } from 'theme/Global.styles.js';
import {
  StyledMessageItem,
  StyledMessageText,
  StyledMessageSender,
  StyledMessageSubject,
} from './MessageItem.styles.js';

const {
  spacing,
} = variables;

const MessageItem = ({message, ...rest}) => {
  const {subject="CRA",  documentItem} = message;
  return (
    <StyledMessageItem padding={rest.itemPadding}>
      <Flex alignCenter>
        <StyledMessageSubject bold>{subject}</StyledMessageSubject>
        <Divider/>
        <SM>{'10:02am '}</SM>
      </Flex>
      <StyledMessageSender bold>Vince Profeta</StyledMessageSender>
      <StyledMessageText>Hello John Smith we wanted to ,this is some sample messaging text for the app to perform basic needs to display messages and news</StyledMessageText>

      <div style={{padding: `${spacing} 0`}}>
        <DocumentDownload document={documentItem} />
      </div>
    </StyledMessageItem>
  )
}

export default MessageItem