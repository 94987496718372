import React from 'react';
import { XXXL, MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { Button } from 'theme/Button'

import {
  ResetPasswordSuccessfullPage,
  Section,
  BackgroundImg,
  Logo,
  TextWrapper
} from './ResetPasswordSuccessful.styles';


const ResetPasswordSuccessful = () => {
  
  return (
    <ResetPasswordSuccessfullPage>
      <Section dark>
        <Flex justifyCenter>
        <FlexItem>
          <Logo src="/images/logo_white.svg"/>
        </FlexItem>
      </Flex>
      <TextWrapper>
        <XXXL center tag="h1">Password Changed Successfully!</XXXL>
        <MD center tag="p">You may now use your new password to log in to your account.</MD>
      </TextWrapper>
      <Flex justifyCenter>
        <FlexItem>
          <Button publicButton style={{marginTop: `40px`}}>Login</Button>
        </FlexItem>
      </Flex>
      <BackgroundImg src="/images/hero-pipes.png" />
      </Section>
    </ResetPasswordSuccessfullPage>
  )
}


export default PageViewWrapper(ResetPasswordSuccessful, {pageName: 'ResetPasswordSuccessful'})