import React from 'react';
import {variables} from 'theme/variables';
import {trackClick} from 'utility/analytics';
import { SearchInput } from 'theme/SearchInput';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { LG } from '@zendeskgarden/react-typography';
import { Menu, Item, HeaderItem } from '@zendeskgarden/react-menus';
import { Button as ZendeskButton, IconButton } from '@zendeskgarden/react-buttons';
import Platform from 'components/Platform/Platform';
import history from 'historyContainer';
import {logout} from 'state/authentication/actions';
import {toggleDrawer} from 'state/sidebars/actions';
import Icon from 'components/Icon/Icon';

import {
  AppHeaderComponent,
  MenuColumn,
  SearchWrapperCol
} from './app-header.styles.js'

const {
  custom_blue
} = variables;

 
const AppHeader = () => {  
 
  async function appLogout() {
    trackClick('logout', 'logout', 'AppHeader');
    await logout();
    history.push('/login');
  }

  return (
    <AppHeaderComponent>
      <Grid fluid>
        <Row>
          <Col size={10} md={6} sm={3}>
            <Flex alignCenter>
              <FlexItem>
                
              </FlexItem>
              <FlexItem>
                <LG bold color="#fff" tag="h3">Pipe Search</LG>
              </FlexItem>
            </Flex>
          </Col>

          <Platform component={Col} mobile alignCenter justifyEnd size={2}>
            <IconButton onClick={toggleDrawer} disableBackground alignCenter justifyEnd size="large" aria-label="Menu">
              <Icon icon="icon-align-text" fontSize={'18px'}/>
            </IconButton>
          </Platform>

          <Col size={12} md={6} sm={9}>
            <Row>
              <SearchWrapperCol>
                <SearchInput iconColor={custom_blue} placeholder="Search Vendors & Inventory" />
              </SearchWrapperCol>
              <MenuColumn size={'70px'}>
                <Flex alignCenter style={{height: '100%'}}>
                  <MenuDropdown logout={appLogout} />
                </Flex>
              </MenuColumn>
            </Row>
          </Col>
        </Row>
      </Grid>
    </AppHeaderComponent>
  )
}

const MenuDropdown = ({logout}) => {
  return (
    <Menu
      arrow
      placement="bottom-end"
      trigger={({ ref, isOpen }) => (
        <ZendeskButton link innerRef={ref}>
          <Flex alignCenter style={{height: 30, position: 'relative', marginRight: 13}}>
            <span style={{color: '#fff'}}>Account</span>
            <span style={{paddingLeft: 4, paddingTop: 4}}>
              
            </span>
          </Flex>
        </ZendeskButton>
      )}
    >
      <HeaderItem>Header Item Text</HeaderItem>
      <Item key="profile">Profile</Item>
      <Item key="settings">Settings</Item>
      <Item disabled>Theme Editor</Item>
      <Item key="article-editor">Article Editor</Item>
      <Item key="logout" onClick={logout}>Logout</Item>
    </Menu>
  )
}


export default AppHeader
