import React, { useState } from 'react';
import { MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import Icon from "components/Icon/Icon";

import {
  AvailabilityBlur,
  CriteriaWrapper,
  InventoryResultsButton,
  CriteriaInput,
  LocationWrapper,
  CancelWrapper,
  NoBlur,
  FieldsWrapper,
  TableSidebar,
} from '../../CompareItems.styles';
import { variables } from 'theme/variables';
const {
  color_white
} = variables;

const CompareBlur = (props) => {
  const [blur, setBlur] = useState("Blur")

  if (blur === "Blur") {
    return (
      <TableSidebar>
        <AvailabilityBlur />
        <CriteriaWrapper>
          <Flex center full>
            <FlexItem>
              <MD center tag="h5">Lead Time & Confidence</MD>
              <MD center tag="p">Please enter your <strong>Quanity</strong> and <strong>Location</strong> to display accurate Lead Time & Confidence levels.</MD>
              <InventoryResultsButton onClick={() => setBlur("Criteria")}>Enter Criteria</InventoryResultsButton>
            </FlexItem>
          </Flex>
        </CriteriaWrapper>
      </TableSidebar>
    )
  } else if (blur === "Criteria") {
    return (
      <TableSidebar>
        <AvailabilityBlur />
        <LocationWrapper>
          <Icon onClick={() => setBlur("Blur")} icon="icon-close" color={color_white} style={{ position: `absolute`, top: `24px`, right: `24px`, fontSize: `8px` }} />
          <Flex center>
            <FlexItem>
              <FieldsWrapper>
                <MD left tag="p" className="location">Location</MD>
                <CriteriaInput type="text" name="location" required placeholder="Location" style={{ margin: `0 0 12px 0` }} />
                <MD left tag="p" className="quantity">Quantity</MD>
                <CriteriaInput type="text" name="location" required placeholder="Quantity" />
                <Flex justifyCenter>
                  <FlexItem>
                    <InventoryResultsButton className="submit-button" onClick={() => setBlur(false)}>Submit</InventoryResultsButton>
                  </FlexItem>
                </Flex>
              </FieldsWrapper>
            </FlexItem>
          </Flex>
          <Flex justifyCenter>
            <FlexItem>
              <CancelWrapper>
                <MD center tag="p" onClick={() => setBlur("Blur")}>Cancel</MD>
              </CancelWrapper>
            </FlexItem>
          </Flex>
        </LocationWrapper>
      </TableSidebar>
    )
  } else
    return (
      <NoBlur />
    )
}
export default CompareBlur;
