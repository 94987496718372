import React from 'react';
import styled from 'styled-components';

const SVGWrapper = styled.div`
  left: 45%;
  height: 185%;
  top: -40%;
  position: relative;
  opacity: 0.2;

  .abstract-bg {
    width: 100%;
    height: auto;
  }

  svg {
    width: auto;
    height: 100%;
  }

  .rotate-right {
    animation: rotate-right 50s linear infinite;
  }
  .rotate-left {
    animation: rotate-left 50s linear infinite;
  }

  @keyframes rotate-right {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(360deg)
    }
  }
  @keyframes rotate-left {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(-360deg)
    }
  }

  .layer-1 {
    transform-origin: center center;
    transform-box: fill-box;

    .lines path {
      stroke-dashoffset: 0;
      stroke-width: 0.8px;
      fill: none;
      stroke: #023F92;
      opacity: 0;
      will-change: stroke-dashoffset;
    }
    .dots path {
      opacity: 0;
    }
    
  }

  .layer-2 {
    transform-origin: center center;
    transform-box: fill-box;
    path {
      opacity: 0;
    }
  }

  
`;


const GetStartedBackground = (props) => {
  return (
    <SVGWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-150 -300 1500 1500" className="get-started-bg">
        <defs>
          <filter id="c" width="106.3%" height="112.9%" x="-3.2%" y="-6.4%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1.4"/>
          </filter>
          <filter id="b" width="107%" height="112.4%" x="-3.5%" y="-6.2%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1.4"/>
          </filter>
          <filter id="a" width="128.8%" height="126.2%" x="-14.4%" y="-13.1%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1.4"/>
          </filter>
          <filter id="e" width="111.5%" height="138.5%" x="-5.8%" y="-19.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1.4"/>
          </filter>
          <filter id="f" width="132.7%" height="123.1%" x="-16.3%" y="-11.6%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.1"/>
          </filter>
          <filter id="d" width="126.2%" height="121.8%" x="-13.1%" y="-10.9%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.1"/>
          </filter>
          <filter id="g" width="128.9%" height="128.9%" x="-14.5%" y="-14.5%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1.4"/>
          </filter>
          <filter id="h" width="128.9%" height="128.9%" x="-14.5%" y="-14.5%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1.4"/>
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          
          <g fill="#023F92">
            <g className="layer-1 rotate-right">
              <g className="dots">
                <path d="M1061.927 444.289a5.693 5.693 0 0 0-.195-8.043c-2.263-2.167-5.867-2.07-8.032.201a5.673 5.673 0 0 0 .195 8.019c2.273 2.164 5.867 2.09 8.032-.177"/>
                <path d="M1068.237 215.11a5.694 5.694 0 0 0-.195-8.044c-2.262-2.164-5.856-2.068-8.02.203a5.672 5.672 0 0 0 .183 8.018c2.274 2.165 5.867 2.091 8.032-.176"/>
                <path d="M470.674 104.06a2.984 2.984 0 0 0-2.985 2.975 2.992 2.992 0 0 0 2.985 2.986 2.994 2.994 0 0 0 2.987-2.986 2.985 2.985 0 0 0-2.987-2.976"/>
                <path d="M529.053 800.92a3.109 3.109 0 0 0 0 6.215 3.11 3.11 0 0 0 3.105-3.105 3.11 3.11 0 0 0-3.105-3.11"/>
                <path d="M682.2 769.842a3.11 3.11 0 0 0-3.106 3.108 3.109 3.109 0 0 0 3.105 3.105 3.109 3.109 0 0 0 3.107-3.105 3.11 3.11 0 0 0-3.107-3.108"/>
                <path d="M486.512 772.008a2.562 2.562 0 0 0-2.564 2.564 2.568 2.568 0 0 0 2.564 2.566 2.562 2.562 0 0 0 2.564-2.566 2.556 2.556 0 0 0-2.564-2.564"/>
                <path d="M467.427 710.63a2.688 2.688 0 0 0-2.696 2.686 2.69 2.69 0 0 0 2.696 2.696 2.69 2.69 0 0 0 2.694-2.696 2.687 2.687 0 0 0-2.694-2.686"/>
                <path d="M615.074 103.296a4.161 4.161 0 0 0-4.166-4.158 4.16 4.16 0 0 0-4.158 4.158c0 2.297 1.864 4.171 4.158 4.171a4.17 4.17 0 0 0 4.166-4.17"/>
                <path d="M527.7 117.508a4.648 4.648 0 0 0-4.648 4.65 4.653 4.653 0 0 0 4.649 4.651 4.654 4.654 0 0 0 4.649-4.65 4.648 4.648 0 0 0-4.65-4.651"/>
                <path d="M461.476 149.34a2.9 2.9 0 0 0-2.905 2.897 2.908 2.908 0 0 0 2.905 2.906 2.907 2.907 0 0 0 2.905-2.906 2.899 2.899 0 0 0-2.905-2.897"/>
                <path d="M673.964 791.42a5.131 5.131 0 0 0-5.131 5.133 5.137 5.137 0 0 0 5.131 5.131 5.135 5.135 0 0 0 5.13-5.131 5.13 5.13 0 0 0-5.13-5.134"/>
                <path d="M934.152 413.022a4.747 4.747 0 0 0-4.748 4.75 4.753 4.753 0 0 0 4.748 4.75c2.616 0 4.74-2.134 4.74-4.75a4.745 4.745 0 0 0-4.74-4.75"/>
                <path d="M776.748 716.455a4.34 4.34 0 0 0-4.34 4.336 4.343 4.343 0 0 0 4.34 4.34 4.34 4.34 0 0 0 0-8.676"/>
                <path d="M590.758 777.91a4.922 4.922 0 0 0 0 9.843 4.923 4.923 0 0 0 4.92-4.921 4.924 4.924 0 0 0-4.92-4.922"/>
                <path d="M597.77 696.338a4.344 4.344 0 1 0 0 8.69 4.342 4.342 0 0 0 4.34-4.341 4.343 4.343 0 0 0-4.34-4.349"/>
                <path d="M623.01 170.027a3.061 3.061 0 0 0-3.057 3.057 3.064 3.064 0 0 0 3.056 3.067 3.067 3.067 0 0 0 3.067-3.067 3.065 3.065 0 0 0-3.067-3.057"/>
                <path d="M494.581 173.564a3.061 3.061 0 0 0-3.055 3.056 3.064 3.064 0 0 0 3.055 3.068 3.068 3.068 0 0 0 3.067-3.068 3.065 3.065 0 0 0-3.067-3.056"/>
                <path d="M859.853 666.892a3.39 3.39 0 0 0-3.386 3.397 3.382 3.382 0 0 0 3.386 3.387 3.39 3.39 0 0 0 3.396-3.387 3.4 3.4 0 0 0-3.396-3.397"/>
                <path d="M844.946 647.95a3.403 3.403 0 0 0 0 6.805 3.403 3.403 0 0 0 0-6.805"/>
                <path d="M913.303 567.891a4.828 4.828 0 0 0-4.819 4.82 4.828 4.828 0 0 0 4.82 4.82 4.82 4.82 0 0 0 4.81-4.82c0-2.657-2.156-4.82-4.81-4.82"/>
                <path d="M739.407 670.911a4.759 4.759 0 0 0-4.76 4.76 4.764 4.764 0 0 0 4.76 4.761 4.764 4.764 0 0 0 4.76-4.761 4.758 4.758 0 0 0-4.76-4.76"/>
                <path d="M810.624 526.355a4.106 4.106 0 0 0-4.098 4.11 4.107 4.107 0 0 0 4.098 4.11 4.115 4.115 0 0 0 4.107-4.11 4.115 4.115 0 0 0-4.107-4.11"/>
                <path d="M921.719 365.584a3.088 3.088 0 0 0-3.087 3.078 3.09 3.09 0 0 0 3.087 3.087 3.086 3.086 0 0 0 3.075-3.087 3.084 3.084 0 0 0-3.075-3.078"/>
                <path d="M896.42 289.014a4.622 4.622 0 0 0-4.627-4.62 4.621 4.621 0 0 0-4.62 4.62 4.622 4.622 0 0 0 4.62 4.62 4.623 4.623 0 0 0 4.628-4.62"/>
                <path d="M811.26 320.624a4.547 4.547 0 0 0-4.547 4.561 4.552 4.552 0 0 0 4.546 4.55 4.561 4.561 0 0 0 4.562-4.55 4.557 4.557 0 0 0-4.562-4.56"/>
                <path d="M875.442 562.427a3.114 3.114 0 0 0-3.117 3.109 3.11 3.11 0 1 0 6.222 0 3.11 3.11 0 0 0-3.105-3.109"/>
                <path d="M873.058 388.266a3.865 3.865 0 0 0-3.868-3.868 3.86 3.86 0 0 0-3.855 3.868 3.853 3.853 0 0 0 3.855 3.869 3.86 3.86 0 0 0 3.868-3.87"/>
                <path d="M883.697 460.547a3.864 3.864 0 0 0-3.868-3.867 3.86 3.86 0 0 0-3.854 3.867 3.853 3.853 0 0 0 3.854 3.87 3.858 3.858 0 0 0 3.868-3.87"/>
                <path d="M912.973 411.417a3.004 3.004 0 0 0 3.006-3.006 3.005 3.005 0 0 0-3.006-3.006 3.003 3.003 0 0 0-3.006 3.006 3.002 3.002 0 0 0 3.006 3.006"/>
                <path d="M897.402 328.673a3.982 3.982 0 0 0-3.976 3.989 3.983 3.983 0 1 0 3.976-3.989"/>
                <path d="M647.313 135.25a2.872 2.872 0 0 0-2.864 2.876 2.873 2.873 0 0 0 2.864 2.877 2.877 2.877 0 0 0 0-5.753"/>
                <path d="M695.004 211.269a3.852 3.852 0 0 0-3.856 3.859 3.852 3.852 0 1 0 7.703 0 3.852 3.852 0 0 0-3.847-3.86"/>
                <path d="M778.95 208.042a3.462 3.462 0 0 0 3.466 3.468 3.454 3.454 0 0 0 3.458-3.468 3.466 3.466 0 0 0-3.458-3.467 3.475 3.475 0 0 0-3.466 3.467"/>
                <path d="M844.946 249.575a3.583 3.583 0 0 0-3.586 3.589 3.582 3.582 0 0 0 3.586 3.587 3.591 3.591 0 0 0 3.596-3.587 3.592 3.592 0 0 0-3.596-3.589"/>
                <path d="M777.578 164.646a5.316 5.316 0 0 0 5.32-5.314 5.32 5.32 0 0 0-5.32-5.33c-2.926 0-5.31 2.385-5.31 5.33a5.32 5.32 0 0 0 5.31 5.314"/>
                <path d="M358.894 699.314a4.168 4.168 0 0 0-4.17 4.18 4.163 4.163 0 0 0 4.17 4.167c2.304 0 4.186-1.861 4.186-4.166a4.183 4.183 0 0 0-4.186-4.181"/>
                <path d="M396.292 668.225a3.55 3.55 0 0 0 3.558-3.548 3.55 3.55 0 0 0-3.558-3.547 3.547 3.547 0 0 0 0 7.095"/>
                <path d="M234.319 509.297a2.923 2.923 0 0 0-2.925 2.926 2.917 2.917 0 0 0 2.925 2.927 2.916 2.916 0 0 0 2.925-2.927 2.922 2.922 0 0 0-2.925-2.926"/>
                <path d="M255.759 482.296a2.854 2.854 0 1 0-5.71 0 2.857 2.857 0 0 0 2.855 2.868 2.864 2.864 0 0 0 2.855-2.868"/>
                <path d="M254.658 586.35a2.565 2.565 0 0 0-2.566 2.568 2.556 2.556 0 0 0 2.566 2.564 2.562 2.562 0 0 0 2.564-2.564 2.572 2.572 0 0 0-2.564-2.567"/>
                <path d="M286.206 589.399a4.51 4.51 0 0 0-4.507 4.51 4.509 4.509 0 0 0 4.507 4.51 4.51 4.51 0 0 0 0-9.02"/>
                <path d="M276.469 627.545a4.404 4.404 0 0 0-4.4 4.4 4.403 4.403 0 0 0 4.4 4.396 4.398 4.398 0 1 0 0-8.796"/>
                <path d="M397.384 732.418a4.271 4.271 0 0 0 0 8.542 4.262 4.262 0 0 0 4.27-4.272 4.266 4.266 0 0 0-4.27-4.27"/>
                <path d="M234.319 384.549a5.165 5.165 0 0 0-5.16 5.16 5.166 5.166 0 0 0 5.16 5.162 5.166 5.166 0 0 0 5.16-5.162 5.165 5.165 0 0 0-5.16-5.16"/>
                <path d="M264.006 292.611a3.894 3.894 0 0 0-3.89 3.89 3.884 3.884 0 0 0 3.89 3.878 3.875 3.875 0 0 0 3.876-3.878 3.884 3.884 0 0 0-3.876-3.89"/>
                <path d="M202.235 377.236a3.894 3.894 0 0 0 3.625 4.137 3.884 3.884 0 0 0 4.123-3.63 3.873 3.873 0 0 0-3.615-4.12 3.882 3.882 0 0 0-4.133 3.613"/>
                <path d="M116.8 610.915a3.893 3.893 0 0 0 3.625 4.135 3.884 3.884 0 0 0 4.123-3.627 3.876 3.876 0 0 0-3.616-4.123 3.882 3.882 0 0 0-4.132 3.615"/>
                <path d="M222.502 598.337a3.893 3.893 0 0 0 5.363 1.212 3.884 3.884 0 0 0 1.2-5.361 3.873 3.873 0 0 0-5.348-1.207 3.886 3.886 0 0 0-1.215 5.356"/>
                <path d="M315.982 172.36a3.892 3.892 0 0 0 .275 5.491 3.88 3.88 0 0 0 5.483-.281 3.876 3.876 0 0 0-.275-5.478 3.883 3.883 0 0 0-5.483.267"/>
                <path d="M221.079 691.294a5.696 5.696 0 0 0 4.368-6.755c-.659-3.066-3.685-5.01-6.75-4.35a5.664 5.664 0 0 0-4.351 6.732c.656 3.068 3.67 5.031 6.733 4.373"/>
                <path d="M50.336 449.521a3.892 3.892 0 0 0 1.52 5.285 3.88 3.88 0 0 0 5.275-1.524 3.876 3.876 0 0 0-1.517-5.271 3.882 3.882 0 0 0-5.278 1.51"/>
                <path d="M136.482 740.287a3.89 3.89 0 0 0 5.479-.446 3.885 3.885 0 0 0-.453-5.475 3.87 3.87 0 0 0-5.464.448 3.884 3.884 0 0 0 .438 5.473"/>
                <path d="M220.842 658.255a3.887 3.887 0 0 0 5.483.392 3.886 3.886 0 0 0 .382-5.48 3.872 3.872 0 0 0-5.47-.388 3.883 3.883 0 0 0-.395 5.476"/>
                <path d="M920.522 177.11a3.891 3.891 0 0 0-5.286 1.513 3.884 3.884 0 0 0 1.52 5.28 3.873 3.873 0 0 0 5.27-1.512 3.885 3.885 0 0 0-1.504-5.281"/>
                <path d="M907.693 108.87a3.892 3.892 0 0 0-2 5.122 3.88 3.88 0 0 0 5.119 1.991 3.875 3.875 0 0 0 1.992-5.108 3.882 3.882 0 0 0-5.11-2.005"/>
                <path d="M1039.51 399.81a3.887 3.887 0 0 0-5.174-1.856 3.881 3.881 0 0 0-1.84 5.174 3.862 3.862 0 0 0 5.152 1.849 3.873 3.873 0 0 0 1.862-5.167"/>
                <path d="M921.374 521.768a3.891 3.891 0 0 0-5.176-1.856 3.884 3.884 0 0 0-1.845 5.174 3.87 3.87 0 0 0 5.16 1.848 3.883 3.883 0 0 0 1.86-5.166"/>
                <path d="M1156.962 420.663a3.887 3.887 0 0 0-5.174-1.856 3.879 3.879 0 0 0-1.84 5.173 3.873 3.873 0 0 0 5.163 1.85 3.877 3.877 0 0 0 1.85-5.167"/>
                <path d="M344.69 498.776a3.895 3.895 0 0 0-2.047-5.106 3.882 3.882 0 0 0-5.094 2.051 3.877 3.877 0 0 0 2.042 5.091 3.883 3.883 0 0 0 5.099-2.036"/>
                <path d="M730.59 747.792a4.744 4.744 0 0 0 3.863-5.477 4.731 4.731 0 0 0-5.474-3.85 4.723 4.723 0 0 0-3.851 5.463c.446 2.574 2.89 4.31 5.463 3.864"/>
                <path d="M137.707 548.916a5.69 5.69 0 0 0 7.971 1.058c2.49-1.91 2.95-5.476 1.043-7.966a5.663 5.663 0 0 0-7.949-1.052c-2.49 1.907-2.97 5.473-1.065 7.96"/>
                <path d="M969.347 357.708a3.89 3.89 0 0 0-5.268-1.576 3.884 3.884 0 0 0-1.565 5.264 3.873 3.873 0 0 0 5.253 1.571 3.885 3.885 0 0 0 1.58-5.259"/>
                <path d="M976.302 329.049a3.893 3.893 0 0 0-4.155 3.603 3.882 3.882 0 0 0 3.603 4.144 3.873 3.873 0 0 0 4.14-3.594 3.882 3.882 0 0 0-3.588-4.153"/>
                <path d="M910.17 675.413c-1.12 2.93-4.411 4.404-7.339 3.282-2.927-1.12-4.384-4.407-3.263-7.338a5.662 5.662 0 0 1 7.32-3.269c2.926 1.12 4.402 4.398 3.283 7.325"/>
                <path d="M981.846 717.85a2.845 2.845 0 0 1-3.875 1.072 2.835 2.835 0 0 1-1.063-3.87 2.828 2.828 0 0 1 3.862-1.071 2.841 2.841 0 0 1 1.076 3.868"/>
                <path d="M372.098 442.84a4.865 4.865 0 0 0-4.87 4.859c0 2.686 2.184 4.85 4.87 4.85a4.847 4.847 0 0 0 4.848-4.85 4.855 4.855 0 0 0-4.848-4.86"/>
                <path d="M530.596 201.257a3.682 3.682 0 0 0-3.689-3.678 3.687 3.687 0 0 0-3.685 3.678 3.688 3.688 0 0 0 7.374 0"/>
                <path d="M659.006 285.336a3.674 3.674 0 0 0-3.677 3.678 3.673 3.673 0 0 0 3.677 3.677 3.68 3.68 0 0 0 3.678-3.677 3.68 3.68 0 0 0-3.678-3.678"/>
                <path d="M529.053 306.613a3.95 3.95 0 0 0-3.949 3.949 3.943 3.943 0 0 0 3.949 3.948 3.949 3.949 0 0 0 0-7.897"/>
                <path d="M792.176 423.405a5.64 5.64 0 0 0 5.64-5.633 5.644 5.644 0 0 0-5.64-5.653 5.644 5.644 0 0 0-5.642 5.653 5.64 5.64 0 0 0 5.642 5.633"/>
                <path d="M287.838 256.67a3.504 3.504 0 0 0 3.508-3.506 3.505 3.505 0 0 0-3.508-3.509 3.508 3.508 0 0 0 0 7.016"/>
                <path d="M334.426 198.691a2.576 2.576 0 0 0-2.563 2.566 2.575 2.575 0 0 0 2.563 2.565 2.565 2.565 0 1 0 0-5.131"/>
                <path d="M460.699 187.912a2.576 2.576 0 0 0-2.564 2.565 2.576 2.576 0 0 0 2.564 2.565 2.565 2.565 0 1 0 0-5.13"/>
                <path d="M317.757 656.408a3.584 3.584 0 0 0-3.588 3.588 3.592 3.592 0 0 0 3.588 3.598 3.6 3.6 0 0 0 3.595-3.598 3.592 3.592 0 0 0-3.595-3.588"/>
                <path d="M317.757 474.86a2.765 2.765 0 1 0 0 5.532 2.765 2.765 0 0 0 2.765-2.766 2.76 2.76 0 0 0-2.765-2.766"/>
                <path d="M439.424 241.417a2.73 2.73 0 0 0 2.726-2.726 2.73 2.73 0 0 0-5.461 0 2.732 2.732 0 0 0 2.735 2.726"/>
                <path d="M288.141 433.6a5.68 5.68 0 1 0 0 11.36c3.132 0 5.668-2.546 5.668-5.68a5.668 5.668 0 0 0-5.668-5.68"/>
                <path d="M346.8 716.343a3.046 3.046 0 1 0 0 6.093 3.045 3.045 0 0 0 3.045-3.047 3.045 3.045 0 0 0-3.045-3.046"/>
                <path d="M264.006 367.459a3.084 3.084 0 0 0-3.078 3.077 3.084 3.084 0 0 0 3.078 3.077 3.083 3.083 0 0 0 3.074-3.077 3.083 3.083 0 0 0-3.074-3.077"/>
                <path d="M317.757 332.681a5.043 5.043 0 0 0-5.042 5.053 5.04 5.04 0 0 0 5.042 5.04 5.04 5.04 0 0 0 5.047-5.04 5.042 5.042 0 0 0-5.047-5.053"/>
                <path d="M186.673 169.42a5.04 5.04 0 0 0-5.04 5.052 5.038 5.038 0 0 0 5.04 5.04 5.04 5.04 0 0 0 5.047-5.04 5.041 5.041 0 0 0-5.047-5.051"/>
                <path d="M376.895 224.67a4.493 4.493 0 0 0-4.486 4.489 4.485 4.485 0 0 0 4.486 4.48 4.478 4.478 0 0 0 4.48-4.48 4.486 4.486 0 0 0-4.48-4.49"/>
                <path d="M421.993 170.484a4.494 4.494 0 0 0-4.487 4.49 4.485 4.485 0 0 0 4.487 4.481 4.478 4.478 0 0 0 4.48-4.48 4.487 4.487 0 0 0-4.48-4.491"/>
                <path d="M494.591 235.857a4.494 4.494 0 0 0-4.488 4.49 4.485 4.485 0 1 0 4.488-4.49"/>
                <path d="M334.426 248.031a2.57 2.57 0 0 0-2.563 2.566 2.582 2.582 0 0 0 2.563 2.567 2.572 2.572 0 0 0 2.568-2.567 2.56 2.56 0 0 0-2.568-2.566"/>
                <path d="M80.428 521.116a3.683 3.683 0 0 0-3.559 3.803c.064 2.024 1.758 3.636 3.79 3.57a3.69 3.69 0 0 0 3.56-3.8 3.684 3.684 0 0 0-3.79-3.573"/>
                <path d="M1013.416 235.362a5.696 5.696 0 0 0-.19-8.043c-2.267-2.165-5.861-2.07-8.026.202a5.662 5.662 0 0 0 .191 8.017c2.268 2.165 5.863 2.093 8.025-.176"/>
                <path d="M274.057 211.724a3.89 3.89 0 0 0-5.176-1.856 3.882 3.882 0 0 0-1.843 5.173 3.87 3.87 0 0 0 5.16 1.849 3.883 3.883 0 0 0 1.859-5.166"/>
                <path d="M211.922 233.98a3.888 3.888 0 0 0-5.175-1.855 3.88 3.88 0 0 0-1.843 5.172 3.87 3.87 0 0 0 5.16 1.849 3.883 3.883 0 0 0 1.858-5.166"/>
                <path d="M1026.932 500.362a3.882 3.882 0 0 0-5.171-1.857 3.882 3.882 0 0 0-1.844 5.174 3.875 3.875 0 0 0 5.164 1.848 3.876 3.876 0 0 0 1.851-5.165"/>
                <path d="M1006.792 460.26a3.888 3.888 0 0 0-5.175-1.857 3.882 3.882 0 0 0-1.843 5.174 3.87 3.87 0 0 0 5.16 1.848 3.882 3.882 0 0 0 1.858-5.166" />
              </g>
              <g className="lines">
                <path d="M317.126 475.766v-133.6l-28.288 96.975 28.288 36.625zm.631 2.5a.665.665 0 0 1-.468-.21l-29.648-38.384a.621.621 0 0 1-.113-.57l29.624-101.556a.646.646 0 0 1 .607-.455l.099.007a.63.63 0 0 1 .538.624v139.904a.647.647 0 0 1-.412.593l-.067.027-.16.02z"/>
                <path d="M288.086 437.201l28.611-98.075-51.923 31.684 23.312 66.391zm.054 2.718a.638.638 0 0 1-.603-.454l-24.13-68.713a.642.642 0 0 1 .265-.756l53.758-32.808a.617.617 0 0 1 .734.049c.196.17.275.424.206.661l-29.627 101.558a.633.633 0 0 1-.57.462h-.033z"/>
                <path d="M318.396 476.545l52.861-29.114-52.861-106.985v136.099zm-.639 1.72c-.128 0-.225-.047-.284-.076-.232-.145-.347-.346-.347-.563V337.722c0-.286.205-.546.487-.617l.044-.011.091-.005c.232 0 .452.126.573.329l54.336 109.993a.638.638 0 0 1-.269.847l-54.318 29.92a.666.666 0 0 1-.313.087z"/>
                <path d="M659.134 287.297l35.036-71.919-70.071-40.915 35.035 112.834zm-.147 2.346a.627.627 0 0 1-.583-.42l-35.999-115.946a.658.658 0 0 1 .195-.68.683.683 0 0 1 .414-.149c.12 0 .234.035.334.1l71.967 42.032a.64.64 0 0 1 .268.804l-36.006 73.905a.62.62 0 0 1-.571.354h-.019z"/>
                <path d="M528.398 309.22l-2.097-107.003-85.666 36.633 87.763 70.37zm.655 1.982a.604.604 0 0 1-.424-.168l-89.602-71.847a.603.603 0 0 1-.232-.62c.02-.169.155-.356.345-.453l87.52-37.435.252-.056c.126 0 .25.04.356.115a.604.604 0 0 1 .278.508l2.144 109.299c0 .25-.14.482-.35.586a.634.634 0 0 1-.287.07z"/>
                <path d="M793.036 416.76l75.056-28.749-56.458-61.484-18.598 90.233zm-.86 1.641a.63.63 0 0 1-.622-.764l19.085-92.59a.646.646 0 0 1 .451-.486l.055-.015.111-.007c.19 0 .365.08.485.22l57.92 63.08a.625.625 0 0 1 .148.576c-.02.179-.171.366-.385.446l-77.088 29.53h-.069l-.09.01z"/>
                <path d="M289.344 252.444l44.454-2.455v-47.073l-44.454 49.528zm-1.506 1.358a.612.612 0 0 1-.565-.377.591.591 0 0 1 .138-.717l46.553-51.874a.636.636 0 0 1 .473-.215h.071l.157.044c.248.1.4.328.4.594v49.34a.629.629 0 0 1-.59.63l-46.605 2.575h-.032z"/>
                <path d="M335.065 249.562l40.566-20.476-40.566-26.657v47.133zm-.639 1.664a.572.572 0 0 1-.348-.115.615.615 0 0 1-.28-.514v-49.34c0-.22.117-.433.307-.55a.724.724 0 0 1 .327-.088c.135 0 .264.043.37.125l42.443 27.888a.605.605 0 0 1 .278.582c.002.192-.13.405-.343.513l-42.464 21.43a.627.627 0 0 1-.29.07z"/>
                <path d="M462.357 151.144l63.46-28.82-54.651-14.499-8.809 43.32zm-.881 1.733a.617.617 0 0 1-.397-.147.655.655 0 0 1-.229-.578l9.192-45.243a.71.71 0 0 1 .278-.408.728.728 0 0 1 .344-.095h.062l.13.03 57.002 15.108a.66.66 0 0 1 .475.527c.007.337-.15.573-.39.669l-66.2 30.081-.267.056z"/>
                <path d="M394.484 735.025l-35.494-30.611-11.16 14.656 46.654 15.955zm2.805 2.303l-.162-.066-50.54-17.273a.628.628 0 0 1-.403-.421.723.723 0 0 1 .075-.524l12.133-15.94a.609.609 0 0 1 .427-.245l.034-.001c.204 0 .338.05.447.14l38.492 33.214a.625.625 0 0 1 .146.787.617.617 0 0 1-.554.329h-.095z"/>
                <path d="M343.73 714.567l-26.47-54.132-37.635-25.581 64.104 79.713zm3.07 5.462a.661.661 0 0 1-.491-.237l-70.336-87.45c-.187-.265-.171-.604.023-.82a.621.621 0 0 1 .47-.212.67.67 0 0 1 .355.102l41.296 28.06a.827.827 0 0 1 .214.236l29.04 59.404c.149.277.042.653-.248.823a.65.65 0 0 1-.323.094z"/>
                <path d="M346.92 718.19l11.147-14.659-37.9-40.069 26.753 54.727zm-.151 1.839a.668.668 0 0 1-.536-.356l-29.037-59.39a.644.644 0 0 1 .184-.795.632.632 0 0 1 .373-.122c.17 0 .337.07.46.193l41.146 43.495c.207.208.232.55.056.807l-12.113 15.918a.65.65 0 0 1-.502.25h-.031z"/>
                <path d="M285.63 589.735l1.848-148.656-33.873 41.363 32.025 107.293zm.576 4.815a.654.654 0 0 1-.606-.447l-33.31-111.618a.678.678 0 0 1 .122-.59l35.235-43.015c.088-.137.277-.236.482-.236h.073l.159.044a.627.627 0 0 1 .406.631l-1.921 154.596a.672.672 0 0 1-.381.575l-.04.048-.219.012z"/>
                <path d="M316.18 658.156l-29.795-62.392-9.194 35.906 38.989 26.486zm1.577 2.48a.739.739 0 0 1-.315-.082l-41.326-28.086a.637.637 0 0 1-.268-.662l9.741-38.051a.657.657 0 0 1 .517-.479l.036-.006.059-.001c.26 0 .49.149.585.384l31.552 66.07c.104.29.048.56-.133.742-.182.13-.312.171-.448.171z"/>
                <path d="M286.914 588.917l30.147-111.148-28.317-36.656-1.83 147.804zm-.888 5.633l-.045-.05a.624.624 0 0 1-.403-.59l1.924-154.634c0-.279.188-.528.457-.601l.05-.013.132-.017c.183 0 .359.083.484.227l29.64 38.362c.119.137.166.337.12.537l-31.563 116.304a.645.645 0 0 1-.616.475h-.18z"/>
                <path d="M252.687 478.018l10.552-106.227-28.217 18.217 17.665 88.01zm.217 4.917a.621.621 0 0 1-.624-.522l-18.583-92.58a.663.663 0 0 1 .255-.648l29.707-19.177a.613.613 0 0 1 .677-.013.627.627 0 0 1 .29.648L253.53 482.365a.616.616 0 0 1-.616.57h-.01z"/>
                <path d="M253.736 480.266l33.676-41.112-23.058-65.71-10.618 106.822zm-.832 2.67c-.055 0-.181 0-.306-.08-.206-.067-.371-.355-.317-.648l11.096-111.739c-.002-.276.242-.533.566-.56h.014c.327 0 .557.165.644.41l24.137 68.756a.683.683 0 0 1-.09.585l-35.26 43.037c-.085.131-.284.238-.484.238z"/>
                <path d="M265.827 368.676l51.37-31.339 15.925-83.254-67.295 114.593zm-1.821 2.49a.667.667 0 0 1-.416-.142c-.241-.24-.28-.56-.13-.814l70.42-119.945a.667.667 0 0 1 .55-.305l.245.048c.265.087.438.384.384.694l-16.67 87.142c-.065.224-.178.367-.329.44l-53.727 32.796c-.117.085-.254.085-.327.085z"/>
                <path d="M396.733 735.289l-1.053-69.056-35.856 37.216 36.909 31.84zm.651 2.039a.606.606 0 0 1-.427-.172l-38.471-33.186a.595.595 0 0 1-.223-.465c-.023-.13.043-.32.182-.459l37.386-38.81a.686.686 0 0 1 .466-.198h.08l.159.052a.62.62 0 0 1 .385.587l1.102 72.007a.636.636 0 0 1-.37.579.519.519 0 0 1-.269.065z"/>
                <path d="M398.007 735.813l68.005-22.695-69.058-47.226 1.053 69.921zm-.623 1.515a.681.681 0 0 1-.337-.1c-.204-.146-.301-.33-.301-.528l-1.082-72.017c0-.245.122-.454.325-.567a.726.726 0 0 1 .308-.078c.137 0 .265.044.373.125l71.12 48.63a.63.63 0 0 1 .257.64c-.021.202-.187.403-.417.481l-70.104 23.393-.142.02z"/>
                <path d="M253.936 583.725l-1.64-99.253-17.288 27.853 18.928 71.4zm.722 5.833a.643.643 0 0 1-.616-.468l-20.328-76.698a.6.6 0 0 1 .067-.505l18.58-29.92a.617.617 0 0 1 .532-.304h.06l.141.034c.235.044.438.302.438.6l1.765 106.613c0 .316-.23.588-.536.641l-.031.007h-.072z"/>
                <path d="M485.497 773.444l-18.488-59.315-67.833 22.632 86.32 36.683zm.963 1.758l-.119-.024-89.203-37.9a.637.637 0 0 1-.392-.6c.026-.302.203-.52.453-.595l70.086-23.386.152-.015.271.066c.149.06.279.206.333.39l19.073 61.242c.078.185.032.433-.133.628l-.033.038-.04.027a.68.68 0 0 1-.396.13h-.052z"/>
                <path d="M233.606 389.868l29.9-93.917 1.227.368-29.931 93.952-1.196-.403"/>
                <path d="M527.707 121.5l76.767-17.392-129.375 3.446 52.608 13.945zm-.056 1.288l-.107-.011-57.036-15.126a.641.641 0 0 1-.473-.661c.053-.352.317-.584.63-.584l140.222-3.739c.366.022.603.227.654.538.03.354-.179.639-.488.71l-83.25 18.873h-.152z"/>
                <path d="M533.527 803.162l134.53-6.946-77.245-12.735-57.285 19.681zm-4.474 1.506c-.31 0-.58-.23-.625-.531-.068-.286.11-.602.422-.707l61.754-21.219.135-.015.128.013 83.198 13.71a.66.66 0 0 1 .538.645c-.028.36-.302.618-.629.618l-144.89 7.486h-.031z"/>
                <path d="M590.2 781.792l6.875-80.403-127.584 12.368L590.2 781.792zm.558 1.68c-.137 0-.24-.052-.296-.078l-123.351-69.527c-.23-.113-.36-.388-.305-.67a.63.63 0 0 1 .552-.508l130.368-12.64c.208 0 .341.052.467.157.17.189.227.368.202.548l-7.008 82.13c0 .186-.127.396-.322.507a.66.66 0 0 1-.307.082z"/>
                <path d="M529.15 803.328l58.51-20.102-98.847-7.834 40.337 27.936zm-.097 1.34a.606.606 0 0 1-.373-.13l-42.523-29.442a.632.632 0 0 1-.24-.744.63.63 0 0 1 .593-.419l104.299 8.262c.284.023.52.242.572.54a.63.63 0 0 1-.412.692l-61.773 21.223-.143.018z"/>
                <path d="M587.935 781.966l-119.433-67.31 18.496 59.31 100.937 8zM486.46 775.2a.636.636 0 0 1-.552-.453l-19.092-61.238c-.06-.277.021-.525.2-.68a.654.654 0 0 1 .71-.072l123.347 69.523a.65.65 0 0 1 .3.725c-.114.299-.357.468-.616.468L486.46 775.2z"/>
                <path d="M933.214 416.661l-11.46-45.233-8.045 36.622 19.505 8.611zm.938 1.74l-.193-.03-21.238-9.374a.633.633 0 0 1-.367-.724l8.744-39.742a.62.62 0 0 1 .61-.498c.29 0 .544.193.618.472l12.434 49.104a.607.607 0 0 1-.224.66.603.603 0 0 1-.384.132z"/>
                <path d="M776.79 719.847l66.185-67.39-102.424 23.595 36.239 43.795zm-.053 1.585c-.237-.028-.4-.125-.496-.27l-37.327-45.088a.665.665 0 0 1-.109-.608.657.657 0 0 1 .44-.41l105.608-24.338.098-.008c.222 0 .428.12.547.322a.658.658 0 0 1-.079.747l-68.212 69.458a.644.644 0 0 1-.46.195h-.01z"/>
                <path d="M741.94 674.434l102.528-23.629 29.615-83.358L741.94 674.434zm-2.533 1.867a.622.622 0 0 1-.578-.37.633.633 0 0 1 .188-.76L875.035 565.05a.639.639 0 0 1 .757-.041.637.637 0 0 1 .248.742l-30.499 85.812c-.037.18-.216.352-.453.404L739.51 676.292l-.103.01z"/>
                <path d="M780.765 717.612l78.145-47.494-14.017-17.809-64.128 65.303zm-4.017 3.82a.64.64 0 0 1-.452-1.086l68.2-69.445a.668.668 0 0 1 .47-.183.63.63 0 0 1 .474.23l14.91 18.947c.111.132.162.31.136.49a.677.677 0 0 1-.288.444l-83.114 50.507a.701.701 0 0 1-.336.096z"/>
                <path d="M693.12 213.293l-45.835-74.016-23.362 33.607 69.197 40.409zm1.884 2.464c-.077 0-.222 0-.361-.108l-71.955-42.017a.61.61 0 0 1-.296-.391c-.032-.211.002-.373.09-.507l24.323-34.966c.08-.176.306-.237.401-.264l.049-.012.07-.005c.223 0 .43.119.538.312l47.673 77c.156.225.13.537-.07.761a.642.642 0 0 1-.462.197z"/>
                <path d="M846.67 648.397l65.425-75.265-36.244-6.864-29.18 82.13zm-1.724 3.589a.583.583 0 0 1-.36-.118c-.223-.13-.34-.444-.24-.726l30.49-85.815a.649.649 0 0 1 .606-.423l.103.007 37.88 7.178c.23.05.403.207.478.42.072.2.027.445-.121.618l-68.351 78.64a.628.628 0 0 1-.485.219z"/>
                <path d="M859.758 669.134l50.15-91.554-64.13 73.789 13.98 17.765zm.095 1.785a.632.632 0 0 1-.493-.226l-14.913-18.95a.654.654 0 0 1 .014-.804l68.365-78.646a.6.6 0 0 1 .47-.216c.13 0 .26.04.364.113.28.201.358.571.181.845l-53.425 97.557a.61.61 0 0 1-.541.327h-.022z"/>
                <path d="M912.995 405.369l8.038-36.57-22.354-33.13 14.316 69.7zm-.022 3.682a.634.634 0 0 1-.618-.484l-15.574-75.78a.65.65 0 0 1 .368-.712.705.705 0 0 1 .25-.048.65.65 0 0 1 .535.278l24.309 36.005a.647.647 0 0 1 .1.467l-8.75 39.775a.636.636 0 0 1-.62.5z"/>
                <path d="M919.223 363.842l-26.098-69.493 4.894 38.075 21.204 31.418zm2.496 5.46a.639.639 0 0 1-.53-.271l-24.31-36.017-.093-.23-5.618-43.692a.66.66 0 0 1 .447-.689l.057-.018.125-.008c.276 0 .509.167.596.428l29.92 79.643a.636.636 0 0 1-.315.783.533.533 0 0 1-.279.07z"/>
                <path d="M896.429 330.027l-5.233-40.669-43.647-33.408 48.88 74.077zm.973 3.274a.654.654 0 0 1-.516-.274l-52.467-79.515a.64.64 0 0 1 .083-.804.622.622 0 0 1 .439-.182c.145 0 .286.05.402.143l46.838 35.833a.67.67 0 0 1 .248.427l5.61 43.644a.654.654 0 0 1-.413.684l-.063.023-.161.02z"/>
                <path d="M868.185 386.227l-23.513-130.981-32.649 69.81 56.162 61.171zm1.005 2.679a.652.652 0 0 1-.45-.193l-57.939-63.112c-.165-.14-.225-.417-.122-.677l33.692-72.031a.656.656 0 0 1 .588-.37c.365.057.571.264.62.536l24.243 135.1a.632.632 0 0 1-.338.675.686.686 0 0 1-.294.072z"/>
                <path d="M875.902 560.84l36.33-152.065-42.361-19.498 6.03 171.563zm-.49 5.333a.637.637 0 0 1-.609-.628l-6.242-177.255c0-.227.1-.42.27-.543a.675.675 0 0 1 .365-.116l.273.064 43.773 20.141c.272.125.42.43.35.723l-37.54 157.123a.614.614 0 0 1-.61.49h-.03z"/>
                <path d="M869.484 386.287l27.192-53.58-50.552-76.617 23.36 130.197zm-.323 2.619c-.34-.058-.544-.259-.594-.521l-24.245-135.114a.632.632 0 0 1 .402-.704l.065-.023.156-.015c.21 0 .409.103.531.277l52.464 79.507c.12.194.132.41.044.618l-28.227 55.626a.63.63 0 0 1-.567.349h-.03z"/>
                <path d="M912.1 407.314l-14.95-72.753-27.093 53.405 42.044 19.348zm.827 1.737l-.166-.034-.057-.022-43.772-20.144c-.176-.113-.27-.221-.328-.35a.624.624 0 0 1 .041-.547l28.2-55.585a.604.604 0 0 1 .557-.351l.107.01c.25.046.449.226.518.47l15.566 75.787a.626.626 0 0 1-.236.631.6.6 0 0 1-.384.135h-.046z"/>
                <path d="M695.334 214.462l84.823-6.871-131.087-67.837 46.264 74.708zm-.33 1.295a.606.606 0 0 1-.56-.333l-47.662-76.958a.65.65 0 0 1 .07-.771.617.617 0 0 1 .778-.114l135.073 69.905a.638.638 0 0 1 .337.68c-.075.295-.299.489-.566.514l-87.436 7.077h-.034z"/>
                <path d="M842.16 250.374l-63.715-88.75 4.573 46.072 59.143 42.678zm2.786 3.428a.607.607 0 0 1-.39-.146l-62.512-45.096a.635.635 0 0 1-.26-.433l-4.833-48.736a.628.628 0 0 1 .418-.654l.222-.04c.21 0 .402.1.514.266l67.358 93.83c.18.257.15.601-.072.82a.621.621 0 0 1-.445.19z"/>
                <path d="M205.533 377.957l.446-1.201 28.836 11.066-.478 1.168-28.804-11.033"/>
                <path d="M225.246 595.744l29.828-8.02.301 1.225-29.783 8.03-.346-1.235"/>
                <path d="M318.121 174.972l1.094-.665 15.736 26.586-1.1.621-15.73-26.542"/>
                <path d="M219.219 686.065l2.763-30.772 1.255.141-2.741 30.734-1.277-.103"/>
                <path d="M53.014 451.576l.916-.896 89.727 94.46-.93.853-89.713-94.417"/>
                <path d="M138.326 736.992l79.872-50.435.652 1.081-79.826 50.427-.698-1.073"/>
                <path d="M223.164 655.28l55.274-24.092.48 1.168-55.227 24.092-.527-1.168"/>
                <path d="M844.425 252.513l74.035-72.73.897.915-74.081 72.749-.851-.934"/>
                <path d="M908.567 112.132l1.271-.158 9.04 68.643-1.259.111-9.052-68.596"/>
                <path d="M933.73 416.617l102.543-15.84.195 1.268-102.595 15.827-.143-1.255"/>
                <path d="M317.476 477.961l.84-.943L341.818 497l-.821.984-23.521-20.023"/>
                <path d="M729.102 743.428l8.727-68.188 1.25.191-8.704 68.148-1.273-.151"/>
                <path d="M141.633 544.991l92.755-33.371.41 1.192-92.71 33.377-.455-1.198"/>
                <path d="M921.278 367.353l44.894-8.499.259 1.256-44.94 8.487-.213-1.244"/>
                <path d="M964.351 359.532l11.218-27.186 1.2.454-11.253 27.217-1.165-.485"/>
                <path d="M859.583 671.424l.052-1.262 45.656 2.609-.091 1.277-45.617-2.624"/>
                <path d="M1058.885 439.912l95.382-18.112.238 1.304-95.371 18.112-.249-1.304"/>
                <path d="M184.876 174.451l.802-1.054 103.795 79.212-.802 1.055-103.795-79.213"/>
                <path d="M976.098 332.572l87.939-120.721 1.061.782-87.932 120.72-1.068-.781"/>
                <path d="M1035.343 400.839l1.298-.685 22.049 40.265-1.288.735-22.059-40.315"/>
                <path d="M528.627 311.636a.601.601 0 0 1-.1-1.195l129.777-21.811a.601.601 0 1 1 .2 1.187l-129.776 21.81-.1.009"/>
                <path d="M659.134 287.899a.59.59 0 0 1-.328-.099l-132.475-86.617a.601.601 0 1 1 .658-1.007l132.474 86.618a.6.6 0 0 1 .175.832.598.598 0 0 1-.504.273"/>
                <path d="M623.348 173.149l-.218-.04-81.23-31.546a.6.6 0 0 1 .2-1.161l105.747-3.205c.32-.01.607.252.62.584a.604.604 0 0 1-.584.62l-102.786 3.114 78.467 30.47a.602.602 0 0 1-.216 1.164"/>
                <path d="M811.314 325.421l-28.879-117.584 1.167-.285 28.879 117.582-1.167.287"/>
                <path d="M739.787 675.319l-1.08-.527 71.303-145.687.555.31 65.14 36.233-.586 1.051-64.584-35.925-70.748 144.545"/>
                <path d="M398.929 252.39l61.286-61.821-38.11-14.905-23.176 76.726zm-1.957 2.409a.546.546 0 0 1 .022-.377l24.095-79.787a.605.605 0 0 1 .312-.376c.197-.082.361-.086.512-.032l39.655 15.523c.192.037.303.242.35.33l.024.044.021.066a.62.62 0 0 1-.176.599l-63.76 64.304a.614.614 0 0 1-.755.11.632.632 0 0 1-.3-.404z"/>
                <path d="M696.262 746.89l-13.201 25.394 43.57-2.632-30.369-22.762zm-14.224 26.66a.603.603 0 0 1-.535-.88l14.02-26.964a.603.603 0 0 1 .4-.31.595.595 0 0 1 .494.106l32.246 24.17a.6.6 0 0 1 .214.653.598.598 0 0 1-.538.428l-46.265 2.796-.036.002z"/>
                <path d="M462.843 204.628l31.142 34.161v-60.856l-31.142 26.695zm31.744 36.316a.598.598 0 0 1-.445-.197l-32.605-35.766a.602.602 0 0 1 .054-.863l32.605-27.95a.601.601 0 0 1 .993.458v63.716a.603.603 0 0 1-.385.561l-.217.041z"/>
                <path d="M810.639 325.65l-.137-.017-151.065-35.758a.601.601 0 0 1-.444-.724.597.597 0 0 1 .723-.447l148.878 35.242-114.365-107.86a.6.6 0 1 1 .826-.875l115.998 109.4a.6.6 0 0 1-.414 1.039"/>
                <path d="M980.132 717.748a.613.613 0 0 1-.304-.084l-73.568-43.098a.602.602 0 0 1 .61-1.037l73.566 43.098a.602.602 0 0 1-.304 1.12"/>
                <path d="M208.532 236.508l6.25 34.595 54.12-56.852-60.37 22.257zm5.791 36.83l-.204-.03a.707.707 0 0 1-.496-.559l-6.617-36.578a.725.725 0 0 1 .468-.804l63.83-23.536a.725.725 0 0 1 .85.281.705.705 0 0 1-.085.886l-57.222 60.115a.712.712 0 0 1-.524.225z"/>
                <path d="M984.254 486.148l38.468 14.807-19.23-38.08-19.238 23.273zm39.955 16.864l-.262-.046-41.142-15.827a.758.758 0 0 1-.438-.495.767.767 0 0 1 .14-.645l20.574-24.883a.71.71 0 0 1 .636-.251c.242.027.45.177.56.382l20.566 40.719c.14.27.098.586-.097.802a.732.732 0 0 1-.537.244z"/>
                <path  d="M881.462 461.026l36.55 61.692 31.542-77.504-68.092 15.812zm-1.613-.292a.685.685 0 0 1 .027-.348c.1-.232.259-.367.454-.408l70.136-16.281a.632.632 0 0 1 .62.197c.079.105.12.193.14.286a.629.629 0 0 1-.04.39l-32.48 79.818a.634.634 0 0 1-.572.388c-.202.022-.434-.099-.56-.308l-37.673-63.598-.052-.136z"/>
                <path d="M188.366 519.905L81.523 525.38l39.305 84.5 67.538-89.975zm1.96-.716a.611.611 0 0 1-.153.43l-68.957 91.856a.6.6 0 0 1-.613.252c-.168-.015-.36-.142-.464-.33l-40.164-86.328-.065-.249a.621.621 0 0 1 .104-.36.602.602 0 0 1 .5-.295l109.135-5.595a.659.659 0 0 1 .597.333.63.63 0 0 1 .08.286z"/>
                <path d="M1011.895 233.453l76.583 83.154-42.03-74.434-34.553-8.72zm80.263 88.92a.692.692 0 0 1-.53-.234l-82.415-89.482a.719.719 0 0 1-.09-.852.736.736 0 0 1 .793-.334l37.182 9.385a.658.658 0 0 1 .443.334l45.245 80.11a.716.716 0 0 1-.206.933.734.734 0 0 1-.422.14z"/>
              </g>
            </g>
            <g className="layer-2 rotate-left">
              <path d="M1076.69 607.854l-20.857 26.714 24.191-13.357-3.333-13.357zm-23.574 29.754a.72.72 0 0 1-.552-.254.74.74 0 0 1-.022-.915l23.921-30.623a.745.745 0 0 1 .725-.262c.26.056.477.262.542.523l3.831 15.322a.747.747 0 0 1-.357.803l-27.742 15.311a.705.705 0 0 1-.346.095z" filter="url(#a)"/>
              <path d="M322.915 605.914l115.164 23.71-62.601-64.076-52.563 40.366zm118.021 24.769a.705.705 0 0 1-.075.458.713.713 0 0 1-.771.366l-119.002-24.505a.712.712 0 0 1-.551-.54c-.075-.272.039-.557.266-.737l54.298-41.696a.72.72 0 0 1 .951.067l64.69 66.203a.68.68 0 0 1 .194.384z" filter="url(#b)"/>
              <path d="M704.686 712.315l-127.876 36.68 72.734 25.416 55.142-62.096zm2.561-1.271a.651.651 0 0 1-.145.467l-56.891 64.06c-.122.18-.39.266-.66.189l-75.05-26.227a.653.653 0 0 1-.427-.549c.02-.367.204-.595.468-.671l131.9-37.823a.63.63 0 0 1 .704.27c.061.1.092.19.101.284z" filter="url(#c)"/>
              <path d="M295.11 729.357l42.362 51.338-10.274-36.035-32.087-15.303zm43.472 54.935l-.03-.032-46.611-56.492a.727.727 0 0 1-.017-.897.74.74 0 0 1 .88-.217l35.302 16.846c.19.092.317.252.378.452l11.305 39.644a.718.718 0 0 1-.359.83.705.705 0 0 1-.848-.134z" filter="url(#d)"/>
              <path d="M314.897 93.668l64.94 7.179-32.48-18.48-32.46 11.3zm68.313 8.99h-.083l-71.538-7.916a.716.716 0 0 1-.635-.635.715.715 0 0 1 .467-.756l35.778-12.442a.685.685 0 0 1 .588.054l35.78 20.36c.29.168.43.523.325.843a.712.712 0 0 1-.682.492z" filter="url(#e)"/>
              <path d="M852.763 44.915l-27.828 47.168 33.028-31.614-5.2-15.554zm-31.078 51.99a.695.695 0 0 1-.448-.15.735.735 0 0 1-.178-.936l31.266-52.997a.704.704 0 0 1 .7-.345c.27.025.506.222.599.483l5.838 17.49a.732.732 0 0 1-.186.748l-37.105 35.51a.66.66 0 0 1-.486.196z" filter="url(#f)"/>
              <path d="M95.072 620.961H84.825v-10.609h-1.221v10.609H73.37v1.137h10.234v10.695h1.221v-10.695h10.247v-1.137"/>
              <path d="M375.337 334.435h-10.244v-10.609h-1.223v10.609h-10.243v1.129h10.243v10.704h1.223v-10.704h10.244v-1.129"/>
              <path d="M851.086 720.188h-10.243V709.58h-1.222v10.608h-10.247v1.125h10.247v10.694h1.222v-10.694h10.243v-1.125"/>
              <path d="M1160.426 364.979h-10.24V354.37h-1.223v10.609h-10.25v1.126h10.25v10.693h1.223v-10.693h10.24v-1.126"/>
              <path d="M496.668 64.095a5.58 5.58 0 0 1-5.591 5.592 5.586 5.586 0 0 1-5.589-5.592 5.58 5.58 0 0 1 5.589-5.59 5.573 5.573 0 0 1 5.591 5.59"/>
              <path d="M450.038 3.951a3.944 3.944 0 1 1-7.889 0A3.938 3.938 0 0 1 446.1 0c2.178 0 3.94 1.76 3.94 3.951"/>
              <path d="M38.328 184.395a3.946 3.946 0 0 1-3.95 3.941 3.935 3.935 0 0 1-3.94-3.941 3.935 3.935 0 0 1 3.94-3.95 3.946 3.946 0 0 1 3.95 3.95"/>
              <path d="M1138.713 787.765a3.93 3.93 0 0 1-3.94 3.93 3.921 3.921 0 0 1-3.93-3.93c0-2.201 1.743-3.952 3.93-3.952 2.186 0 3.94 1.75 3.94 3.952"/>
              <path d="M1051.427 658.764a3.934 3.934 0 0 1-3.95 3.941 3.92 3.92 0 0 1-3.919-3.94c0-2.18 1.743-3.95 3.918-3.95 2.198 0 3.951 1.77 3.951 3.95"/>
              <path d="M762.852 58.519a5.569 5.569 0 0 1-5.576 5.579c-3.088 0-5.603-2.492-5.603-5.579a5.604 5.604 0 0 1 5.603-5.605c3.086 0 5.576 2.503 5.576 5.605"/>
              <path d="M452.126 791.708a5.57 5.57 0 0 1-5.576 5.578c-3.089 0-5.603-2.494-5.603-5.578a5.604 5.604 0 0 1 5.603-5.606c3.087 0 5.576 2.502 5.576 5.606"/>
              <path d="M160.662 298.264c-7.134 0-12.94 5.797-12.94 12.932 0 7.136 5.806 12.956 12.94 12.956 7.134 0 12.938-5.82 12.938-12.956 0-7.135-5.804-12.932-12.938-12.932zm0 27.468c-8.008 0-14.52-6.511-14.52-14.536 0-8.01 6.512-14.525 14.52-14.525 8.006 0 14.53 6.515 14.53 14.525 0 8.025-6.524 14.536-14.53 14.536z" filter="url(#g)"/>
              <path d="M999.391 279.76c-7.135 0-12.938 5.793-12.938 12.931 0 7.136 5.803 12.943 12.938 12.943 7.136 0 12.94-5.807 12.94-12.943 0-7.138-5.804-12.932-12.94-12.932zm0 27.469c-8.007 0-14.519-6.515-14.519-14.538 0-8.024 6.512-14.537 14.52-14.537 8.007 0 14.532 6.513 14.532 14.537 0 8.023-6.525 14.538-14.533 14.538z"/>
              <path d="M764.378 602.038c-7.123 0-12.93 5.806-12.93 12.956 0 7.122 5.807 12.93 12.93 12.93 7.145 0 12.951-5.808 12.951-12.93 0-7.15-5.806-12.956-12.951-12.956zm0 27.468c-8.009 0-14.52-6.514-14.52-14.512 0-8.023 6.511-14.55 14.52-14.55 8.02 0 14.543 6.527 14.543 14.55 0 7.998-6.523 14.512-14.543 14.512z" filter="url(#h)"/>
              <path d="M171.848 438.645c0 3.102-2.49 5.59-5.59 5.59a5.582 5.582 0 0 1-5.592-5.59 5.591 5.591 0 0 1 5.593-5.592c3.1 0 5.59 2.502 5.59 5.592"/>
              <path d="M205.532 796.558a3.94 3.94 0 0 1-3.94 3.94 3.931 3.931 0 0 1-3.935-3.94 3.925 3.925 0 0 1 3.935-3.95 3.934 3.934 0 0 1 3.94 3.95"/>
              <path d="M392.792 318.463a3.945 3.945 0 1 1-3.938-3.952 3.937 3.937 0 0 1 3.938 3.952"/>
              <path d="M446.094 658.765a3.945 3.945 0 0 1-7.889 0 3.939 3.939 0 0 1 3.951-3.951 3.937 3.937 0 0 1 3.938 3.951"/>
              <path d="M833.318 464.424c0 2.166-1.76 3.939-3.938 3.939a3.946 3.946 0 1 1 0-7.89 3.937 3.937 0 0 1 3.938 3.95"/>
              <path d="M7.876 600.149a3.944 3.944 0 0 1-3.94 3.95A3.936 3.936 0 0 1 0 600.15a3.936 3.936 0 0 1 3.936-3.951 3.944 3.944 0 0 1 3.94 3.95"/>
              <path d="M1023.645 123.519a5.569 5.569 0 0 1-5.575 5.577c-3.09 0-5.604-2.491-5.604-5.577a5.604 5.604 0 0 1 5.604-5.606c3.085 0 5.575 2.502 5.575 5.606"/>
              <path d="M326.834 601.226a5.694 5.694 0 0 0-7.9 1.504c-1.763 2.597-1.076 6.127 1.518 7.89a5.66 5.66 0 0 0 7.875-1.505c1.762-2.594 1.1-6.128-1.493-7.889"/>
              <path d="M350.82 79.999a3.89 3.89 0 0 0-5.176-1.855 3.883 3.883 0 0 0-1.843 5.173 3.872 3.872 0 0 0 5.16 1.849 3.885 3.885 0 0 0 1.86-5.167"/>
              <path d="M373.626 562.778a3.895 3.895 0 0 0-.979 5.413 3.88 3.88 0 0 0 5.403.968 3.874 3.874 0 0 0 .977-5.395 3.881 3.881 0 0 0-5.401-.986"/>
              <path d="M706.253 706.924a4.344 4.344 0 1 0 4.338 4.346 4.34 4.34 0 0 0-4.338-4.346"/>
              <path d="M575.05 745.984a3.108 3.108 0 0 0 0 6.213 3.109 3.109 0 0 0 3.107-3.106 3.11 3.11 0 0 0-3.106-3.107"/>
            </g>
          </g>
        </g>
      </svg>
    </SVGWrapper>
  )
}


export default GetStartedBackground;
