import * as effects from './effects';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import { map } from 'lodash';
import tree from 'state';

const onboardingCursor = tree.select(['onboarding']);

export async function getOnboardingMeta() {
  await asyncTreeRequester({
    func: effects.getOnboardingMeta,
    cursor: onboardingCursor,
    path: ['meta']
  });  
}

