import styled from 'styled-components';
import { SM, LG } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
const {
  spacing,
  spacing_xxs,
  custom_light_grey
} = variables;

export const StyledNotificationItem = styled('div')`
  padding: ${props => props.padding || `${spacing} 0`};
  border-bottom: 1px solid ${custom_light_grey};
  cursor: pointer;
  &:first-child {
    padding-top: 0;
  }
`
export const StyledNotificationText = styled(SM)`
  padding-top: ${spacing_xxs};
`

export const StyledNotificationSubject = styled(LG)`
 
`

export const StyledNotificationSender = styled(SM)`
  padding-top: ${spacing_xxs};  
`