import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
import { Button } from '@zendeskgarden/react-buttons';

const {
  color_white,
  media_md,
  media_lg,
  spacing,
  custom_button_blue,
  public_font_size_xxl,
  public_font_size,
  public_font_size_sm,
  public_font_size_md
} = variables;


export const CreateAccountTemplatePage = styled(Page)`
  
`;

export const Section = styled.section`
  
  padding: 56px 0 200px 0;
  position: relative;
  width: 100vw;
  height: 1300px;

 
  h1 {
    font-size: ${public_font_size};
    padding: 0 0 ${spacing} 0;
    letter-spacing: 2px;
    margin: 20px 0 5px 0;
  }

  p {
    padding: 0 0 ${spacing} 0;
    color: rgba(255, 255, 255, 0.7);
    margin: 0 0 16px 0;
  }

  .already {
    color: #a2a2a2;
    font-size: ${public_font_size_sm};
  }

  @media ${media_md} {
    padding: 80px 0 200px 0;
  

    h1 {
      font-size: ${public_font_size_xxl};
      padding: 0 0 ${spacing} 0;
      letter-spacing: 2px;
    }  
  }

  @media ${media_lg} {
    padding: 100px 0 200px 0;
  
`;

export const Logo = styled.img`
  height: 44px;
  width: 230px;
  margin-bottom: 30px;
`

export const CreateAccountForm = styled.form`
  position: relative;
  z-index: 2;
  height: 848px;	
  width: 340px;	
  margin-top: 40px;
  border-radius: 4px;	
  background-color: ${color_white};	
  box-shadow: 0 30px 50px 0 rgba(68,89,109,0.1);

  @media ${media_md} {
    height: 800px;	
    width: 600px;
  }
`

export const FormFieldWrapper = styled.div`
  height: 117px;
  width: 332px;
  padding: 0 32px;
  margin-top: 0px;
  input {
    border: none;
    border-bottom: 1px solid #D0D0D0;
    width: 100%;
    padding-bottom: 10px;
  }

  h5 {
    padding-bottom: 20px;
  }

  @media ${media_md} {
    width: 526px;
    padding: 0 0 0 64px;
    margin-top: 30px;
  }
`
export const FormFieldWrapperName = styled.div`
  margin: 60px 0 0 0;
  padding: 0 32px;
  width: 332px;

  input {
    border: none;
    border-bottom: 1px solid #D0D0D0;
    width: 100%;
    padding-bottom: 10px;
  }

  h5 {
    padding-bottom: 20px;
  }

  @media ${media_md} {
    margin: 60px 0 20px 64px;
    width: 204px;
    padding: 0;
  }
`

export const FormFieldWrapperLastName = styled.div`
  margin: 32px 0;
  padding: 0 32px;
  width: 332px;
  input {
    border: none;
    border-bottom: 1px solid #D0D0D0;
    width: 100%;
    padding-bottom: 10px;
  }

  h5 {
    padding-bottom: 20px;
  }

  @media ${media_md} {
    margin: 60px 0 20px 16px;
    width: 204px;
    padding: 0;
  }
`

export const CreateAccountButton = styled(Button)`
  padding: 20px 20px;
  border-radius: 8px;	
  margin: 20px 0 30px 0;
  width: 176px;
  background-color: ${custom_button_blue};	
  box-shadow: 0 2px 4px 0 rgba(58,122,208,0.3);
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 21px;

`;

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
  width: 500px;
  left: 0; 
  right: -74%; 
  bottom: 0;
  top: 650px;
  margin: 0 auto;
`
