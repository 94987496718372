import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Row } from '@zendeskgarden/react-grid';
import { Page } from 'theme/Page';
import { Button } from '@zendeskgarden/react-buttons';

const {
  custom_dark_blue,
  color_white,
  custom_dark_grey,
  spacing_sm,
  media_sm,
  media_md,
  media_lg,
  spacing,
  custom_button_blue,
  public_font_size_md,
  public_font_size_sm
} = variables;



export const LoginTemplatePage = styled(Page)`
 
`;

export const Section = styled.section`
  
  padding: 64px 0 280px 0;
  
  width: 100vw;
  height: auto;

  

  h5 {
    color: #3E3E3E;
    margin-bottom: 8px;
  }

  

  p {
    padding: 0 0 ${spacing} 0;
    color: rgba(255, 255, 255, 0.7);
  }

  @media ${media_sm} {
    padding: 80px 0 280px 0;
  }


  @media ${media_lg} {
    padding: 100px 0 280px 0;
  }

`;

export const Logo = styled.img`
  height: 44px;
  width: 230px;
  margin-bottom: 40px;
`

export const LoginForm = styled.form`
  position: relative;
  z-index: 2;
  height: 586px;	
  width: 90vw;	
  margin-top: 40px;
  border-radius: 4px;	
  background-color: ${color_white};	
  box-shadow: 0 30px 50px 0 rgba(68,89,109,0.1);

  .email {
    margin-top: 60px;
  }

  .password {
    margin-bottom: 25px;
  }

  @media ${media_sm} {
    width: 480px;	
  }

  @media ${media_md} {
    width: 480px;	
  }
`

export const FormFieldWrapper = styled.div`
  height: 117px;
  width: 75vw;
  margin-top: 24px;
  padding: 0 0 0 64px;
  input {
    border: none;
    border-bottom: 1px solid #D0D0D0;
    width: 100%;
    padding-bottom: 8px;
  }

  h5 {
    padding-bottom: 20px;
  }

  .password {
    margin: 10px 0 25px 0;
  }

  @media ${media_sm} {
    input {
      width: 360px;
    }
  }

  @media ${media_lg} {
    width: 410px;
    
    input {
      width: 350px;
    }
  }
`

export const LoginButton = styled(Button)`
  padding: 20px 60px;
  border-radius: 8px;	
  margin-bottom: 30px;
  background-color: ${custom_button_blue};	
  width: 185px;
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 21px;
`;

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
  width: 500px; 
  left: 0; 
  right: -74%; 
  bottom: 0; 
  top: 560px; 
  margin: 0 auto; 
`

export const FormTextBottom = styled.div`
  p {
    color: #a2a2a2;
    font-size: ${public_font_size_sm};
  }
`

export const Line = styled.div`
	box-sizing: border-box;
	height: 2px;
	width: 34px;
  border: 1px solid #EAEAEA;
  margin-bottom: 25px;
`
