import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
import { Button } from '@zendeskgarden/react-buttons';

const {
  custom_dark_blue,
  color_white,
  spacing,
  spacing_sm,
  media_sm,
  media_md,
  media_lg,
  public_font_size_xxl,
  public_font_size_xl,
  public_font_size_md,
  public_font_size_sm,
  custom_button_blue
} = variables;



export const ResetPasswordSuccessfullPage = styled(Page)`
  padding: 0;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  h1, h2, h3, h4, h5 {
    font-weight: bold;
    line-height: normal;
  }

  h1 {
    font-size: ${public_font_size_xxl};
    line-height: 56px;
  }

  h2 {
    font-size: ${public_font_size_xxl};
    line-height: 48px;
  }

  h3 {
    font-size: ${public_font_size_xl};
    line-height: 48px;
  }

  h4 {
    font-size: 25px;
    line-height: 24px;
  }

  h5 {
    font-size: 18px;
    line-height: 32px;
  }

  p {
    font-size: ${public_font_size_md};
    line-height: 16px;
  }

  li {
    font-size: ${public_font_size_sm};
    line-height: 30px;
    span {
      font-size: 30px;
      position: relative;
      top: 5.9px;
      color: #D7D7D7;
      padding-right: 10px;
    }
  }
`;

export const Section = styled.section`
  
  padding: 64px 0px 280px;
  position: relative;
  width: 100vw;
  height: auto;

  h1, h2, h3, h4, p {
    color: ${props => props.dark ? color_white : custom_dark_blue}
  }

  h1 {
    font-size: 28px;
    line-height: 32px;
  }

  @media ${media_md} {
    padding: 80px 0px 440px;

    h1 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
    }
  }

  @media ${media_lg} {
    padding: 110px 0px 400px;
  }

`;

export const LoginButton = styled(Button)`
  position: relative;
  z-index: 10;
  padding: 20px 20px;
  border-radius: 8px;	
  margin: 40px 0 30px 0;
  width: 185px;
  background-color: ${custom_button_blue};	
  box-shadow: 0 15px 30px -12px rgba(0,0,0,0.5);
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 21px;

  
`;

export const BackgroundImg = styled.img`
  width: 500px;
  left: 0;
  right: -76%;
  bottom: 0;
  top: 400px;
  margin: 0 auto; 
  overflow: hidden;
  position: absolute;
  height: auto;
`

export const Logo = styled.img`
  height: auto;
  width: 230px;
  margin: 0 0 160px 0;

  @media ${media_md} {
    margin: 0 0 240px 0;
  }
`

export const TextWrapper = styled.div`
 position: relative;
  z-index: 10;

  h1 {
    padding: 0 ${spacing} ${spacing_sm};
    letterSpacing: 2px;
  }

  p {
    padding: ${spacing};
    color: rgba(255, 255, 255, 0.7);
  }

  @media ${media_sm} {
    h1 {
      padding: 0 ${spacing_sm} ${spacing_sm};
      letterSpacing: 2px;
    }
  }

  @media ${media_md} {
    h1 {
      padding: 0 ${spacing_sm} ${spacing_sm};
      letterSpacing: 2px;
    }

    p {
      padding: ${spacing} 0 ${spacing} 0;
      color: rgba(255, 255, 255, 0.7);
    }
  }

`
