import styled from 'styled-components';
import {variables} from 'theme/variables';
import { SM, MD } from '@zendeskgarden/react-typography';
import { Col } from '@zendeskgarden/react-grid';
const {
  spacing_sm,
  spacing_md,
  custom_light_grey,
} = variables;

export function defineRowStyles({simple, condensed, table, hideActions}) {
  return {
    typeColStyle: {
      size: simple ? undefined : "auto",
      condensed: !!condensed,
      simple: simple,
      alignCenter: table,
      table
    },
    projectNameColStyle: {
      condensed,
      extraPaddingRight: !condensed && !simple,
      alignCenter: table,
      table
    },
    wellNameColStyle: {
      size: "auto",
      md: simple ? false : 'auto',
      xxl: false,
      extraPaddingRight: !simple,
      alignCenter: table,
      table
    },
    locationColStyle: {
      size: hideActions ? 'auto' : undefined,
      md: 'auto',
      alignCenter: table,
      xxl: hideActions ? 'auto' : false,
      table
    }
  }
}



export const ActionRow = styled('div')`
  padding-top: ${spacing_md};
  padding-bottom: ${spacing_md};
  border-top: 1px solid ${custom_light_grey};
  cursor: pointer;
  ${props => props.simple ? `
    padding-top: 0;
    padding-bottom: 0;
    border-top: none;
  ` : ''}
  ${props => props.table ? `
    border-bottom: 1px solid ${custom_light_grey};
    border-top: none;
    padding-top: 0;
  ` : ''}
`

export const LowerText = styled(MD)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: ${spacing_sm};
  ${props => props.table ? `
    padding-top: 0;
  ` : ''}
`
export const UpperText = styled(SM)`
  line-height: 21px !important; 
  ${props => props.table ? `
    display: none;
  ` : ''}
`

export const TypeCol = styled(Col)`
  min-width: ${props => props.condensed || props.simple ? '180px' : '180px'};
  @media (max-width: 1420px) {
    min-width: 180px;
  }
`

export const ProjectNameCol = styled(Col)`
  min-width: ${props => props.table ? '120px' : props.condensed ? '100px' : '120px'};
`

export const WellNameCol = styled(Col)`
  min-width: ${props => props.table ? '115px' : '70px'}; 
`

export const LocationCol = styled(Col)`
  min-width: ${props => props.table ? '77px' : '55px'}; 
  * {
    outline: none;
  }
`
export const NotificationCol = styled(Col)`
  min-width: 45px;
  ${props => props.table ? `
    min-width: 92px;
  ` : ''}
`

export const ActionCol = styled(Col)`
  min-width: 44px;
  ${props => props.table ? `
    display: none;
  ` : ''}
`
