// OVERIDE CSS
import {variables} from 'theme/variables';
const {
  spacing_lg,  
  spacing_md,
  custom_grey,
  custom_light_grey
} = variables;


const TableHeaderCell = {
  'tables.table': props => {
    return `
      ${props.nested ? `
        margin-left: ${spacing_lg} !important;
        margin-top: -${spacing_md} !important;
        position: relative;
        &:before {
          content: "";
          width: 15px;
          height: 15px;
          border-left: 1px solid ${custom_grey};
          border-bottom: 1px solid ${custom_grey};
          position: absolute;
          left: -13px;
          top: 5px;
        }
      ` : ''}

      ${props.styling === false ? `
        background: transparent !important;
        * {
          border-left: none !important;
          box-shadow: none !important;
          background: transparent !important;
        }
      ` : ''}

      ${props.border ? `
        border: 1px solid ${custom_light_grey} !important;
      ` : ''} 
    `
  },

  'tables.header_cell': props => {
    return `
      ${props.padding === false ? `
        padding: 0 !important; 
      ` : ''}

      ${props.size === 'small' ? `
        padding-top: 10px !important
        padding-bottom: 10px !important
      ` : ''}

      ${props.size === 'default' ? `
        padding-top: 12px !important
        padding-bottom: 12px !important
      ` : ''}

      ${props.bold ? `
        font-weight: 600; // matches real table 
      ` : ''}

      .index__c-table__row__cell__sortable___3Smrc:hover:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' color='%230168ef' fill='%230168ef'%3E%3Cpath fill='currentColor' d='M10 5H2a.5.5 0 0 1-.46-.31.47.47 0 0 1 .11-.54L5.29.5A1 1 0 0 1 6.7.5l3.65 3.65a.49.49 0 0 1 .11.54A.51.51 0 0 1 10 5z'/%3E%3Cpath d='M2 7a.5.5 0 0 0-.46.31.47.47 0 0 0 .11.54L5.3 11.5a1 1 0 0 0 1.41 0l3.65-3.65a.49.49 0 0 0 .11-.54A.53.53 0 0 0 10 7z'/%3E%3C/svg%3E") !important;
      }

      .index__c-table__row__cell__sortable___3Smrc:hover:after, .index__c-table__row__cell__sortable___3Smrc:focus:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' color='%230168ef' fill='%230168ef'%3E%3Cpath fill='currentColor' d='M10 5H2a.5.5 0 0 1-.46-.31.47.47 0 0 1 .11-.54L5.29.5A1 1 0 0 1 6.7.5l3.65 3.65a.49.49 0 0 1 .11.54A.51.51 0 0 1 10 5z'/%3E%3Cpath d='M2 7a.5.5 0 0 0-.46.31.47.47 0 0 0 .11.54L5.3 11.5a1 1 0 0 0 1.41 0l3.65-3.65a.49.49 0 0 0 .11-.54A.53.53 0 0 0 10 7z'/%3E%3C/svg%3E") !important;
      }
    `
  },

  'tables.header_row': props => {
    return `
      ${props.border === false ? `
        border: none !important
      ` : ''}

      ${props.background ? `
        background: ${props.background};
        border-bottom-color: ${props.background} !important;
      ` : ''}
    `
  },

  'tables.cell': props => {
    return `
      box-shadow: none !important;
      ${props.border === false ? `
        border: none !important
      ` : ''}

       ${props.expandedCell ? `
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      ` : ''}

      ${props.size === 'small' ? `
        padding-top: 10px !important
        padding-bottom: 10px !important
      ` : ''}

      ${props.size === 'default' ? `
        padding-top: 12px !important
        padding-bottom: 12px !important
      ` : ''}

      ${props.alignCenter ? `
        display: flex !important;
        align-items: center !important;
      ` : ''}

      ${props.justifyBetween ? `
        display: flex !important;
        justify-content: space-between !important;
      ` : ''}

      ${props.justifyCenter ? `
        display: flex !important;
        justify-content: center !important;
      ` : ''}

       ${props.justifyEnd ? `
        display: flex !important;
        justify-content: flex-end !important;
      ` : ''}

      ${props.lBracket ? `
        & + div {
          padding-left: 0 !important;
        }
      ` : ''}
    `
  },

  'tables.row': props => {
    return `
      ${props.cursor ? `
        cursor: ${props.cursor} !important
      ` : ''}

      ${props.wrapRow ? `
        flex-wrap: wrap !important
      ` : ''}

      ${props.background ? `
        background: ${props.background} !important
        border-bottom-color: ${props.background} !important;
      ` : ''}

      ${props.transparent ? `
        background: transparent !important
      ` : ''}

      ${props.active ? `
        background: #edf7ff !important
      ` : ''}

      ${props.raiseOnHover ? `
        transition: all 0.2s ease;
        &:hover {
          background: transparent !important
          box-shadow: 0 0 15px rgba(0,0,0,0.08);
        }
      ` : ''}
    `
  },

};

export default TableHeaderCell;
