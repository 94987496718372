import React from 'react';
import { LG, MD } from '@zendeskgarden/react-typography';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import Icon from "components/Icon/Icon";

import { 
  Dropdown, 
  Menu, 
  Item, 
  Trigger 
} from '@zendeskgarden/react-dropdowns';



import {
  FormFieldWrapper,
  FiltersWrapper,
  MobileWrapper
} from '../../InventoryOverlay.styles';

import { variables } from 'theme/variables';
const {
  color_white
} = variables;


const TechnicalRequirements = (props) => {
    return (
      <Grid>
        <MobileWrapper>
          <FiltersWrapper>
            <Row>
              <Col md={6}>
                <FormFieldWrapper className="category">
                  <LG left tag="h5">Category<Icon icon="icon-chevron-down" color={color_white} style={{display: `inline`, float: `right`, margin: `16px 10px 0 0`, position: `relative`, top: `32px`, left: `8px`}} /></LG>
                  {/* <select class="dropdown">
                    <option value="">Choose Category</option>
                    <option value="C1">Category 1</option>
                    <option value="C2">Category 2</option>
                    <option value="C3">Category 3</option>
                    <option value="C4">Category 4</option>
                    <option value="C5">Category 5</option>
                    <option value="C6">Category 6</option>
                    <option value="C7">Category 7</option>
                    <option value="C8">Category 8</option>
                  </select> */}
                  <Dropdown className="dropdown">
                  <Trigger>
                    <div className="option">Category</div>
                  </Trigger>
                  <Menu>
                    <Item value="category-1">Category 1</Item>
                    <Item value="category-2">Category 2</Item>
                    <Item value="category-3">Category 3</Item>
                  </Menu>
                </Dropdown>
                </FormFieldWrapper>
              </Col>
              <Col md={6}>
                <FormFieldWrapper className="od">
                    <LG left tag="h5" style={{display: `inline-block`}}>Min Burst</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Min Burst"></input>
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormFieldWrapper className="category">
                  <LG left tag="h5" style={{display: `inline-block`}}>Min Collapse</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Min Collapse"></input>
                </FormFieldWrapper>
              </Col>
              <Col md={6}>
                <FormFieldWrapper className="od">
                  <LG left tag="h5">Min Tensile Strength</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Min Tensile Strength"></input>
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormFieldWrapper className="category">
                  <LG left tag="h5" style={{display: `inline-block`}}>Max OD</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Max OD"></input>
                </FormFieldWrapper>
              </Col>
              <Col md={6}>
                <FormFieldWrapper className="od">
                  <LG left tag="h5">Min ID</LG>
                  <input type="text" id="name" name="name" required placeholder="Enter Min ID"></input>
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormFieldWrapper className="category min-pren">
                  <LG left tag="h5">Min PREN</LG>
                  <input type="text" id="min-pren" name="name" required placeholder="Enter Min PREN"></input>
                </FormFieldWrapper>
              </Col>
            </Row> 
          </FiltersWrapper>
        </MobileWrapper>
      </Grid>
    )
}

export default TechnicalRequirements;
