import styled from 'styled-components';
import { SM } from '@zendeskgarden/react-typography';
import {variables} from 'theme/variables';
const {
  custom_blue,
  custom_grey,
  font_weight_bold,
  spacing_md,
  color_white
} = variables;

const BUBBLE_WIDTH = 34;
const BUBBLE_WIDTH_HALF = BUBBLE_WIDTH / 3;
const BUBBLE_INNER_WIDTH = 24;

export const StyledOrderStatisBar = styled('div')`
  padding: 20px 0 20px;
  width: 100%;
  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;

export const LineWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 447px;
  width: 100%;
`;


export const Line = styled('div')`
  width: 100%;
  display: flex;
  background: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.22);
  position: relative;
  height: 18px;
  align-items: center;
  border-radius: 12px;
`;


export const LineInner = styled('div')`
  display: flex;
  background: ${custom_blue};
  align-items: center;
  position: absolute;
  left: 7px;
  width: ${props => props.distance - (props.last ? BUBBLE_WIDTH_HALF : 0)}px;
  top: 6px;
  bottom: 6px;
  border-radius: 12px;
`;


export const Bubble = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  position: relative;
  border: 0px solid #fff;
  position: absolute;
  left: ${props => props.distance - (props.last ? BUBBLE_WIDTH_HALF : props.inverted ? (BUBBLE_WIDTH/2) + 3 : 0)}px;
  min-width: ${BUBBLE_WIDTH+2}px;
  height: ${BUBBLE_WIDTH+2}px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.22);
  border-radius: 100px;
  background-color: #fff;
`;

export const BubbleInner = styled('div')`
  background-color: #fff;
  position: relative;
  overflow: visible;
  user-select: none;
  min-width: ${BUBBLE_INNER_WIDTH}px;
  height: ${BUBBLE_INNER_WIDTH}px;
  border-radius: 100px;
  position: absolute;
  background: ${custom_blue};
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.22);
  border: 3px solid #fff;
  
`;

export const BubbleInnerLine = styled('div')`
  background: ${custom_blue};
  align-items: center;
  position: absolute;
  left: -12px;
  right: 20px;
  top: 15px;
  bottom: 15px;
  border-radius: 0px;
`;

export const BubbleInnerShade = styled('div')`
  background-color: #fff;
  position: relative;
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: ${props => props.last ? 2 : 0}px;
  left: 0px;
  border-radius: 7px;
  position: absolute;

`;

export const LabelWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  margin-top: ${spacing_md};
`;


export const Label = styled(SM)`
  white-space: nowrap;
  cursor: default;
  text-transform: capitalize;
  color: ${props => props.labelColor || custom_grey} !important;
  ${props => props.active ? `
    color: ${props.inverted ? color_white : custom_blue} !important;
    font-weight: ${font_weight_bold};

  ` : ''}
`;