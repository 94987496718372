import React, { useState } from 'react';
import {useBranch} from 'baobab-react/hooks';
import Modal from "forks/customModal";
import {toggleDrawer} from 'state/sidebars/actions';

const StyledDrawer = Modal.styled`
  width: ${props => props.width || '20rem'};
  height: 100vh;
  position: fixed;
  padding: 1rem;
  top: 0px;
  bottom: 0;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  opacity: 1;
  min-width: 200px;
  transform: ${props => props.opacity ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0px, 0)'};
  transition: transform cubic-bezier(0.2, 0, 0, 1) 240ms 10ms;
  overflow: hidden;
`
 
const GlobalMobileDrawer = (props) => {
  const [opacity, setOpacity] = useState(0);
  const {drawerOpen} = useBranch({
    drawerOpen: ['sidebars', 'mobileDrawerOpen']
  });

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1)
    }, 20);
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0)
      setTimeout(resolve, 300);
    });
  }

  return (
    <StyledDrawer
      isOpen={drawerOpen}
      onBackgroundClick={toggleDrawer}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onEscapeKeydown={toggleDrawer}
      opacity={opacity}
      allowScroll={true}
      backgroundProps={{ opacity: opacity }}>
        <div>
          
        </div>
    </StyledDrawer>
  )
}


export default GlobalMobileDrawer;