import styled from 'styled-components';
import { variables } from 'theme/variables';
const {
  spacing_md,
  custom_light_grey,
} = variables;

export const DocumentWrapper = styled('div')`
  padding: ${spacing_md};
  border: 1px solid ${custom_light_grey};
  cursor: pointer;
`
