import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import FrequentlyAskedQuestionsSection from './components/FrequentlyAskedQuestionsSection/FrequentlyAskedQuestionsSection.js'
import QuestionsSection from './components/QuestionsSection/QuestionsSection.js'
import GetStarted from 'components/PublicPages/GetStarted';


import {
  FrequentlyAskedQuestionsPage
} from './FrequentlyAskedQuestions.styles';


const FrequentlyAskedQuestions = () => {
  
  return (
    <FrequentlyAskedQuestionsPage style={{padding: `0`, overflow: `hidden`}}>
      <FrequentlyAskedQuestionsSection />
      <QuestionsSection />
      <GetStarted />
    </FrequentlyAskedQuestionsPage>
  )
}


export default PageViewWrapper(FrequentlyAskedQuestions, {pageName: 'FrequentlyAskedQuestions'})