import React from 'react';
import { MD } from '@zendeskgarden/react-typography';
import useTableSort from 'hooks/useTableSort';
import {
  Table,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
} from '@zendeskgarden/react-tables';
import SidebarItemsTableRow from './components/SidebarItemsTableRow/SidebarItemsTableRow';

 
const SidebarItemsTable = (props) => {
  const { sortedData, isAscending, sortKey, toggleSoryKeyAndAscending } = useTableSort(
    props.data || [],
    "id"
  );


  return (
    <Table size="large" styling={false} style={props.style || {}}>
      <Head>
        <HeaderRow>
          <HeaderCell width="40%">
            <MD>Title</MD>
          </HeaderCell>

           <HeaderCell width="19%">
            <MD>Quantity</MD>
          </HeaderCell>

          <HeaderCell width="18%">
            <MD>Availability</MD>
          </HeaderCell>
          <HeaderCell width="15%">
            <MD>Need Date</MD>
          </HeaderCell>
          <HeaderCell width="8%"></HeaderCell>
        </HeaderRow>
      </Head>
      <Body>
        {sortedData.map((data, i) => (
          <SidebarItemsTableRow sortedData={sortedData} data={data} key={`items-${i}`} />
        ))}
      </Body>
    </Table>
  )
}





export default SidebarItemsTable

