import React, { useState } from 'react';
import Flex, { FlexItem } from 'styled-flex-component';
import { Row, Col } from '@zendeskgarden/react-grid';

import MoreFilters from '../MoreFilters/MoreFilters.js'
import TechnicalRequirements from '../TechnicalRequirements/TechnicalRequirements'

import {
  InventoryButtonItem,
  InventoryButtonTechnical,
  InventoryWrapper
} from '../../ItemSubmission.styles';
import { variables } from 'theme/variables';
const {
  color_white
} = variables;

const InventoryButtons = (props) => {
  const [display, setDisplay] = useState ("Item/Product")

  if(display === "Item/Product") {
    return (
      <Row>
        <Col md={12}>
          <Flex justifyCenter>
            <FlexItem>
              <InventoryWrapper>
                <InventoryButtonItem className="inventory-mobile-left item"onClick={() => setDisplay("Item/Product")} style={{borderBottom: `1px solid ${color_white}`}}>Item / Product</InventoryButtonItem>
                <InventoryButtonTechnical className="inventory-mobile-right"onClick={() => setDisplay("TecnicalRequirements")} style={{color: `rgba(255, 255, 255, 0.5)`}}>Technical Requirements</InventoryButtonTechnical>
              </InventoryWrapper>
              <MoreFilters />
            </FlexItem>
          </Flex>
        </Col>
      </Row>
    )
  } else
    return (
      <Row>
        <Col md={12}>
          <Flex justifyCenter>
            <FlexItem>
              <InventoryWrapper>
                <InventoryButtonItem className="inventory-mobile-left item"onClick={() => setDisplay("Item/Product")} style={{color: `rgba(255, 255, 255, 0.5)`}}>Item / Product</InventoryButtonItem>
                <InventoryButtonTechnical className="inventory-mobile-right"onClick={() => setDisplay("TecnicalRequirements")} style={{borderBottom: `1px solid ${color_white}`}}>Technical Requirements</InventoryButtonTechnical>
              </InventoryWrapper>
              <TechnicalRequirements />
            </FlexItem>
          </Flex>
        </Col>
      </Row>
    )
}

export default InventoryButtons;

