import React from 'react';
import { XXL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { HashLink as Link } from 'react-router-hash-link';

import {
  SideContainer,
  SideBarFloat,
  NavSideBar,
  DividingLineNav,
  Oval
} from '../../ViewItemDetails.styles';

const SideBar = (props) => {
  return (
    <SideBarFloat>
        <Grid>
          <NavSideBar> 
              <Link smooth to="/view-item-details#item-information"><a href="#" style={{display: `block`}}><Oval /><MD left tag="p">Item Information</MD></a></Link>
              <DividingLineNav />
              <Link smooth to="/view-item-details#technical"><a className="links" href="#" style={{display: `block`}}><MD left tag="p">Technical Information</MD></a></Link>
              <DividingLineNav />
              <Link smooth to="/view-item-details#availability"><a className="links" href="#" style={{display: `block`}}><MD left tag="p">Availability</MD></a></Link>
              <DividingLineNav />
              <Link smooth to="/view-item-details#documentation"><a className="links" href="#" style={{display: `block`}}><MD left tag="p">Documentation</MD></a></Link>
              <DividingLineNav />
              <Link smooth to="/view-item-details#similar-items"><a className="links" href="#" style={{display: `block`}}><MD left tag="p">Similar Items</MD></a></Link>
              <DividingLineNav />
            </NavSideBar>
          </Grid>
      </SideBarFloat>
  )
}

export default SideBar;