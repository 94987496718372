import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
import { Button } from '@zendeskgarden/react-buttons';
import { Section } from 'components/PublicPages/GlobalPublicStyles/';

const {
  custom_dark_blue,
  color_white,
  custom_dark_grey,
  spacing_sm,
  media_sm,
  media_md,
  media_lg,
  spacing,
  custom_button_blue,
  public_font_size_xxl,
  public_font_size_md
} = variables;


export const AccountCreatedPage = styled(Page)`

`;

export const AccountCreatedSection = styled(Section)`
  
  padding: 64px 0px 280px;
  position: relative;
  width: 100vw;
  height: auto;

  

  @media ${media_md} {
    padding: 80px 0px 440px;

    h1 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
    }
  }

  @media ${media_lg} {
    padding: 110px 0px 400px;
  }

`;

export const AccountCreatedButton = styled(Button)`
  position: relative;
  z-index: 10;
  padding: 20px 20px;
  border-radius: 8px;	
  margin: 40px 0 30px 0;
  width: 226px;
  background-color: ${custom_button_blue};
  box-shadow: 0 15px 30px -12px rgba(0,0,0,0.5);
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 14px;

  
`;

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
  width: 500px; 
  left: 0; 
  right: -76%;
  bottom: 0;
  top: 400px;
  margin: 0 auto; 
`

export const Logo = styled.img`
  height: auto;
  width: 230px;
  margin: 0 0 160px 0;

  @media ${media_md} {
    margin: 0 0 240px 0;
  }
`

export const TextWrapper = styled.div`
  position: relative;
  z-index: 10;

  h1 {
    padding: 0 ${spacing} ${spacing_sm};
    letterSpacing: 2px;
  }

  p {
    padding: ${spacing};
    color: rgba(255, 255, 255, 0.7);
  }

  @media ${media_md} {
    h1 {
      padding: 0 0 ${spacing_sm} 0;
      letterSpacing: 2px;
    }

    p {
      padding: ${spacing} 0 ${spacing} 0;
      color: rgba(255, 255, 255, 0.7);
    }
  }
`
