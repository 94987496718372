import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import RecoverForm from './components/RecoverForm/RecoverForm.js'



import {
  CantLoginPage
} from './CantLogin.styles';


const CantLogin = () => {
  
  return (
    <CantLoginPage style={{padding: `0`, overflow: `hidden`}}>
      <RecoverForm />
    </CantLoginPage>
  )
}


export default PageViewWrapper(CantLogin, {pageName: 'CantLogin'})