import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Button } from '@zendeskgarden/react-buttons';
import PublicPage from 'components/PublicPages/PublicPage'

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';


const {
  custom_dark_blue,
  color_white,
  media_md,
  media_lg,
  spacing,
  custom_button_blue,
  public_mobile_font_size_xs,
  public_font_size_sm,
  public_font_size_md
} = variables;

export const CompareItemsPage = styled(PublicPage)`
  
`;

export const TopSection = styled(Section)`

`;

export const CompareSection = styled(Section)`
  
`;

export const ItemsWrapper = styled.div`
  position: relative;
  height: 393px;
  width: 75vw;
  background-color: ${color_white};
  border-radius: 4px;

  p {
    font-size: ${public_font_size_sm};
  }
`

export const CompareTableWrapper = styled.div`
  margin: 0 24px;
  
  .table-header-row {
    padding: 0 0 16px 0;
    background-color: #E7EAEE;

    &:hover {
      background-color: #E7EAEE !important;
    }
  }

  .table-row {
    padding: 16px 0;

    h5 {
      font-size: 20px;
      line-height: 32px;
      color: #3E3E3E;
    }

    &:hover {
      background-color: transparent !important;
    }
  }
`

export const TableScrollWrapper = styled.div`
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  clear: both;
`

export const TableWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  clear: both;
  margin: 0 24px;
  border-left: 1px solid #EFEFEF;
  border-right: 1px solid #EFEFEF;
`

export const Sticky = styled.div`
  position: sticky;
  top: 0;
  height: 73px;
  background-color: ${custom_button_blue}

  h3 {
    font-size: 22px;
    line-height: 29px;
    position: relative;
    top: -52px;
    left: 56px;
    color: FFF;
  }
`

export const PipesWrapper = styled.div`
  width: 100%; 
  height: 100%; 
  top: 0; 
  left: 0; 
  position: absolute;
  overflow: hidden; 

  .left-pipes {
     width: 500px; 
     left: -99%; 
     right: 0; 
     bottom: 0; 
     top: 31vh;
     margin: 0 auto; 
  }

  .right-pipes {
     width: 500px; 
     left: 0; 
     right: -80%; 
     bottom: 0; 
     top: 24vh;
     margin: 0 auto; 
  }
`


export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
`

export const CompareWrapper = styled.div`
  position: relative;
  top: -400px;
  height: auto;
  margin: 0 24px;
  background-color: ${color_white};
  border-radius: 4px;
  box-shadow: 0 30px 50px 0 rgba(68,89,109,0.1);

  p {
    font-size: ${public_font_size_sm};
  }
`

export const Confidence = styled.div`
  margin-left: 10px;
  height: 21px;
  width: 52px;
  border-radius: 4px;
  color: ${color_white};
  font-size: ${public_mobile_font_size_xs};
  line-height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Availability = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
	height: 48px;
	width: 28%;
	border-radius: 4px 4px 0 0;
  background-color: ${custom_button_blue}
  
  p {
    position: relative;
    top: 15px;
    left: 5px;
    color: ${color_white};
    font-size: ${public_font_size_md};
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
  }
`

export const AvailabilityWrapper = styled.div`
  height: 48px;
  width: 30%;
  min-width: 300px;
	border-radius: 4px 4px 0 0;
  background-color: ${custom_button_blue}
  // float: right;

  position: absolute;
  right: 0;
  top: 1px;
  float: none;

  p {
    padding: 0 0 ${spacing} 0;
    letter-spacing: 0.32px;
    margin: 10px 0 0 20px; 
    font-weight: normal;
    color: ${color_white};
  }

  @media ${media_md} {
    margin-top: 0;
  }
`
export const AvailabilityBlur = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
	background-color: white;
  opacity: 0.9;

  height: 100%;
  width: 100%;
  top: 0;


  filter: blur(100%);
  -webkit-filter: blur(100%);
	-moz-filter: blur(100%);
	-o-filter: blur(100%);
  -ms-filter: blur(100%);
  
  @media ${media_md} {
    // height: 916px;
  }

  @media ${media_lg} {
    // right: 0;
    // top: 214px;
    // height: 780px;
  }
`

export const CancelWrapper = styled.div`

  p {
    padding: 0 0 5px 0;
    letterSpacing: 0.29px;
    fontWeight: normal;
    position: relative; 
    top: 98px; 
    left: 112px;
  }
`

export const CriteriaInput = styled.input`
  width: 257px;
  padding: 10px 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${color_white};
  color: ${color_white};

  ::placeholder {
      font-size: ${public_font_size_sm};
      color: rgba(255, 255, 255, 0.8);
    }

`

export const CriteriaWrapper = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  max-width: 90%;
  min-width: 330px;
  max-height: 260px;
  height: auto;

  position: absolute;
  padding: 0 20px;
  z-index: 3;
	border-radius: 4px;
	background-color: ${custom_button_blue}
  box-shadow: 0 5px 25px 0 rgba(68,89,109,0.2);


  
  h5 {
    font-size: 20px;
    line-height: 14px;
    font-weight: bold;
    color: ${color_white};
    padding: 0;
    margin-top: 0;
    margin-bottom: 16px;
  }

  p {
    color: ${color_white};
    font-size: 11px;
    line-height: 20px;
    width: 284px;
    margin-bottom: 16px;
  }

  button {
    position: relative; 
    margin: 0 auto;
    height: 48px;
    width: 131px;
    border-radius: 4px;
    background-color: ${color_white};
    box-shadow: 0 10px 15px -7px rgba(31,44,61,0.35);
  }
`

export const FieldsWrapper = styled.div`
  margin-top: 40px;
`

export const InventoryResultsButton = styled(Button)`
  padding: 0;
  height: 48px; 
  width: 186px;
  border-radius: 8px;	
  background-color: ${color_white};	
  box-shadow: 0 10px 15px -7px rgba(31,44,61,0.35);
  color: ${custom_button_blue}
  border: 1px solid ${custom_button_blue}
  outline: none;
  font-size: ${public_font_size_sm};
  font-weight: normal;
  line-height: 14px;
  left: 50%;
  transform: translateX(-50%);

   &:hover {
    background-color: ${color_white} !important;
    color: ${custom_button_blue} !important;
  }

`;

export const GetPricingButton = styled(Button)`
  position: relative;
  padding: 0;
  left: 28px;
  top: -8px;
  height: 34px;	
  width: 92px;	
  border: 1px solid ${custom_button_blue}	
  border-radius: 4px;	
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;


  &:hover {
    background-color: ${custom_button_blue} !important;
  }

`;

export const BackButton = styled(Button)`
  position: absolute;
  z-index: 10;
  padding: 0 0 0 24px;
  left: 24px;
  top: 96px;
  height: 46px;	
  width: 167px;	
  border: 1px solid ${color_white};
  border-radius: 4px;
  color: ${color_white};
  font-size: ${public_font_size_md};
  font-weight: normal;
  line-height: 14px;


  &:hover {
    background-color: ${custom_button_blue} !important;
  }


  @media ${media_md} {
    left: 24px;
    top: 172px;
  }
`;

export const LocationWrapper = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  max-width: 90%;
  min-width: 330px;
  height: 334px;

  position: absolute;
  padding: 0 20px;
  z-index: 3;
	border-radius: 4px;
	background-color: ${custom_button_blue}
  box-shadow: 0 5px 25px 0 rgba(68,89,109,0.2);
  
  h5 {
    color: ${color_white};
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    padding: 0;
    margin-bottom: 0;
  }

  button {
    position: relative; 
    top: 32px;
    margin: 0 auto;
    display: block;
    height: 48px;
    width: 131px;
    border-radius: 8px;
    background-color: ${color_white};
    box-shadow: 0 10px 15px -7px rgba(31,44,61,0.35);
  }
`

export const NoBlur = styled.div`

`

export const BlurWrapper = styled.div`

  @media ${media_lg} {
    position: relative;
  }
`

export const ConfidenceLarge = styled.div`
  position: relative;
  top: 0px;
  left: 12px;
  height: 21px;
  width: 52px;
  border-radius: 4px;
  color: ${color_white};
  font-size: ${public_mobile_font_size_xs};
  line-height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OverflowContainer = styled.div`
  width: 1400px;
  height: auto;
  box-sizing: border-box;
  overflow: auto;
  padding: 0px 0px 0px 0px;

  @media ${media_lg} {
    width: auto;
  }
`

export const TableSidebar = styled.div`
  width: 30%;
  height: calc(100% - 99px);
  position: absolute;
  right: 0;
  top: 98px;
  padding: 0px 40px;
  border-left: 1px solid #EFEFEF;

`;
