import React from 'react';
import { variables } from 'theme/variables';
import Flex from 'styled-flex-component';
import { Grid, Row } from '@zendeskgarden/react-grid';
import { SM } from '@zendeskgarden/react-typography';

import { Link } from 'react-router-dom';
import { Item } from '@zendeskgarden/react-menus';

import {
  AppFooterComponent,
  FooterCol,
  Logo,
  FooterDesc,
} from './app-footer.styles.js';

const { logo_url } = variables;

const AppFooter = () => {
  return (
    <AppFooterComponent>
      <Grid fluid>
        <Row>
          <FooterCol size={12} md={4}>
            <Flex alignCenter>
              <Logo src={logo_url} />
            </Flex>

            <FooterDesc style={{ maxWidth: '300px' }} paddingTopSm>
              Text of the printing and typesetting industry. Lorem Ipsum has
              been the industry's standard dummy text ever.{' '}
            </FooterDesc>

            <SM paddingTopSm>© 2022 Global Pipe Co.</SM>
          </FooterCol>

          <FooterCol size={12} md={5}>
            <Item>
              <Link to='/privacy-policy'>Privacy policy</Link>
            </Item>

            <Item>
              <Link to='/terms'>Terms & Conditions</Link>
            </Item>

            <Item>
              <Link to='/contact'>Contact Us</Link>
            </Item>
          </FooterCol>

          <FooterCol size={12} md={3}>
            <Item>
              <Link to='/company'>Company</Link>
            </Item>

            <Item>
              <Link to='/why-us'>Why Pipe Search</Link>
            </Item>
          </FooterCol>
        </Row>
      </Grid>
    </AppFooterComponent>
  );
};

export default AppFooter;
