import { useState } from 'react';
import _ from 'lodash';

export default function useTableSort(someArray, initialSortKey) {
  const [state, setState] = useState({
    isAscending: true,
    sortKey: initialSortKey
  });

  const sortedData = sortData(someArray, state)
  return {
    ...state,
    sortedData,
    toggleAscending: () =>
      setState(state => ({
        ...state,
        isAscending: !state.isAscending
      })),
    setSortKey: sortKey => setState(state => ({ ...state, sortKey })),
    
    toggleSoryKeyAndAscending: (sortKey) => {
      setState(state => ({
        ...state,
        isAscending: sortKey === state.sortKey ? !state.isAscending : false,
        sortKey
      }));

    }
  };
};

function sortData(someArray, state) {
  return _.orderBy(someArray, state.sortKey, state.isAscending ? 'asc' : 'desc');
}