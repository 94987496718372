import fetchJson from 'utility/fetchJson';
import {BASE} from '../../constants';

export async function getToken() {
  let token = await fetch(`${BASE}/api/auth/token/`);
  token = await token.json();
  return token;     
}

export async function signIn(data) {
  const response = await fetchJson(`${BASE}/api/auth/login/`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return response
}

export async function logout() {
  const response = await fetchJson(`${BASE}/api/auth/logout/`, {
    method: 'POST'
  })
  return response
}

export async function forgotPassword(data) {
  const response = await fetchJson(`${BASE}/api/auth/password/reset/`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return response
}

export async function checkSession() {
  const response = await fetchJson(`${BASE}/api/auth/session/`, {
    method: 'GET'
  })
  return response
}