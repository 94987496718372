import Baobab from 'baobab';
import _ from 'lodash';
import getInitialState from './getInitialState';
import {canUseDOM} from 'exenv';

const options = {
  immutable: true,
  pure: false,
  persistent: false
}

let tree;
if (canUseDOM) {
  const {cursors, facets} = getInitialState(window.__INITIAL_STATE__);
  tree = new Baobab(_.merge(cursors, facets), options);
} else {
  tree = new Baobab({}, options);
}

export default tree;