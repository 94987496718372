import {variables} from 'theme/variables';
const {
  custom_blue,
} = variables;

const MenuTheme = {
  'tags.tag_view': props => {
    return `
      ${props.absolute ? `
        position: absolute;
        top: 0;
        right: 0;     
      ` : ''}

      ${props.button ? `
        top: -10px;
        right: -10px;
      ` : ''}

      ${props.circle ? `
        border-radius: 100px !important;
      ` : ''}

      ${props.circle && props.size === "small" ? `
        min-width: 24px;
        min-height: 24px;
      ` : ''}

      ${props.circle && props.xs ? `
        min-width: 19px !important;
        max-width: 19px !important;
        min-height: 19px !important;
        max-height: 19px !important;
        font-size: 8px !important;
      ` : ''}

      ${props.xs && !props.circle ? `
        font-size: 8px !important;
        height: 16px !important;
      ` : ''}

      ${props.blue ? `
        background-color: ${custom_blue} !important;
        color: #fff !important;
      ` : ''}

      ${props.color ? `
        background-color: ${props.color} !important;
        color: #fff !important;
      ` : ''}


      ${props.shadow ? `
        box-shadow: 0px 4px 5px rgba(66, 100, 251, 0.31);
      ` : ''}

      ${props.center ? `
        text-align: center;
        justify-content: center;
      ` : ''}

      ${props.uppercase ? `
        text-transform: uppercase;
      ` : ''}

      ${props.size === 'small' ? `
        padding: 0 6px !important;
        font-size: 9px;
        height: 17px;
        font-size: 9px !important;
      ` : ''}

      ${props.size === 'medium' ? `
        padding: 0 6px !important;
        font-size: 9px;
        height: 17px;
        font-size: 9px !important;
      ` : ''}


    `;

  },
};

export default MenuTheme;
