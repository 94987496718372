import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
import { Button } from "@zendeskgarden/react-buttons";

const {
  custom_dark_blue,
  custom_button_blue,
  color_white,
  custom_dark_grey,
  media_md,
  public_wrapper_width,
  section_padding,
  section_padding_sm,
  public_font_size_md,
  public_font_weight_bold,
} = variables;

export const PublicPage = styled(Page)`
  padding: 0;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  max-width: ${public_wrapper_width};
  margin: 0 auto;
  width: 100%;
`;


export const Section = styled.section`
  background-color: ${props => props.dark ? custom_dark_blue : color_white};
  padding: ${section_padding_sm} 0px ${section_padding_sm};
  @media ${media_md} {
    padding: ${section_padding} 0px ${section_padding};
  }
  position: relative;

  h1, h2, h3, h4, p {
    color: ${props => props.dark ? color_white : custom_dark_grey};
  }
`;

export const SectionArrow = styled.div`
  position: absolute;
  width: 100%;
  top: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;

  &:after {
    content: '';
    background-color: ${custom_dark_blue};
    width: 100px;
    height: 100px;
    border-bottom-right-radius: 20px;
    transform: rotate(45deg);
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  @media ${media_md} {
    top: 30px;
    &:after {
      width: 160px;
      height: 160px;
    }
    
  }
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
`;

export const BackgroundImg = styled.img`
  position: relative;
  height: auto;
`;


export const PublicButton = styled(Button)`
  padding: 20px 60px;
  border-radius: 8px;	
  background-color: ${custom_button_blue};	
  box-shadow: 0 15px 30px -12px rgba(0,0,0,0.5);
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: ${public_font_weight_bold};
  line-height: 21px;
`;

export const BackgroundCircleXL = styled.div`
  position: absolute;
  height: 1200px;	
  width: 1200px;	
  border: 200px solid rgba(1, 90, 208, 0.05);
  border-radius: 50%;
`

export const BackgroundCircleLG = styled.div`
  height: 745px;	
  width: 746px;	
  border: 120px solid #F2F5F9;
  border-radius: 50%;
`

export const BackgroundCircleMD = styled.div`
  position: absolute;
  height: 569px;	
  width: 569px;	
  border: 120px solid rgba(1, 90, 208, 0.48);
  border-radius: 50%;
`

export const BackgroundCircleSM = styled.div`
  box-sizing: border-box;	
  height: 354px;	
  width: 354px;	
  border: 60px solid ${color_white};	
  border-radius: 50%;
`
