import React from 'react';
import styled from 'styled-components';

import {variables} from 'theme/variables';

const {
  media_md
} = variables;

const DividingLine = (props) => {
  let margin = '';
  if (props.left) {
    margin = '0 0 70px';
  } else if (props.right) {
    margin = '0 0 70px auto';
  } else {
    margin = '0 auto 70px';
  }

  const SvgWrapper = styled.div`
    width: 110px;
    margin: 0 auto 35px;
    overflow: hidden;

    @media ${media_md} {
      width: 210px;
        margin: ${margin};
    }
    path {
      stroke: ${props.light ? "rgba(255,255,255,0.27)" : "rgba(65,65,65,0.27)"};
      
    }
    svg {
      clip-path: ${props => props.animated === false ? `inset(-2px 106px -2px 106px)` : ``};
    }
    
  `;

  return (
    <div>
      <SvgWrapper className="dividing-line" animated={props.animated}>
        <svg width="212px" height="3px" viewBox="0 0 212 3" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0.5L210 0.5" transform="translate(1 1)" fill="none" strokeWidth="2" strokeLinecap="round" strokeDasharray="1 6" />
        </svg>
      </SvgWrapper>
    </div>
  )
};

export default DividingLine;
