import React, { useState, useEffect, useRef } from 'react';
import { variables } from 'theme/variables';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';
import Icon from 'components/Icon/Icon';
import anime from 'animejs';
import { Waypoint } from 'react-waypoint';
import { Link } from 'react-router-dom';
import MobileMenu from './components/MobileMenu/MobileMenu';
import { withRouter } from 'react-router-dom';
import {
  PublicHeaderComponent,
  Menu,
  MenuItem,
  Logo,
  HeaderRight,
  Dropdown,
  MobileMenuTrigger,
} from './PublicHeader.styles.js';
import SearchOverlay from './components/SearchOverlay/SearchOverlay';
import InventoryOverlay from './components/InventoryOverlay/InventoryOverlay';

import SVGLogo from './components/SVGLogo';

const { color_white, custom_pale_blue } = variables;

const PublicHeader = (props) => {
  const [animated, setAnimated] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [mmActive, setmmActive] = useState(false);
  const animation = useRef();
  const scrollY = useRef();
  const [direction, setDirection] = useState();
  const [mmScroll, setmmScroll] = useState(0);
  const [searchOverlayActive, activateSearchOverlay] = useState(false);
  const [inventoryOverlayActive, activateInventoryOverlay] = useState(false);
  const animationTimer = useRef();

  useEffect(() => {
    window.addEventListener('scroll', evalScroll);

    animation.current = anime.timeline({
      autoplay: false,
      complete: function () {
        setAnimated(true);
      },
    });
    animation.current.add(
      {
        targets: '.animate-logo',
        opacity: ['0', '1'],
        duration: 1200,
        easing: 'linear',
      },
      200
    );
    animation.current.add(
      {
        targets: '.menu-1 li .inner > span, .menu-1 li .inner > a',
        translateY: [40, 0],
        duration: 600,
        easing: 'cubicBezier(.22,.27,.1,.74)',
        delay: anime.stagger(100),
      },
      200
    );
    animation.current.add(
      {
        targets: '.menu-2 li .inner > *',
        opacity: ['0', '1'],
        duration: 1200,
        easing: 'linear',
      },
      200
    );
    animation.current.add(
      {
        targets: '.header-button',
        opacity: ['0', '1'],
        easing: 'linear',
        duration: 1200,
      },
      200
    );
    animation.current.add(
      {
        targets: '.mm-trigger span',
        opacity: {
          value: 1,
          duration: 500,
          easing: 'linear',
        },
        delay: anime.stagger(50),
      },
      500
    );

    return () => {
      window.removeEventListener('scroll', evalScroll);
      animation.current.pause();
      animation.current = null;
      clearInterval(animationTimer.current);
    };
  }, []);

  useEffect(() => {
    let timer = null;

    if (mmActive) {
      setmmScroll(window.scrollY);
      timer = setTimeout(function () {
        document.body.style.overflowY = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
      }, 700);
    } else if (!mmActive) {
      document.body.style.overflowY = '';
      document.documentElement.style.overflowY = '';
      window.scrollTo(0, mmScroll);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [mmActive]);

  useEffect(() => {
    if (mmActive) {
      setmmActive(false);
    }
  }, [props.location]);

  useEffect(() => {
    let timer = null;
    if (searchOverlayActive) {
      setmmScroll(window.scrollY);
      timer = setTimeout(function () {
        document.body.style.overflowY = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
      }, 700);
    } else if (!searchOverlayActive) {
      document.body.style.overflowY = '';
      document.documentElement.style.overflowY = '';
      window.scrollTo(0, mmScroll);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [searchOverlayActive]);

  useEffect(() => {
    let timer = null;
    if (inventoryOverlayActive) {
      setmmScroll(window.scrollY);
      timer = setTimeout(function () {
        document.body.style.overflowY = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
      }, 700);
    } else if (!inventoryOverlayActive) {
      document.body.style.overflowY = '';
      document.documentElement.style.overflowY = '';
      window.scrollTo(0, mmScroll);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [inventoryOverlayActive]);

  function toggleMM() {
    setmmActive(!mmActive);
  }

  function evalScroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > scrollY.current && winScroll > 100) {
      setDirection('down');
    } else {
      setDirection('up');
    }
    scrollY.current = winScroll;

    if (winScroll > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  function animate() {
    if (!animated) {
      animationTimer.current = setTimeout(function () {
        animation.current.play();
      }, 500);
    }
  }

  function toggleSearch() {
    activateSearchOverlay(!searchOverlayActive);
  }
  function toggleInventory() {
    activateInventoryOverlay(!inventoryOverlayActive);
  }

  return (
    <PublicHeaderComponent
      scrolled={scrolled && !mmActive}
      mmActive={mmActive}
      scrollDirection={direction === 'down'}
      transparent={props.transparent}
    >
      <Waypoint onEnter={animate} />
      <div className='header-inner'>
        <Grid className='header-grid'>
          <Row className='header-row'>
            <Col xl={3} lg={3} md={6} sm={6} size={6} className='logo-col'>
              <Link to='/'>
                <Logo className='animate-logo'>
                  <SVGLogo white={!scrolled || mmActive} />
                </Logo>
              </Link>
            </Col>
            <Col size={6} xl={6} className='primary-menu-col'>
              <Menu className='animate-menu menu-1 menu'>
                <MenuItem>
                  <div className='inner'>
                    <span onClick={toggleInventory}>Find Inventory</span>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className='inner'>
                    <Link to='/why-us'>Why Us</Link>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className='inner'>
                    <Link to='/services'>Services</Link>
                  </div>
                </MenuItem>
                <MenuItem hasDropdown>
                  <div className='inner'>
                    <span>Technical Resources</span>
                  </div>
                  <Dropdown>
                    <ul>
                      <MenuItem>
                        <Link to='/blog'>Blog</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to='/faq'>FAQ</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to='/help-center'>Help Center</Link>
                      </MenuItem>
                    </ul>
                  </Dropdown>
                </MenuItem>
                <MenuItem>
                  <div className='inner'>
                    <Link to='/contact'>Contact</Link>
                  </div>
                </MenuItem>
              </Menu>
            </Col>

            <Col size={6} xl={3} lg={3} className='secondary-menu-col'>
              <HeaderRight>
                <Menu className='menu-2 menu'>
                  <MenuItem>
                    <div className='inner'>
                      <span
                        onClick={toggleSearch}
                        style={{ display: `block`, opacity: `0` }}
                      >
                        <Icon
                          icon='icon-search-left'
                          color={scrolled ? custom_pale_blue : color_white}
                        />
                      </span>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className='inner'>
                      {/* <Link to="/login">Sign In</Link> */}
                    </div>
                  </MenuItem>
                  <Button
                    headerButton
                    className='header-button'
                    style={{ opacity: `0` }}
                  >
                    Get Started
                  </Button>
                </Menu>
              </HeaderRight>
            </Col>
          </Row>
        </Grid>
      </div>
      <MobileMenuTrigger
        onClick={() => toggleMM()}
        active={mmActive}
        scrolled={scrolled}
        className='mm-trigger'
      >
        <span />
        <span />
        <span />
      </MobileMenuTrigger>
      <MobileMenu active={mmActive} />

      <SearchOverlay toggleSearch={toggleSearch} active={searchOverlayActive} />
      <InventoryOverlay
        toggleInventory={toggleInventory}
        active={inventoryOverlayActive}
      />
    </PublicHeaderComponent>
  );
};

export default withRouter(PublicHeader);
