import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import Search from './components/Search.js'



import {
  SearchTemplatePage
} from './SearchTemplate.styles';


const SearchTemplate = () => {
  
  return (
    <SearchTemplatePage>
      <Search />
    </SearchTemplatePage>
  )
}


export default PageViewWrapper(SearchTemplate, {pageName: 'SearchTemplate'})