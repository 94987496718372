import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
import { Button } from '@zendeskgarden/react-buttons';

const {

  color_white,

  media_sm,
  media_md,

  spacing,
  custom_button_blue,
  public_font_size_xxl,
  public_font_size_md
} = variables;



export const CantLoginPage = styled(Page)`
  
`;

export const Section = styled.section`
  padding: 64px 0 280px 0;
  position: relative;
  width: 100vw;
  height: auto;


 
  
  p {
    padding: ${spacing} 0 ${spacing} 0;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.7);
  }

  .login {
    color: ${custom_button_blue}
    fontSize: 14px;
  }

  .trouble {
    color: #a0a0a0;
    fontSize: 14px; 
    padding: ${spacing} 0 0 0;
  }

  @media ${media_sm} {
    padding: 80px 0 280px 0;
  }

  @media ${media_md} {
    padding: 100px 0 280px 0;

    h1 {
      font-size: ${public_font_size_xxl};
      line-height: 56px;
    }
  }

`;

export const Logo = styled.img`
  height: 44px;
  width: 230px;
  margin-bottom: 30px;
`

export const LoginForm = styled.form`
  position: relative;
  z-index: 2;
  height: 443px;	
  width: 90vw;	
  margin-top: 40px;
  border-radius: 4px;	
  background-color: ${color_white};	
  box-shadow: 0 30px 50px 0 rgba(68,89,109,0.1);

  @media ${media_sm} {
    height: 443px;	
    width: 480px;	
  }
`

export const FormFieldWrapper = styled.div`
  height: 117px;
  padding: 0 0 0 64px
  width: 75vw;
  margin: 56px 0 15px 0;
  input {
    border: none;
    border-bottom: 1px solid #D0D0D0;
    width: 100%;
    padding-bottom: 10px;
  }

  h5 {
    padding-bottom: 24px;
  }

  @media ${media_sm} {
    width: 380px;
    input {
      width: 360px;
    }
  }

  @media ${media_md} {
    input {
      width: 360px;
    }
  }
`

export const CantLoginButton = styled(Button)`
  padding: 20px 60px;
  border-radius: 8px;	
  margin-bottom: 30px;
  width: 203px;
  background-color: ${custom_button_blue};
  color: ${color_white};
  border: 0;
  outline: none;
  font-size: ${public_font_size_md};
  font-weight: bold;
  line-height: 21px;
`;

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
  width: 500px; 
  left: 0; 
  right: -74%;
  bottom: 0 
  top: 330px
  margin: 0 auto; 
`

export const Line = styled.div`
	box-sizing: border-box;
	height: 2px;
	width: 34px;
  border: 1px solid #EAEAEA;
  margin: -16px 0 8px 0;
`
