import React from 'react';
import Icon from 'components/Icon/Icon';
import {
  TableWrapper,
  TableScrollWrapper
} from '../../ViewItemDetails.styles.js';

import {
  Table,
  Caption,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@zendeskgarden/react-tables';

import { variables } from 'theme/variables';
const {
  custom_button_blue
} = variables;


const SimilarItemsTable = (props) => {
  return (
    <TableWrapper>
     <TableScrollWrapper>
      <Table style={{minWidth: `1140px`}}>
          <Head>
            <HeaderRow className="table-header-row">
              <HeaderCell width="25%">Item</HeaderCell>
              <HeaderCell width="9%">Brand</HeaderCell>
              <HeaderCell width="10%">Manufacturer</HeaderCell>
              <HeaderCell width="9%">Min-Burst</HeaderCell>
              <HeaderCell width="10%">Min-Collapse</HeaderCell>
              <HeaderCell width="14%">Body Yield Strength</HeaderCell>
              <HeaderCell width="8%">Drift</HeaderCell>
              <HeaderCell width="8%">PREN</HeaderCell>
              <HeaderCell width="7%">MTR</HeaderCell>
            </HeaderRow>
          </Head>
          <Body>
            <Row className="table-row">
              <Cell width="25%"><a href="#">OCTG 2.375" 5.8# 22CR 130 VAM ACE</a></Cell>
              <Cell width="9%">CRA-P</Cell>
              <Cell width="10%">Sumitomo</Cell>
              <Cell width="9%">10,000 PSI</Cell>
              <Cell width="10%">7,000 PSI</Cell>
              <Cell width="14%">16.45</Cell>
              <Cell width="8%">9.404"</Cell>
              <Cell width="8%">16.45</Cell>
              <Cell width="7%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="25%"><a href="#">OCTG 2.375" 5.8# 22CR 130 VAM ACE</a></Cell>
              <Cell width="9%">CRA-P33</Cell>
              <Cell width="10%">Sumitomo</Cell>
              <Cell width="9%">10,000 PSI</Cell>
              <Cell width="10%">7,000 PSI</Cell>
              <Cell width="14%">16.45</Cell>
              <Cell width="8%">9.404"</Cell>
              <Cell width="8%">16.45</Cell>
              <Cell width="7%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="25%"><a href="#">OCTG 2.375" 5.8# 22CR 130 VAM ACE</a></Cell>
              <Cell width="9%">CRA-P8</Cell>
              <Cell width="10%">Sumitomo</Cell>
              <Cell width="9%">10,000 PSI</Cell>
              <Cell width="10%">7,000 PSI</Cell>
              <Cell width="14%">16.45</Cell>
              <Cell width="8%">9.404"</Cell>
              <Cell width="8%">16.45</Cell>
              <Cell width="7%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="25%"><a href="#">OCTG 2.375" 5.8# 22CR 130 VAM ACE</a></Cell>
              <Cell width="9%">CRA-P69</Cell>
              <Cell width="10%">Sumitomo</Cell>
              <Cell width="9%">10,000 PSI</Cell>
              <Cell width="10%">7,000 PSI</Cell>
              <Cell width="14%">16.45</Cell>
              <Cell width="8%">9.404"</Cell>
              <Cell width="8%">16.45</Cell>
              <Cell width="7%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="25%"><a href="#">OCTG 2.375" 5.8# 22CR 130 VAM ACE</a></Cell>
              <Cell width="9%">CRA-P65</Cell>
              <Cell width="10%">Sumitomo</Cell>
              <Cell width="9%">10,000 PSI</Cell>
              <Cell width="10%">7,000 PSI</Cell>
              <Cell width="14%">16.45</Cell>
              <Cell width="8%">9.404"</Cell>
              <Cell width="8%">16.45</Cell>
              <Cell width="7%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
            </Row>
          </Body>
        </Table>
      </TableScrollWrapper>
    </TableWrapper>
  )
}

export default SimilarItemsTable;
