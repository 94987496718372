import React, { useState } from 'react';
import { MD } from '@zendeskgarden/react-typography';
import { Grid, Col } from '@zendeskgarden/react-grid';
import Icon from 'components/Icon/Icon';

import {
  ResultsWrapper,
  ResultsSection,
  DividingLine,
  TopWrapper,
  ToggleButton,
  ConfidenceLarge,
  VerticalLine,
  InventoryResultsButtonAdjust,
  AvailabilityWrapper,
  AvailabilityBlur,
  CriteriaWrapper,
  CompareItemsWrapper,
  FiltersWrapper,
  CompareWrapper,
  TableWrapper,
  TableScrollWrapper,
  TechnicalDataWrapper,
  PaginationWrapper
} from '../../FindInventoryResults.styles.js';

import {
  Table,
  Caption,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@zendeskgarden/react-tables';

import { Tooltip } from '@zendeskgarden/react-tooltips';

import CriteriaBlur from '../CriteriaBlur/CriteriaBlur';
import { variables } from 'theme/variables';
const {
  custom_button_blue
} = variables;

const ResultsComponent = (props) => {

  return (
    <ResultsSection>
      <ResultsWrapper>
        <TableWrapper>
        <Grid>
          <Row className="table-row-top">
              <MD left tag="h5" className="showing">Showing <b>40</b> Results</MD>
              <CompareItemsWrapper>
                <FiltersWrapper className="filter-wrapper">
                  <a href="#"><MD left className="Filters" tag="p">Filters</MD>
                  <Icon icon="icon-settings" color={custom_button_blue} style={{position: `relative`, top: `-45px`, left: `-10px`, fontSize: `18px`}}/></a>
                </FiltersWrapper>
                <CompareWrapper className="compare-wrapper">
                  <a href="#"><MD left className="Compare" tag="p">Compare Items</MD>
                  <Icon icon="icon-icon-compare" color={custom_button_blue} style={{position: `relative`, top: `-44px`, left: `-4px`}}/></a>
                </CompareWrapper>
              </CompareItemsWrapper>
              <InventoryResultsButtonAdjust className="mobile-adjust adjust-left">Adjust Search Criteria</InventoryResultsButtonAdjust>
          </Row>
        </Grid>
        <TableScrollWrapper>
          <Table className="results-table" style={{ minWidth: `1024px`, position: `relative` }}>
            <CriteriaBlur />
          <Head style={{ position: `relative`, paddingTop: `50px` }}>
              <AvailabilityWrapper>
                <MD left tag="p">Availability</MD>
              </AvailabilityWrapper>
            <HeaderRow className="table-header-row">
              <HeaderCell width="15%">Compare</HeaderCell>
              <HeaderCell width="55%">Item</HeaderCell>
              <HeaderCell width="22%">Brand</HeaderCell>
              <HeaderCell width="22%">Manufacturer</HeaderCell>
              <HeaderCell width="22%">Technical</HeaderCell>
              <HeaderCell width="22%">MTR</HeaderCell>
              <HeaderCell width="22%">Quantity</HeaderCell>
              <HeaderCell width="22%">Lead Time</HeaderCell>
              <HeaderCell width="22%">Confidence</HeaderCell>
            </HeaderRow>
          </Head>
          <Body>
            <Row className="table-row">
              <Cell width="15%"><ToggleButton type="checkbox"/></Cell>
              <Cell width="55%">OCTG 2.375" 5.8# 22CR 130 VAM ACE</Cell>
              <Cell width="22%">CRA-P</Cell>
              <Cell width="22%">Sumitomo</Cell>
              <Cell width="22%"><Tooltip className="tooltip"
                                  placement="end"
                                  type="light"
                                  size="extra-large"
                                  trigger={<Icon icon="icon-information" color={custom_button_blue} style={{position: `relative`, left: `28px`}}/>}
>
                                  <MD tag="h5" className="technical-data h5" style={{fontSize: `16px`, fontWeight: `bold`, color: `#3E3E3E`}}>Technical Data</MD>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-td" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>BurstPressure</h6>
                                    <p className="p">10,959 PSI</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px 30px 8px 48px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Collapse Strength</h6>
                                    <p className="p">7,504 PSI</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-bys" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Body Yield Strength</h6>
                                    <p className="p">2,088 LBS</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px -8px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Drift</h6>
                                    <p className="p">9.404”</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  </Tooltip>
              </Cell>
              <Cell className="cell-border-right" width="22%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
              <Cell width="22%">15,000</Cell>
              <Cell width="22%">Lead Time</Cell>
              <Cell width="22%"><ConfidenceLarge style={{backgroundColor: `#38D79F`}}>High</ConfidenceLarge></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="15%"><ToggleButton type="checkbox"/></Cell>
              <Cell width="55%">OCTG 2.375" 5.8# 22CR 130 VAM ACE</Cell>
              <Cell width="22%">CRA-P33</Cell>
              <Cell width="22%">Sumitomo</Cell>
              <Cell width="22%"><Tooltip className="tooltip"
                                  placement="end"
                                  type="light"
                                  size="extra-large"
                                  trigger={<Icon icon="icon-information" color={custom_button_blue} style={{position: `relative`, left: `28px`}}/>}
>
                                  <MD tag="h5" className="technical-data h5" style={{fontSize: `16px`, fontWeight: `bold`, color: `#3E3E3E`}}>Technical Data</MD>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-td" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>BurstPressure</h6>
                                    <p className="p">10,959 PSI</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px 30px 8px 48px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Collapse Strength</h6>
                                    <p className="p">7,504 PSI</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-bys" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Body Yield Strength</h6>
                                    <p className="p">2,088 LBS</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px -8px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Drift</h6>
                                    <p className="p">9.404”</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  </Tooltip>
              </Cell>
              <Cell className="cell-border-right" width="22%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
              <Cell width="22%">15,000</Cell>
              <Cell width="22%">Lead Time</Cell>
              <Cell width="22%"><ConfidenceLarge style={{backgroundColor: `#38D79F`}}>High</ConfidenceLarge></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="15%"><ToggleButton type="checkbox"/></Cell>
              <Cell width="55%">OCTG 2.375" 5.8# 22CR 130 VAM ACE</Cell>
              <Cell width="22%">CRA-P8</Cell>
              <Cell width="22%">Sumitomo</Cell>
              <Cell width="22%"><Tooltip className="tooltip"
                                  placement="end"
                                  type="light"
                                  size="extra-large"
                                  trigger={<Icon icon="icon-information" color={custom_button_blue} style={{position: `relative`, left: `28px`}}/>}
>
                                  <MD tag="h5" className="technical-data h5" style={{fontSize: `16px`, fontWeight: `bold`, color: `#3E3E3E`}}>Technical Data</MD>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-td" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>BurstPressure</h6>
                                    <p className="p">10,959 PSI</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px 30px 8px 48px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Collapse Strength</h6>
                                    <p className="p">7,504 PSI</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-bys" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Body Yield Strength</h6>
                                    <p className="p">2,088 LBS</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px -8px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Drift</h6>
                                    <p className="p">9.404”</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  </Tooltip>
              </Cell>
              <Cell className="cell-border-right" width="22%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
              <Cell width="22%">15,000</Cell>
              <Cell width="22%">Lead Time</Cell>
              <Cell width="22%"><ConfidenceLarge style={{backgroundColor: `#38D79F`}}>High</ConfidenceLarge></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="15%"><ToggleButton type="checkbox"/></Cell>
              <Cell width="55%">OCTG 2.375" 5.8# 22CR 130 VAM ACE</Cell>
              <Cell width="22%">CRA-P69</Cell>
              <Cell width="22%">Sumitomo</Cell>
              <Cell width="22%"><Tooltip className="tooltip"
                                  placement="end"
                                  type="light"
                                  size="extra-large"
                                  trigger={<Icon icon="icon-information" color={custom_button_blue} style={{position: `relative`, left: `28px`}}/>}
>
                                  <MD tag="h5" className="technical-data h5" style={{fontSize: `16px`, fontWeight: `bold`, color: `#3E3E3E`}}>Technical Data</MD>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-td" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>BurstPressure</h6>
                                    <p className="p">10,959 PSI</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px 30px 8px 48px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Collapse Strength</h6>
                                    <p className="p">7,504 PSI</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-bys" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Body Yield Strength</h6>
                                    <p className="p">2,088 LBS</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px -8px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Drift</h6>
                                    <p className="p">9.404”</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  </Tooltip>
              </Cell>
              <Cell className="cell-border-right" width="22%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
              <Cell width="22%">15,000</Cell>
              <Cell width="22%">Lead Time</Cell>
              <Cell width="22%"><ConfidenceLarge style={{backgroundColor: `#38D79F`}}>High</ConfidenceLarge></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="15%"><ToggleButton type="checkbox"/></Cell>
              <Cell width="55%">OCTG 2.375" 5.8# 22CR 130 VAM ACE</Cell>
              <Cell width="22%">CRA-P65</Cell>
              <Cell width="22%">Sumitomo</Cell>
              <Cell width="22%"><Tooltip className="tooltip"
                                  placement="end"
                                  type="light"
                                  size="extra-large"
                                  trigger={<Icon icon="icon-information" color={custom_button_blue} style={{position: `relative`, left: `28px`}}/>}
>
                                  <MD tag="h5" className="technical-data h5" style={{fontSize: `16px`, fontWeight: `bold`, color: `#3E3E3E`}}>Technical Data</MD>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-td" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>BurstPressure</h6>
                                    <p className="p">10,959 PSI</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px 30px 8px 48px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Collapse Strength</h6>
                                    <p className="p">7,504 PSI</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-bys" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Body Yield Strength</h6>
                                    <p className="p">2,088 LBS</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px -8px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Drift</h6>
                                    <p className="p">9.404”</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  </Tooltip>
              </Cell>
              <Cell className="cell-border-right" width="22%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
              <Cell width="22%">15,000</Cell>
              <Cell width="22%">Lead Time</Cell>
              <Cell width="22%"><ConfidenceLarge style={{backgroundColor: `#F17552`}}>Medium</ConfidenceLarge></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="15%"><ToggleButton type="checkbox"/></Cell>
              <Cell width="55%">OCTG 2.375" 5.8# 22CR 130 VAM ACE</Cell>
              <Cell width="22%">CRA-P52</Cell>
              <Cell width="22%">Sumitomo</Cell>
              <Cell width="22%"><Tooltip className="tooltip"
                                  placement="end"
                                  type="light"
                                  size="extra-large"
                                  trigger={<Icon icon="icon-information" color={custom_button_blue} style={{position: `relative`, left: `28px`}}/>}
>
                                  <MD tag="h5" className="technical-data h5" style={{fontSize: `16px`, fontWeight: `bold`, color: `#3E3E3E`}}>Technical Data</MD>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-td" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>BurstPressure</h6>
                                    <p className="p">10,959 PSI</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px 30px 8px 48px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Collapse Strength</h6>
                                    <p className="p">7,504 PSI</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-bys" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Body Yield Strength</h6>
                                    <p className="p">2,088 LBS</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px -8px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Drift</h6>
                                    <p className="p">9.404”</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  </Tooltip>
              </Cell>
              <Cell className="cell-border-right" width="22%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
              <Cell width="22%">15,000</Cell>
              <Cell width="22%">Lead Time</Cell>
              <Cell width="22%"><ConfidenceLarge style={{backgroundColor: `#F17552`}}>Medium</ConfidenceLarge></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="15%"><ToggleButton type="checkbox"/></Cell>
              <Cell width="55%">OCTG 2.375" 5.8# 22CR 130 VAM ACE</Cell>
              <Cell width="22%">CRA-P30</Cell>
              <Cell width="22%">Sumitomo</Cell>
              <Cell width="22%"><Tooltip className="tooltip"
                                  placement="end"
                                  type="light"
                                  size="extra-large"
                                  trigger={<Icon icon="icon-information" color={custom_button_blue} style={{position: `relative`, left: `28px`}}/>}
>
                                  <MD tag="h5" className="technical-data h5" style={{fontSize: `16px`, fontWeight: `bold`, color: `#3E3E3E`}}>Technical Data</MD>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-td" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>BurstPressure</h6>
                                    <p className="p">10,959 PSI</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px 30px 8px 48px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Collapse Strength</h6>
                                    <p className="p">7,504 PSI</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-bys" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Body Yield Strength</h6>
                                    <p className="p">2,088 LBS</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px -8px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Drift</h6>
                                    <p className="p">9.404”</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  </Tooltip>
              </Cell>
              <Cell className="cell-border-right" width="22%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
              <Cell width="22%">15,000</Cell>
              <Cell width="22%">Lead Time</Cell>
              <Cell width="22%"><ConfidenceLarge style={{backgroundColor: `#F17552`}}>Medium</ConfidenceLarge></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="15%"><ToggleButton type="checkbox"/></Cell>
              <Cell width="55%">OCTG 2.375" 5.8# 22CR 130 VAM ACE</Cell>
              <Cell width="22%">CRA-P57</Cell>
              <Cell width="22%">Sumitomo</Cell>
              <Cell width="22%"><Tooltip className="tooltip"
                                  placement="end"
                                  type="light"
                                  size="extra-large"
                                  trigger={<Icon icon="icon-information" color={custom_button_blue} style={{position: `relative`, left: `28px`}}/>}
>
                                  <MD tag="h5" className="technical-data h5" style={{fontSize: `16px`, fontWeight: `bold`, color: `#3E3E3E`}}>Technical Data</MD>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-td" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>BurstPressure</h6>
                                    <p className="p">10,959 PSI</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px 30px 8px 48px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Collapse Strength</h6>
                                    <p className="p">7,504 PSI</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-bys" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Body Yield Strength</h6>
                                    <p className="p">2,088 LBS</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px -8px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Drift</h6>
                                    <p className="p">9.404”</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  </Tooltip>
              </Cell>
              <Cell className="cell-border-right" width="22%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
              <Cell width="22%">15,000</Cell>
              <Cell width="22%">Lead Time</Cell>
              <Cell width="22%"><ConfidenceLarge style={{backgroundColor: `#F1C752`}}>Low</ConfidenceLarge></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="15%"><ToggleButton type="checkbox"/></Cell>
              <Cell width="55%">OCTG 2.375" 5.8# 22CR 130 VAM ACE</Cell>
              <Cell width="22%">CRA-P57</Cell>
              <Cell width="22%">Sumitomo</Cell>
              <Cell width="22%"><Tooltip className="tooltip"
                                  placement="end"
                                  type="light"
                                  size="extra-large"
                                  trigger={<Icon icon="icon-information" color={custom_button_blue} style={{position: `relative`, left: `28px`}}/>}
>
                                  <MD tag="h5" className="technical-data h5" style={{fontSize: `16px`, fontWeight: `bold`, color: `#3E3E3E`}}>Technical Data</MD>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-td" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>BurstPressure</h6>
                                    <p className="p">10,959 PSI</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px 30px 8px 48px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Collapse Strength</h6>
                                    <p className="p">7,504 PSI</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-bys" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Body Yield Strength</h6>
                                    <p className="p">2,088 LBS</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px -8px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Drift</h6>
                                    <p className="p">9.404”</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  </Tooltip>
              </Cell>
              <Cell className="cell-border-right" width="22%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
              <Cell width="22%">15,000</Cell>
              <Cell width="22%">Lead Time</Cell>
              <Cell width="22%"><ConfidenceLarge style={{backgroundColor: `#F1C752`}}>Low</ConfidenceLarge></Cell>
            </Row>
            <Row className="table-row">
              <Cell width="15%"><ToggleButton type="checkbox"/></Cell>
              <Cell width="55%">OCTG 2.375" 5.8# 22CR 130 VAM ACE</Cell>
              <Cell width="22%">CRA-P57</Cell>
              <Cell width="22%">Sumitomo</Cell>
              <Cell width="22%"><Tooltip className="tooltip"
                                  placement="end"
                                  type="light"
                                  size="extra-large"
                                  trigger={<Icon icon="icon-information" color={custom_button_blue} style={{position: `relative`, left: `28px`}}/>}
>
                                  <MD tag="h5" className="technical-data h5" style={{fontSize: `16px`, fontWeight: `bold`, color: `#3E3E3E`}}>Technical Data</MD>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-td" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>BurstPressure</h6>
                                    <p className="p">10,959 PSI</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px 30px 8px 48px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Collapse Strength</h6>
                                    <p className="p">7,504 PSI</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  <Row style={{border: `none`}}>
                                  <TechnicalDataWrapper className="left-bys" style={{margin: `8px 30px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Body Yield Strength</h6>
                                    <p className="p">2,088 LBS</p>
                                  </TechnicalDataWrapper>
                                  <TechnicalDataWrapper className="right-td" style={{margin: `8px -8px 8px 10px`}}>
                                    <h6 className="h6" style={{marginBottom: `16px`,fontSize: `16px`, lineHeight: `14px`, fontWeight: `500`, color: `#3E3E3E`}}>Drift</h6>
                                    <p className="p">9.404”</p>
                                  </TechnicalDataWrapper>
                                  </Row>
                                  </Tooltip>
              </Cell>
              <Cell className="cell-border-right" width="22%"><Icon icon="icon-database-system-2" color={custom_button_blue} style={{position: `relative`, left: `8px`}}/></Cell>
              <Cell width="22%">15,000</Cell>
              <Cell width="22%">Lead Time</Cell>
              <Cell width="22%"><ConfidenceLarge style={{backgroundColor: `#F1C752`}}>Low</ConfidenceLarge></Cell>
            </Row>
          </Body>
          </Table>
        </TableScrollWrapper>

         <MD left tag="p" style={{margin: `33px 0 0 24px`, color: `#8A8A8A`}}>Showing 1 to 10 of 40 Items Uploaded</MD>
        </TableWrapper>
      </ResultsWrapper>
  </ResultsSection>

  )
}

export default ResultsComponent;
