import React from 'react';
import { XXXL, MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { Button }from 'theme/Button'

import {
  CantLoginRecoverPage,
  Section,
  BackgroundImg,
  Logo,
  TextWrapper
} from './CantLoginRecover.styles';

const CantLoginRecover = () => {
  
  return (
    <CantLoginRecoverPage>
      <Section dark>
        <Flex justifyCenter>
        <FlexItem>
          <Logo src="/images/logo_white.svg"/>
        </FlexItem>
      </Flex>
      <TextWrapper>
        <XXXL center tag="h1">Recovery Email Submitted</XXXL>
        <MD center tag="p">Please check your email and follow the instructions to recover your password.</MD>
      </TextWrapper>
      <Flex justifyCenter>
        <FlexItem>
          <Button publicButton style={{marginTop: `40px`}}>Login</Button>
        </FlexItem>
      </Flex>
      <BackgroundImg src="/images/hero-pipes.png" />
      </Section>
    </CantLoginRecoverPage>
  )
}


export default PageViewWrapper(CantLoginRecover, {pageName: 'CantLoginRecover'})