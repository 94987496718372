import React from 'react';
import styled from 'styled-components';
import { MD } from '@zendeskgarden/react-typography';
import Flex from 'styled-flex-component';
import {closeModal} from 'state/modals/actions';
import Icon from 'components/Icon/Icon';
import {variables} from 'theme/variables';
const { 
  color_white,
  custom_blue,
  border_radius
} = variables;



const StyledModalHeader = styled.div`
  height: auto;
  border-top-right-radius: ${border_radius};
  border-top-left-radius: ${border_radius};
`
 
const GlobalModalHeader = (props) => {
  return (
    <StyledModalHeader>
      <Flex style={{padding: '0 15px 0', background: custom_blue}} justifyBetween alignCenter>
        <MD color={color_white} bold>{props.title || ''}</MD>
        <Icon color={color_white} onClick={closeModal} justifyEnd fontSize="9px" buttonStyle icon="icon-close" />
      </Flex>
    </StyledModalHeader>
  )
}


export default GlobalModalHeader;

