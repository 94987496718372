import React from 'react';
import { XXXL } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import Icon from 'components/Icon/Icon'
import {Button} from 'theme/Button'
import {
  BackgroundContainer,
  Wrapper
} from 'components/PublicPages/GlobalPublicStyles';
import {

  Logo,
  BackgroundCircle,
  SearchFieldWrapper,

  Close,
  SearchOverlayStyles
} from './SearchOverlay.styles';

import {
  variables
} from 'theme/variables';

import DividingLine from 'components/PublicPages/DividingLine';

const {
  custom_button_blue
} = variables;



const Search = (props) => {
  return (
    <SearchOverlayStyles dark active={props.active}>
      <Wrapper className="search-inner">
      <BackgroundContainer>
        <BackgroundCircle className="left-circle"/>
        <BackgroundCircle className="right-circle"/>
      </BackgroundContainer>
      <Flex justifyLeft>
        <FlexItem>
          <Logo src="/images/logo_white.svg" />
        </FlexItem>
      </Flex>
      <Flex justifyRight>
        <FlexItem>
          <Close onClick={props.toggleSearch}><Icon icon="icon-close" color={custom_button_blue} style={{position: `absolute`, top: `50%`, left: `50%`, fontSize: `16px`, fontWeight: `900`, margin: `-8px 0 0 -8px`}} /></Close>
        </FlexItem>
      </Flex>
          <Flex justifyCenter>
            <FlexItem>
              <DividingLine light />
            </FlexItem>
          </Flex>
          <Flex justifyCenter>
            <FlexItem>
          <SearchFieldWrapper>
            <XXXL center white className="search-header" tag="h1">Search</XXXL>
            <input type="text" name="search" placeholder="Search Topics"></input>
            <Icon icon="icon-search-left" color="#67778d" style={{position: `relative`, zIndex: `2`, top: `-40px`, fontSize: `22px`}} />
            <Flex justifyCenter>
              <FlexItem>
                <Button publicButton className="search-button">Search</Button>
              </FlexItem>
            </Flex>
          </SearchFieldWrapper>
        </FlexItem>
      </Flex>
      </Wrapper>

      
    </SearchOverlayStyles>

  )
}

export default Search;
