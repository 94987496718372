import React from 'react';
import Icon from 'components/Icon/Icon';
import {useBranch} from 'baobab-react/hooks';
import {SidebarFilterHeader, SidebarFilterBody, SidebarContentWrapper} from '../../GlobalSideBar.styles.js';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { LG } from '@zendeskgarden/react-typography';
import {closeSidebar} from 'state/sidebars/actions';
import {variables} from 'theme/variables';
import InterestHeader from '../components/InterestHeader/InterestHeader';
import TaskList from '../components/TaskList/TaskList';
import MessageList from '../components/MessageList/MessageList';
import DocumentList from '../components/DocumentList/DocumentList';
import { Tabs, TabPanel } from '@zendeskgarden/react-tabs';
import { ScrollContainer } from 'theme/Global.styles.js';
import { Input } from '@zendeskgarden/react-textfields';

const {
  spacing,
} = variables;



const InterestSummarySidebar = () => {
  const {activeInterest} = useBranch({
    activeInterest: ['interests', 'activeInterest']
  });
  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <Icon icon="icon-flag" fontSize="20px" color={'#fff'} />
            <LG paddingHorizontal bold color="#fff">Summary</LG>
          </Flex>
          <IconButton onClick={closeSidebar} disableBackground alignCenter justifyEnd size="large" aria-label="closeSidebar">
            <Icon button icon="icon-close" fontSize="13px" color={'#fff'} />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>

      <InterestHeader activeInterest={activeInterest}/>

      <SidebarFilterBody>
        <div className="tabs-style-override">
          <Tabs border={false}>
            <TabPanel label="Pending Tasks" key="tab-1">
              <ScrollContainer top={'80px'}>
                <TaskList itemPadding={`${spacing}`} tasks={[1,2,3]}/>
              </ScrollContainer>
            </TabPanel>
            <TabPanel label="Messaging" key="tab-2">
              <ScrollContainer bottom={'80px'} top={'80px'}>
                <MessageList itemPadding={`${spacing}`} messages={[1,2,3]}/>
              </ScrollContainer>
              <div style={{position:'absolute', left:0, right:0, bottom: 0, padding: '20px'}}>
                <Input placeholder="Send a message"/>
              </div>
            </TabPanel>
            <TabPanel label="Documents" key="tab-3">
              <ScrollContainer top={'80px'}>
                <DocumentList itemPadding={`${spacing}`} documents={[1,2,3]}/>
              </ScrollContainer>
            </TabPanel>
          </Tabs>
        </div>
      </SidebarFilterBody>
    </SidebarContentWrapper>
  )
}

export default InterestSummarySidebar;