import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Button } from '@zendeskgarden/react-buttons';
import PublicPage from 'components/PublicPages/PublicPage'
import '@zendeskgarden/react-pagination/dist/styles.css';
import '@zendeskgarden/react-tables/dist/styles.css';

import {
  Section
} from 'components/PublicPages/GlobalPublicStyles';

const {
  custom_dark_blue,
  color_white,
  media_sm,
  media_md,
  media_lg,
  spacing,
  custom_button_blue,
  public_font_size_sm,
  public_mobile_font_size_xs,
  public_font_size_md,
  public_font_size_xs
} = variables;

export const InventoryResultsPage = styled(PublicPage)`
`;

export const TopSection = styled(Section)`
  
  padding: 0;
  position: relative;

  h1, h2, h3, h4, p {
    color: ${props => props.dark ? color_white : custom_dark_blue}
  }

  h5, p {
    padding: 0 0 ${spacing} 0;
    letter-spacing: 0.29px;
    font-weight: normal;
    margin-bottom: 0;
  }
`;

export const ResultsSection = styled(Section)`
  
  padding: 0;
  position: relative;

  h1, h2, h3, h4, p {
    color: ${props => props.dark ? color_white : custom_dark_blue}
  }

  h5, p {
    padding: 0 0 ${spacing} 0;
    letter-spacing: 0.29px;
    font-weight: normal;
    margin-bottom: 0;
  }

  .showing {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: -6px 0 0 0
    width: 186px;
  }

  .items-mobile-center {
    position: absolute;
  }

  @media ${media_md} {
    .showing {
      position: relative;
      left: 0;
      transform: translateX(0);
      margin: 16px 0 0 -8px;
    }

    .items-mobile-center {
      right: 25px;
      top: 10px;
    }
  }

  @media ${media_lg} {
    .showing {
      margin: 16px 0 12px 24px;
    }

    
  }
`;


export const Wrapper = styled.div`
  position: relative
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;

  h1 {
    padding: 0 0 ${spacing} 0;
    letterSpacing: 2px;
  }
`

export const BackgroundImg = styled.img`
  overflow: hidden;
  position: absolute;
  height: auto;
`

export const InventoryResultsButton = styled(Button)`
  padding: 0;
  height: 48px; 
  width: 186px;
  border-radius: 8px;	
  background-color: ${color_white};	
  box-shadow: 0 10px 15px -7px rgba(31,44,61,0.35);
  color: ${custom_button_blue}
  border: 1px solid ${custom_button_blue}
  outline: none;
  font-size: ${public_font_size_sm};
  font-weight: normal;
  line-height: 14px;
  display: block;

   &:hover {
    background-color: ${color_white} !important;
    color: ${custom_button_blue} !important;
  }

`;

export const InventoryResultsButtonAdjust = styled(Button)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 82px;
  padding: 0;
  height: 48px; 
  width: 186px;
  margin: 33px 10px 40px 0;
  border-radius: 8px;	
  background-color: ${color_white};	
  color: ${custom_button_blue}
  border: 1px solid ${custom_button_blue}
  outline: none;
  font-size: ${public_font_size_sm};
  font-weight: bold;
  line-height: 14px;

  &:hover {
    background-color: ${custom_button_blue} !important;
  }

  @media ${media_md} {
    right: 16px;
    transform: translateX(0);
    top: 0;
    left: auto;
  }
`;

export const ResultsWrapper = styled.div`
  position: relative;
  margin: 0 24px;
  top: -144px;
  height: auto;
  background-color: ${color_white};
  border-radius: 4px;
  box-shadow: 0 30px 50px 0 rgba(68,89,109,0.1);

  .table-header-row {
    padding: 0 0 16px 0;
    background-color: #E7EAEE;

    &:hover {
      background-color: #E7EAEE !important;
    }
  }

  .table-row {
    padding: 16px 0;

    h5 {
      font-size: 20px;
      line-height: 32px;
      color: #3E3E3E;
    }

    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      border-lef
    }
  }

  .table-row-top {
    padding: 16px 0;
    border-bottom: none;

    h5 {
      font-size: 20px;
      line-height: 32px;
      color: #3E3E3E;
    }

    &:hover {
      background-color: transparent !important;
    }
  }
  
  .results-table {
    margin-top: 96px;
  }

  @media ${media_md} {
    .results-table {
      margin: 0;
    }
  }
`

export const TableWrapper = styled.div`
  margin: 0 24px;

  .result-table {
    margin-top: 104px;
  }

  @media ${media_md} {
    .result-table {
      margin: 0;
    }
  }
`

export const TableScrollWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  clear: both;

  div[class^="TooltipContainer__"], div[class*="TooltipContainer__"] {
    z-index: 50000;
  }
`

export const ToggleButton = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px
  height: 14px;
  display: inline-block;
  position: relative;
  top: 3px;
  left: 23px;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: 2px solid #B0BED1;
  cursor: pointer;
  background-color: ${color_white};
  transition: background-color ease 0.3s;

 &:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  width: 6px;
  height: 6px;
  background: ${custom_button_blue}
  left: 2px;
  top: 2px;
  border-radius: 50%;
  font: 10px/28px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -22px;
  word-spacing: 37px;
  color: ${color_white};
  text-shadow: -1px -1px rgba(0,0,0,0.15);
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

&:checked {
  background-color: ${color_white};
  border: 2px solid ${custom_button_blue}
  color: ${custom_button_blue}
  box-shadow: 0 1.5px 2.5px rgba(3,124,239, 0.6);
}

&:checked:before {
  left: 8px;
}
`

export const DividingLine = styled.div`
  position: relative;
  left: 1%;
  width: 98%;
  height: 1px;
  border: 1px solid #EFEFEF;
`

export const TopWrapper = styled.div`
  height: 46px;
  width: 100%;
  background-color: #E7EAEE;

  p {
    position: relative;
    bottom: 15px;
    font-weight: 500;
  }
`

export const ConfidenceLarge = styled.div`
  position: relative;
  top: 2px;
  left: 12px;
  height: 21px;
  width: 52px;
  border-radius: 4px;
  color: ${color_white};
  font-size: ${public_mobile_font_size_xs};
  line-height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const VerticalLine = styled.div`
  position: absolute;
  top: 200px;
  right: 31%;
	height: 752px;
	width: 1px;
	background-color: #EFEFEF;
`

export const AvailabilityWrapper = styled.div`
  height: 48px;
  width: 30%;
  min-width: 300px;
	border-radius: 4px 4px 0 0;
  background-color: ${custom_button_blue}
  // float: right;

  position: absolute;
  right: 0;
  top: 1px;
  float: none;

  p {
    padding: 0 0 ${spacing} 0;
    letter-spacing: 0.32px;
    margin: 10px 0 0 20px; 
    font-weight: normal;
    color: ${color_white};
  }

  @media ${media_md} {
    margin-top: 0;
  }
`

export const AvailabilityBlur = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
	background-color: white;
  opacity: 0.9;

  height: 100%;
  width: 100%;
  top: 0;


  filter: blur(100%);
  -webkit-filter: blur(100%);
	-moz-filter: blur(100%);
	-o-filter: blur(100%);
  -ms-filter: blur(100%);
`;

export const TableSidebar = styled.div`
  width: 30%;
  height: calc(100% - 99px);
  position: absolute;
  right: 0;
  top: 98px;
  padding: 0px 40px;
  border-left: 1px solid #EFEFEF;

`;

export const CriteriaWrapper = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  max-width: 90%;
  min-width: 330px;
  max-height: 260px;
  height: auto;

  position: absolute;
  padding: 0 20px;
  z-index: 3;
	border-radius: 4px;
	background-color: ${custom_button_blue}
  box-shadow: 0 5px 25px 0 rgba(68,89,109,0.2);


  
  h5 {
    font-size: 20px;
    line-height: 14px;
    font-weight: bold;
    color: ${color_white};
  }

  p {
    color: ${color_white};
    font-size: 11px;
    line-height: 20px;
  }

  button {
    position: relative; 
    margin: 0 auto;
    height: 48px;
    width: 131px;
    border-radius: 4px;
    background-color: ${color_white};
    box-shadow: 0 10px 15px -7px rgba(31,44,61,0.35);
  }
`

export const LocationWrapper = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  max-width: 90%;
  min-width: 330px;
  height: 334px;

  position: absolute;
  padding: 0 20px;
  z-index: 3;
	border-radius: 4px;
	background-color: ${custom_button_blue}
  box-shadow: 0 5px 25px 0 rgba(68,89,109,0.2);
  
  h5 {
    color: ${color_white};
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    padding: 0;
  }

  button {
    position: relative; 
    margin: 0 auto;
    display: block;
    height: 48px;
    width: 131px;
    border-radius: 8px;
    background-color: ${color_white};
    box-shadow: 0 10px 15px -7px rgba(31,44,61,0.35);
  }
`

export const FieldsWrapper = styled.div`
  margin: 40px 0 0 0;

  .location {
    margin-top: 0;
    margin-bottom: -8px;
  }

  .quantity {
    margin-bottom: -8px;
  }

  .submit-button {
    margin: 32px 0 0 0; 
  }

  input::placeholder {
    font-size: ${public_font_size_sm};
    color: rgba(255, 255, 255, 0.96);
  }
`

export const CriteriaInput = styled.input`
  width: 257px;
  padding: 10px 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${color_white};
  color: ${color_white};

  &

`

export const CompareItemsWrapper = styled.div`
    p {
      margin-left: 24px;
      color: ${custom_button_blue}
    }

    

    @media ${media_md} {
      p {
        margin: 33px 0 0 24px;
      }

      .Filters {
        position: relative;
        font-size: ${public_font_size_sm};
        font-weight: bold;
        width: 100px;
        right: 0;
        top: 0px;
        color: ${custom_button_blue}
      }

      .Compare {
        position: relative;
        font-size: ${public_font_size_sm};
        font-weight: bold;
        width: 200px;
        right: 0;
        top: 0px;
        color: ${custom_button_blue}
      }
    }
`

export const CancelWrapper = styled.div`

  p {
    margin-top: 8px;
    padding: 0 0 5px 0;
    letterSpacing: 0.29px;
    fontWeight: normal;
  }

  &:hover {
    cursor: pointer;
  }
`

export const NoBlur = styled.div`

`

export const PipesWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0; 
  left: 0;
  position: absolute; 

  .right-pipe {
    width: 165px;
    left: 0;
    right: -225px;
    bottom: 0;
    top: 19px;
    margin: 0 auto;
  }

  .left-pipe {
    width: 165px;
    left: -207px;
    right: 0%;
    bottom: 0;
    top: 77px;
    margin: 0 auto;
  }

    .left-pipes {
      display: none;
    }

    .right-pipes {
      display: none;
  }

  @media ${media_sm} {
    .left-pipes {
      display: block;
      width: 500px;
      left: -90%;
      right: 0;
      bottom: 0;
      top: 152px;
      margin: 0 auto;
    }

    .right-pipes {
      display: block;
      width: 500px;
      left: 0;
      right: -80%;
      bottom: 0;
      top: 112px;
      margin: 0 auto;
    }

    .right-pipe {
      display: none;
  }

    .left-pipe {
      display: none;
  }
  }

  @media ${media_md} {
    .left-pipes {
      display: block;
      width: 500px;
      left: -90%;
      right: 0;
      bottom: 0;
      top: 152px;
      margin: 0 auto;
    }

    .right-pipes {
      display: block;
      width: 500px;
      left: 0;
      right: -80%;
      bottom: 0;
      top: 112px;
      margin: 0 auto;
    }

    .right-pipe {
      display: none;
  }

    .left-pipe {
      display: none;
  }
}
  
`

export const FiltersWrapper = styled.div`
  position: absolute;
  display: inline;
  top: 76px;
  left: 50%;
  transform: translateX(-50%);
  
  a {
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  @media ${media_md} {
    position: absolute;
    transform: translateX(0);
    left: auto;
    right: 185px;
    top: 10px;
  }

  @media ${media_lg} {
    right: 185px;
    top: 10px;
  }
`

export const CompareWrapper = styled.div`
  position: absolute;
  display: inline;
  top: 47px;
  left: 50%;
  transform: translateX(-50%);

  @media ${media_md} {
    position: absolute;
    transform: translateX(0);
    left: auto;
    right: 252px;
    top: 10px;
  }

  @media ${media_lg} {
    right: 252px;
    top: 10px;
  }
`

export const SearchCriteriaWrapper = styled.div`
  position: absolute;
  right: 34px;

  .mobile-adjust {
    top: 50px;
    left: 50%;
    transform: translateX(-122%)
  }

  .items-mobile-center {
    position: absolute;
  }

  @media ${media_md} {
   .mobile-adjust {
      top: -10px;
      left: -178px;
      transform: translateX(0);
    }
    
    .items-mobile-center {
      position: relative;
    }
  }
`

export const IconHoverWrapper = styled.span`
  :hover {
    cursor: pointer
  }
`

export const TechnicalDataWrapper = styled.div`
  .h5 {
    font-size: ${public_font_size_md};
    font-weight: bold;
    color: #3E3E3E;
  }

  .h6 {
    font-size: ${public_font_size_sm};
    line-height: 14px;
    font-weight: 500;
    color: #3E3E3E;
  }

  .p {
    font-size: ${public_font_size_xs};
    line-height: 16px;
    color: #3E3E3E;
  }
`

export const PaginationWrapper = styled.div`
  position: relative;
`

