import React from 'react';
import { XXXL, MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import Icon from 'components/Icon/Icon'
import {
  SearchSection,
  BackgroundImg,
  SearchFieldWrapper,
  PipesWrapper
} from '../../SearchResults.styles';


const Search = (props) => {
  return (
    <SearchSection dark>
      <PipesWrapper>
        <BackgroundImg className="left-pipes"  src="/images/hero-pipes.png" />
        <BackgroundImg className="right-pipes" src="/images/hero-pipes.png" />
        <BackgroundImg className="left-pipe"  src="/images/Pipes.svg" />
        <BackgroundImg className="right-pipe" src="/images/Pipes.svg" />
      </PipesWrapper>
      <Flex justifyCenter>
        <FlexItem>
          <SearchFieldWrapper>
            <XXXL center tag="h1">Search</XXXL>
            <input type="text" id="name" name="name" placeholder="Facilitators"></input>
            <Icon icon="icon-search-left" color="#67778d" style={{position: `relative`, top: `-45px`, fontSize: `22px`}} />
          </SearchFieldWrapper>
        </FlexItem>
      </Flex>
    </SearchSection>

  )
}

export default Search;