import React, { useState } from 'react';
import { LG, MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button'

import {
  WorldMapBg,
  WorldMapBgLoggedIn,
  AvailabilityWrapper,
  MarginWrapper,
  ConfidenceSmall
} from '../../ViewItemDetails.styles';

const WorldMapOverlay = (props) => {
  const [loggedIn, loginState] = useState("loggedOut")

  if(loggedIn === "loggedOut") {
    return (
      <div>
      <AvailabilityWrapper>
          <Row>
            <Col md={3} lg={2}>
              <LG left tag="h5" className="no-display-mobile-map">Lead Time</LG>
            </Col>
            <Col md={3} lg={2}>
              <LG left tag="h5" className="no-display-mobile-map">Confidence</LG>
            </Col>
            <Col md={3} lg={2}>
              <LG left tag="h5" className="no-display-mobile-map">Region Available</LG>
            </Col>
          </Row>
          <Row>
            <Col md={3} lg={2}>
              <MD left tag="p">8-10 Weeks</MD>
            </Col>
            <Col md={3} lg={2}>
              <ConfidenceSmall>High</ConfidenceSmall>
            </Col>
            <Col md={3} lg={2}>
              <MD left tag="p">Africa</MD>
            </Col>
          </Row>
        </AvailabilityWrapper>
      <WorldMapBg>
        <Flex justifyLeft alignCenter full>
          <FlexItem>
            <MarginWrapper>
                <MD center tag="h6" className="view-availability" style={{ fontSize: `24px`, lineHeight: `24px`, letterSpacing: `0.34px`, color: `#3E3E3E`}}>SignUp To View Availability</MD>
                <Button publicButton className="view-availability-button" onClick={() => loginState("loggedIn")} style={{position: `relative`, borderRadius: `4px`, width: `228px`, height: `63px`}}>Sign Up</Button>
            </MarginWrapper>
          </FlexItem>
        </Flex>
      </WorldMapBg>
      </div>
    )
  } else
    return (
      <div>
        <AvailabilityWrapper style={{zIndex: `10`}}>
          <Row>
            <Col md={3} lg={2}>
              <LG left tag="h5">Lead Time</LG>
            </Col>
            <Col md={3} lg={2}>
              <LG left tag="h5">Confidence</LG>
            </Col>
            <Col md={3} lg={2}>
              <LG left tag="h5">Region Available</LG>
            </Col>
          </Row>
          <Row>
            <Col md={3} lg={2}>
              <MD left tag="p">8-10 Weeks</MD>
            </Col>
            <Col md={3} lg={2}>
              <ConfidenceSmall>High</ConfidenceSmall>
            </Col>
            <Col md={3} lg={2}>
              <MD left tag="p">Africa</MD>
            </Col>
          </Row>
        </AvailabilityWrapper>
        <WorldMapBgLoggedIn />
      </div>
    )
}

export default WorldMapOverlay;