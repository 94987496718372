import React from 'react';
import { XXXL, XL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import {
  TopSection,
  HeroImg,
  HeroOverlay,
  HeaderWrapper
} from '../../BlogPostTemplate.styles';

const { spacing } = variables;

const BlogHeader = (props) => {
  return (
    <TopSection dark>
      <HeroImg><HeroOverlay></HeroOverlay></HeroImg>
      <Flex justifyLeft>
        <FlexItem>
          <HeaderWrapper className="blog-post-text">
            <XXXL left tag="h1">Example Of A Wonderful Blog Post Title That Goes Here</XXXL>
            <MD left tag="h5">July 12, 2019 &nbsp; | &nbsp; Support</MD>
          </HeaderWrapper>
        </FlexItem>
      </Flex>
    </TopSection>

  )
}

export default BlogHeader;