import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import SearchSection from './components/SearchSection/SearchSection.js'
import InfoSection from './components/InfoSection/InfoSection.js'


import {
  HelpCenterPage
} from './HelpCenter.styles';


const HelpCenter = () => {
 
  return (
    <HelpCenterPage>
      <SearchSection />
      <InfoSection />
    </HelpCenterPage>
  )
}


export default PageViewWrapper(HelpCenter, {pageName: 'HelpCenter'})