import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import TopSection from './components/TopSection/TopSection.js'
import BlogPosts from './components/BlogPosts/BlogPosts.js'
import GetStarted from 'components/PublicPages/GetStarted';


import {
  BlogTemplatePage
} from './Blog.styles';


const BlogTemplate = () => {

  return (
    <BlogTemplatePage style={{ padding: `0`, overflow: `hidden` }}>
      <TopSection />
      <BlogPosts />
      <GetStarted />
    </BlogTemplatePage>
  )
}


export default PageViewWrapper(BlogTemplate, { pageName: 'BlogTemplate' })