import {variables} from 'theme/variables';
const {
  spacing_sm,
  line_height_xxxl,
  font_size_sm,
  font_size_md,
  custom_grey,
  font_weight_semibold,
  custom_black,
  custom_silver,
  custom_blue,
  color_red_400,
  custom_orange
} = variables;


const TextFieldTheme = {
  'textfields.message': `
    && {
      margin-top: ${spacing_sm}
    }
  `,
  'textfields.input': props => textInputProps(props),
  'textfields.text_group': props => textInputProps(props),
};


function textInputProps(props) {
  return `
    width: 100%;
    ${props.itemSelection ? `
      border-width: 0 !important;
      background: transparent;
      border-bottom-width: 1px !important;
      border-style: solid;
      border-color: ${custom_silver};
      border-radius: 0 !important;
      padding: .71429em 0 !important;;
      outline: none !important;
      box-shadow: none !important;
      font-size: ${font_size_sm};
      color: ${custom_black} !important; 
      ::placeholder {
        color: ${custom_grey} !important;
      }
      ${props.itemSelection === 'small' ? `
        padding: .71429em 0 !important;
      ` : ''}
      ${props.size ? `
        min-height: ${props.size}px !important;
        height: ${props.size}px !important;
        padding: 0 !important;
        margin-bottom: 0 !important;
      ` : ''}
    ` : ''}

    ${props.container ? `
      position: relative;
      input {
        padding-right: ${props.paddingRight || 23}px !important;
      }
    ` : ''}

    ${props.paddingRight && !props.container ? `
      padding-right: ${props.paddingRight || 23}px !important;
    ` : ''}


    ${props.scaled ? `
      height: 58px;
    ` : ''}

    ${props.size === 'xl' ? `
      height: ${line_height_xxxl};
    ` : ''}

    ${props.medium ? `
      font-size: ${font_size_md};
    ` : ''}

    ${props.colorOverride ? `
      color: ${props.colorOverride} !important;
      ::placeholder {
        color: ${props.colorOverride} !important;
      }
    ` : ''}

    &:focus:not([disabled]) {
      border-bottom: 1px solid ${custom_blue} !important;
      ${props.colorOverride ? `
        ::placeholder {
          color: ${custom_grey} !important;
        }
      ` : ''}
    }

    ${props.semibold ? `
      font-weight: ${font_weight_semibold};
      ::placeholder {
        font-weight: ${font_weight_semibold};
      }
    ` : ''}

    ${props.icon ? `
      padding-left: 3em !important;
    ` : ''}

    ${props['aria-haspopup'] ? `
      padding-left: 25px !important;
    ` : ''}

    ${props.hideOutline ? `
      box-shadow: none !important;
    ` : ''}
    
    ${props.validation === 'error' ? `
      color: ${color_red_400} !important;
      ::placeholder {
        color: ${color_red_400} !important;
      }
    ` : ''}

    ${props.validation === 'warning' ? `
      color: ${custom_orange} !important;
      ::placeholder {
        color: ${custom_orange} !important;
      }
    ` : ''}

  `;
}

export default TextFieldTheme;