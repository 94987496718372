import React, { useState, useRef, useEffect } from 'react';
import { XXL, MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import { Button } from 'theme/Button';
import {
  Wrapper,
  BackgroundContainer,
} from 'components/PublicPages/GlobalPublicStyles';
import AnimatedTitle from 'components/PublicPages/AnimatedTitle';
import { Waypoint } from 'react-waypoint';
import anime from 'animejs';
import GetStartedBackground from './components/GetStartedBackground';
import { animations } from 'components/PublicPages/AnimationSnippets';
import { GetStartedSection } from './components/GetStarted.styles';

const GetStarted = (props) => {
  const [animated, setAnimated] = useState(false);
  const title = useRef();
  const animation = useRef();
  const animationTimer = useRef();
  useEffect(() => {
    animation.current = anime
      .timeline({
        autoplay: false,
        begin: function () {
          title.current.animate();
        },
        complete: function () {
          setAnimated(true);
        },
      })
      .add(animations.paragraph('.get-started-section p'), 300)
      .add(animations.button('.get-started-button'), 400)
      .add(
        {
          targets: '.get-started-bg .layer-1 .dots path',
          opacity: {
            value: [0, 1],
            duration: 100,
            easing: 'linear',
          },
        },
        0
      )
      .add(
        {
          targets: '.get-started-bg .layer-2 path',
          opacity: {
            value: [0, 1],
            duration: 100,
            easing: 'linear',
          },
          delay: anime.stagger(5),
        },
        500
      )
      .add(
        {
          targets: '.get-started-bg .layer-1 .lines path',
          strokeDashoffset: {
            value: [anime.setDashoffset, 0],
            easing: 'linear',
            duration: 4000,
          },
          opacity: {
            duration: 10,
            value: 1,
            easing: 'linear',
          },
          delay: anime.stagger(50),
        },
        0
      );

    return () => {
      animation.current.pause();
      animation.current = null;
      clearInterval(animationTimer.current);
    };
  }, []);
  function animate() {
    if (!animated) {
      animationTimer.current = setTimeout(function () {
        animation.current.play();
      }, 500);
    }
  }

  return (
    <GetStartedSection className='get-started-section'>
      <BackgroundContainer className='bg'>
        <GetStartedBackground />
      </BackgroundContainer>
      <Wrapper style={{ position: 'relative' }}>
        <Waypoint onEnter={animate} />
        <XXL center tag='h2'>
          <AnimatedTitle
            ref={title}
            animated={animated}
            className='get-started-title'
          >
            Ready To Get Started?
          </AnimatedTitle>
        </XXL>
        <MD center tag='p' animated={animated}>
          Sign up for a Global Pipe Co. account to see our platform in action.
        </MD>

        <Flex justifyCenter>
          <FlexItem>
            <Button
              publicButton
              className='get-started-button'
              animated={animated}
            >
              Get Started
            </Button>
          </FlexItem>
        </Flex>
      </Wrapper>
    </GetStartedSection>
  );
};

export default GetStarted;
