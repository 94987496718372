import React from 'react';
import { MD } from '@zendeskgarden/react-typography';
import Icon from 'components/Icon/Icon';
import { IconButton } from '@zendeskgarden/react-buttons';
import Flex from 'styled-flex-component';
import {variables} from 'theme/variables';
import styled from 'styled-components';

const {
  custom_blue,
  routed_modal_header_height,
  spacing,
  spacing_lg,
  app_page_padding_small,
  app_page_padding_medium,
  media_sm,
  logo_url_white
} = variables;

const StyledTopicHeader = styled.div`
  background-color: rgba(14, 54, 107, 0.35);
  min-height: ${routed_modal_header_height};
  max-height: ${routed_modal_header_height};
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  padding: ${props => `${spacing} ${app_page_padding_small}`};
  @media ${media_sm} {
   padding: ${props => `${spacing} ${app_page_padding_medium}`};
  }
`

export const Logo = styled('div')`
  background-image: url("${props => props.src}");
  background-size: contain;
  width: 150px;
  height: 30px;
  opacity: 1;
  background-repeat: no-repeat;
`;

const TopicHeader = ({logo, title, icon, closeModal}) => {
  return (
    <StyledTopicHeader padding={`${spacing} ${spacing_lg}`}>
      <Flex full justifyBetween alignCenter>
        {
            logo ?
            <Flex alignCenter>
              <Logo src={logo_url_white} />
            </Flex> :
            <Flex alignCenter>
              <Icon icon={icon} fontSize="16px" color={'#fff'} />
              <MD paddingHorizontalSm bold color="#fff">{title}</MD>
            </Flex>
        }
        <IconButton onClick={closeModal} disableBackground alignCenter justifyCenter size="large" aria-label="closeModal">
          <Icon button icon="icon-close" fontSize="13px" color={'#fff'} />
        </IconButton>
      </Flex>
    </StyledTopicHeader>
  )
}


export default TopicHeader;