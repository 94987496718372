import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import Login from './components/Login/Login.js'



import {
  LoginTemplatePage
} from './LoginTemplate.styles';


const LoginTemplate = () => {
  
  return (
    <LoginTemplatePage style={{padding: `0`, overflow: `hidden`}}>
      <Login />
    </LoginTemplatePage>
  )
}


export default PageViewWrapper(LoginTemplate, {pageName: 'LoginTemplate'})