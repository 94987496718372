import React from 'react';
import { MD } from '@zendeskgarden/react-typography';
import { Button as ZendeskButton } from '@zendeskgarden/react-buttons';
import {variables} from 'theme/variables';
import DocumentDownload from 'components/DocumentDownload/DocumentDownload';
import {
  StyledTaskItem,
} from './TaskItem.styles.js';

const {
  spacing,
} = variables;

const TaskItem = ({task, ...rest}) => {
  const {title="Document Review Required", buttonText="Sign Now", documentItem} = task;
  return (
    <StyledTaskItem padding={rest.itemPadding}>
      <MD bold>{title}</MD>
      <div style={{padding: `${spacing} 0`}}>
        <DocumentDownload document={documentItem} />
      </div>
      <ZendeskButton uppercase semiBold size="large" primary>
        {buttonText}
      </ZendeskButton>
    </StyledTaskItem>
  )
}

export default TaskItem