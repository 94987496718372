import styled from 'styled-components';
import {variables} from 'theme/variables';
const {
  custom_blue,
  spacing_sm,
  spacing,
  custom_light_grey
} = variables;

export const MenuHeader = styled.div`
  padding: ${spacing_sm} ${spacing} ${spacing_sm} ${spacing};
  background-color: ${custom_blue};
  height: 50px;
  display: flex;
  align-items: center;
  border-top-right-radius: 4px; 
  border-top-left-radius: 4px; 
`

export const MenuBody = styled.div`
  padding: ${spacing_sm} ${spacing};
  min-height: 230px;
  max-height: 230px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  ::-webkit-scrollbar{
    width: 0;
  }
`

export const MenuFooter = styled.div`
  padding: ${spacing};
  border-top: 1px solid ${custom_light_grey};
  border-bottom-right-radius: 4px; 
  border-bottom-left-radius: 4px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
`
