import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import InventoryFilters from '././components/InventoryFilters/InventoryFilters'



import {
  ItemSubmissionPage
} from './ItemSubmission.styles';


const ItemSubmission = () => {

  return (
    <ItemSubmissionPage style={{ padding: `0`, overflow: `hidden` }}>
      <InventoryFilters />
    </ItemSubmissionPage>
  )
}


export default PageViewWrapper(ItemSubmission, { pageName: 'ItemSubmission' })