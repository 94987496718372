import styled from 'styled-components';
import { variables } from 'theme/variables';

const {
  color_white,
  custom_button_blue,
  public_font_size_sm
} = variables;

export const RecentPosts = styled.div`
.slider {
  width: 100%;
  margin: 16px 0 80px;
  padding-bottom: 80px;

  .slick-next, .slick-prev {
    display: block;
    
    position: absolute;
    top: calc(100% - 30px);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 41px;
    height: 41px;
    transform: none;
    background: white;
    border: 1px solid ${custom_button_blue};
    border-radius: 50%;
    transition: all 0.2s linear;
    

    &:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      -webkit-font-feature-settings: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: ${custom_button_blue};
      font-size: ${public_font_size_sm};
      transition: all 0.2s linear;
    }

    &:hover {
      background-color: ${custom_button_blue};
      &:before {
        color: ${color_white};
      }
    }
  }
  .slick-next {
    right: -50px;
    &:before {
      content: "\\e907";
    }
  }
  
  .slick-prev {
    left: -50px;
    &:before {
      content: "\\e906";
    }
  }
  
  .slick-dots {
    bottom: 0px;

    li {
      button {
        width: 12px;
        height: 12px;
        background: transparent;
        border: 1px solid ${custom_button_blue};
        border-radius: 100%;
        margin: 0 auto;
        transition: all 0.2s linear;
        &:before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background: ${custom_button_blue};
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

`;
