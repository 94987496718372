import React from 'react';
import { XXXL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import Icon from 'components/Icon/Icon'
import {
  TopSection,
  BackgroundImg,
  BackgroundImgMobile,
  SearchFieldWrapper,
  PipesWrapper
} from '../../HelpCenter.styles';

const { spacing } = variables;

const SearchSection = (props) => {
  return (
    <TopSection dark>
        <PipesWrapper>
          <BackgroundImg className="left-pipes"  src="/images/hero-pipes.png" />
          <BackgroundImg className="right-pipes" src="/images/hero-pipes.png" />
          <BackgroundImgMobile className="left-pipe"  src="/images/Pipes.svg" />
          <BackgroundImgMobile className="right-pipe" src="/images/Pipes.svg" />
        </PipesWrapper>
      <Flex justifyCenter>
        <FlexItem>
          <SearchFieldWrapper>
            <XXXL center tag="h1">Help Center</XXXL>
            <input type="text" name="name" placeholder="Enter Search Terms..."></input>
            <Icon icon="icon-search-left" color="rgba(255,255,255,0.4)" style={{position: `relative`, top: `-45px`, fontSize: `22px`}} />
          </SearchFieldWrapper>
        </FlexItem>
      </Flex>
    </TopSection>

  )
}

export default SearchSection;