import React from 'react';
import { XXL, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { Button } from 'theme/Button'

import {
  AccountCreatedPage,
  AccountCreatedSection,
  BackgroundImg,
  Logo,
  TextWrapper
} from './AccountCreated.styles';

const { spacing } = variables;

const AccountCreated = () => {
  
  return (
    <AccountCreatedPage>
      <AccountCreatedSection dark>
        <Flex justifyCenter>
        <FlexItem>
          <Logo src="/images/logo_white.svg"/>
        </FlexItem>
      </Flex>
      <TextWrapper>
        <XXL center tag="h1">Account Created Successfully!</XXL>
        <MD center tag="p">Please continue to complete the registration process.</MD>
      </TextWrapper>
      <Flex justifyCenter>
        <FlexItem>
          <Button publicButton style={{marginTop: `40px`}}>Complete Registration</Button>
        </FlexItem>
      </Flex>
      <BackgroundImg src="/images/hero-pipes.png" />
      </AccountCreatedSection>
    </AccountCreatedPage>
  )
}


export default PageViewWrapper(AccountCreated, {pageName: 'AccountCreated'})
