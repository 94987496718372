import styled from 'styled-components';
import {Page} from 'theme/Page';
import { Col } from '@zendeskgarden/react-grid';
import {variables} from 'theme/variables';
const {color_grey_100, media_md, media_sm, app_page_padding_medium} = variables

export const LoginPage = styled(Page)`
  padding: 0
`;

export const LoginWrapper = styled.div`
  margin: 0;
  max-width: 500px;
  width: 100%;
  flex: 1; 
  margin-top: 0px;
  padding: 0;
  @media ${media_sm} {
    padding: 0 ${app_page_padding_medium}
  }
  @media ${media_md} {
    margin-top: 100px;
    padding-left: 40px;
  }
`;

export const PageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: calc(100vh - 70px);
  @media ${media_md} {
    align-items: stretch;
    justify-content: stretch;
    display: flex;
  }
`;

export const LeftWrapper = styled(Col)`
  background: ${color_grey_100};
  height: 100px;
  @media ${media_md} {
    height: auto;
  }
`;