import React from 'react';
import { SM } from '@zendeskgarden/react-typography';
import useTableSort from 'hooks/useTableSort';
import {
  Table,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@zendeskgarden/react-tables';

 
const SidebarItemsTable = (props) => {
  const { sortedData, isAscending, sortKey, toggleSoryKeyAndAscending } = useTableSort(
    props.data || [],
    "id"
  );


  return (
    <Table nested size="small" styling={false}>
      <Head>
        <HeaderRow style={{height: '40px'}}>
          <HeaderCell size="small" width="10%">
            <SM ellipsis>Title</SM>
          </HeaderCell>
          
          <HeaderCell size="small" width="10%">
            <SM ellipsis>Category</SM>
          </HeaderCell>

          <HeaderCell size="small" width="10%">
            <SM ellipsis>OD</SM>
          </HeaderCell>

          <HeaderCell size="small" width="10%">
            <SM ellipsis>Weight</SM>
          </HeaderCell>

          <HeaderCell size="small" width="10%">
            <SM ellipsis>Footage</SM>
          </HeaderCell>

          <HeaderCell size="small" width="10%">
            <SM ellipsis>Wall</SM>
          </HeaderCell>

          <HeaderCell size="small" width="10%">
            <SM ellipsis>Min Yield</SM>
          </HeaderCell>

           <HeaderCell size="small" width="10%">
            <SM ellipsis>Quantity</SM>
          </HeaderCell>

          <HeaderCell size="small" width="10%">
            <SM ellipsis>Availability</SM>
          </HeaderCell>

          <HeaderCell size="small" width="10%">
            <SM ellipsis>Need Date</SM>
          </HeaderCell>
          
        </HeaderRow>
      </Head>
      <Body>
        {sortedData.map((data, i) => (
          <Row key={i}>
            <Cell size="small" width="10%">
              <SM overflowTip ellipsis>{data.name}</SM>
            </Cell>
            <Cell size="small" width="10%">
              <SM overflowTip ellipsis>{data.category_name || '--'}</SM>
            </Cell>
            <Cell size="small" width="10%">
              <SM overflowTip ellipsis>{data.od || '--'}</SM>
            </Cell>
            <Cell size="small" width="10%">
              <SM overflowTip ellipsis>{data.weight_per_foot || '--'}</SM>
            </Cell>

            <Cell size="small" width="10%">
              <SM overflowTip ellipsis>{data.footage || '--'}</SM>
            </Cell>

            <Cell size="small" width="10%">
              <SM overflowTip ellipsis>{data.wall || '--'}</SM>
            </Cell>

            <Cell size="small" width="10%">
              <SM overflowTip ellipsis>{data.min_yield || '--'}</SM>
            </Cell>

            <Cell size="small" width="10%">
              <SM overflowTip ellipsis>{data.quantity || '--'}</SM>
            </Cell>
            <Cell size="small" width="10%">
              <SM overflowTip ellipsis>{data.availability || '--'}</SM>
            </Cell>
            <Cell size="small" width="10%">
              <SM  overflowTip ellipsis>{data.date || '--'}</SM>
            </Cell>
            
          </Row>
        ))}
      </Body>
    </Table>
  )
}





export default SidebarItemsTable

