import React from 'react'
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {get} from 'lodash'
import { Button } from 'theme/Button';
import {FormInput, Form, FormFooter} from 'theme/Form';
import { Message } from '@zendeskgarden/react-textfields';
import {signIn} from 'state/authentication/actions';

function formValidation(values) {
  let errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (get(values.password, 'length') < 5) {
    errors.password = 'Password must be atleast 6 characters.';
  }
  return errors;
}

const LoginForm = (props) => {
  
  async function submitLogin(values, { setSubmitting, setStatus }) {
    setSubmitting(true)
    const signInSuccess = await signIn(values);
    const error = signInSuccess.error
    if (error) {
      setStatus({error})
    }
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validate={formValidation}
      onSubmit={submitLogin}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status
      }) => {
        return (
          <Form size="large" onSubmit={handleSubmit}>
            <FormInput
              scaled={true}
              placeholder=''
              label="Email"
              type="email"
              name="email"
              autoComplete="current-email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              showMessage={true}
              error={(errors.email && touched.email && errors.email)}
              icon={'icon-profile'}
            />

            <FormInput
              scaled={true}
              placeholder=''
              label='Password'
              type='password'
              name="password"
              autoComplete="current-password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              showMessage={true}
              error={(errors.password && touched.password && errors.password)}
              icon={'icon-lock'}
            />

            <FormFooter>
              <Button link>
                <Link to="/forgot-password">
                  Forgot Password?
                </Link>              
              </Button>
            </FormFooter>

            <FormFooter>
              <Button
                size="large"
                type="submit"
                primary
                loading={isSubmitting}
                disabled={isSubmitting}>
                  Login
              </Button>
            </FormFooter>

            {
              status && status.error ?
              <FormFooter>
                <Message validation={"error"}>{status.error}</Message>
              </FormFooter> : null
            }
          </Form>
        )}
      }
    </Formik>
  )
};

export default LoginForm

