import React from 'react';
import MessageItem from 'components/MessageItem/MessageItem';
import {map} from 'lodash'
import { StyledMessageList } from './MessageList.style.js';

const MessageList = ({messages, ...rest}) => {
  return (
    <StyledMessageList {...rest}>
      {
        map(messages, (message, i) => {
          return <MessageItem key={i} message={message} {...rest} />
        })
      }
    </StyledMessageList>
  )
}

export default MessageList