import React, { useRef, useEffect, useState } from 'react';
import { canUseDOM } from 'exenv';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Routes from './routes';
import ModalRoutes from './modalRoutes.js';
import { renderRoutes } from 'react-router-config';
import { Switch, withRouter } from 'react-router-dom';
import ModalWrappers from 'components/ModalWrappers/ModalWrappers';
import AppHeader from 'components/AppHeader/AppHeader';
import AppHeaderSidebar from 'components/AppHeader/AppHeaderSidebar';
import PublicHeader from 'components/AppHeader/PublicHeader';
import PublicFooter from 'components/AppFooter/PublicFooter';
import AppFooter from 'components/AppFooter/AppFooter';
import { get, intersection } from 'lodash';
import { AppContainer, PageTransition } from './App.styles.js';
import { setRoutedModalIsAnimating } from 'state/application/actions';
import './app.css';
import { ParallaxProvider } from 'react-scroll-parallax';

const hiddenHeaderRoutes = [
  'page-login',
  'page-find-inventory',
  'page-cant-login',
  'page-cant-login-recover',
  'page-create-account-template',
  'page-login-template',
  'page-account-created',
  'page-reset-password',
  'page-reset-password-successful',
  'page-search-template',
  'page-compare-items',
];
const hiddenFooterRoutes = [
  'page-login',
  'page-find-inventory',
  'page-cant-login',
  'page-cant-login-recover',
  'page-create-account-template',
  'page-login-template',
  'page-account-created',
  'page-reset-password',
  'page-reset-password-successful',
  'page-search-template',
  'page-compare-items',
];
const sidebarRoutes = ['page-dashboard', 'page-settings'];
const publicRoutes = [
  'page-m',
  'page-landing',
  'page-services',
  'page-why-us',
  'page-company',
  'page-contact',
  'page-find-inventory-results',
  'page-view-item-details',
  'page-help-center',
  'page-help-center-article',
  'page-faq',
  'page-search-results',
  'page-terms',
  'page-sitemap',
  'page-blog',
  'page-blog-post',
];
const transparentHeaderRoutes = ['page-landing', 'page-blog-post'];
const App = (props) => {
  const transitionOverlay = useRef();
  function animateOut() {
    transitionOverlay.current.classList.add('active');
  }
  function animateIn() {
    window.scrollTo(0, 0);
    transitionOverlay.current.classList.remove('active');
  }

  const pageClasses = generatePageClass(props);
  const hiddenHeader = intersection(
    hiddenHeaderRoutes,
    pageClasses.split(' ')
  ).length;
  const hiddenFooter = intersection(
    hiddenFooterRoutes,
    pageClasses.split(' ')
  ).length;
  const sidebarHeader = intersection(
    sidebarRoutes,
    pageClasses.split(' ')
  ).length;
  const publicHeader = intersection(
    publicRoutes,
    pageClasses.split(' ')
  ).length;
  const publicFooter = intersection(
    publicRoutes,
    pageClasses.split(' ')
  ).length;
  const transparentHeader = intersection(
    transparentHeaderRoutes,
    pageClasses.split(' ')
  ).length;

  let path = props.location.pathname;
  const modalString = get(path.match(/(\/m\/)|(\/m$)/), '[0]');
  const overflowHidden = !!modalString;
  return (
    <div
      className={`App ${pageClasses} ${
        overflowHidden ? 'main-overflow-hidden' : ''
      }`}
    >
      <PageTransition>
        <div className='overlay' ref={transitionOverlay}>
          <div className='pane' />
          <div className='pane' />
          <div className='pane' />
          <div className='pane' />
        </div>

        {!hiddenHeader && sidebarHeader ? (
          <AppHeaderSidebar />
        ) : !hiddenHeader && publicHeader ? (
          <PublicHeader transparent={transparentHeader === 1} />
        ) : !hiddenHeader ? (
          <AppHeader />
        ) : null}

        <TransitionGroup className='transition-group'>
          <CSSTransition
            key={props.location.key}
            timeout={{
              enter: publicHeader ? 1000 : 0,
              exit: publicHeader ? 1000 : 0,
            }}
            onEnter={() => (publicHeader ? animateOut() : null)}
            onExited={() => animateIn()}
            classNames={publicHeader ? 'fade' : ''}
          >
            <div className='transition-container'>
              <ParallaxProvider>
                <AppContainer
                  hiddenHeader={hiddenHeader}
                  publicHeader={publicHeader}
                  transparentHeader={transparentHeader === 1}
                >
                  {!modalString || true ? (
                    <AppRoutes
                      appRoutes={Routes}
                      location={props.location}
                      modalString={modalString}
                    />
                  ) : null}
                  {canUseDOM ? (
                    <TransitionGroup>
                      <CSSTransition
                        in={true}
                        onEntered={() => setRoutedModalIsAnimating(false)}
                        onExited={() => setRoutedModalIsAnimating(false)}
                        onEnter={() => setRoutedModalIsAnimating(true)}
                        onExit={() => setRoutedModalIsAnimating(true)}
                        unmountOnExit={true}
                        appear={true}
                        key={`${!!modalString}` || `m`}
                        timeout={1700}
                        classNames='routed-modal'
                      >
                        <Switch location={props.location}>
                          {renderRoutes(ModalRoutes)}
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  ) : null}
                </AppContainer>
              </ParallaxProvider>

              {!hiddenFooter && publicFooter ? (
                <PublicFooter />
              ) : !hiddenFooter ? (
                <AppFooter />
              ) : null}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </PageTransition>
      <ModalWrappers />
    </div>
  );
};

const AppRoutesWrapper = ({ appRoutes, location }) => {
  return <Switch location={location}>{renderRoutes(appRoutes)}</Switch>;
};

const AppRoutes = React.memo(AppRoutesWrapper, (pp, np) => {
  return np.modalString;
});

function generatePageClass(props) {
  const path = get(props, 'location.pathname', '');
  return path === '/'
    ? 'page-landing'
    : path.replace(/\//g, ' page-').toLowerCase();
}

export default withRouter(App);
