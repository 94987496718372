import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import SearchSection from './components/SearchSection/SearchSection.js'
import Article from './components/Article/Article.js'


import {
  HelpCenterArticlePage
} from './HelpCenterArticle.styles';


const HelpCenterArticle = () => {
  return (
    <HelpCenterArticlePage style={{padding: `0`, overflow: `hidden`}}>
      <SearchSection />
      <Article />
    </HelpCenterArticlePage>
  )
}


export default PageViewWrapper(HelpCenterArticle, {pageName: 'HelpCenterArticle'})