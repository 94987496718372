import React from 'react';
import { XXXL, MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';

import {
  PasswordSection,
  Logo,
  ResetPasswordForm,
  FormFieldWrapper,
  BackgroundImg
} from '../../ResetPassword.styles';

const ResetForm = (props) => {
  return (
    <PasswordSection dark>
      <Flex justifyCenter>
        <FlexItem>
          <Logo src="/images/logo_white.svg" />
        </FlexItem>
      </Flex>
      <XXXL center tag="h1">Reset Your Password</XXXL>
      <MD center tag="p">Please create a new password to access your account.</MD>
      <Flex justifyCenter>
        <FlexItem>
          <ResetPasswordForm>
            <Grid>
              <Row>
                <Col md={12}>
                  <Flex justifyCenter>
                    <FlexItem>
                      <FormFieldWrapper>
                        <MD left tag="h5">New Password</MD>
                        <input type="text" id="name" name="name" required placeholder="Enter New Password"></input>
                      </FormFieldWrapper>
                    </FlexItem>
                  </Flex>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Flex justifyCenter>
                    <FlexItem>
                    <FormFieldWrapper style={{margin: `20px 0 20px 0`}}>
                      <MD left tag="h5">Confirm New Password</MD>
                      <input type="text" id="name" name="name" required placeholder="Confirm New Password"></input>
                    </FormFieldWrapper>
                    </FlexItem>
                  </Flex>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Flex justifyCenter>
                    <FlexItem>
                      <Button publicButton>Reset Password</Button>
                    </FlexItem>
                  </Flex>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <MD center tag="p"><a href="#">Cancel</a></MD>
                </Col>
              </Row>
            </Grid>
          </ResetPasswordForm>
        </FlexItem>
      </Flex>
      <BackgroundImg src="/images/hero-pipes.png" />
    </PasswordSection>

  )
}

export default ResetForm;