import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { Router } from 'react-router-dom';
import { canUseDOM } from 'exenv';
import { get } from 'lodash';
// import * as Sentry from '@sentry/browser';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import tree from './state';
import history from './historyContainer';
import App from './App';
import ErrorBoundry from 'components/ErrorBoundry/ErrorBoundry';
import ThemeProviderOverrides from 'theme/ThemeProviderOverrides/index';
import { ENVIRONMENT } from './constants';
import 'theme/variables';
import './index.css';
import './theme/global-build.css'

// import Store from 'store/store';
import { getToken, checkSession } from 'state/authentication/effects';
import Root from './Root';


// Sentry.init({
//  dsn: "https://6eec6aec614711e9b0674201c0a8d030@sentry.io/1441528",
//  environment: ENVIRONMENT,
// });


const OFFLINE_MODE = false;

const Provider = Root;
const AppBundle = (
  <Router history={history}>
    <ThemeProvider theme={ThemeProviderOverrides}>
      <Provider>
        <ErrorBoundry>
          <App />
        </ErrorBoundry>
      </Provider>
    </ThemeProvider>
  </Router>
);


function render() {
  let session = refreshSession()
  window.onload = async () => {
    session = await session;
    Loadable.preloadReady().then(() => {
      ReactDOM.hydrate(
        AppBundle,
        document.getElementById('root')
      );
    });
  };
}


async function refreshSession() {
  if (OFFLINE_MODE) {
    window.OFFLINE_MODE = true
    return Promise.resolve()
  }

  return new Promise(async (resolve) => {
    if (wasServerRendered()) {
      resolve()
    } else {
      try {
        const {csrftoken} = await getToken();
        tree.set(['authentication', 'csrftoken'], csrftoken);
        const session = await checkSession();
        if (get(session, 'success')) {
          tree.set(['authentication', 'session'], session);
        }
        resolve()
      } catch(err) {
        // IF ERROR REDIRECT (TODO)
        resolve() // REOLSOVE FOR NOW
        console.log(err)
      }
      
    }
  })
}

function wasServerRendered() {
  return !canUseDOM || (canUseDOM && window.__INITIAL_STATE__)
}


render()
// registerServiceWorker();

