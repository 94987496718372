import styled from 'styled-components';
import { Col, Grid } from '@zendeskgarden/react-grid';
import {variables} from 'theme/variables';
const {
  app_header_height,
  app_page_padding,
  media_sm,
  app_mobile_header_height,
  media_md,
  app_page_padding_medium,
  custom_blue,
  custom_background_color,
  sidebar_menu_width,
  sidebar_padding,
  app_page_padding_small
} = variables;


export const AppHeaderComponent = styled.header`
  position: fixed;
  z-index: 10;
  top: 0;
  height: ${app_mobile_header_height};
  right: 0;
  left: 0;
  background: ${custom_blue};
  display: flex;
  padding: 0;
  @media ${media_sm} {
    align-items: center;
    height: ${app_header_height};
    padding: 0 ${app_page_padding_medium};
  }
  @media ${media_md} {
    padding: 0 ${app_page_padding};
  }
`

export const HeaderGrid = styled(Grid)`
  @media ${media_sm} {
    padding: 0 !important;
  }
`;

export const AppHeaderComponentSidebar = styled(AppHeaderComponent)`
  background: ${custom_background_color};
  padding: 5px 8px 0;
  @media ${media_sm} {
    padding: 0;
  }
  @media ${media_md} {
    padding: 0;
  }
`;

export const Logo = styled('div')`
  background-image: url("${props => props.src}");
  background-size: contain;
  width: 100%;
  height: 35px;
  background-position: center left;
  background-repeat: no-repeat;
  cursor: pointer;
  @media ${media_md} {
    height: 40px;
  }
`;


export const MenuColumn = styled(Col)`
  display: none;
  @media ${media_sm} {
    display: block;
  }
`;

export const LogoColumn = styled(Col)`
  @media ${media_sm} {
    max-width: ${sidebar_menu_width} !important;
    padding: 0 ${sidebar_padding} !important;
  }
`;

export const ActionColumn = styled(Col)`
  @media ${media_sm} {
    padding: 0 ${app_page_padding_small} 0 0 !important;
  }
  @media ${media_md} {
    padding: 0 ${app_page_padding} 0 0 !important;
  }
`;

export const SearchWrapperCol = styled(Col)`
  max-width: 100%;
  @media ${media_sm} {
    max-width: 600px !important;
  }
`;