import React, { useState } from 'react';
import {useBranch} from 'baobab-react/hooks';
import {default as StyledModalComponent} from "forks/customModal";
import AllAlloyModal from './modals/AllAlloyModal/AllAlloyModal';

import {closeModal} from 'state/modals/actions';

const StyledModal = StyledModalComponent.styled`
  min-width: ${props => props.width || '200px'};
  min-height: ${props => props.height || '20rem'};
  height: auto;
  width: auto
  display: flex;
  align-items: flex-start;
  margin-top: 10rem;
  border-radius: 0.28571429rem;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
  opacity: ${props => props.opacity};
  transform: ${props => props.opacity ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20px, 0)'};
  transition: all ease 600ms 20ms;
`
const innerModalStyles = {          
  'position': 'relative',
  'top': 'auto',
  'bottom': 'auto',
  'left': 'auto',
  'right': 'auto',
  'boxShadow': 'none',
  'background': 'none',
}
 
const GlobalModals = (props) => {
  const [opacity, setOpacity] = useState(0);
  const {modals} = useBranch({
    modals: ['modals']
  });

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1)
    }, 100);
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0)
      setTimeout(resolve, 300);
    });
  }

  return (
    <StyledModal
      isOpen={modals.modalOpen}
      onBackgroundClick={closeModal}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onEscapeKeydown={closeModal}
      opacity={opacity}
      backgroundProps={{ opacity: opacity }}>
        {
          modals.modalType === 'allAlloyModal'?
          <AllAlloyModal {...modals.modalData} styles={innerModalStyles} /> : null
        }
    </StyledModal>
  )
}


export default GlobalModals;

