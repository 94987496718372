import React, { useLayoutEffect, useState } from 'react';
import { get, map } from 'lodash';
import useWindowSize from 'hooks/useWindowSize';
import {
  StyledOrderStatisBar,
  LineInner,
  LineWrapper,
  Line,
  Bubble,
  BubbleInner,
  BubbleInnerShade,
  BubbleInnerLine,
  Label,
  LabelWrapper
} from './OrderStatusBar.styles.js';


const OrderStatusBar = ({style, status, statuses, inverted, labelColor}) => {
  const windowSize = useWindowSize();
  const [elWidths, setWidths] = useState([]);
  
  useLayoutEffect(() => {
    const els = document.querySelectorAll('.status-el');
    const wrapperOffset = document.querySelector('.wrapper-el').offsetLeft;

    const widths = map(els, (e, i, arr) => {
      const last = i === arr.length - 1;
      const offsetLeft = e.offsetLeft - wrapperOffset
      return {
        width: e.offsetWidth,
        middle: (offsetLeft) + (last ? e.offsetWidth : (e.offsetWidth / 2)),
        last
      };
    });
    setWidths(widths);
  }, [windowSize]);

  const active = statuses.indexOf(status) || 0;
  const middle = get(elWidths, `[${active}].middle`, 0)
  const last = get(elWidths, `[${active}].last`, 0)
  return (
    <StyledOrderStatisBar status style={style}>
      <LineWrapper>
        <Line>
          <LineInner last={last} distance={middle}/>
        </Line>
        <Bubble inverted={inverted} last={last} distance={middle}>
          <BubbleInnerShade last={last}/>
          <BubbleInnerLine/>
          <BubbleInner/>
        </Bubble>
      </LineWrapper>

      <LabelWrapper className={`wrapper-el`}>
        {
          map(statuses, (status, i, arr) => {
            return <Label labelColor={labelColor} active={active === i} inverted={inverted} className={`status-el`} grey key={i}>{status.replace(/-/g, ' ')}</Label>
          })
        }
      </LabelWrapper>

    </StyledOrderStatisBar>
  );
}


export default OrderStatusBar