import styled from 'styled-components';
import { variables } from 'theme/variables';
const {
  spacing,
  custom_light_grey
} = variables;

export const StyledTaskItem = styled('div')`
  padding: ${props => props.padding || `${spacing} 0`};
  border-bottom: 1px solid ${custom_light_grey}
  &:first-child {
    padding-top: 0;
  }
`
