import React, { useState, useEffect } from 'react';
import { XXL, MD } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button'

import {
  MarginWrapper,
  DocumentationBg,
  SignUpButton
} from '../../ViewItemDetails.styles';

const DocumentationOverlay = (props) => {
  const [loggedIn, loginState] = useState("loggedOut")

  if(loggedIn === "loggedOut") {
    return (
      <DocumentationBg>
          <Flex justifyLeft alignCenter full>
            <FlexItem>
              <MarginWrapper>
                <MD center tag="h6" className="view-documentation" style={{fontSize: `24px`, lineHeight: `24px`, letterSpacing: `0.34px`, color: `#3E3E3E`}}>SignUp To View Documentation</MD>
                <Button publicButton className="view-documentation-button" onClick={() => loginState("loggedIn")} style={{ borderRadius: `4px`, margin: `0 0 0 4px`, width: `228px`, height: `63px`}}>Sign Up</Button>
              </MarginWrapper>
            </FlexItem>
          </Flex>
        </DocumentationBg>
    )
  } else
    return (
      <div>

      </div>
    )
}

export default DocumentationOverlay;