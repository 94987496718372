import React from 'react';
import {
  ExpandedRow,
  LowerText,
  UpperText,
  ItemCol,
  ExpandedIcon,
} from './ProjectActionItemExpandable.styles.js';


 
const ProjectActionItemExpandable = ({facilitator, referenceNum, items, submitDate, quoteDeadline, confidenceLevel, openItemsSidebar}) => { 
  return (
    <ExpandedRow>
      <ExpandedIcon />
      <ItemCol size="auto" extraPaddingRight>
        <UpperText grey>Facilitator</UpperText>
        <LowerText>{facilitator}</LowerText>
      </ItemCol>

      <ItemCol size="auto" extraPaddingRight>
        <UpperText grey>Reference #</UpperText>
        <LowerText>{referenceNum}</LowerText>
      </ItemCol>

      <ItemCol size="auto" extraPaddingRight>
        <UpperText grey>Items</UpperText>
        <LowerText link onClick={openItemsSidebar}>
          View
        </LowerText>
      </ItemCol>

      <ItemCol size="auto" extraPaddingRight>
        <UpperText grey>Submitted Date</UpperText>
        <LowerText>{submitDate}</LowerText>
      </ItemCol>

      <ItemCol size="auto" extraPaddingRight>
        <UpperText grey>Quote Deadline</UpperText>
        <LowerText>{quoteDeadline}</LowerText>
      </ItemCol>

      <ItemCol size="auto">
        <UpperText grey>Confidence Level</UpperText>
        <LowerText>{confidenceLevel}</LowerText>
      </ItemCol>
     
    </ExpandedRow>
  )
}


export default ProjectActionItemExpandable
