import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from 'components/Icon/Icon';
import { SM } from '@zendeskgarden/react-typography';
import {variables} from 'theme/variables';
const {
  custom_light_grey,
  spacing_sm,
} = variables;

const StyledAccordian = styled('div')`
  padding: ${spacing_sm} 0;
`

const StyledBody = styled('div')`
  padding: ${spacing_sm} 0;
`

const StyledHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${custom_light_grey};
  padding: ${spacing_sm} 0;
  cursor: pointer;
  user-select: none;
`

const Accordion = ({ title, children, onToggle, style={}, headerStyle={}, bodyStyle={} }) => {
  const [visibility, setVisibility] = useState(true);
  return (
    <StyledAccordian style={style}>
      <StyledHeader
        style={headerStyle}
        onClick={() => {
          setVisibility(!visibility);
          if (onToggle) onToggle(!visibility);
        }}>
          <SM uppercase>{title}</SM>
          {
            visibility ? 
            <Icon icon="icon-chevron-up" fontSize="12px" /> :
            <Icon icon="icon-chevron-down" fontSize="12px" />
          }
      </StyledHeader>
      {
        visibility ? 
          <StyledBody style={bodyStyle}>
            {children}
          </StyledBody> 
        : null
      }
    </StyledAccordian>
  );
};

export default Accordion;