import styled from 'styled-components';
import { Section } from '../GlobalPublicStyles';

export const GetStartedSection = styled(Section)`
  background-color: rgba(241,247,254,1);
  .bg {
    .parallax-outer {
      height: 100%;
      .parallax-inner {
        height: 100%;
      }
    }  
  }
`;
