import React from 'react';
import { Input } from '@zendeskgarden/react-textfields';
import {InputMediaWrapper, InputMediaIconWrapper} from 'theme/Form';
import Icon from 'components/Icon/Icon';


export const SearchInput = ({value, onSearch, placeholder, icon, iconColor, ...rest}) => (
  <InputMediaWrapper>
    <Input
      {...rest}
      hideOutline={true}
      icon={'true'}
      onChange={onSearch}
      value={value}
      placeholder={placeholder || ''}
    />
    <InputMediaIconWrapper>
      <Icon icon="icon-search-left" color={iconColor} />
    </InputMediaIconWrapper> 
  </InputMediaWrapper>
);

