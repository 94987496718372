import React from 'react';
import { XXXL, LG, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';

import {
  Section,
  Logo,
  CreateAccountForm,
  FormFieldWrapper,
  FormFieldWrapperName,
  FormFieldWrapperLastName,
  BackgroundImg,
} from '../../CreateAccount.styles';

const { spacing } = variables;

const Login = (props) => {
  return (
    <Section dark>
      <Flex justifyCenter>
        <FlexItem>
          <Logo src='/images/logo_white.svg' />
        </FlexItem>
      </Flex>
      <XXXL center tag='h1'>
        Welcome to Global Pipe Co.!
      </XXXL>
      <MD center tag='p'>
        To register for an account, please complete the information below.
      </MD>
      <Flex justifyCenter>
        <FlexItem>
          <Grid>
            <CreateAccountForm>
              <Row>
                <Col sm={12} md={6}>
                  <FormFieldWrapperName>
                    <MD left tag='h5'>
                      First Name
                    </MD>
                    <input
                      type='text'
                      id='first-name'
                      name='name'
                      required
                      placeholder='Enter First Name'
                    ></input>
                  </FormFieldWrapperName>
                </Col>
                <Col sm={12} md={6}>
                  <FormFieldWrapperLastName>
                    <MD left tag='h5'>
                      Last Name
                    </MD>
                    <input
                      type='text'
                      id='last-name'
                      name='name'
                      required
                      placeholder='Enter Last Name'
                    ></input>
                  </FormFieldWrapperLastName>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormFieldWrapper>
                    <MD left tag='h5'>
                      Work Email
                    </MD>
                    <input
                      type='email'
                      id='email'
                      name='name'
                      required
                      placeholder='Enter Email'
                    ></input>
                  </FormFieldWrapper>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormFieldWrapper>
                    <MD left tag='h5'>
                      Work Phone Number
                    </MD>
                    <input
                      type='tel'
                      id='tel'
                      name='phone'
                      pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                      required
                      placeholder='Enter Phone Number'
                    ></input>
                  </FormFieldWrapper>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormFieldWrapper>
                    <MD left tag='h5'>
                      Password
                    </MD>
                    <input
                      type='password'
                      id='password'
                      name='name'
                      required
                      placeholder='Enter Password'
                    ></input>
                  </FormFieldWrapper>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Flex justifyCenter>
                    <FlexItem>
                      <Button publicButton style={{ marginBottom: `30px` }}>
                        Create Account
                      </Button>
                    </FlexItem>
                  </Flex>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <MD center className='already' tag='p'>
                    Already have a Global Pipe Co. account?{' '}
                    <a href='#'>Login</a>{' '}
                  </MD>
                </Col>
              </Row>
            </CreateAccountForm>
          </Grid>
        </FlexItem>
      </Flex>
      <BackgroundImg src='/images/hero-pipes.png' />
    </Section>
  );
};

export default Login;
