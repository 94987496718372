import React from 'react';
import { MD } from '@zendeskgarden/react-typography';
import {
  CompareSection,
  CompareTableWrapper,
  CompareWrapper,
  AvailabilityWrapper,
  GetPricingButton,
  ConfidenceLarge,
  TableScrollWrapper,
  OverflowContainer
} from '../../CompareItems.styles.js';

import {
  Table,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@zendeskgarden/react-tables';

import CompareBlur from '../CompareBlur/CompareBlur';

const CompareTable = (props) => {
  return (
    <CompareSection>
          <CompareWrapper>
                <CompareTableWrapper>
                  <TableScrollWrapper>
                    <OverflowContainer>  
                    <Table className="compare-table" style={{minWidth: `1400px`, marginTop: `48px`, position: `relative`}}>
                    <CompareBlur />
                    <Head style={{ position: `relative`, paddingTop: `50px` }}>
                      <AvailabilityWrapper>
                        <MD left tag="p">Availability</MD>
                      </AvailabilityWrapper>
                        <HeaderRow className="table-header-row">
                          <HeaderCell width="19%">Item</HeaderCell>
                          <HeaderCell width="7%">Brand</HeaderCell>
                          <HeaderCell width="8%">Manufacturer</HeaderCell>
                          <HeaderCell width="7%">Min-Burst</HeaderCell>
                          <HeaderCell width="9%">Min-Collapse</HeaderCell>
                          <HeaderCell width="10%">Min T Strength</HeaderCell>
                          <HeaderCell width="5%">Drift</HeaderCell>
                          <HeaderCell width="7%">Min PREN</HeaderCell>
                          <HeaderCell width="7%">Quantity</HeaderCell>
                          <HeaderCell width="7%">Lead Time</HeaderCell>
                          <HeaderCell width="13%">Confidence</HeaderCell>
                        </HeaderRow>
                      </Head>
                      <Body>
                        <Row className="table-row">
                          <Cell width="19%"><a href="#">OCTG 2.375" 5.8# 22CR 130 VAM ACE</a></Cell>
                          <Cell width="7%">CRA-P</Cell>
                          <Cell width="8%">Sumitomo</Cell>
                          <Cell width="7%">10,000 PSI</Cell>
                          <Cell width="9%">7,000 PSI</Cell>
                          <Cell width="10%">16.45</Cell>
                          <Cell width="5%">9.404"</Cell>
                          <Cell width="7%">16.45</Cell>
                          <Cell width="7%">15,000</Cell>
                          <Cell width="7%">8-10 Weeks</Cell>
                          <Cell width="13%"><ConfidenceLarge style={{backgroundColor: `#38D79F`}}>High</ConfidenceLarge> <GetPricingButton>Get Pricing</GetPricingButton></Cell>
                        </Row>
                        <Row className="table-row">
                          <Cell width="19%"><a href="#">OCTG 2.375" 5.8# 22CR 130 VAM ACE</a></Cell>
                          <Cell width="7%">CRA-P33</Cell>
                          <Cell width="8%">Sumitomo</Cell>
                          <Cell width="7%">10,000 PSI</Cell>
                          <Cell width="9%">7,000 PSI</Cell>
                          <Cell width="10%">16.45</Cell>
                          <Cell width="5%">9.404"</Cell>
                          <Cell width="7%">16.45</Cell>
                          <Cell width="7%">15,000</Cell>
                          <Cell width="7%">8-10 Weeks</Cell>
                          <Cell width="13%"><ConfidenceLarge style={{backgroundColor: `#38D79F`}}>High</ConfidenceLarge><GetPricingButton>Get Pricing</GetPricingButton></Cell>
                        </Row>
                        <Row className="table-row">
                          <Cell width="19%"><a href="#">OCTG 2.375" 5.8# 22CR 130 VAM ACE</a></Cell>
                          <Cell width="7%">CRA-P8</Cell>
                          <Cell width="8%">Sumitomo</Cell>
                          <Cell width="7%">10,000 PSI</Cell>
                          <Cell width="9%">7,000 PSI</Cell>
                          <Cell width="10%">16.45</Cell>
                          <Cell width="5%">9.404"</Cell>
                          <Cell width="7%">16.45</Cell>
                          <Cell width="7%">15,000</Cell>
                          <Cell width="7%">8-10 Weeks</Cell>
                          <Cell width="13%"><ConfidenceLarge style={{backgroundColor: `#38D79F`}}>High</ConfidenceLarge><GetPricingButton>Get Pricing</GetPricingButton></Cell>
                        </Row>
                        <Row className="table-row">
                          <Cell width="19%"><a href="#">OCTG 2.375" 5.8# 22CR 130 VAM ACE</a></Cell>
                          <Cell width="7%">CRA-P69</Cell>
                          <Cell width="8%">Sumitomo</Cell>
                          <Cell width="7%">10,000 PSI</Cell>
                          <Cell width="9%">7,000 PSI</Cell>
                          <Cell width="10%">16.45</Cell>
                          <Cell width="5%">9.404"</Cell>
                          <Cell width="7%">16.45</Cell>
                          <Cell width="7%">15,000</Cell>
                          <Cell width="7%">8-10 Weeks</Cell>
                          <Cell width="13%"><ConfidenceLarge style={{backgroundColor: `#38D79F`}}>High</ConfidenceLarge><GetPricingButton>Get Pricing</GetPricingButton></Cell>
                        </Row>
                        <Row className="table-row">
                          <Cell width="19%"><a href="#">OCTG 2.375" 5.8# 22CR 130 VAM ACE</a></Cell>
                          <Cell width="7%">CRA-P65</Cell>
                          <Cell width="8%">Sumitomo</Cell>
                          <Cell width="7%">10,000 PSI</Cell>
                          <Cell width="9%">7,000 PSI</Cell>
                          <Cell width="10%">16.45</Cell>
                          <Cell width="5%">9.404"</Cell>
                          <Cell width="7%">16.45</Cell>
                          <Cell width="7%">15,000</Cell>
                          <Cell width="7%">8-10 Weeks</Cell>
                          <Cell width="13%"><ConfidenceLarge style={{backgroundColor: `#38D79F`}}>High</ConfidenceLarge><GetPricingButton>Get Pricing</GetPricingButton></Cell>
                        </Row>
                      </Body>
                    </Table>
                  </OverflowContainer>
                  </TableScrollWrapper>
              </CompareTableWrapper>
          </CompareWrapper>
    </CompareSection>
  )
}

export default CompareTable;