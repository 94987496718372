import React from 'react';
import TaskItem from './components/TaskItem/TaskItem';
import {map} from 'lodash'
import { StyledTasList } from './TaskList.style.js';

const TaskList = ({tasks, ...rest}) => {
  return (
    <StyledTasList {...rest}>
      {
        map(tasks, (task, i) => {
          return <TaskItem key={i} task={task} {...rest} />
        })
      }
    </StyledTasList>
  )
}

export default TaskList