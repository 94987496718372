import React from 'react';
import { XXXL, MD } from '@zendeskgarden/react-typography';

import Flex, { FlexItem } from 'styled-flex-component';
import Icon from 'components/Icon/Icon'
import {Button} from 'theme/Button'
import { HashLink as Link } from 'react-router-hash-link';

import {
  SearchSection,
  Logo,
  BackgroundCircle,
  SearchFieldWrapper,
  Close
} from '../SearchTemplate.styles';

import DividingLine from 'components/PublicPages/DividingLine';

import { variables } from 'theme/variables';
const {
  custom_button_blue
} = variables;

const Search = (props) => {
  return (
    <SearchSection dark>
      <Flex justifyLeft>
        <FlexItem>
          <Logo src="/images/logo_white.svg" />
        </FlexItem>
      </Flex>
      <Flex justifyRight>
        <FlexItem>
          <Link to="this.props.history.goBack()"><Close><Icon icon="icon-close" color={custom_button_blue} style={{position: `absolute`, top: `50%`, left: `50%`, fontSize: `16px`, fontWeight: `900`, margin: `-8px 0 0 -8px`}} /></Close></Link>
        </FlexItem>
      </Flex>
          <Flex justifyCenter>
            <FlexItem>
              <DividingLine light />
            </FlexItem>
          </Flex>
          <Flex justifyCenter>
            <FlexItem>
          <SearchFieldWrapper>
            <XXXL center className="search-header" tag="h1">Search</XXXL>
            <input type="text" name="name" placeholder="Search Topics"></input>
            <Icon icon="icon-search-left" color="#67778d" style={{position: `relative`, zIndex: `2`, top: `-40px`, fontSize: `22px`}} />
            <Flex justifyCenter>
              <FlexItem>
                <Button publicButton className="search-button">Search</Button>
              </FlexItem>
            </Flex>
          </SearchFieldWrapper>
        </FlexItem>
      </Flex>
      <BackgroundCircle className="left-circle"/>
      <BackgroundCircle className="right-circle"/>
    </SearchSection>

  )
}

export default Search;
