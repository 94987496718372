import React from 'react';
import DocumentItem from './components/DocumentItem/DocumentItem';
import {map} from 'lodash'
import { StyledDocumentList } from './DocumentList.style.js';

const TaskList = ({documents, ...rest}) => {
  return (
    <StyledDocumentList {...rest}>
      {
        map(documents, (d, i) => {
          return <DocumentItem key={i} document={d} {...rest} />
        })
      }
    </StyledDocumentList>
  )
}

export default TaskList