import styled from 'styled-components';
import { Grid } from '@zendeskgarden/react-grid';
import {variables} from 'theme/variables';
const {
  custom_dark_blue,
  color_white,
  media_sm,
  media_md,
  public_font_size_md,
  public_font_weight_bold,
  footer_width,
  public_font_size_sm,
  public_line_height_lg,
  copyright_color,
  public_font_size_xs,
  custom_button_blue
} = variables;

export const PublicFooterComponent = styled.footer`
  background-color: ${custom_dark_blue};
  padding: 56px 0px 28px;

  @media ${media_md} {
    padding: 124px 0px 28px;
  }
`;

export const FooterGrid = styled(Grid)`
  max-width: ${footer_width};
`;

export const FooterMenu = styled.div`
  margin: 0px 12px 50px;
  @media ${media_md} {
    margin: 0px 0px 50px;
  }

  .title {
    font-size: ${public_font_size_md};
    font-weight: ${public_font_weight_bold};
    letter-spacing: 1px;
    margin-bottom: 16px;
    color: ${color_white};
    text-align: left;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    

    li {
      margin: 8px 0px;
      
      a {
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: ${public_font_size_sm};
        letter-spacing: 1.13px;
        line-height: ${public_line_height_lg};
        display: block;
        transition: all 0.2s ease-out;
        &:hover, &:focus {
          text-decoration: none;
        }

        &:hover {
          transform: translateX(10px);
          color: ${custom_button_blue} !important;
        }
      }
    }
  }

`;

export const Copyright = styled.div`
  font-size: ${public_font_size_xs};
  text-align: center;
  color: ${copyright_color};
  letter-spacing: 0.96px;
  margin: 56px 0px 0px;


  

  @media ${media_md} {
    margin: 108px 0px 0px;
  }
  
  .mobile-br {
    display: block;

    @media ${media_md} {
      display: none;
    }
  }

  .crafted {
    display: block;
    a {
      color: rgba(255, 255, 255, 0.5) !important;
      text-decoration: none;
      transition: color 0.2s linear;
      &:hover, &:focus, &:active {
        color: ${custom_button_blue} !important;
        text-decoration: none;
      }
    }
    
    @media ${media_md} {
      display: inline;
      &:before {
        content: '| ';
      }
    }
  }
`;

export const Logo = styled.div`
  max-width: 165px;
  width: 100%;
  margin: 0 auto 40px;

  @media ${media_md} {
    margin: 0 auto 74px;
  }

  img {
    width: 100%;
    height: auto;
  }

`;
