import styled from 'styled-components';
import { variables } from 'theme/variables';
const {
  custom_dark_blue,
  color_white,
  custom_button_blue,
  custom_pale_blue,
  public_font_size_sm,
  custom_dark_grey,
  media_lg,
  public_font_family,
  media_md
} = variables;

export const PublicHeaderComponent = styled.header`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 85px;
  background: ${custom_dark_blue};
  font-family: ${public_font_family};

  ${props => props.transparent ? `
    background: transparent;
  ` : ''}

  .header-inner {
    max-width: 1280px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 3;
    padding: 0px 12px;
  }

  .header-grid, .header-row {
    height: 100%;
  }

  ${props => props.mmActive ? `
    .header-inner {
      // background-color: ${custom_dark_blue};
    }      
    ` : ''}

  transition: all 0.2s linear;

  ${props => props.scrolled ? `
    background: ${color_white};
    box-shadow: 0 20px 20px -12px rgba(20,29,47,0.08);
    height: 75px !important;

    .menu {
      .inner > a, .inner > span {
        color: ${custom_pale_blue} !important;

        &:hover {
          color: ${custom_button_blue} !important;
        }

        &:after {
          background: #EBECEE;
        }
      }
    }

    .header-button {
      background-color: ${custom_button_blue};
      color: ${color_white};
      transform: scale(0.8);
    }
  `: ''}

  ${props => props.scrollDirection ? `
    transform: translateY(-100%);
  
  ` : ''}

  

  .header-button {
    transition: transform 0.1s linear;
  }

  .primary-menu-col, .secondary-menu-col {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .logo-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @media ${media_md} {
    height: 124px;
    .secondary-menu-col {
      display: flex;
    }
  }


  @media ${media_lg} {
    .primary-menu-col {
      display: flex;
      
    }
    .logo-col {
      align-items: center;
    }
  }
`;

export const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.menu-1 {
    li a, li span {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 84%;
        height: 10px;
        background: ${custom_button_blue};
        left: 0;
        right: 0;
        top: 50%;
        z-index: -1;
        margin: 0 auto;
        transform: scaleY(0);
        transition: transform 0.2s linear;
        transform-origin: center bottom;
      }
      &:hover:after {
        transform: scaleY(1);
      }
    }
  }


  &.animate-menu {
    li {
      .inner {
        overflow: hidden;
        a, span {
          display: block;
          transform: translateY(40px);
          will-change: transform;
        }
      }
      
      
      
    }
  }
`;

export const MenuItem = styled.li`
  color: ${color_white};
  
  position: relative;
  flex-shrink: 0;
  a, span {
    padding: 16px 12px;
    color: ${color_white} !important;
    font-weight: bold;
    font-size: ${public_font_size_sm};
    transition: color 0.2s linear;
    text-decoration: none;
    position: relative;
    cursor: pointer;

    [class^="icon-"]:before {
      cursor: pointer;
    }

    &:hover, &:focus {
      color: ${color_white} !important;
      text-decoration: none;
      &:after {
        
      }
    }
  }

  ${props => props.hasDropdown ? `
    &:hover {
      
      div {
        opacity: 1;
        transform: translateY(0px);
        pointer-events: all;
      }
    }
  ` : ''};
`;

export const HeaderRight = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  Button {
    background-color: ${color_white};
    color: ${custom_button_blue};
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  left: -50px;
  right: -50px;
  max-width: 200px;
  margin: 0 auto;
  width: 200px;
  box-shadow: 1px -2px 17px rgba(13,43,83,0.15);
  pointer-events: none;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    right: 0;
    left: 0;
    margin: 0 auto;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    background-color: ${color_white};
    transform: rotate(45deg);
  }

  ul {
    overflow: hidden;
    background: ${color_white};
    border-radius: 4px;
    > li {
      a {
        color: ${custom_dark_grey} !important;
        line-height: 18px;
        padding: 20px;
        display: block;
        transition: background-color 0.2s linear, color 0.2s linear;
        &:hover {
          background-color: ${custom_button_blue};
        }
      }
    }
  }

  
  opacity: 0;
  transform: translateY(50px);
  transition: 0.2s linear;
`;

export const Logo = styled('div')`
  max-width: 186px;
  opacity: 0;
  will-change: opacity;

  svg {
    width: auto;
    height: 32px;

    @media ${media_md} {
      height: auto;
      width: 100%;
    }
  }
`;


export const MobileMenuTrigger = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 24px;
  width: 24px;
  height: 20px;
  cursor: pointer;
  display: block;
  z-index: 4;

  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: -20px;
    left: -20px;
    bottom: -20px;
    right: -20px;
    margin: auto;
    background: white;
    transform: scale(0);
    transition: transform 0.3s cubic-bezier(0.220, 0.265, 0.085, 0.980);
    will-change: transform;

  }


  span {
    width: 100%;
    height: 2px;
    background-color: ${props => props.scrolled ? custom_button_blue : color_white};
    position: absolute;
    border-radius: 2px;
    right: 0;
    transition: all 0.2s linear;
    z-index: 2;
    opacity: 0;
    will-change: transform, opacity, width;


    &:nth-child(1) {
      top: 0;
      transform-origin: 0% 50%;
    }
    &:nth-child(2) {
      top: 0;
      bottom: 0;
      margin: auto;
      width: calc(100% - 3px);
      z-index: 1;

      
      
    }
    &:nth-child(3) {
      bottom: 0;
      width: calc(100% - 6px);
      transform-origin: 100% 50%;
    }
  }

  ${props => props.active ? `
    &:before {
      transform: scale(1);
    }
    span {
      &:nth-child(1) {
        width: 14px;
        background: ${custom_button_blue};
        transform: translateX(-3px) translateY(14px) rotate(-45deg);
      }
      &:nth-child(2) {
        width: 0px;
        
      }
      &:nth-child(3) {
        width: 14px;
        background: ${custom_button_blue};
        transform: translateX(-7px) translateY(-4px) rotate(45deg);
        
      }
    }
  ` : ''}

  @media ${media_lg} {
    display: none;
  }
`;



