import React, { useState } from 'react';
import history from 'historyContainer';
import Flex from 'styled-flex-component';
import { MD } from '@zendeskgarden/react-typography';
import { Dropdown, Trigger, Menu, Item } from '@zendeskgarden/react-dropdowns';
import { Button } from 'theme/Button';
import {variables} from 'theme/variables';
import Icon from 'components/Icon/Icon';

const {
  line_height_xxxl,
  spacing_md,
  spacing_sm
} = variables;

const AccountMenuDropdown = ({logout}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <Dropdown 
      style={{width: '200px'}}
      arrow={false}
      placement="bottom-end"
      onStateChange={changes => {
        if (changes.isOpen !== menuIsOpen && Object.prototype.hasOwnProperty.call(changes, 'isOpen')) {
          setMenuIsOpen(changes.isOpen);
        }
      }}>
      <Trigger>
        <div>
          <Button whiteButtonLink black bold>
            <Flex alignCenter style={{height: line_height_xxxl, position: 'relative', marginRight: 0}}>
              <MD>Hi, John Smith</MD>
              <span style={{paddingLeft: 7, paddingTop: 2}}>
                 <Icon icon={'icon-chevron-down'} fontSize={'10px'}/>
              </span>
            </Flex>
          </Button>
        </div>
      </Trigger>
      {
        !menuIsOpen ? null :
        <Menu placement="bottom" arrow>
          <Item hoverColorLight bold value="dashboard" onClick={() => history.push('/dashboard')}>
            <AccountMenuDropdownItem 
              icon={'icon-menu'}
              title="Dashboard" />
          </Item>

          <Item hoverColorLight bold value="settings" onClick={() => history.push('/settings')}>
            <AccountMenuDropdownItem 
              icon={'icon-settings-v'}
              title="Settings" />
          </Item>

          <Item hoverColorLight bold value="logout" onClick={logout}>
            <AccountMenuDropdownItem           
              icon={'icon-exit'}
              title="Logout" />
          </Item>
        </Menu>
      }
    </Dropdown>
  )
}


const AccountMenuDropdownItem = ({icon, title}) => {
  return (  
    <Flex alignCenter>
      <span style={{paddingLeft: spacing_sm, paddingRight: spacing_md, paddingTop: 2}}>
        <Icon icon={icon} fontSize={'15x'}/>
      </span>
      <MD>{title}</MD>
    </Flex>
  )
}


export default AccountMenuDropdown;