import styled, {keyframes} from 'styled-components';
import {variables} from 'theme/variables';

const {
  app_header_height,
  app_footer_height,
  app_mobile_header_height,
  app_mobile_footer_height,
  media_sm,
  media_md,
  custom_dark_blue,
  custom_blue
} = variables;

export const AppContainer = styled.section`
  padding-top: ${props => props.hiddenHeader ? '0px' : (props => props.publicHeader ? (props => props.transparentHeader ? '0px' : '100px') : app_mobile_header_height)};
  min-height: ${props => props.publicHeader ? `calc(100vh - 100px)` : `calc(100vh - ${app_mobile_footer_height})`};
  background-color: ${props => props.publicHeader ? custom_dark_blue : ''};
  @media ${media_md} {
    min-height: ${props => props.publicHeader ? `calc(100vh - 100px)` : `calc(100vh - ${app_footer_height})`};
  }
  @media ${media_sm} {
    padding-top: ${props => props.hiddenHeader ? '0px' : (props => props.publicHeader ? (props => props.transparentHeader ? '0px' : '100px') : app_header_height)};
  }  
`

export const PageTransition = styled.div`
  



    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 21;
      pointer-events: none;

      .pane {
        width: 100%;
        height: 25%;
        position: relative;
        background-color: ${custom_blue};
        transform: scaleY(1.1) scaleX(0);
        transition: transform 0.3s cubic-bezier(.25,.1,.25,1);
        transform-origin: center left;
        // opacity: 0.3;
        will-change: transform;

        &:nth-child(1) {
          transition-delay: 0.2s;
        }
        &:nth-child(2) {
          transition-delay: 0.3s;
        }
        &:nth-child(3) {
          transition-delay: 0.4s;
        }
        &:nth-child(4) {
          transition-delay: 0.5s;
        }
      }
      &.active {
        .pane {
          transform: scaleY(1.1) scaleX(1);
        }
      }
    }

    
  
      
      .fade-enter {
        opacity: 0.01;
      }
    
      .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
      }
    
      .fade-exit {
        opacity: 1;
      }
    
      .fade-exit.fade-exit-active {
        opacity: 0;
        transition: opacity 300ms ease-in 2000ms;
      }
      
        
      
        
      
      div.transition-group {
        position: relative;
      }
      .transition-container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
  
`;

export const FullModalWrapper = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 20;
`

export const ModalScrollContainer = styled.section`
  overflow-y: scroll;
  overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
