import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import SearchSection from './components/SearchSection/SearchSection.js'
import Results from './components/ResultsSection/Results.js'



import {
  SearchResultsPage
} from './SearchResults.styles';


const SearchResults = () => {

  return (
    <SearchResultsPage style={{padding: `0`, overflow: `hidden`}}>
      <SearchSection />
      <Results />
    </SearchResultsPage>
  )
}


export default PageViewWrapper(SearchResults, {pageName: 'SearchResults'})