import React from 'react';
import Icon from 'components/Icon/Icon';
import { Button as ZendeskButton } from '@zendeskgarden/react-buttons';
import {variables} from './variables';
import Spinner from 'components/Spinner/Spinner'
import Flex, { FlexItem } from 'styled-flex-component';

const {spacing_xs, color_grey_600, line_height_xxl, color_white, custom_blue} = variables

export const Button = ({loading, children, solo, alignCenter, justifyCenter, iconBefore, iconAfter, iconSize, iconColor, publicButton,  ...rest}) => (
  <ZendeskButton {...rest} publicButton={publicButton}>
    { publicButton ? <Icon color={color_white} icon="icon-arrow-right-short" /> : null }
    <Flex alignCenter justifyCenter style={{marginLeft: `0px`}}>
      {
        loading ?
        <FlexItem style={{paddingRight: `${spacing_xs}`}}>
          <Flex style={{minHeight: rest.size === 'large' ? line_height_xxl : line_height_xxl}} alignCenter justifyCenter>
            <Spinner color={rest.link ? (rest.color || custom_blue) : color_white} size={'10px'} />
          </Flex>
        </FlexItem> : null
      }
      {
        iconBefore ?
        <Icon color={iconColor} fontSize={iconSize || '15px'} style={{paddingRight: `${spacing_xs}`}} icon={iconBefore} /> : null
      }
      {
        (loading && !solo) || !loading ?
        children : null
      }
      {
        iconAfter ?
        <Icon color={iconColor}fontSize={iconSize || '15px'} style={{paddingLeft: `${spacing_xs}`}} icon={iconAfter} /> : null
      }
    </Flex>
  </ZendeskButton>
);

