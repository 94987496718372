import React from 'react';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

import GetPricingSection from './components/GetPricingSection/GetPricingSection'
import InformationSection from './components/InformationSection/InformationSection'

import { ViewItemDetailsPage } from './ViewItemDetails.styles'

const ViewItemDetails = (props) => {

  return (
    <ViewItemDetailsPage style={{ padding: `0`, overflow: `hidden` }}>
      <GetPricingSection />
      <InformationSection />
    </ViewItemDetailsPage>
  )
}

export default PageViewWrapper(ViewItemDetails, { pageName: 'ViewItemDetails' });