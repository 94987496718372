import React from 'react';
import styled, {keyframes} from 'styled-components';
import {variables} from 'theme/variables';
const {
  color_blue_400,
  spinner_duration
} = variables;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerStyle = styled('div')`
  margin: 0px auto;
  font-size: ${props => props.size || '10px'};
  position: relative;
  border-top: 0.2em solid ${props => props.background || 'rgba(0, 0, 0, 0.1)'};
  border-right: 0.2em solid ${props => props.background || 'rgba(0, 0, 0, 0.1)'};
  border-bottom: 0.2em solid ${props => props.background || 'rgba(0, 0, 0, 0.1)'};
  border-left: 0.2em solid ${props => props.color || color_blue_400};
  transform: translateZ(0);
  animation: ${rotate} ${props => props.duration || spinner_duration} infinite linear;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  &:after {
    border-radius: 50%;
    width: 2em;
    height: 2em;
  }
`
 
const Spinner = (props) => {  
  return (
    <SpinnerStyle {...props} className="loader-spinner">
      {props.children}
    </SpinnerStyle>
  )
}


export default Spinner
